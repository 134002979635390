import { FC, useState } from 'react';
import { Controller, ControllerProps, FormState } from 'react-hook-form';
import { View, ViewFilled } from '@carbon/icons-react';
import { IconButton, InputAdornment } from '@mui/material';

import { TextField } from 'src/components';
import { stylesMyProfile } from '../styles';
import { UserProfileDetails } from './index';

interface TextInputFieldProps {
  formState: FormState<UserProfileDetails>;
  label: string;
  isPasswordInput: boolean;
  name: string;
}

export const TextInputField: FC<
  TextInputFieldProps & Pick<ControllerProps<UserProfileDetails>, 'control'>
> = ({ control, formState, label, isPasswordInput, name }) => {
  const [showPassword, setShowPassword] = useState(true);

  const toggleShowPassword = () => setShowPassword((show) => !show);

  return (
    <Controller
      name={name as keyof UserProfileDetails}
      control={control}
      render={({ field }) => (
        <TextField
          {...field}
          autoComplete='off'
          fullWidth
          name={name}
          label={label}
          type={isPasswordInput && showPassword ? 'password' : 'text'}
          sx={stylesMyProfile.textFieldWrapper}
          InputProps={
            isPasswordInput
              ? {
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={toggleShowPassword}
                        edge='end'
                      >
                        {showPassword ? (
                          <ViewFilled style={stylesMyProfile.passwordIcon} />
                        ) : (
                          <View style={stylesMyProfile.passwordIcon} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }
              : {}
          }
          error={
            !!formState.errors &&
            (formState.errors as Record<keyof FormData, { message: string }>)?.[
              name as keyof FormData
            ]?.message
          }
        />
      )}
    />
  );
};
