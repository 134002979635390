import React, { useContext, useState } from 'react';
import { Box, Typography, Collapse } from '@mui/material';
import { RulesEngineContext } from 'src/context';
import { styles } from 'src/views/Rules/RuleDetails/styles';
import { ChevronDown, ChevronUp } from '@carbon/icons-react';

export const SelectedNetworksAndEquipments = () => {
  const { selectedAPNs, selectedEquipments } = useContext(RulesEngineContext);
  const [expandedNetworks, setExpandedNetworks] = useState(false);
  const [expandedEquipments, setExpandedEquipments] = useState(false);

  const handleShowAllNetworks = () => {
    setExpandedNetworks(!expandedNetworks);
  };

  const handleShowAllEquipments = () => {
    setExpandedEquipments(!expandedEquipments);
  };
  return (
    <Box width='50%'>
      <Typography variant='h2' mb='27px'>
        Equipment concerned
      </Typography>
      <Box mb='15px'>
        <Typography variant='h4' mb='5px'>
          Networks
        </Typography>
        {selectedAPNs.slice(0, 4).map(({ name }) => (
          <Typography key={name}>{name}</Typography>
        ))}
        {selectedAPNs.length > 4 && (
          <Box>
            <Collapse in={expandedNetworks}>
              {selectedAPNs.slice(4).map(({ name }) => (
                <Typography key={name}>{name}</Typography>
              ))}
            </Collapse>
            <Box onClick={handleShowAllNetworks} sx={styles.boxShow}>
              <Typography sx={styles.descriptionExpandLink}>
                {expandedNetworks ? 'Show less' : 'Show more'}
              </Typography>
              {expandedNetworks ? (
                <ChevronUp style={styles.showMoreIcon} />
              ) : (
                <ChevronDown style={styles.showMoreIcon} />
              )}
            </Box>
          </Box>
        )}
      </Box>
      <Typography variant='h4' mb='5px'>
        Equipments
      </Typography>
      {selectedEquipments.slice(0, 4).map(({ id, name }) => (
        <Typography key={id}>{name}</Typography>
      ))}
      {selectedEquipments.length > 4 && (
        <Box>
          <Collapse in={expandedEquipments}>
            {selectedEquipments.slice(4).map(({ id, name }) => (
              <Typography key={id}>{name}</Typography>
            ))}
          </Collapse>
          <Box onClick={handleShowAllEquipments} sx={styles.boxShow}>
            <Typography sx={styles.descriptionExpandLink}>
              {expandedEquipments ? 'Show less' : 'Show more'}
            </Typography>
            {expandedEquipments ? (
              <ChevronUp style={styles.showMoreIcon} />
            ) : (
              <ChevronDown style={styles.showMoreIcon} />
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};
