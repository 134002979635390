import { useContext, useEffect, useState } from 'react';
import { Box, ButtonBase, Typography, Button } from '@mui/material';
import {
  CloudOutlined,
  AccessAlarmOutlined,
  TimelineOutlined,
} from '@mui/icons-material';

import { StyleButtonContentContainer, styles } from './styles';
import { KpiWidget } from './KpiWidget';
import { TimeWidget } from './TimeWidget';
import { AddWidgetContext } from 'src/context/AddWidgetContext';
import { WidgetType } from 'src/enums/widget';
import { KPIWidget } from 'src/types/widget';
import { WidgetFieldsContainer } from './WidgetFieldsContainer';

const widgetTypes = [
  // {
  //   title: 'Time',
  //   type: WidgetType.TIME,
  //   icon: <AccessTimeOutlined sx={styles.widgetIcon} />,
  // },
  {
    title: 'Weather',
    type: WidgetType.WEATHER,
    icon: <CloudOutlined sx={styles.widgetIcon} />,
  },
  {
    title: 'Alarm',
    type: WidgetType.ALARM,
    icon: <AccessAlarmOutlined sx={styles.widgetIcon} />,
  },
  {
    title: 'KPI',
    type: WidgetType.KPI,
    icon: <TimelineOutlined sx={styles.widgetIcon} />,
  },
];

interface WidgetTypesProps {
  disableSubmit: boolean;
  onAddWidget: (data: Partial<KPIWidget>) => void;
  onClose: () => void;
}

export const WidgetTypes: React.FC<WidgetTypesProps> = ({
  disableSubmit,
  onAddWidget,
  onClose,
}) => {
  const { widget, updateWidget } = useContext(AddWidgetContext);

  const [displayWidgetView, setDisplayWidgetView] = useState(false);

  const renderWidget = () => {
    switch (widget?.widgetType) {
      case 'TIME':
        return <TimeWidget goBack={handleGoBack} />;
      case 'KPI':
        return (
          <KpiWidget
            disableSubmit={disableSubmit}
            goBack={handleGoBack}
            onAddWidget={onAddWidget}
          />
        );
      case 'WEATHER':
        return (
          <KpiWidget
            disableSubmit={disableSubmit}
            goBack={handleGoBack}
            onAddWidget={onAddWidget}
          />
        );
      case 'ALARM':
        return (
          <KpiWidget
            disableSubmit={disableSubmit}
            goBack={handleGoBack}
            onAddWidget={onAddWidget}
          />
        );
      default:
        return onClose();
    }
  };

  const handleClose = () => {
    onClose();
  };

  const handleGoBack = () => {
    setDisplayWidgetView(false);
  };

  useEffect(() => {
    setDisplayWidgetView(false);
  }, []);

  return (
    <>
      {!displayWidgetView ? (
        <WidgetFieldsContainer
          isFlex
          title="Select Widget"
          actions={
            <>
              <Button onClick={handleClose}>Cancel</Button>
              <Button
                variant="contained"
                onClick={() => setDisplayWidgetView(true)}
                disabled={!widget?.widgetType}
              >
                Next Step
              </Button>
            </>
          }
        >
          <Box display="flex" justifyContent="center">
            {widgetTypes.map((widgetType) => (
              <ButtonBase
                sx={styles.widgetTypeButtonContainer}
                key={widgetType.type}
                onClick={() =>
                  updateWidget({
                    label: '',
                    org: '',
                    metricsLevel: '',
                    apn: '',
                    imsi: '',
                    site: '',
                    vlan: '',
                    node: '',
                    metricName: '',
                    type: undefined,
                    widgetType: widgetType.type,
                  })
                }
              >
                <StyleButtonContentContainer
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  active={widgetType.type === widget?.widgetType}
                >
                  {widgetType.icon}
                  <Typography variant="body1">{widgetType.title}</Typography>
                </StyleButtonContentContainer>
              </ButtonBase>
            ))}
          </Box>
        </WidgetFieldsContainer>
      ) : (
        renderWidget()
      )}
    </>
  );
};
