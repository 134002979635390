import { yupResolver } from '@hookform/resolvers/yup';
import { Close } from '@mui/icons-material';
import {
  DialogContent,
  DialogActions,
  Box,
  DialogTitle,
  IconButton,
  MenuItem,
  ListItemText,
} from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import React, { useContext, useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  addUEsToGroup,
  AddUEToGroupData,
  createUEGroup,
  CreateUEGroupData,
  getAllUEGroups,
} from 'src/apis/ue';
import { Button, Loading, Select, TextField } from 'src/components';
import { AppContext, AuthContext, ToastContext } from 'src/context';
import { QueryName } from 'src/enums/query';
import { Device } from 'src/types/device';
import { UEGroup } from 'src/types/domain';
import { createGroupSchema } from 'src/yup-validations/addGroup';
import { StyledDialog, styles } from './styles';

interface GroupModelProps {
  open: boolean;
  onCancel: () => void;
  onSave: () => void;
  selectedDevices: Device[];
}

type GroupInputs = {
  group_selected: string;
  group_name: string;
  group_site: string;
  group_ues: string[];
};

interface groupListItem {
  name: string;
  id: string;
}

export const GroupModal: React.FC<GroupModelProps> = ({
  onCancel,
  onSave,
  open,
  selectedDevices,
}) => {
  /* Creating an array of ueids from the selectedDevices array. */
  const uesArray = selectedDevices.map((device: Device) => device.ueid);

  const { user } = useContext(AuthContext);
  const { showToast } = useContext(ToastContext);
  const { site } = useContext(AppContext);
  const [group, selectGroup] = useState<string>();
  const [groupListAvailable, setGroupListAvailable] = useState<groupListItem[]>(
    [],
  );
  const [isGroupSelectionDisabled, setIsGroupSelectionDisabled] =
    useState<boolean>(true);
  const [isGroupNameHidden, setIsGroupNameHidden] = useState<boolean>(true);

  const organization = user?.organizations?.[0] as string;

  const { data: equipmentsGroupsData, isLoading: equipmentsGroupsLoading } =
    useQuery(
      [QueryName.GET_ALL_UE_GROUPS, site],
      () =>
        getAllUEGroups({
          organization,
          site,
        }),
      { enabled: !!organization && !!site },
    );

  const availableGroups = equipmentsGroupsData?.data;

  const { control, formState, handleSubmit, reset } = useForm({
    defaultValues: {
      group_selected: '',
      group_name: '',
      group_site: site,
      group_ues: uesArray,
    },
    resolver: yupResolver(createGroupSchema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const handleSelectGroup = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === '0') {
      setIsGroupNameHidden(false);
    } else {
      setIsGroupNameHidden(true);
    }
    selectGroup(event.target.value);
  };

  const startGroupList = () => {
    setIsGroupSelectionDisabled(true);
    setGroupListAvailable([]);
    const defaultGroup: groupListItem = {
      name: 'New group',
      id: '0',
    };
    setGroupListAvailable([defaultGroup]);
  };

  const fillGroupList = () => {
    availableGroups?.groups.map((group: UEGroup) => {
      const groupListItem: groupListItem = {
        name: group.groupName,
        id: group.id,
      };
      setGroupListAvailable((prev) => [...prev, groupListItem]);
      return false;
    });
    setIsGroupSelectionDisabled(false);
  };

  useEffect(() => {
    if (site) {
      reset({
        group_selected: '',
        group_name: '',
        group_site: site,
        group_ues: uesArray,
      });
    }
    if (group) {
      reset({
        group_selected: group,
        group_name: '',
        group_site: site,
        group_ues: uesArray,
      });
    }
    if (availableGroups) {
      startGroupList();
      fillGroupList();
    }
  }, [availableGroups, site, group]);

  const addUEsToGroupMutation = useMutation<
    AxiosResponse,
    AxiosError,
    AddUEToGroupData
  >({
    mutationFn: addUEsToGroup,
    onSuccess: () => {
      showToast('UEs added to the group', 'success');
      reset({
        group_name: '',
        group_selected: '',
        group_site: site,
        group_ues: uesArray,
      });
      onSave();
    },
    onError: () => {
      showToast('Failed to add UEs to a group, Please try again', 'error');
    },
  });

  const handleCancel = () => {
    onCancel();
    reset();
  };

  const createGroupMutation = useMutation<
    AxiosResponse,
    AxiosError,
    CreateUEGroupData
  >({
    mutationFn: createUEGroup,
    onSuccess: ({ data: { site, id } }) => {
      const addUEsToGroupData: AddUEToGroupData = {
        id,
        ues: uesArray,
        organization,
        site,
      };
      addUEsToGroupMutation.mutate(addUEsToGroupData);
    },
    onError: () => {
      showToast('Failed to create a group, Please try again', 'error');
    },
  });

  const handleSave = (data: GroupInputs) => {
    if (data.group_selected === '0') {
      const createGroupData: CreateUEGroupData = {
        groupName: data.group_name,
        site,
        organization,
      };
      createGroupMutation.mutate(createGroupData);
    } else {
      const addUEsToGroupData: AddUEToGroupData = {
        id: data.group_selected,
        ues: data.group_ues,
        organization,
        site,
      };
      addUEsToGroupMutation.mutate(addUEsToGroupData);
    }
  };
  return (
    <StyledDialog open={open}>
      <DialogTitle>
        Group equipments
        <IconButton sx={styles.closeIconButton} onClick={onCancel}>
          <Close fontSize='medium' />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box>
          <ul>
            {selectedDevices.map((device) => (
              <li key={device.ueid}>{device.name}</li>
            ))}
          </ul>
        </Box>
        <Loading isLoading={equipmentsGroupsLoading}>
          <Box sx={{ mt: '4px', mb: '12px' }}>
            <Controller
              name='group_selected'
              control={control}
              render={({ field }) => (
                <Select
                  id='group-selected'
                  width={398}
                  label='Group'
                  placeholder='Group'
                  disabled={isGroupSelectionDisabled}
                  error={
                    !!formState.errors &&
                    formState.errors?.group_selected?.message
                  }
                  {...field}
                  onChange={handleSelectGroup}
                >
                  {groupListAvailable.map((group) => (
                    <MenuItem key={group.name} value={group.id}>
                      <ListItemText primary={group.name} />
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Box>
        </Loading>
        {!isGroupNameHidden && (
          <Controller
            name='group_name'
            control={control}
            render={({ field }) => (
              <TextField
                hidden={isGroupNameHidden}
                fullWidth
                id='group-name'
                label='Group name'
                error={
                  !!formState.errors && formState.errors?.group_name?.message
                }
                placeholder='Group name'
                {...field}
              />
            )}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button kind='primary-ghost' onClick={handleCancel}>
          Cancel
        </Button>
        <Button kind={'primary'} onClick={handleSubmit(handleSave)}>
          Save
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};
