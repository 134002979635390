export const styles = {
  cardContainer: {
    height: '100%',
    p: 1,
    cursor: 'move',
  },
  label: {
    fontWeight: 'bold',
  },
  deleteOptionText: {
    color: '#C73E1D',
  },
  deleteOptionIcon: {
    fontSize: '22px',
    color: '#C73E1D',
  },
};
