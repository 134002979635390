import { Dialog, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

import { WidgetTypes } from './WidgetTypes';
import { KPIWidget } from 'src/types/widget';
import { AddWidgetContextProvider } from 'src/context/AddWidgetContext';
import { styles } from './styles';

interface AddWidgetModalProps {
  open: boolean;
  disableSubmit: boolean;
  onAddWidget: (data: Partial<KPIWidget>) => void;
  onClose: () => void;
}

export const AddWidgetModal: React.FC<AddWidgetModalProps> = ({
  open,
  disableSubmit,
  onAddWidget,
  onClose,
}) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
      }}
      fullWidth
      maxWidth="md"
      disableEscapeKeyDown
    >
      <AddWidgetContextProvider>
        <WidgetTypes
          disableSubmit={disableSubmit}
          onClose={handleClose}
          onAddWidget={onAddWidget}
        />
      </AddWidgetContextProvider>
      <IconButton sx={styles.closeIcon} onClick={handleClose}>
        <Close fontSize="medium" />
      </IconButton>
    </Dialog>
  );
};
