import styled from '@emotion/styled';
import { Box, BoxProps } from '@mui/system';

export const EquipmentContainer = styled(Box)<
  BoxProps & { isDragging: boolean }
>`
  & {
    background: ${({ isDragging }) => (isDragging ? '#DCECEA' : '#F0F8F7')};
    box-shadow: ${({ isDragging }) =>
      isDragging
        ? '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'
        : 'unset'};
    width: 481px;
    padding: 1px 20px;
    cursor: move;
    .MuiSvgIcon-root {
      color: ${({ isDragging }) => (isDragging ? '#007777' : '#55CFBB')};
    }
    :hover {
      background: #dcecea;
      .MuiSvgIcon-root {
        color: #007777;
      }
    }
  }
`;
