import * as Yup from 'yup';

export const createPolicy = Yup.object().shape({
  allocationPerUser: Yup.number()
    .typeError('Limit must be a number')
    .required('Limit is required')
    .min(0, 'Limit must be greater than 0')
    .max(99999, 'Limit must be less than 100000'),
  policyName: Yup.string()
    .required('Name is required')
    .max(30, 'Must be less than 30 letters'),
});
