import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Button, ButtonGroup, ButtonProps } from '@mui/material';
import { ReactNode } from 'react';

export interface StyledButtonProps extends Omit<ButtonProps, 'color'> {
  kind:
    | 'primary'
    | 'primary-ghost'
    | 'secondary'
    | 'secondary-ghost'
    | 'custom';
  textColor?: string;
  backgroundColor?: string;
  hoverTextColor?: string;
  hoverBackgroundColor?: string;
  border?: string;
  borderTop?: string;
  borderBottom?: string;
  borderLeft?: string;
  borderRight?: string;
  padding?: string;
  children: ReactNode;
}

const getCSS = ({
  kind,
  textColor,
  backgroundColor,
  hoverTextColor,
  hoverBackgroundColor,
  disabled,
  border,
  borderTop,
  borderBottom,
  borderLeft,
  borderRight,
}: StyledButtonProps) => {
  switch (kind) {
    case 'custom':
      return css`
        background: ${backgroundColor};
        color: ${textColor};
        border: ${border};
        border-top: ${borderTop};
        border-bottom: ${borderBottom};
        border-left: ${borderLeft};
        border-right: ${borderRight};
        :hover {
          color: ${hoverTextColor};
          background: ${hoverBackgroundColor};
        }
      `;
    case 'primary':
      return css`
        background: ${disabled ? '#00000024' : '#55cfbb'};
        color: ${disabled ? '#888888' : '#fff'};
        border: 1px solid ${disabled ? 'transparent' : '#55cfbb'};
        :hover {
          background: ${disabled ? '#00000024' : '#2ea392'};
        }
      `;
    case 'primary-ghost':
      return css`
        background: ${disabled ? '#00000024' : '#fff'};
        color: ${disabled ? '#888888' : '#55cfbb'};
        border: 1px solid ${disabled ? 'transparent' : '#55cfbb'};
        :hover {
          background: ${disabled ? '#00000024' : '#e5efef'};
        }
      `;
    case 'secondary':
      return css`
        background: ${disabled ? '#00000024' : '#005555'};
        color: ${disabled ? '#888888' : '#fff'};
        border: 1px solid ${disabled ? 'transparent' : '#005555'};
        :hover {
          background: ${disabled ? '#00000024' : '#004848'};
        }
      `;
    case 'secondary-ghost':
      return css`
        background: ${disabled ? '#00000024' : '#fff'};
        color: ${disabled ? '#888888' : '#005555'};
        border: 1px solid ${disabled ? 'transparent' : '#005555'};
        :hover {
          background: ${disabled ? '#00000024' : '#e5e5e5'};
        }
      `;
    default:
      return css``;
  }
};

export const StyledButton = styled(Button)<StyledButtonProps>`
  & {
    border-radius: 1px;
    font-size: 14px;
    padding: ${({ padding = '8px 40px' }) => padding};
    line-height: 1.143;
    text-transform: unset;
    ${getCSS}
  }
`;

export interface StyledSplitButtonProps {
  kind: 'primary' | 'primary-ghost';
}

export const StyledSplitButton = styled(ButtonGroup)<StyledSplitButtonProps>`
  & {
    button:nth-child(2) {
      border-left-color: ${({ kind }) =>
        kind === 'primary' ? '#fff' : '#55cfbb'};
    }
  }
`;
