import { Box } from '@mui/material';
import Map_, { Marker, Popup, MapRef, Source, Layer } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { FmdGood } from '@mui/icons-material';
import { points, bbox } from '@turf/turf';
import { GeoJSONSourceOptions } from 'mapbox-gl';

import { styles } from './styles';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { AppContext } from 'src/context';
import { RulesEngineContext } from 'src/context/RulesEngineContext';

export const Map: React.FC = () => {
  const { sidebarOpen } = useContext(AppContext);
  const { selectedAPNs, selectedEquipments } = useContext(RulesEngineContext);

  const [hoveredStateId, setHoveredStateId] = useState<number | null>(null);
  const [hoverInfo, setHoverInfo] = useState<{
    x: number;
    y: number;
    name: string;
  } | null>(null);
  const [cursor, setCursor] = useState<string>('grab');

  const windowsPlatforms = useMemo(
    () => ['Win32', 'Win64', 'Windows', 'WinCE'],
    [],
  );

  const platform = navigator?.userAgent || navigator?.platform;

  const geomapData: GeoJSONSourceOptions['data'] = {
    type: 'FeatureCollection',
    features:
      selectedAPNs.map((apn, index) => ({
        id: index + 1,
        type: 'Feature',
        properties: {
          apnName: apn.name,
          color: '#00E615',
        },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[(apn.geometry?.coordinates ?? []) as []]],
        },
      })) ?? [],
  };
  const filteredEquipments = selectedEquipments
    .filter((equipment) => equipment.coordinates !== null)
    .map((equipment) => {
      return {
        id: equipment.id,
        name: equipment.name,
        coordinates: {
          latitude: equipment.coordinates?.latitude ?? 0,
          longitude: equipment.coordinates?.longitude ?? 0,
        },
        type: equipment.type,
      };
    });

  const mapRef = useRef<MapRef>(null);

  const [width, setWidth] = useState(0);
  const [resizing, setResizing] = useState(false);
  const [popup, setPopup] = useState<{
    name: string;
    coordinates: {
      latitude: number;
      longitude: number;
    };
  } | null>(null);

  const fitBounds = useCallback(() => {
    const total = selectedAPNs.length + filteredEquipments.length;

    if (total === 0) {
      return;
    }

    if (selectedAPNs.length === 1) {
      if (selectedAPNs[0].geometry == null) {
        return;
      }
    }

    if (selectedAPNs.length === 0 && selectedEquipments.length === 1) {
      if (selectedEquipments[0]?.coordinates) {
        mapRef.current
          ?.setCenter({
            lat: selectedEquipments[0].coordinates.latitude,
            lng: selectedEquipments[0].coordinates.longitude,
          })
          .zoomTo(13);
      }
      return;
    }

    const apnCoordinates = selectedAPNs.reduce<number[][]>((prev, curr) => {
      let tempArr = [];
      if (curr.geometry?.coordinates) {
        tempArr = [...prev, ...curr.geometry?.coordinates];
      } else {
        tempArr = [...prev];
      }
      return tempArr;
    }, []);

    const equipmentCoordinates = filteredEquipments.reduce<number[][]>(
      (prev, curr) => {
        const tempArr = [
          ...prev,
          [curr.coordinates.longitude, curr.coordinates.latitude],
        ];
        return tempArr;
      },
      [],
    );

    const features = points([...apnCoordinates, ...equipmentCoordinates]);
    const [minLng, minLat, maxLng, maxLat] = bbox(features);

    mapRef.current?.fitBounds(
      [
        [minLng, minLat],
        [maxLng, maxLat],
      ],
      { padding: 50, duration: 1000 },
    );
  }, [selectedAPNs, filteredEquipments, selectedEquipments]);

  const handleOnMapLoad = () => {
    fitBounds();
    setResizing(false);
  };

  useEffect(() => {
    fitBounds();
  }, [fitBounds]);

  useEffect(() => {
    setTimeout(() => {
      mapRef.current?.resize();
      fitBounds();
    }, 0);
  }, [fitBounds, width]);

  useEffect(() => {
    setResizing(true);
    const container = document.getElementById('selection-container');
    if (container) {
      if (windowsPlatforms.some((value) => platform.includes(value))) {
        setWidth(
          Math.floor(
            window.screen.width - container.getBoundingClientRect().right - 18,
          ),
        );
      } else {
        setWidth(
          Math.floor(
            window.screen.width - container.getBoundingClientRect().right,
          ),
        );
      }
    }
  }, [platform, sidebarOpen, windowsPlatforms]);

  useEffect(() => {
    setResizing(true);
    const container = document.getElementById('selection-container');
    if (container) {
      if (windowsPlatforms.some((value) => platform.includes(value))) {
        setWidth(
          Math.floor(
            window.screen.width - container.getBoundingClientRect().right - 10,
          ),
        );
      } else {
        setWidth(
          Math.floor(
            window.screen.width - container.getBoundingClientRect().right,
          ),
        );
      }
    }
  }, [platform, windowsPlatforms]);

  return (
    <Box width={width} sx={styles.mapContainer}>
      <Map_
        ref={mapRef}
        initialViewState={{
          longitude: -5.905370495296432,
          latitude: 54.61745492998754,
          zoom: 13,
        }}
        dragPan={false}
        dragRotate={false}
        scrollZoom={false}
        keyboard={false}
        doubleClickZoom={false}
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN_2}
        style={{
          width: '100%',
          height: '100%',
          opacity: resizing ? 0.5 : 1,
          transition: 'opacity 0.25s ease-in',
        }}
        attributionControl={false}
        mapStyle='mapbox://styles/mapbox/satellite-streets-v9'
        onLoad={handleOnMapLoad}
        onResize={() => setResizing(false)}
        cursor={cursor}
        onDragStart={() => setCursor('grabbing')}
        onDragEnd={() => setCursor('grab')}
        onMouseMove={(event) => {
          if (event.features && event.features.length > 0) {
            if (hoveredStateId !== null) {
              mapRef.current?.setFeatureState(
                { source: 'source', id: hoveredStateId },
                { hover: false },
              );
            }
            mapRef.current?.setFeatureState(
              { source: 'source', id: event.features[0].id },
              { hover: true },
            );
            setCursor('pointer');
            setHoveredStateId(event.features[0].id as number);
            setHoverInfo({
              x: event.point.x,
              y: event.point.y,
              name: event.features[0].properties?.apnName,
            });
          }
        }}
        onMouseLeave={() => {
          if (hoveredStateId !== null) {
            mapRef.current?.setFeatureState(
              { source: 'source', id: hoveredStateId },
              { hover: false },
            );
          }
          setCursor('grab');
          setHoveredStateId(null);
          setHoverInfo(null);
        }}
        interactiveLayerIds={['data']}
      >
        <Source id='source' type='geojson' data={geomapData}>
          <Layer
            {...{
              id: 'data',
              type: 'fill',
              paint: {
                'fill-color': '#55CFBB',
                'fill-opacity': [
                  'case',
                  ['boolean', ['feature-state', 'hover'], false],
                  0.4,
                  0.3,
                ],
              },
            }}
          />
          <Layer
            {...{
              id: 'outline',
              type: 'line',
              paint: {
                'line-color': '#55CFBB',
                'line-width': 2,
              },
            }}
          />
        </Source>
        {filteredEquipments.map((equipment) => (
          <Marker
            key={equipment.id}
            longitude={equipment.coordinates.longitude}
            latitude={equipment.coordinates.latitude}
            anchor='bottom'
          >
            <FmdGood
              sx={styles.equipmentMarker}
              onClick={() =>
                setPopup({
                  name: equipment.name,
                  coordinates: {
                    latitude: equipment.coordinates.latitude,
                    longitude: equipment.coordinates.longitude,
                  },
                })
              }
            />
          </Marker>
        ))}
        {popup && (
          <Popup
            latitude={popup.coordinates.latitude}
            longitude={popup.coordinates.longitude}
            onClose={() => setPopup(null)}
          >
            {popup.name}
          </Popup>
        )}
        {hoverInfo && (
          <div
            className='tooltip'
            style={{ left: hoverInfo.x, top: hoverInfo.y }}
          >
            <div>{hoverInfo.name}</div>
          </div>
        )}
      </Map_>
    </Box>
  );
};
