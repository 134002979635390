import * as Yup from 'yup';

export const loginValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Email must be a valid email address')
    .required('Email is required'),
  password: Yup.string()
    .min(8, 'Password must be longer than 8 characters')
    .max(32, 'Password must be shorter than 32 characters')
    .required('Password is required'),
});
