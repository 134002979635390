import { Box, Typography } from '@mui/material';

import { Breadcrumbs } from 'src/components/Breadcrumbs';
import { Main } from 'src/layouts/Main';
import React, { useContext, useState } from 'react';
import { Button, Confirmable, Loading } from 'src/components';
import { TrashCan } from '@carbon/icons-react';
import {
  deleteUsagePolicy,
  getUsagePolicyDetails,
} from 'src/apis/policyManagement';
import { AppContext, AuthContext, ToastContext } from 'src/context';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { routes } from 'src/routes/routes';
import { QueryName } from 'src/enums/query';

export const EditPolicy: React.FC = () => {
  const { showToast } = useContext(ToastContext);
  const { user } = useContext(AuthContext);
  const organization = user?.organizations?.[0] as string;
  const { site } = useContext(AppContext);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { policyId } = useParams<{
    policyId: string;
  }>();
  const { data, isLoading: loadingUsagePolicy } = useQuery(
    [QueryName.GET_USAGE_POLICY_DETAILS, policyId],
    () => getUsagePolicyDetails(organization, site, policyId as string),
    { enabled: !!organization && !!site && !!policyId },
  );

  const closeConfirmModal = () => {
    setConfirmModalOpen(false);
  };

  const openConfirmModal = () => {
    setConfirmModalOpen(true);
  };
  const handleConfirmModal = () => {
    deleteMutation({
      organization,
      site,
      policyId: policyId as string,
    });
    setConfirmModalOpen(false);
  };

  const { mutate: deleteMutation, isLoading: deleteMuatationLoading } =
    useMutation<
      unknown,
      AxiosError,
      { organization: string; site: string; policyId: string }
    >({
      mutationFn: deleteUsagePolicy,
      onSuccess: async () => {
        showToast('Policy deleted successfully!', 'success');
        closeConfirmModal();
        navigate(routes.usagePolicy, {
          state: { back: false },
        });
        await queryClient.invalidateQueries([QueryName.GET_ALL_POLICIES]);
      },
      onError: () => {
        showToast('Cannot delete policy, please try again', 'error');
      },
    });
  return (
    <Main>
      <Loading isLoading={loadingUsagePolicy}>
        <Breadcrumbs
          breadcrumbs={[
            {
              title: 'Overview',
              url: '/',
            },
            {
              title: 'Usage policy',
              url: '/usage-policy',
            },
            {
              title: `Edit ${data?.data?.policyName}`,
            },
          ]}
        />
        <Box display='flex' justifyContent='space-between'>
          <Typography variant='h1'>Edit usage policy</Typography>
          <Button
            kind='custom'
            textColor='#F20000'
            backgroundColor='#FFFFFF'
            border='1px solid #F20000'
            hoverBackgroundColor='#EEF0F2'
            padding='6px 24px'
            onClick={openConfirmModal}
          >
            <TrashCan style={{ margin: '0 10px 0 0' }} />
            Delete this policy
          </Button>
        </Box>
        <Loading isLoading={deleteMuatationLoading}>
          <Confirmable
            open={confirmModalOpen}
            title='Delete the policy?'
            subTitle={`Are you sure you want to delete ${data?.data?.policyName} from policies?`}
            onConfirm={handleConfirmModal}
            onCancel={closeConfirmModal}
          />
        </Loading>
      </Loading>
    </Main>
  );
};
