import { Box, Typography } from '@mui/material';

import { StyledContainer } from './styles';

interface TypeProps {
  title: string;
  descp: string;
  image: string;
  active: boolean;
  onSelect: () => void;
}

export const Type: React.FC<TypeProps> = ({
  title,
  descp,
  image,
  active,
  onSelect,
}) => {
  return (
    <StyledContainer
      display="flex"
      maxWidth={560}
      p="20px"
      active={active}
      onClick={onSelect}
    >
      <img src={image} />
      <Box
        ml="24px"
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <Typography variant="h4" mb="10px">
          {title}
        </Typography>
        <Typography>{descp}</Typography>
      </Box>
    </StyledContainer>
  );
};
