import { createContext, FC, ReactNode, useState } from 'react';
import { Alert, AlertColor, Snackbar } from '@mui/material';

const defaultValues: {
  showToast: (message: string, severity: AlertColor) => void;
} = {
  showToast: () => {},
};

export const ToastContext = createContext(defaultValues);

interface ToastContextProviderProps {
  children: ReactNode;
}

export const ToastContextProvider: FC<ToastContextProviderProps> = ({
  children,
}) => {
  const [open, setOpen] = useState(false);
  const [toast, setToast] = useState<{
    message: string;
    severity: AlertColor;
  }>({
    message: '',
    severity: 'success',
  });

  const showToast = (message: string, severity: AlertColor) => {
    setToast({ message, severity });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      setToast({ message: '', severity: 'success' });
    }, 300);
  };

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        sx={{ marginTop: '34px' }}
      >
        <Alert
          onClose={handleClose}
          severity={toast.severity}
          sx={{ width: '100%', boxShadow: '0px 4px 6px #00000024' }}
        >
          {toast.message}
        </Alert>
      </Snackbar>
    </ToastContext.Provider>
  );
};
