export const createJsonFile = (data: BlobPart[]) => {
  return new Blob(data, { type: 'application/json' });
};

export const downloadFile = (fileName: string, file: Blob) => {
  const element = document.createElement('a');
  element.href = URL.createObjectURL(file);
  element.download = fileName;
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

export const validateFile = (
  file: File,
  validFileTypes: { types: string[]; message: string },
) => {
  if (!validFileTypes.types.includes(file.type)) {
    return validFileTypes.message;
  }
};

export const getFileTypeAndExtension = (selectedFile: File | null) => {
  const fileExtension = selectedFile?.name?.split('.').pop()?.toLowerCase();
  const xlsxType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  if (fileExtension === 'csv') {
    return {
      type: 'text/csv',
      extension: fileExtension,
    };
  } else {
    return {
      type: xlsxType,
      extension: fileExtension,
    };
  }
};
