import { CssBaseline, ThemeProvider } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter } from 'react-router-dom';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import './App.css';
import { theme } from './config/theme';
import {
  AppContextProvider,
  AuthContextProvider,
  ToastContextProvider,
  LicenseContextProvider,
} from './context';
import { Routes } from './routes';
import { isLocalEnv } from './utils/env';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { refetchOnWindowFocus: false, retry: 0, cacheTime: 0 },
    mutations: { networkMode: 'online' },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      {isLocalEnv && (
        <ReactQueryDevtools initialIsOpen={false} position='bottom-right' />
      )}
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <AppContextProvider>
            <ToastContextProvider>
              <AuthContextProvider>
                <LicenseContextProvider>
                  <CssBaseline />
                  <Routes />
                </LicenseContextProvider>
              </AuthContextProvider>
            </ToastContextProvider>
          </AppContextProvider>
        </ThemeProvider>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
