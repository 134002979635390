import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useNavigate } from 'react-router-dom';
import { Box, Link } from '@mui/material';

export const AddElement = ({
  href,
  state,
}: { href: string, state: string }) => {
  const navigate = useNavigate();
  return (
    <Link
      onClick={() => {
        navigate(href, { state: { state } });
      }}
      style={{
        display: 'block',
        background: '#EFF5F5 0% 0% no-repeat padding-box',
      }}
    >
      <Box sx={{ display: 'flex' }}>
        <AddCircleOutlineIcon fontSize='small' style={{ color: '#006161' }} />
        <span
          style={{
            color: '#006161',
            marginLeft: '5px',
          }}
        >
        Add an element
      </span>
      </Box>
    </Link>
  );
};
