export const styles = {
  selectUsersBox: {
    overflowX: 'hidden',
    overflowY: 'auto',
    border: '1px solid #CCCCCC',
  },
  userDetailsText: {
    fontSize: '14px',
    color: '#222222',
    textAlign: 'left',
    paddingLeft: '10px',
  },
  policyDetailsText: {
    fontSize: '12px',
    color: '#888888',
    paddingLeft: '10px',
    paddingTop: '2.5px',
  },
  formControl: {
    paddingLeft: '38px',
  },
  policyBox: {
    display: 'flex',
    width: '50%',
    paddingLeft: '70px',
  },
  parametersBox: {
    marginTop: '18px',
    backgroundColor: '#006161',
    width: '592px',
    height: '119px',
    marginBottom: '18px',
  },
  parametersText: {
    color: '#FFFFFF',
    fontSize: '16px',
    fontWeight: 'bold',
    textAlign: 'left',
    paddingLeft: '32px',
    paddingTop: '20px',
    paddingBottom: '12px',
  },
  usageTextFields: {
    paddingLeft: '32px',
    paddingBottom: '12px',
  },
  usageGB: {
    color: '#FFFFFF',
    paddingLeft: '9px',
    paddingRight: '46px',
    paddingTop: '10px',
    fontSize: '14px',
  },
  dataUsageBox: {
    marginBottom: '',
    backgroundColor: '#EBF2F1',
    width: '389px',
    height: '335px',
  },
  dataUsageText: {
    paddingTop: '27px',
    paddingLeft: '30px',
    fontSize: '16px',
    fontWeight: 'bold',
  },
  usageInfoBox: {
    marginLeft: '14px',
    backgroundColor: '#EBF2F1',
    width: '188px',
    height: '97px',
    marginBottom: '12px',
  },
  recurrenceBox: {
    marginLeft: '14px',
    backgroundColor: '#EBF2F1',
    width: '188px',
    height: '117px',
  },
  usageInfoHeading: {
    paddingTop: '20px',
    paddingLeft: '20px',
    fontSize: '16px',
    fontWeight: 'bold',
  },
  usageInfoText: {
    paddingLeft: '20px',
    fontSize: '22px',
    fontWeight: 'bold',
    color: '#006161',
  },
  usageGBText: {
    paddingTop: '4.75px',
    fontSize: '18px',
    fontWeight: 'bold',
    color: '#006161',
  },
  totalUsageStack: {
    paddingTop: '5px',
    paddingBottom: '100px',
  },
  usedUsageTextLarge: {
    display: 'flex',
    fontSize: '22px',
    fontWeight: 'bold',
    textAlign: 'left',
    color: '#92AEAE',
  },
  usedUsageTextSmall: {
    display: 'flex',
    fontSize: '16px',
    fontWeight: 'bold',
    textAlign: 'left',
    color: '#92AEAE',
    paddingBottom: '50px',
  },
  usedUsageStack: {
    display: 'flex',
    paddingTop: '56px',
    marginLeft: '12px',
  },
  allowenceTextLarge: {
    display: 'flex',
    fontSize: '22px',
    fontWeight: 'bold',
    textAlign: 'left',
    color: '#006161',
    paddingBottom: '0px',
    margin: '0px',
  },
  allowenceTextSmall: {
    paddingTop: '0px',
    display: 'flex',
    fontSize: '16px',
    fontWeight: 'bold',
    textAlign: 'left',
    color: '#006161',
    margin: '0px',
  },
  errors: {
    fontSize: '10px',
    marginLeft: 0,
    backgroundColor: '#F20000',
    color: '#ffffff',
    paddingTop: '0px',
    paddingLeft: '5px',
  },
  errorBox: {
    display: 'flex',
    marginTop: '10px'
  },
  operationButtonBox: {
    display: 'flex',
    marginLeft: '332px',
    marginTop: '41px',
  },
  noUsersText: {
    fontSize: '14px',
    color: '#222222',
    mt: '5px',
    ml: '5px',
  },
  dataUsageStack: {
    paddingLeft: '25px',
    paddingTop: '10px',
    paddingRight: '10px',
  },
  gbText: {
    fontSize: '18px',
    alignSelf: 'flex-end',
  },
  noUserError: {
    color: '#F20000',
    fontSize: '12px',
  },
  characterAdornment: {
    position: 'absolute',
    top: '5px',
    right: '14px',
    paddingTop: '5px',
  },
  characterAdornmentText: {
    fontSize: '9px',
    color: 'rgba(136, 136, 136, 1)',
  },
  operationButton: {
    marginRight: '13px'
  }
};
