import { yupResolver } from '@hookform/resolvers/yup';
import { Close } from '@mui/icons-material';
import {
  DialogContent,
  DialogActions,
  Box,
  DialogTitle,
  IconButton,
  MenuItem,
  ListItemText,
  Typography,
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import React, { useContext, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Button, Loading, Select, TextField } from 'src/components';
import { ToastContext } from 'src/context';
import { QueryName } from 'src/enums/query';
import { createUserGroupSchema } from 'src/yup-validations/addGroup';
import { StyledDialog, styles } from './styles';
import { createUsersGroup, getAllUserGroups } from 'src/apis/group';
import { useUserRole } from 'src/utils/user';

interface UserGroupModalProps {
  open: boolean;
  organization: string;
  onCancel: () => void;
  onSave: () => void;
  selectedUsers: Array<{
    userId: string;
    firstName: string;
    lastName: string;
    groupName: string;
  }>;
}

type GroupInputs = {
  groupSelected: string;
  groupName: string;
};

export const UserGroupModal: React.FC<UserGroupModalProps> = ({
  onCancel,
  onSave,
  open,
  organization,
  selectedUsers,
}) => {
  const queryClient = useQueryClient();
  const { showToast } = useContext(ToastContext);
  const { isSuperUser } = useUserRole();

  const [
    displayUserGroupOverwriteWarning,
    setDisplayUserGroupOverwriteWarning,
  ] = useState(false);

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
  } = useForm({
    defaultValues: {
      groupSelected: '',
      groupName: '',
    },
    resolver: yupResolver(createUserGroupSchema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const { groupSelected } = watch();

  const { data: userGroupsData, isLoading } = useQuery(
    [QueryName.GET_ALL_USER_GROUPS, organization],
    () => getAllUserGroups(organization),
    {
      enabled: !!organization,
    },
  );

  const handleCancel = () => {
    setDisplayUserGroupOverwriteWarning(false);
    onCancel();
    reset();
  };

  const createUsersGroupMutation = useMutation<
    AxiosResponse,
    AxiosError,
    {
      organization: string;
      groupName: string;
      userIds: string[];
    }
  >({
    mutationFn: createUsersGroup,
    onSuccess: async () => {
      showToast('Users added in group successfully!', 'success');
      reset();
      setDisplayUserGroupOverwriteWarning(false);
      onSave();
      await queryClient.invalidateQueries([QueryName.GET_ALL_USER_GROUPS]);
      await queryClient.invalidateQueries([QueryName.GET_ALL_USERS]);
    },
    onError: () => {
      showToast('Failed to create a group, Please try again', 'error');
    },
  });

  const handleSave = (data: GroupInputs) => {
    const usersWithGroup = selectedUsers.filter(
      (selectedUser) => !!selectedUser.groupName,
    );
    if (usersWithGroup.length) {
      setDisplayUserGroupOverwriteWarning(true);
    } else {
      continueSave(data);
    }
  };

  const continueSave = (data: GroupInputs) => {
    const finalData = {
      organization,
      groupName:
        data.groupSelected === 'New Group'
          ? data.groupName
          : data.groupSelected,
      userIds: selectedUsers.map((selectedUser) => selectedUser.userId),
    };
    createUsersGroupMutation.mutate(finalData);
  };

  return (
    <StyledDialog open={open}>
      <DialogTitle>
        {!displayUserGroupOverwriteWarning
          ? 'Group Users'
          : 'User already part of a group'}
        <IconButton sx={styles.closeIconButton} onClick={handleCancel}>
          <Close fontSize='medium' />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {!displayUserGroupOverwriteWarning ? (
          <Box>
            <Box>
              <ul style={{ marginTop: 0 }}>
                {selectedUsers.map((selectedUser) => (
                  <li
                    key={selectedUser.userId}
                  >{`${selectedUser.lastName} ${selectedUser.firstName}`}</li>
                ))}
              </ul>
            </Box>
            <Loading isLoading={isLoading}>
              <Box sx={{ mt: '4px', mb: '12px' }}>
                <Controller
                  name='groupSelected'
                  control={control}
                  render={({ field }) => (
                    <Select
                      id='group-selected'
                      width={398}
                      label='Group'
                      disabled={!organization && isSuperUser()}
                      error={!!errors && errors?.groupSelected?.message}
                      {...field}
                    >
                      {userGroupsData?.data.map((userGroup) => (
                        <MenuItem key={userGroup} value={userGroup}>
                          <ListItemText primary={userGroup} />
                        </MenuItem>
                      ))}
                      <MenuItem key='new-group' value='New Group'>
                        <ListItemText primary='New Group' />
                      </MenuItem>
                    </Select>
                  )}
                />
                {groupSelected === 'New Group' && (
                  <Controller
                    name='groupName'
                    control={control}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        id='group-name'
                        label='Group name'
                        error={!!errors && errors?.groupName?.message}
                        placeholder='Group name'
                        sx={{ mt: 1 }}
                        {...field}
                      />
                    )}
                  />
                )}
              </Box>
            </Loading>
          </Box>
        ) : (
          <>
            <Typography mb={1}>
              {selectedUsers
                .filter((selectedUser) => !!selectedUser.groupName)
                .map(
                  (selectedUser) =>
                    `${selectedUser.lastName} ${selectedUser.firstName}`,
                )
                .join(', ')}{' '}
              are already part of an existing group.
            </Typography>
            <Typography fontWeight='bold'>
              Do you want to remove them from their current group and create a
              new one?
            </Typography>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button kind='primary-ghost' onClick={handleCancel}>
          Cancel
        </Button>
        {displayUserGroupOverwriteWarning ? (
          <Button kind={'primary'} onClick={handleSubmit(continueSave)}>
            Continue
          </Button>
        ) : (
          <Button kind={'primary'} onClick={handleSubmit(handleSave)}>
            Save
          </Button>
        )}
      </DialogActions>
    </StyledDialog>
  );
};
