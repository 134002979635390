export const percentageStyle = {
  fontWeight: 'bold',
  display: 'flex',
  alignItems: 'baseline',
  fontSize: '14px',
};

export const speedStyle = {
  fontSize: '12px',
  color: '#888888',
  display: 'flex',
  alignItems: 'baseline',
};

export const latencyStyle = {
  fontSize: '24px',
  display: 'flex',
  alignItems: 'baseline',
  fontWeight: 'bold',
};

export const peakdownStyle = {
  fontSize: '10px',
  marginLeft: '3px',
};
export const tableStyles = {
  link: {
    '&:hover': {
      textDecoration: 'none',
    },
  },
  green: {
    ...percentageStyle,
    color: '#222222',
  },
  orange: {
    ...percentageStyle,
    color: '#FF9900',
  },
  red: {
    ...percentageStyle,
    color: '#F20000',
  },
  speedGreen: {
    ...speedStyle,
    color: '#222222',
  },
  speedOrange: {
    ...speedStyle,
    color: '#FF9900',
  },
  speedRed: {
    ...speedStyle,
    color: '#F20000',
  },
  latencyGreen: {
    ...latencyStyle,
    color: '#222222',
  },
  latencyOrange: {
    ...latencyStyle,
    color: '#FF9900',
  },
  latencyRed: {
    ...latencyStyle,
    color: '#F20000',
  },
  peakdownGreen: {
    ...peakdownStyle,
    color: '#222222',
  },
  peakdownOrange: {
    ...peakdownStyle,
    color: '#FF9900',
  },
  peakdownRed: {
    ...peakdownStyle,
    color: '#F20000',
  },
};
