import * as Yup from 'yup';

export const createChannelSchema = Yup.object().shape({
  channel_type: Yup.string().required('Channel type is required'),
  channel_name: Yup.string()
    .matches(/^[a-zA-Z0-9-_ ]*$/, 'Valid characters are A-Z a-z 0-9 _ -')
    .max(50, 'Channel name must be shorter than 50 characters')
    .required('Channel name is required'),
  channel_url: Yup.string().url().required('Channel URL is required'),
});
