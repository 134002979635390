import { ChipIcon } from 'src/components';

export const showStatusChip = (status: string) => {
  switch (status) {
    case 'SUCCESS':
      return <ChipIcon label={status} kind='primary-ghost' />;
    case 'FAIL':
      return <ChipIcon label={status} kind='danger-ghost' />;
    default:
      return <></>;
  }
};

export const toTitleCase = (str: string) =>
  str
    .replace(/_/g, ' ')
    .replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase(),
    );

export const replaceSpacesWithUnderscores = (str: string) =>
  str.replace(/\s/g, '_');

export const replaceUnderscoresWithSpaces = (str: string) =>
  str.replace(/_/g, ' ');
