import { ChangeEvent, useCallback, useState } from 'react';
import { Box, TableCell, TableRow, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { Page } from 'src/types/shared';
import { getAllUsers } from 'src/apis/access-management';
import { QueryName } from 'src/enums/query';
import { Checkbox, Loading, Pagination, Table } from 'src/components';
import { UserFilterData, Users, UserInfo } from 'src/types/user';
import { useUserRole } from 'src/utils/user';
import { styles } from '../styles';
import { ListUsersHeader } from './ListUsersHeader';
import { EditUserModal } from '../EditUser';

const columns = [
  {
    label: 'User',
    key: 'user',
  },
  {
    label: 'Email',
    key: 'email',
  },
  {
    label: 'Role',
    key: 'role',
  },
  {
    label: 'Organization',
    key: 'organization',
  },
  {
    label: 'Site',
    key: 'site',
  },
];

export const ListUsers: React.FC = () => {
  const { isReadyOnlyUser } = useUserRole();

  const [currentUser, setCurrentUser] = useState<UserInfo | null>(null);
  const [showEditModal, setShowEditModal] = useState(false);

  const [selected, setSelected] = useState<UserInfo[]>([]);
  const [filters, setFilters] = useState<UserFilterData & Page>({
    organization: '',
    site: [],
    searchText: '',
    role: '',
    groupName: '',
    pageStart: 1,
    pageEnd: 10,
  });

  const handlePageChange = (pageStart: number, pageEnd: number) => {
    setFilters({
      ...filters,
      pageStart,
      pageEnd,
    });
  };

  const { data, isLoading, isFetching } = useQuery(
    [
      QueryName.GET_ALL_USERS,
      filters.organization,
      filters.site,
      filters.role,
      filters.searchText,
      filters.pageStart,
      filters.pageEnd,
      filters.groupName,
    ],
    () =>
      getAllUsers(
        filters.organization !== '' ? filters.organization : undefined,
        filters.site.length ? filters.site.join(',') : undefined,
        filters.role !== '' ? filters.role : undefined,
        filters.groupName !== '' ? filters.groupName : undefined,
        filters.searchText !== '' ? filters.searchText : undefined,
        filters.pageStart,
        filters.pageEnd,
      ),
    {
      enabled: !!filters.organization,
      onSuccess: (data) => {
        if (filters.pageEnd > data?.data?.total) {
          setFilters({
            ...filters,
            pageStart: 1,
            pageEnd: 10,
          });
        }
        setSelected([]);
      },
    },
  );

  const isSelected = (user: UserInfo) =>
    selected.filter((selectedUser) => selectedUser.userId === user.userId)
      .length > 0;

  const applyFilters = useCallback((filters: UserFilterData) => {
    setFilters({
      organization: filters.organization,
      site: filters.site,
      searchText: filters.searchText,
      role: filters.role,
      groupName: filters.groupName,
      pageStart: 1,
      pageEnd: 10,
    });
  }, []);

  const rows = data?.data.users.map((user: UserInfo) => ({
    userId: user.userId,
    firstName: user.firstName,
    lastName: user.lastName,
    enabled: user.enabled,
    roles: user.roles,
    organizations: user.organizations,
    sites: user.sites,
  }));

  const checkedAllCheckboxes =
    rows?.length > 0 && selected?.length === rows?.length;
  const indeterminate = selected?.length > 0 && selected?.length < rows?.length;

  const handleSelectAllCheckboxes = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows?.map((row: Users) => row);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const toggleSelected = useCallback(
    (user: UserInfo) => {
      const selectedIndex = selected
        .map((dev) => dev.userId)
        .indexOf(user.userId);
      const newSelected = [...selected];
      if (selectedIndex === -1) {
        newSelected.push(user);
      } else {
        newSelected.splice(selectedIndex, 1);
      }
      return newSelected;
    },
    [selected],
  );

  const handleClickCheckbox = useCallback(
    (
      event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
      user: UserInfo,
    ) => {
      const isOpenModalElement =
        (event.target as HTMLButtonElement).id === 'openModal';
      if (!isOpenModalElement) {
        const newSelected = toggleSelected(user);
        setSelected(newSelected);
      }
    },
    [toggleSelected],
  );

  const openEditModal = (user: UserInfo) => {
    setShowEditModal(true);
    setCurrentUser(user);
  };

  const closeEditModal = () => {
    setShowEditModal(false);
    setCurrentUser(null);
  };

  return (
    <Box>
      <ListUsersHeader onApply={applyFilters} selected={selected} />
      {currentUser && (
        <EditUserModal
          userInfo={currentUser}
          showModal={!!currentUser && showEditModal}
          closeModal={closeEditModal}
        />
      )}
      <Loading isLoading={isLoading || isFetching}>
        <Table
          handleSelectAllCheckboxes={handleSelectAllCheckboxes}
          indeterminate={indeterminate}
          checkedAllCheckboxes={checkedAllCheckboxes}
          checkbox={!isReadyOnlyUser()}
          columns={columns}
          renderRows={() =>
            rows?.map((row: UserInfo, index: number) => {
              const isItemSelected = isSelected(row);
              const labelId = `enhanced-table-checkbox-${index}`;
              return (
                <TableRow
                  hover
                  onClick={(event: React.MouseEvent<HTMLTableRowElement>) =>
                    handleClickCheckbox(event, row)
                  }
                  role='checkbox'
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.userId}
                  selected={isItemSelected}
                  sx={styles.tableRow}
                >
                  {!isReadyOnlyUser() && (
                    <TableCell padding='checkbox'>
                      <Checkbox
                        color='primary'
                        checked={isItemSelected}
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </TableCell>
                  )}
                  <TableCell padding='none'>
                    <Typography
                      id={'openModal'}
                      onClick={() => openEditModal(row)}
                      sx={styles.userId}
                    >
                      {row.lastName} {row.firstName}
                    </Typography>
                  </TableCell>
                  <TableCell padding='none'>
                    <Typography sx={styles.tableColumn}>
                      {row.userId}
                    </Typography>
                  </TableCell>
                  <TableCell padding='none'>
                    <Box m={0} p={0} display='flex' flexDirection='column'>
                      {row.roles?.map((role: string, index: number) => (
                        <Typography
                          key={`${role}-${index}`}
                          sx={styles.tableColumn}
                          margin='2px 0'
                        >
                          {role}
                        </Typography>
                      ))}
                    </Box>
                  </TableCell>
                  <TableCell padding='none'>
                    <Box m={0} p={0} display='flex' flexDirection='column'>
                      {row.organizations?.map((org: string, index: number) => (
                        <Typography
                          key={`${org}-${index}`}
                          sx={styles.tableColumn}
                          margin='2px 0'
                        >
                          {org}
                        </Typography>
                      ))}
                    </Box>
                  </TableCell>
                  <TableCell padding='none'>
                    <Box m={0} p={0} display='flex' flexDirection='column'>
                      {row.sites?.map((site: string, index: number) => (
                        <Typography
                          key={`${site}-${index}`}
                          sx={styles.tableColumn}
                          margin='2px 0'
                        >
                          {site}
                        </Typography>
                      ))}
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })
          }
        />
      </Loading>
      {data?.data.total > 10 && (
        <Box pt='19px'>
          <Pagination
            currentPage={filters.pageStart}
            disabled={isLoading || isFetching}
            totalRecords={data?.data.total}
            onChange={handlePageChange}
          />
        </Box>
      )}
    </Box>
  );
};
