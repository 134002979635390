import { Link, Typography } from '@mui/material';
import React from 'react';
import { Box } from '@mui/system';
import { stylesBreadcrumbs } from 'src/components/Breadcrumbs/styles';
import { ChevronRight } from '@carbon/icons-react';

interface BreadcrumbsProps {
  breadcrumbs: Array<{
    title: string;
    url?: string;
  }>;
}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ breadcrumbs }) => {
  return (
    <>
      <Box display='flex' alignItems='center'>
        {breadcrumbs?.map((item, index) => (
          <span key={index}>
            {item.url ? (
              <Box display='flex' alignItems='center'>
                <Link href={item.url} sx={stylesBreadcrumbs.breadcrumbStyle}>
                  {item.title}
                </Link>
                <ChevronRight color='#888888' height={10} width={12} />
              </Box>
            ) : (
              <Typography sx={stylesBreadcrumbs.breadcrumbStyle}>
                {item.title}
              </Typography>
            )}
          </span>
        ))}
      </Box>
    </>
  );
};
