import { ApiResponseType } from 'src/types/api';
import { AllocateUE, Device, DeviceType } from 'src/types/device';
import { UE, UEGroup, UpdateUE } from 'src/types/domain';
import { api } from 'src/utils/axios';
import { Dispatch, SetStateAction } from 'react';
import { AxiosProgressEvent } from 'axios';

export type CreateUEGroupData = {
  organization: string;
  site: string;
  groupName: string;
};

export type AddUEToGroupData = {
  id: string;
  organization: string;
  site: string;
  ues: string[];
};

export const getAllUes = (
  {
    organization,
    site,
  }: {
    organization: string;
    site?: string;
  },
  pageStart?: number,
  pageEnd?: number,
) => {
  return api.get<ApiResponseType<{ devices: UE[]; total: number }>>(
    '/topology/v1/ue/all',
    {
      params: {
        organization,
        site,
      },
      headers: {
        pageStart,
        pageEnd,
      },
    },
  );
};

export const editUe = (data: UpdateUE) => {
  return api.patch('/topology/v1/ue/', data);
};

export const getAllUETypes = () => {
  return api.get<ApiResponseType<string[]>>('/topology/v1/metadata/uetype');
};

export const getAllUEGroups = (
  {
    organization,
    site,
  }: {
    organization: string;
    site?: string;
  },
  pageStart?: number,
  pageEnd?: number,
) => {
  return api.get<
    ApiResponseType<{
      groups: UEGroup[];
      total: number;
    }>
  >('/topology/v1/group/all', {
    params: {
      organization,
      site,
    },
    headers: {
      pageStart,
      pageEnd,
    },
  });
};

export const createUEGroup = (data: CreateUEGroupData) => {
  return api.post<ApiResponseType<UEGroup>>('topology/v1/group', data);
};

export const addUEsToGroup = (data: AddUEToGroupData) => {
  return api.put('topology/v1/group', data);
};

export const removeUEsFromGroup = (data: AddUEToGroupData) => {
  return api.delete('/topology/v1/group/ue', { data });
};

export const allocateUE = (data: AllocateUE) => {
  return api.post('topology/v1/ue/allocate', data);
};

export const getAllUnallocatedDevices = ({
  organization,
  site,
}: {
  organization: string;
  site?: string;
}) => {
  return api.get<ApiResponseType<{ devices: Device[]; total: number }>>(
    '/topology/v1/ue/unallocated/all',
    {
      params: {
        organization,
        site,
      },
    },
  );
};

export const getAllDevices = (
  organization: string,
  site?: string,
  type?: string,
  ueGroups?: string,
  searchText?: string,
  includeWatchdog?: boolean,
  pageStart?: number,
  pageEnd?: number,
) => {
  return api.get<ApiResponseType>('/topology/v1/ue/all', {
    params: {
      organization,
      site,
      type,
      ueGroups,
      searchText,
      includeWatchdog,
    },
    headers: {
      pageStart,
      pageEnd,
    },
  });
};

export const getAllUeTypes = () => {
  const url = '/topology/v1/metadata/uetype';
  return api.get<ApiResponseType<string[]>>(url);
};

export const getUEDetails = (
  organization: string,
  site: string,
  ueid: string,
) => {
  return api.get<ApiResponseType<DeviceType>>('/topology/v1/ue/', {
    params: {
      organization,
      site,
      ueid,
    },
  });
};
export const bulkAllocateUe = ({
  file,
  onProgress,
}: {
  file: File;
  onProgress: Dispatch<SetStateAction<number>>;
}) => {
  const formData = new FormData();
  formData.append('file', file);

  if (file) {
    formData.append('file', file);
  }
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress: (progressEvent: AxiosProgressEvent) => {
      const progress =
        (progressEvent.loaded / (progressEvent.total ?? 1)) * 100;
      onProgress(progress);
    },
  };
  return api.post<ApiResponseType<string[]>>(
    '/topology/v1/ue/bulk/allocate',
    formData,
    config,
  );
};
export const getBulkAllocateDeviceReport = async ({
  path,
  org,
}: {
  path: string;
  org: string;
}) => {
  return await api.post(
    `/topology/v1/ue/bulk/report?filePath=${path}&organization=${org}`,
    {},
    {
      responseType: 'blob',
    },
  );
};
