import { AppBar, AppBarProps, CSSObject, styled, Theme } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import { colors } from 'src/config/theme';

const drawerWidth = 250;

export const StyledAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps & { open: boolean }>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: 'calc(100% - 250px)',
    marginLeft: '250px',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: '60px',
  [theme.breakpoints.up('sm')]: {
    width: '60px',
  },
});

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export const StyeledDrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'right',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'space-around',
  margin: '0px 0px 0px auto',
}));

export const StyeledDrawerFooter = styled('div')(({ theme }) => ({
  width: '250px',
  alignItems: 'center',
  textAlign: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'center',
  position: 'fixed',
  bottom: 0,
  fontSize: '12px',
  color: '#55CFBB',
}));

export const stylesSidebar = {
  toggleBtn: {
    padding: '18px 12px',
    height: '12px',
    marginTop: '10px',
  },
  logoWrapper: {
    margin: '0 auto 20px',
  },
  logoWrapperCollapsed: {
    margin: '0 14px 20px',
  },
  labelTextSideBar: {
    textAlign: 'left',
    fontSize: '1em',
    fontFamily: '"Lato"',
    color: '#222222',
    opacity: '1',
  },
  labelTextSideBarActive: {
    textAlign: 'left',
    fontSize: '1em',
    fontFamily: '"Lato"',
    color: colors.secondary + '!important',
    opacity: '1',
    '& span': {
      color: colors.secondary,
    },
  },
  listItemIcon: {
    minWidth: '0',
    marginRight: '17px',
  },
  navItemsWrapper: {
    paddingTop: '0px',
    margin: '0',
  },
  navItems: {
    cursor: 'pointer',
    height: '3rem',
    padding: '8px 25px 8px 25px',
    '&:hover, &.MuiTouchRipple-root': {
      backgroundColor: '#D5EAE8',
      color: colors.secondary + '!important',
      '& svg, span': {
        color: colors.secondary,
      },
    },
  },
  navItemsCollapsed: {
    cursor: 'pointer',
    height: '3rem',
    padding: '8px 25px 8px 20px',
    '&:hover, &.MuiTouchRipple-root': {
      backgroundColor: '#D5EAE8',
      color: colors.secondary,
    },
  },
  activeNavItem: {
    height: '3rem',
    backgroundColor: '#E3F2F1',
    padding: '8px 45px 8px 21px',
    borderLeft: '4px solid #006161!important',
    color: colors.secondary + '!important',
  },
  activeNavItemCollapsed: {
    height: '3rem',
    backgroundColor: '#E3F2F1',
    padding: '8px 20px 8px 16px',
    borderLeft: '4px solid #006161!important',
    transition: '0.2s',
    color: colors.secondary,
    '&:hover, &.MuiTouchRipple-root': {
      transition: '0.1s',
    },
  },
  navItemBtn: {
    padding: '8px 0',
    transition: '0.2s',
    '&:hover, &.MuiTouchRipple-root': {
      backgroundColor: 'transparent',
      transition: '0.1s',
    },
  },
};

export const stylesHeader = {
  appBar: {
    padding: '5px 22px 5px 15px',
    position: 'fixed',
    zIndex: 1000,
  },
  notificationButton: {
    color: colors.secondary,
    margin: '0 14px 0 0',
    padding: '8px',
    '&:hover, &.MuiTouchRipple-root': {
      backgroundColor: 'rgba(0, 97, 97, 0.1)',
    },
  },
  settingsButton: {
    color: colors.secondary,
    margin: '0 14px 0 0',
    padding: '8px',
    '&:hover, :focus-visible': {
      backgroundColor: 'rgba(0, 97, 97, 0.1)',
    },
  },
  profileBtnBox: {
    width: 32,
  },
  iconBtn: {
    color: colors.secondary,
    size: 'small',
    '&:hover, :focus-visible': {
      backgroundColor: 'rgba(0, 97, 97, 0.1)',
    },
    avatarIcon: {
      width: '24px',
    },
    givenNameBox: {
      position: 'absolute',
      margin: '-12px auto 0',
      width: '40px',
      textAlign: 'center',
      typography: {
        fontFamily: '"Lato"',
        fontSize: '10px',
        color: colors.secondary,
      },
    },
  },
  vertDivider: {
    marginLeft: '10px',
    marginRight: '22px',
    width: '2px',
  },
  typography: {
    ml: 'auto',
    fontFamily: '"Lato"',
    fontSize: '1em',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#222222',
    minWidth: 100,
    padding: '0 15px',
  },
  profileMenu: {
    marginTop: 0,
    '& .MuiPaper-root': {
      top: '38px !important',
      borderRadius: '2px !important',
      right: '4px !important',
      left: 'auto !important',
    },
  },
  paperProps: {
    overflow: 'visible',
    filter: 'drop-shadow(0px 1px 2px rgba(0,0,0,0.32))',
    mt: 1.5,
    width: 240,
    '& .MuiAvatar-root': {
      color: '#ffffff',
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
  },
  profileBox: {
    display: 'flex',
    flexDirection: 'row',
    width: 240,
    avatarBox: {
      width: 50,
      paddingLeft: '25px',
      paddingTop: '5px',
    },
    avatarInitials: {
      fontSize: '14px',
      fontFamily: '"Lato"',
      fontWeight: 'bold',
      width: '30px',
      height: '30px',
      backgroundColor: colors.secondary,
    },
    profileInfo: {
      width: 170,
      paddingLeft: '14px',
      fullName: {
        fontWeight: 'bold',
        color: colors.secondary,
        fontFamily: '"Lato"',
        fontSize: '14px',
      },
      email: {
        color: colors.secondary,
        fontFamily: '"Lato"',
        fontSize: '12px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
      myProfileText: {
        color: colors.secondary,
        fontSize: '14px',
        textDecoration: 'underline',
        textDecorationOffset: '2px',
        margin: '2px 0 10px',
        cursor: 'pointer',
        '&:hover': {
          textDecoration: 'none',
        },
      },
    },
  },
  logoutMenuItem: {
    height: '40px',
    '&:hover': {
      backgroundColor: '#ffffff',
    },
    logoutBtn: {
      paddingBottom: 0,
      '&:hover': {
        backgroundColor: '#ffffff',
      },
    },
    logoutText: {
      color: colors.secondary,
      fontFamily: '"Lato"',
      fontSize: '14px',
      fontWeight: 'bold',
    },
  },
};

export const stylesSelectSite = {
  selectbtn: {
    margin: '5px 20px',
    backgroundColor: 'red',
    border: '0 !important',
    '&:hover': {
      backgroundColor: 'rgba(0, 97, 97, 0.1)',
    },
    '&::after': { display: 'none' },
  },
};

export const stylesMyProfile = {
  passwordIcon: {
    color: '#006161',
  },
  textFieldWrapper: {
    paddingBottom: '10px',
    paddingRight: '10px',
  },
  contentWrapper: {
    padding: '0px 24px 0 32px',
  },
  subtitle: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#006161',
    paddingBottom: '10px',
    paddingTop: '20px',
  },
  title: {
    fontSize: '16px',
    fontWeight: 'bold',
    maxWidth: '200px',
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px 24px 0 30px',
  },
  userRole: {
    fontSize: '16px',
    margin: '0 7px',
  },
  actionsWrapper: {
    padding: '0 32px 32px',
  },
};
