import { useState } from 'react';

import { KPIWidget } from 'src/types/widget';
import { Customize } from './Customize';
import { Level } from './Level';
import { Metric } from './Metric';
import { Type } from './Type';

interface KpiWidgetProps {
  disableSubmit: boolean;
  goBack: () => void;
  onAddWidget: (data: Partial<KPIWidget>) => void;
}

export const KpiWidget: React.FC<KpiWidgetProps> = ({
  disableSubmit,
  goBack,
  onAddWidget,
}) => {
  const [activeStep, setActiveStep] = useState(0);

  const handlePreviousStep = () => {
    if (activeStep === 0) {
      goBack();
      return;
    }
    setActiveStep((step) => step - 1);
  };

  const handleNextStep = () => {
    setActiveStep((step) => step + 1);
  };

  const kpiWidgetSteps = [
    {
      component: (
        <Level
          onPreviousStep={handlePreviousStep}
          onNextStep={handleNextStep}
        />
      ),
    },
    {
      component: (
        <Metric
          onPreviousStep={handlePreviousStep}
          onNextStep={handleNextStep}
        />
      ),
    },
    {
      component: (
        <Type onPreviousStep={handlePreviousStep} onNextStep={handleNextStep} />
      ),
    },
    {
      component: (
        <Customize
          disableSubmit={disableSubmit}
          onPreviousStep={handlePreviousStep}
          onAddWidget={onAddWidget}
        />
      ),
    },
  ];

  return <>{kpiWidgetSteps[activeStep].component}</>;
};
