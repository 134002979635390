import { api } from 'src/utils/axios';
import { ApiResponseType } from 'src/types/api';
import { Rule } from 'src/types/rule';

export type CreateRuleData = {
  id: string;
  organization: string;
  site: string;
  name: string;
  description: string;
  type: string;
  state: string;
  from?: string;
  to?: string;
  createdBy: string;
  updatedBy?: string;
  apns: string[];
  ues: string[];
  ueGroups: { [key: string]: string };
  ueNames: { [key: string]: string };
  priorityMap: { [key: number]: string };
  thresholdType?: string;
  threshold?: number;
  notifyOnce?: boolean;
  geoFenceType?: string;
  notification: {
    email: boolean;
    emailContacts: string[];
    slack: boolean;
    slackChannels: { [key: string]: string };
    teams: boolean;
    teamsChannels: { [key: string]: string };
  };
};

export const createRule = (data: CreateRuleData) => {
  return api.post('/rules/v1', data);
};

export const editRule = (data: CreateRuleData) => {
  return api.patch('/rules/v1', data);
};

export const getRulesInfo = (
  organization: string,
  site: string,
  id: string,
  trendRange: string,
) => {
  const url = '/rules/v1';
  return api.get<ApiResponseType<Rule>>(url, {
    params: {
      organization,
      site,
      id,
      trendRange,
    },
  });
};

export const activateRule = ({
  organization,
  site,
  id,
}: {
  organization: string;
  site: string;
  id: string;
}) => {
  return api.post<ApiResponseType>(
    `/rules/v1/activate?organization=${organization}&site=${site}&id=${id}`,
  );
};

export const deactivateRule = ({
  organization,
  site,
  id,
}: {
  organization: string;
  site: string;
  id: string;
}) => {
  return api.post<ApiResponseType>(
    `/rules/v1/deactivate?organization=${organization}&site=${site}&id=${id}`,
  );
};

export const deleteRule = ({
  organization,
  site,
  id,
}: {
  organization: string;
  site: string;
  id: string;
}) => {
  return api.delete(
    `/rules/v1?organization=${organization}&site=${site}&id=${id}`,
  );
};
export type RenameRuleData = {
  organization: string;
  site: string;
  id: string;
  oldName: string;
  newName: string;
};

export const renameRule = (data: RenameRuleData) => {
  return api.post<ApiResponseType>('/rules/v1/rename', data);
};

export const getAllRules = (
  organization: string,
  site: string | undefined,
  createdBy?: string[],
  types?: string[],
  sortCriteria?: string,
  sortDirection?: 'ASC' | 'DESC',
  pageStart?: number,
  pageEnd?: number,
) => {
  return api.get<ApiResponseType>('/rules/v1/list', {
    params: {
      organization,
      site: site !== '' ? site : undefined,
      createdBy: createdBy?.length ? createdBy?.join(',') : undefined,
      type: types?.length ? types?.join(',') : undefined,
      sortCriteria,
      sortDirection,
    },
    headers: {
      pageStart,
      pageEnd,
    },
  });
};

export const bulkActionRule = ({
  organization,
  site,
  action,
  ids,
}: {
  organization: string;
  site: string;
  action: string;
  ids: string[];
}) => {
  const url = '/rules/v1/bulk';
  return api.post<ApiResponseType>(url, {
    organization,
    site,
    action: action.toUpperCase(),
    ids,
  });
};

export const downloadRecentEventLogs = ({
  organization,
  site,
  id,
}: {
  organization: string;
  site: string;
  id: string;
}) => {
  return api.get<ApiResponseType<Blob>>('/rules/v1/eventlog/download', {
    params: {
      organization,
      site,
      id,
    },
    responseType: 'blob',
  });
};

export const getAllAuthors = (organization: string) => {
  const url = '/rules/v1/author';
  return api.get<ApiResponseType<string[]>>(url, {
    params: {
      organization,
    },
  });
};

export const getAllRuleTypes = () => {
  const url = '/rules/v1/type';
  return api.get<ApiResponseType<string[]>>(url);
};

export const getRuleTrend = (data: {
  organization: string;
  site: string;
  range: string;
  startTime?: Date;
  endTime?: Date;
  apns: string[];
  ueGroups?: { [key: string]: string };
  ues?: string[];
  metricType?: string;
  includeMax: boolean;
  includeRecommended: boolean;
  level?: string;
}) => {
  const url = '/pm/v1/metrics/trend';
  return api.post<
    ApiResponseType<{
      organization: string;
      site: string;
      metricType: string;
      recommendedValue: number;
      level: string;
      maxValues: [
        {
          time: string;
          value: number;
        },
      ];
      averageValues: [
        {
          time: string;
          value: number;
        },
      ];
    }>
  >(url, data);
};
