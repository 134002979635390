import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ConsumptionHeader } from '../top-bar/consumption-header';
import { APNConsumptionGraph } from './consumption-graph/APNConsumptionGraph';
import { ConsumptionTable } from './consumption-table/consumptionTable';
import { Main } from 'src/layouts/Main';
import { AppContext, AuthContext } from 'src/context';
import { useSearchParams } from 'react-router-dom';
import {
  ConsumptionAnalyticsCriteria,
  ConsumptionAnalyticsRange,
  getConsumptionAnalytics,
} from 'src/apis/consumption';

export const ApnConsumption: FC = () => {
  const { user } = useContext(AuthContext);
  const { site } = useContext(AppContext);

  const selectedOrganization = user?.organizations?.[0] as string;

  const [selectedUEs, setSelectedUEs] = useState<{
    [key: string]: { data: Array<{ y: number; time: string }> };
  } | null>(null);

  const [params] = useSearchParams({ criteria: 'LATENCY' });

  const range = params.get('range') ?? 'DAY';
  const criteria = params.get('criteria');
  const startTime = params.get('startTime');
  const endTime = params.get('endTime');
  const ues = params.get('ues');

  const [currentTime, setCurrentTime] = useState(new Date().getTime());

  const refreshDataInterval = useRef<ReturnType<typeof setInterval> | null>(
    null,
  );

  const updateAllUes = useCallback(async () => {
    const ueIds = ues ? ues.split(',') : [];
    const tempSelectedUEs: {
      [key: string]: { data: Array<{ y: number; time: string }> };
    } = {};
    for (const ueId of ueIds) {
      let ueData: Array<{ y: number; time: string }> = [];
      try {
        const { data } = await getConsumptionAnalytics({
          organization: selectedOrganization,
          site,
          level: 'IMSI',
          imsi: ueId,
          criteria: criteria as ConsumptionAnalyticsCriteria,
          range: range as ConsumptionAnalyticsRange,
          startTime,
          endTime,
        });
        ueData =
          data?.data?.[ueId]?.map((value) => ({
            y: value.value,
            time: value.time,
          })) ?? [];
      } catch (e) {}
      tempSelectedUEs[ueId] = {
        data: ueData,
      };
    }
    setSelectedUEs({ ...tempSelectedUEs });
  }, [criteria, endTime, range, selectedOrganization, site, startTime, ues]);

  useEffect(() => {
    void updateAllUes();
  }, [ues, currentTime, updateAllUes]);

  useEffect(() => {
    if (refreshDataInterval.current) {
      clearInterval(refreshDataInterval.current);
    }
    refreshDataInterval.current = setInterval(() => {
      setCurrentTime(new Date().getTime());
    }, 30000);
  }, []);

  return (
    <>
      <Main overrideStyles={{ paddingBottom: 0 }}>
        <ConsumptionHeader />
      </Main>
      <APNConsumptionGraph
        org={selectedOrganization}
        level='APN'
        ueData={selectedUEs}
        currentTime={currentTime}
      />
      <Main hideHeader hideSidebar overrideStyles={{ marginTop: 'unset' }}>
        <ConsumptionTable currentTime={currentTime} />
      </Main>
    </>
  );
};
