import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Box, FormControlLabel, Typography } from '@mui/material';

import {
  AuthContext,
  AppContext,
  GeoMapContext,
  GeoMapUEGroup,
} from 'src/context';
import { UEGroup } from 'src/types/domain';
import { QueryName } from 'src/enums/query';
import { Checkbox, Loading } from 'src/components';
import { GeoMapUE } from 'src/context/GeoMapContext';
import { getAllUEGroups, getAllUes } from 'src/apis/ue';

interface GroupEquipmentProps {
  filter: string;
}

export const GroupEquipment: React.FC<GroupEquipmentProps> = ({ filter }) => {
  const { user } = useContext(AuthContext);
  const { site } = useContext(AppContext);
  const { ueGroups, addNewUEGroup, addAllUEGroups, removeUEGroup } =
    useContext(GeoMapContext);

  const { data: equipmentsGroupsData, isLoading: equipmentsGroupsLoading } =
    useQuery(
      [QueryName.GET_ALL_UE_GROUPS, 'geomap_groups_equipments', site],
      () =>
        getAllUEGroups({
          organization: user?.organizations?.[0] as string,
          site,
        }),
      {
        enabled: !!user?.organizations?.[0] && !!site,
        cacheTime: 0,
        onSuccess: () => addAllUEGroups([]),
      },
    );

  const { data: equipmentsData, isLoading: equipmentsLoading } = useQuery(
    [QueryName.GET_ALL_UES, site, 'geomap_unit_equipments'],
    () => getAllUes({ organization: user?.organizations?.[0] as string, site }),
    {
      enabled: !!user?.organizations?.[0] && !!site,
      cacheTime: 0,
    },
  );

  const getALLUEs = (group: UEGroup) => {
    const ues: GeoMapUE[] = [];
    if (group.ues?.length) {
      group.ues.forEach((groupUE) => {
        const ue = equipmentsData?.data.devices.find(
          (device) => device.ueid === groupUE,
        );
        if (ue) {
          ues.push({
            id: ue.ueid,
            name: ue.name,
            type: ue.type,
            geometry: null,
          });
        }
      });
    }
    return ues;
  };

  const selectUEGroup = (group: UEGroup) => {
    addNewUEGroup({
      id: group.id,
      name: group.groupName,
      ues: getALLUEs(group),
    });
  };

  const selectAllGroupEquipment = () => {
    const tempGroups: GeoMapUEGroup[] = [];
    equipmentsGroupsData?.data?.groups?.forEach((ueGroup) => {
      tempGroups.push({
        id: ueGroup.id,
        name: ueGroup.groupName,
        ues: getALLUEs(ueGroup),
      });
      addAllUEGroups([...tempGroups]);
    });
  };

  return (
    <Box>
      <Typography variant='h4' px='10px' pt='15px'>
        Groups of equipment
      </Typography>
      <Box pl='18px'>
        <Loading isLoading={equipmentsGroupsLoading || equipmentsLoading}>
          <Box display='flex' flexDirection='column'>
            {!filter && (equipmentsGroupsData?.data?.total as number) > 0 && (
              <FormControlLabel
                key='all'
                checked={equipmentsGroupsData?.data?.total === ueGroups.length}
                onChange={(_, checked) =>
                  checked ? selectAllGroupEquipment() : addAllUEGroups([])
                }
                control={<Checkbox size='small' />}
                label={
                  <Typography fontSize='14px' color='#222222'>
                    All
                  </Typography>
                }
              />
            )}
            {equipmentsGroupsData?.data?.total === 0 && (
              <Typography fontSize='14px' color='#222222' mt='5px'>
                No ue groups found
              </Typography>
            )}
            {equipmentsGroupsData?.data?.groups
              .filter((group) =>
                group.groupName.toLowerCase().includes(filter.toLowerCase()),
              )
              .map((group) => (
                <FormControlLabel
                  key={group.id}
                  checked={
                    ueGroups.findIndex((ueGroup) => ueGroup.id === group.id) >
                    -1
                  }
                  onChange={(_, checked) =>
                    checked ? selectUEGroup(group) : removeUEGroup(group.id)
                  }
                  control={<Checkbox size='small' />}
                  label={
                    <Box display='flex' alignItems='center'>
                      <Typography fontSize='14px' color='#222222' noWrap>
                        {group.groupName}
                      </Typography>
                      <Typography
                        noWrap
                        fontSize={12}
                        sx={{
                          color: '#888888',
                          marginLeft: 1,
                        }}
                        width='190px'
                      >
                        {group.ues?.join(', ')}
                      </Typography>
                    </Box>
                  }
                />
              ))}
          </Box>
        </Loading>
      </Box>
    </Box>
  );
};
