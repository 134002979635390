import styled from '@emotion/styled'
import { Box, BoxProps as MuiBoxProps } from '@mui/material'

interface BoxProps extends MuiBoxProps {
  active: boolean;
}

export const StyledContainer = styled(Box, { shouldForwardProp: (prop) => prop !== 'active' })<BoxProps>`
  ${({ active }) =>
          active ? 'border: 1px solid #006161' : 'border: 1px solid #707070'};

  & {
    ${({ active }) => active ? 'background: #F1FCFA' : 'background: #FFFFFF'};
    cursor: pointer;

    img {
      height: 159px;
      width: 159px;
      transition: scale 0.1s ease-in;
    }

    :hover {
      box-shadow: 1px 1px 13px #00000029;
    }

    ${({ active }) => active && 'box-shadow: 1px 1px 5px #00000029'};
  }
`
