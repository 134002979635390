import React, { useContext, useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AppContext, ToastContext } from 'src/context';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { editApn, getApnDetails } from 'src/apis/apn';
import Map_, { Layer, MapRef, Marker, Source } from 'react-map-gl';
import { Controller, useForm } from 'react-hook-form';
import { QueryName } from 'src/enums/query';
import { Main } from 'src/layouts/Main';
import { Button, Loading } from 'src/components';
import { Box, Typography } from '@mui/material';
import { styles } from 'src/views/DeviceManagement/ManageNetwork/EditNetwork/styles';
import { circle } from '@turf/turf';
import { AreaControl } from './AreaControl';
import AddIcon from '@mui/icons-material/Add';
import { AxiosError, AxiosResponse } from 'axios';
import { UpdateApn } from 'src/types/domain';
import { Breadcrumbs } from 'src/components/Breadcrumbs';

const initialViewState = {
  latitude: 54.61745492998754,
  longitude: -5.905370495296432,
  zoom: 10,
};

export const EditNetwork: React.FC = () => {
  const { showToast } = useContext(ToastContext);
  const { sidebarOpen } = useContext(AppContext);
  const navigate = useNavigate();
  const { state } = useLocation();
  const mapRef = useRef<MapRef>(null);

  const [viewport, setViewport] = useState({
    latitude: 54.61745492998754,
    longitude: -5.905370495296432,
  });
  const [radius, setRadius] = useState(0);

  const { organization, site, apn } = useParams<{
    organization: string;
    site: string;
    apn: string;
  }>();

  const { data, isLoading, isFetching } = useQuery(
    [QueryName.GET_NETWORK_DETAILS, apn],
    () => getApnDetails(organization as string, site as string, apn as string),
    { enabled: !!organization && !!site },
  );

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      ambrUl: '',
      ambrDl: '',
      numberOfEquipment: 0,
      QCI: 0,
      latitude: 0,
      longitude: 0,
      radius: 0,
    },
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    if (data) {
      const response = data.data;
      reset({
        ambrUl: response.ambrDl,
        ambrDl: response.ambrUl,
        numberOfEquipment: response.dscp,
        QCI: response.qci,
      });
      if (response.longitude && response.latitude) {
        setViewport({
          longitude: response.longitude,
          latitude: response.latitude,
        });
        setRadius(response.radius);
      }
    }
  }, [data, reset]);

  useEffect(() => {
    setTimeout(() => {
      mapRef.current
        ?.setCenter({
          lat: viewport.latitude,
          lng: viewport.longitude,
        })
        .zoomTo(13);
    }, 300);
  }, [viewport]);

  useEffect(() => {
    setTimeout(() => {
      mapRef.current?.resize();
    }, 0);
  }, [sidebarOpen]);

  const editNetworkMutation = useMutation<AxiosResponse, AxiosError, UpdateApn>(
    {
      mutationFn: editApn,
      onSuccess: () => {
        showToast('Network edited successfully!', 'success');
        navigate('/devices');
      },
      onError: () => {
        showToast('Failed to edit Network, Please try again', 'error');
      },
    },
  );

  const handleSaveApn = async () => {
    const apnData = {
      organization,
      site,
      apn,
      latitude: viewport.latitude,
      longitude: viewport.longitude,
      radius,
    };
    editNetworkMutation.mutate(apnData);
  };

  const locationCircle = circle(
    [viewport.longitude, viewport.latitude],
    radius,
    {
      steps: 50,
      units: 'meters',
    },
  );

  return (
    <Main overrideStyles={{ padding: 0 }}>
      <Loading isLoading={isLoading || isFetching}>
        <Box id='selection-container' sx={styles.shadowBox}>
          <Box sx={styles.textBox}>
            <Breadcrumbs
              breadcrumbs={[
                {
                  title: 'Overview',
                  url: '/',
                },
                {
                  title: 'Device management',
                  url: '/devices',
                },
                { title: 'Edit ' + String(data?.data.name) },
              ]}
            />
            <Box display='flex' justifyContent='space-between' paddingTop='4px'>
              <Box sx={styles.networkDetailsBox}>
                <Typography variant='h1'>Edit APN | {apn}</Typography>
                <Typography variant='h3' mt='26px' mb='24px'>
                  Network details
                </Typography>
                <Box sx={{ display: 'flex' }}>
                  <Controller
                    name='ambrUl'
                    control={control}
                    render={({ field }) => (
                      <Box mb='24px'>
                        <Typography variant='h2' sx={styles.editSections}>
                          Uplink Limit
                        </Typography>
                        <Typography
                          width={218}
                          variant='body1'
                          {...field}
                          key={data?.data.qci}
                        >
                          {data?.data.ambrUl} mbps
                        </Typography>
                      </Box>
                    )}
                  />
                  <Controller
                    name='ambrDl'
                    control={control}
                    render={({ field }) => (
                      <Box mb='24px'>
                        <Typography variant='h2' sx={styles.editSections}>
                          Downlink Limit
                        </Typography>
                        <Typography
                          width={218}
                          variant='body1'
                          {...field}
                          key={data?.data.qci}
                        >
                          {data?.data.ambrDl} mbps
                        </Typography>
                      </Box>
                    )}
                  />
                </Box>
                <Box sx={{ display: 'flex', paddingBottom: '24px' }}>
                  <Controller
                    name='numberOfEquipment'
                    control={control}
                    render={({ field }) => (
                      <Box mb='12px'>
                        <Typography variant='h2' sx={styles.editSections}>
                          Number of equipment
                        </Typography>
                        <Typography
                          width={218}
                          variant='body1'
                          {...field}
                          key={data?.data.qci}
                        >
                          {data?.data.dscp}
                        </Typography>
                      </Box>
                    )}
                  />
                  <Controller
                    name='QCI'
                    control={control}
                    render={({ field }) => (
                      <Box mb='12px'>
                        <Typography variant='h2' sx={styles.editSections}>
                          QCI
                        </Typography>
                        <Typography
                          width={218}
                          variant='body1'
                          {...field}
                          key={data?.data.qci}
                        >
                          {data?.data.qci}
                        </Typography>
                      </Box>
                    )}
                  />
                </Box>
                <Box sx={styles.operationButtonsBox}>
                  <Button kind='primary' onClick={handleSubmit(handleSaveApn)}>
                    Save
                  </Button>
                  <Button
                    kind='primary-ghost'
                    sx={styles.cancelBtn}
                    onClick={() => {
                      if (state?.back) {
                        navigate(-1);
                      } else {
                        navigate('/devices');
                      }
                    }}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={styles.mapContainer}>
          <Box sx={styles.coverageRadiusContainer}>
            <Box sx={styles.controlAreaBox}>
              <AreaControl
                onCoordinatesChange={(coordinates) => {
                  setViewport({
                    ...coordinates,
                  });
                }}
                radius={radius}
                onRadiusChange={setRadius}
              />
            </Box>
          </Box>
          <Map_
            ref={mapRef}
            initialViewState={initialViewState}
            mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN_2}
            mapStyle='mapbox://styles/mapbox/satellite-streets-v9'
            style={styles.map}
          >
            <Marker
              longitude={viewport.longitude}
              latitude={viewport.latitude}
              anchor='center'
            >
              <AddIcon sx={styles.marker} />
            </Marker>
            <Source id='my-data' type='geojson' data={locationCircle}>
              <Layer id='point-90-hi' type='fill' paint={styles.layerPaint} />
            </Source>
          </Map_>
        </Box>
      </Loading>
    </Main>
  );
};
