import React from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Typography, TypographyProps } from '@mui/material';
import { styles } from './styles';

interface StatusProps extends Pick<TypographyProps, 'fontSize' | 'fontWeight'> {
  kind: 'success' | 'warning' | 'danger';
  text: string;
}

export const Status: React.FC<StatusProps> = ({
  kind,
  text,
  fontSize,
  fontWeight,
}) => {
  return (
    <>
      <Box display='flex' alignItems='center'>
        {kind === 'success' ? (
          <CheckCircleIcon style={styles.successStatusIcon} />
        ) : kind === 'warning' ? (
          <ErrorIcon style={styles.warningStatusIcon} />
        ) : (
          <CancelIcon style={styles.dangerStatusIcon} />
        )}
        <Typography
          variant='body1'
          fontSize={fontSize ?? 12}
          fontWeight={fontWeight}
          style={
            kind === 'success'
              ? styles.successText
              : kind === 'warning'
              ? styles.warningText
              : styles.dangerText
          }
        >
          {text}
        </Typography>
      </Box>
    </>
  );
};
