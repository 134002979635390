import { Box, Typography } from '@mui/material';

import { Main } from 'src/layouts/Main';
import { Rule } from 'src/types/rule';
import { ExectionTrend } from './ExecutionTrend';
import { RecentEventLogs } from './RecentEventLogs';
import { SuccessTrend } from './SuccessTrend';

const filters = [
  {
    label: 'Day',
    value: 'DAY',
  },
  {
    label: 'Week',
    value: 'WEEK',
  },
  {
    label: 'Year',
    value: 'MONTH',
  },
];

interface EventLogsProps {
  selectedFilter: string;
  trendData: Rule['trendData'];
  executionData: Rule['executionData'];
  recentEventLogs: Rule['eventLogs'];
  onFilterChange: (filter: string) => void;
}

export const EventLogs: React.FC<EventLogsProps> = ({
  selectedFilter,
  trendData,
  executionData,
  recentEventLogs,
  onFilterChange,
}) => {
  return (
    <Main hideSidebar hideHeader overrideStyles={{ marginTop: 'unset' }}>
      <Box display='flex' columnGap='12px' mb='25px'>
        {filters.map((filter) => (
          <Box
            key={filter.value}
            padding='5px 15px'
            border={
              selectedFilter === filter.value
                ? '1px solid #006161'
                : '1px solid transparent'
            }
            sx={{
              background:
                selectedFilter === filter.value ? '#F1FCFA' : 'transparent',
              cursor: 'pointer',
              ':hover': {
                background: '#F1FCFA',
              },
            }}
            onClick={() => onFilterChange(filter.value)}
          >
            <Typography fontSize={14} color='#006161'>
              {filter.label}
            </Typography>
          </Box>
        ))}
      </Box>
      <Box display='flex' columnGap='79px'>
        <Box width={700}>
          <SuccessTrend trendData={trendData} />
          <ExectionTrend executionData={executionData} />
        </Box>
        <RecentEventLogs recentEventLogs={recentEventLogs} />
      </Box>
    </Main>
  );
};
