import { Box, InputLabel, SelectProps } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';

import { StyledErrorMessage, StyledFormControl, StyledSelect } from './styles';
import { Button } from '../Button';
import { forwardRef, ForwardRefRenderFunction } from 'react';

interface StyledSelectProps extends Omit<SelectProps, 'error'> {
  menu?: boolean;
  checkboxes?: boolean;
  width?: number;
  error?: string;
  onChange?: (event: any) => void;
  value?: string | string[];
  open?: boolean;
  hoverBackground?: string;
  onClose?: () => void;
  onOpen?: () => void;
  handleSubmit?: () => void;
}

export const Select_: ForwardRefRenderFunction<
  HTMLDivElement,
  StyledSelectProps
> = (
  {
    menu,
    checkboxes,
    error,
    width,
    label,
    children,
    value,
    handleSubmit,
    ...rest
  },
  ref,
) => {
  if (menu) {
    return (
      <StyledFormControl
        ref={ref}
        size='small'
        variant='filled'
        error={!!error}
        sx={{ width }}
      >
        <StyledSelect
          menu
          displayEmpty
          label={label}
          IconComponent={KeyboardArrowDown}
          value={value}
          {...rest}
        >
          {children}
        </StyledSelect>
      </StyledFormControl>
    );
  }

  if (checkboxes) {
    return (
      <StyledFormControl
        ref={ref}
        size='small'
        variant='filled'
        error={!!error}
        sx={{ width }}
      >
        <StyledSelect
          menu
          multiple
          displayEmpty={true}
          value={value ?? []}
          label={label}
          IconComponent={KeyboardArrowDown}
          {...rest}
        >
          {children}
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              margin: 0,
            }}
          >
            <Button kind='primary' onClick={handleSubmit}>
              Submit
            </Button>
          </Box>
        </StyledSelect>
      </StyledFormControl>
    );
  }

  return (
    <StyledFormControl
      ref={ref}
      size='small'
      variant='filled'
      error={!!error}
      sx={{ width }}
    >
      <InputLabel>{label}</InputLabel>
      <StyledSelect
        label={label}
        error={!!error}
        IconComponent={KeyboardArrowDown}
        value={value}
        {...rest}
      >
        {children}
      </StyledSelect>
      {error && <StyledErrorMessage>{error}</StyledErrorMessage>}
    </StyledFormControl>
  );
};

export const Select = forwardRef(Select_);
