import { Box, Grid, Typography } from '@mui/material';
import React, { FC, useState } from 'react';
import { Search } from '@carbon/icons-react';
import { EquipmentWrapper, styles } from './styles';
import { Button, Chip, TextField } from 'src/components';
import { AddEquipmentModal } from './AddEquipmentModal';

interface EquipmentObj {
  id: string;
  name: string;
  usage: string;
  status: boolean;
}

export const Equipments: FC = () => {
  const [showAddEquipmentModal, setShowAddEquipmentModal] = useState(false);
  const [searchText, setSearchQuery] = useState<string>('');
  let timer: ReturnType<typeof setTimeout>;
  const debounceSearch = (event: React.ChangeEvent) => {
    const value = (event.target as HTMLInputElement).value;
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      setSearchQuery(value);
    }, 500);
  };

  const openAddEquipmentModal = () => {
    setShowAddEquipmentModal(true);
  };

  const closeAddEquipmentModal = () => {
    setShowAddEquipmentModal(false);
  };

  const mockSampleData: EquipmentObj[] = [
    {
      id: 'UE 1',
      name: 'Equipment 1',
      usage: '1.73GB',
      status: true,
    },
    {
      id: 'UE 2',
      name: 'Equipment 2',
      usage: '1.50GB',
      status: false,
    },
    {
      id: 'UE 3',
      name: 'Equipment 3',
      usage: '1.00GB',
      status: true,
    },
    {
      id: 'UE 4',
      name: 'Equipment 4',
      usage: '0.50GB',
      status: false,
    },
  ];

  const searchedData = mockSampleData.filter((entry) =>
    Object.values(entry).some(
      (val) =>
        typeof val === 'string' &&
        val.toLowerCase().includes(searchText.toLowerCase()),
    ),
  );
  return (
    <>
      <AddEquipmentModal
        showModal={showAddEquipmentModal}
        closeModal={closeAddEquipmentModal}
      />
      <EquipmentWrapper>
        <Typography sx={styles.title}>My equipment</Typography>
        <Box sx={styles.optionsWraperBox}>
          <Box sx={styles.searchBox}>
            <TextField
              isSearchField
              placeholder='Search'
              id='search-equipments'
              InputProps={{
                endAdornment: <Search size={20} color='#888888' />,
              }}
              onChange={debounceSearch}
            />
          </Box>
          <Box sx={styles.addEquipmentBox}>
            <Button
              kind='primary'
              sx={styles.addEquipmentBtn}
              padding='7.7px 8px 7.7px 8px'
              onClick={openAddEquipmentModal}
            >
              Add an equipment
            </Button>
          </Box>
        </Box>
        <Box sx={styles.equipmentListWrapper}>
          {searchedData.map((equipment) => (
            <Grid
              sx={
                equipment.status
                  ? styles.equipmentItemWrapperActive
                  : styles.equipmentItemWrapperInactive
              }
              key={equipment.id}
              container
              spacing={0}
            >
              <Grid item xs={12} sx={styles.equipmentIdNameWrapper}>
                <Typography sx={styles.equipmentId}>{equipment.id}</Typography>
                <Typography sx={styles.equipmentName}>
                  {equipment.name}
                </Typography>
                <Typography sx={styles.equipmentUsage}>
                  {equipment.usage}
                </Typography>
              </Grid>
              <Grid item xs={12} sx={styles.equipmentStatusWrapper}>
                <Chip
                  kind={equipment.status ? 'primary' : 'default'}
                  variant='outlined'
                  label={equipment.status ? 'ACTIVE' : 'INACTIVE'}
                />
              </Grid>
            </Grid>
          ))}
        </Box>
      </EquipmentWrapper>
    </>
  );
};
