import * as Yup from 'yup';

export const editEndpointSchema = Yup.object().shape({
  vendor: Yup.string().required('Endpoint vendor is required'),
  description: Yup.string(),
  connectionInfo: Yup.object().when('vendor', {
    is: (vendor: string) =>
      vendor === 'ERICSSON_EP5G' || vendor === 'RHOMBUS_CCTV',
    then: Yup.object({
      url: Yup.string()
        .required('Url is required')
        .url('Url must be a valid url'),
      key: Yup.string().required('Key is required'),
    }),
  }),
});
