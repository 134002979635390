export const styles = {
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '20px 34px 15px 34px',
  },
  contentWrapper: {
    padding: '0 34px',
    height: '120px',
  },
  title: {
    fontSize: '16px',
    fontWeight: 'bold',
    maxWidth: '200px',
  },
  contactAdmin: {
    fontSize: '14px',
    color: '#006161',
    textDecoration: 'underline',
    marginLeft: '36px',
    padding: 0,
    ':hover': {
      cursor: 'pointer',
      textDecoration: 'none',
    },
  },
  addElementWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    margin: 0,
    height: '35px',
    width: '100%',
    background: '#EFF5F5 0% 0% no-repeat padding-box',
    cursor: 'pointer',
  },
  dialogActions: {
    padding: '10px 34px 27px 34px',
  },
  noDevice: {
    fontSize: '14px',
    color: '#888888',
    margin: '16px 0',
  },
  ueId: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
  ueName: {
    fontSize: '14px',
    marginLeft: '40px',
  },
  name: {
    fontSize: '14px',
  },
  owned: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#FF9900',
  },
  ownedByLoggedUser: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#00A410',
  },
  ownedWrapper: {
    display: 'flex',
    flexDirection: 'row',
    mt: '3px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  warningIcon: {
    margin: '0 10px',
    color: '#FF9900',
  },
  closeIcon: { padding: '8px 0' },
  checkboxIcon: {
    margin: '0 10px',
    color: '#006161',
  },
  checkIcon: {
    margin: '0 10px',
    color: '#00A410',
  },
};
