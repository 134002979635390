export const styles = {
  textBox: {
    width: '600px',
  },
  shadowBox: {
    height: 'calc(100vh - 50px)',
    overflowY: 'auto',
    boxShadow: '8px 0 5px -3px rgb(0 0 0 / 25%)',
    zIndex: 1,
    position: 'relative',
    paddingTop: '20px',
    paddingLeft: '20px',
  },
  chipBtn: {
    marginLeft: '12px',
    marginBottom: '6px',
  },
  marker: {
    cursor: 'pointer',
    color: '#3CDECB',
    width: '34px',
    height: '34px',
    filter: 'drop-shadow(0px 3px 6px #00000029)',
    '&.MuiSvgIcon-root:hover': {
      color: '#2EA392',
    },
  },
  mapContainer: {
    position: 'absolute',
    right: '0px',
    top: '0px',
    height: 'calc(100vh - 50px)',
  },
};
