import { TextFieldProps } from '@mui/material';
import { forwardRef, ForwardRefRenderFunction } from 'react';
import { StyledTextField } from './styles';

interface StyledTextFieldProps {
  width?: number;
  error?: string;
  isSearchField?: boolean;
}

const TextField_: ForwardRefRenderFunction<
  HTMLDivElement,
  StyledTextFieldProps & Omit<TextFieldProps, 'error'>
> = ({ width, error, ...rest }, ref) => {
  return (
    <StyledTextField
      ref={ref}
      size="small"
      variant="filled"
      width={width}
      error={!!error}
      helperText={error}
      {...rest}
    />
  );
};

export const TextField = forwardRef(TextField_);
