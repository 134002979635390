import styled from '@emotion/styled';
import {
  FormControl,
  FormControlProps,
  FormHelperText,
  Select,
  SelectProps,
} from '@mui/material';

export const StyledFormControl = styled(FormControl)<FormControlProps>`
  & {
    .MuiFormLabel-root {
      color: ${({ error }) => (error ? '#F20000' : '#888888')};
      top: -2px;
    }

    .MuiFormLabel-root.Mui-focused {
      top: 0px;
      font-size: 12px;
      color: ${({ error }) => (error ? '#F20000' : '#006161')};
    }
    .MuiFormLabel-root.MuiFormLabel-filled {
      top: 0px;
      font-size: 12px;
    }

    .Mui-focused {
      border-color: ${({ error }) => (error ? '#F20000' : '#006161')};
    }

    .Mui-focused,
    .MuiSelect-filled {
      background: #fff !important;
    }

    .MuiInputBase-root,
    .MuiInputBase-root:hover,
    .MuiFilledInput-root,
    .MuiFilledInput-root:hover {
      ::before,
      ::after {
        border-bottom: unset !important;
      }
    }

    .MuiSvgIcon-root {
      height: 27px;
      width: 19px;
    }
  }
`;

// https://github.com/emotion-js/emotion/issues/2193
const styledOptions = {
  shouldForwardProp: (name: string) => name !== 'menu',
};

export const StyledSelect = styled(Select, styledOptions)<
  SelectProps & { menu?: boolean; hoverBackground?: string }
>`
  & {
    border: 1px solid ${({ error }) => (error ? '#F20000' : '#cccccc')};
    border-radius: 2px;

    :hover {
      border-color: ${({ error }) => (error ? '#F20000' : '#006161')};
    }
    background: ${({ hoverBackground = '#fff !important' }) => hoverBackground};
    :hover {
      background: #fff;
    }
    .MuiSelect-select.MuiSelect-filled {
      :hover {
        background: ${({ hoverBackground = '#fff !important' }) =>
          hoverBackground};
      }
      padding-top: ${({ menu }) => (menu ? '4px' : '16px')};
      .MuiListItemText-root {
        margin: 0 !important;
      }
    }
  }
`;

export const StyledErrorMessage = styled(FormHelperText)`
  & {
    margin-left: 0;
    margin-top: 3px;
    line-height: 1.143;
    font-size: 10px;
  }
`;
