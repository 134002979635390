export enum QueryName {
  GET_ORG_NAMES = 'getOrgNames',
  GET_SITE_NAMES = 'getSiteNames',
  GET_ALARMS_BY_ORG = 'getAlarmsByOrg',
  GET_ALARMS_COUNT = 'getAlarmsCount',
  GET_VLAN_NAMES_BY_ORG = 'getVlanNamesByOrg',
  GET_APN_NAMES_BY_ORG = 'getApnNamesByOrg',
  GET_SITES_BY_ORG = 'getListOfSites',
  GET_VENDORS = 'getListofVendors',
  GET_ORG_PROP = 'getOrgProperties',
  GET_SITE_PROP = 'getSiteProperties',
  GET_ALARMS_BY_SEVERITY = 'getAlarmsBySeverity',
  GET_ALARMS_BY_TYPE = 'getAlarmsByType',
  GET_DASHBOARDS_LIST = 'getDashboardsList',
  GET_DASHBOARD_DETAILS = 'getDashboardDetails',
  GET_IMSI_NAMES_BY_ORG = 'getIMSINamesByOrg',
  GET_METRIC_NAMES = 'getMetricNames',
  GET_NODE_NAMES_BY_ORG = 'getNodeNamesByOrg',
  GET_INSTANT_METRICS_DATA = 'getInstantMetricsData',
  GET_METRICS_LEVEL = 'getMetricsLevel',
  GET_ENDPOINTS = 'getEndpoints',
  GET_ORG_WITH_SITES = 'getOrganizationsWithSites',
  GET_ENDPOINT_PROP = 'getEndpointProperties',
  GET_ALL_ENDPOINTS = 'getAllEndpoints',
  GET_ALL_APNS = 'getAllApns',
  GET_ALL_NODES = 'getAllNodes',
  GET_ALL_VLANS = 'getAllVlans',
  GET_ALL_UES = 'getAllUEs',
  GET_RANGE_METRICS_DATA = 'getRangeMetricsData',
  GET_ALL_RULES = 'getAllRules',
  GET_ALL_USER_EMAILS = 'getAllUserEmails',
  GET_ALL_USERS = 'getAllUsers',
  GET_ALL_ROLES = 'getAllRoles',
  GET_RULE_DETAIL = 'getRuleDetail',
  GET_ALL_UE_GROUPS = 'getAllUEGroups',
  GET_ALL_CHANNELS = 'getAllChannels',
  GET_COORDINATES = 'getCoordinates',
  GET_ALL_AUTHORS = 'getAllAuthors',
  GET_ALL_RULE_TYPES = 'getAllRuleTypes',
  GET_ALL_DEVICES = 'getAllDevices',
  GET_CONSUMPTION_SITE_TABLE_DATA = 'getConsumptionSiteTableData',
  GET_CONSUMPTION_APN_TABLE_DATA = 'getConsumptionApnTableData',
  GET_ALL_GROUPS = 'getAllGroups',
  GET_ALL_UE_TYPES = 'getAllUETypes',
  GET_CONSUMPTION_ANALYTICS = 'getConsumptionAnalytics',
  GET_DEVICE_DETAILS = 'getDeviceDetails',
  GET_SITE_DETAILS = 'getSiteDetails',
  GET_ALL_WATCHDOGS = 'getAllWatchdogs',
  GET_RULE_TREND = 'getRuleTrend',
  GET_APNS_ANALYTICS_DATA = 'getAPNsAnalyticsData',
  GET_PAST_COORDINATES = 'getPastCoordinates',
  GET_THRESHOLD_PAST_TREND = 'getThresholdPastTrend',
  GET_ORGANIZATION = 'getOrganization',
  GET_NETWORK_DETAILS = 'getNetworkDetails',
  GET_ALL_USER_GROUPS = 'getAllUserGroups',
  GET_PRODUCTS_LICENSE = 'getProductsLicense',
  GET_USER_DETAILS = 'getUserDetails',
  GET_USERS_NAME = 'getUsersName',
  GET_ALL_POLICIES = 'getAllPolicies',
  GET_USAGE_POLICY_DETAILS = 'getUsagePolicyDetails',
  GET_ALL_UNALLOCATED_UES = 'getAllUnallocatedUes',
}
