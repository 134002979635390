import axios, { AxiosRequestConfig } from 'axios';
import { LocalStorage } from 'src/enums/localStorage';

const anonymousEndpoints = [
  `${process.env.REACT_APP_BACKEND_URL}accessmanagement/v1/token/generate`,
];

export const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

function getAuthToken() {
  const token = localStorage.getItem(LocalStorage.ACCESS_TOKEN);
  if (token) {
    const authToken = JSON.parse(token);
    return `Bearer ${authToken}`;
  }
}

const authInterceptor = async (request: AxiosRequestConfig) => {
  if (request.url && anonymousEndpoints.includes(request.url)) {
    return request;
  }
  const authToken = getAuthToken();
  if (request.headers && !!authToken) {
    request.headers['Authorization'] = authToken;
  }
  return request;
};

api.interceptors.request.use(authInterceptor);
