import styled from '@emotion/styled';
import { Switch } from '@mui/material';

export const StyledSwitch = styled(Switch)`
  & {
    height: 46px;
    width: 74px;
    .MuiSwitch-switchBase {
      top: 5px;
      left: 6px;
      .MuiSwitch-thumb {
        width: 18px;
        height: 18px;
      }
    }
    .MuiSwitch-switchBase.Mui-checked {
      color: #fff;
      left: 12px;
    }

    .MuiSwitch-track {
      border-radius: 15px;
      background-color: #888888 !important;
      opacity: 1 !important;
    }

    .Mui-checked + .MuiSwitch-track {
      background-color: #007777 !important;
    }

    .MuiSwitch-track::before {
      ${({ checked }) =>
        checked
          ? `
      content: 'ON';
      left: 18px;
      `
          : `
          content: 'OFF';
          right: 18px;
        `}
      color: #fff;
      font-size: 11px;
      position: absolute;
      top: 14px;
    }
  }
`;
