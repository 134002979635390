import { ReactNode, createContext, FC, useState } from 'react';
import { Widget } from 'src/types/widget';

const defaultValues: {
  widget: Widget | null;
  updateWidget: (data: Partial<Widget>) => void;
} = {
  widget: null,
  updateWidget: () => {},
};

export const AddWidgetContext = createContext(defaultValues);

interface AddWidgetContextProviderProps {
  children: ReactNode;
}

export const AddWidgetContextProvider: FC<AddWidgetContextProviderProps> = ({
  children,
}) => {
  const [widget, setWidget] = useState<Widget | null>(null);

  const updateWidget = (data: Partial<Widget>) => {
    const updatedWidget = {
      ...widget,
      ...data,
    };
    setWidget(updatedWidget as Widget);
  };

  return (
    <AddWidgetContext.Provider value={{ widget, updateWidget }}>
      {children}
    </AddWidgetContext.Provider>
  );
};
