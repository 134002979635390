import styled from '@emotion/styled';
import { TreeView } from '@mui/lab';
export const StyledTreeView = styled(TreeView)`
  & {
    .MuiTreeItem-root {
      direction: rtl;
      background-color: #eff5f5;
      width: 516px;
    }
    .MuiTreeItem-content.Mui-expanded:hover {
      background-color: rgba(101, 159, 159, 0.1);
    }
    .MuiTreeItem-content.Mui-selected {
      background-color: rgba(101, 159, 159, 0.1);
    }
    .MuiTreeItem-label {
      height: 40px;
      align-content: center;
    }
    .MuiTreeItem-content:hover {
      background-color: rgba(101, 159, 159, 0.1);
    }
    .MuiTreeItem-iconContainer {
      color: #006161;
      width: 57px;
    }
    .MuiTreeItem-content.Mui-disabled {
      background-color: #eff5f5;
      opacity: 1;
      cursor: default;
    }
  }
`;
