import { useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/system';
import { IconButton, SvgIcon, Tooltip, Typography } from '@mui/material';

import {
  differenceInHours,
  differenceInMilliseconds,
  differenceInMinutes,
  format,
  isSameMinute,
} from 'date-fns';
import { StyledSlider } from './styles';
import { ReactComponent as ResetTimeIcon } from 'src/assets/reset.svg';
import { ReactComponent as CalendarIcon } from 'src/assets/calendar.svg';
import { ReactComponent as Forward6Icon } from 'src/assets/forward-6.svg';

interface TimelineSliderProps {
  sliderValue: number;
  values?: Array<{
    value: number;
    date: Date;
  }>;
  selectedDate: Date | null;
  sliderDate: Date | null;
  onUpdateSliderValue: (value: number) => void;
  onChangeCurrentDate: (value: number) => void;
  goForward6h: () => void;
  onResetCurrentDateTime: () => void;
  onDispalyCustomDateSelect: () => void;
}

export const TimelineSlider: React.FC<TimelineSliderProps> = ({
  sliderValue,
  values,
  selectedDate,
  sliderDate,
  onUpdateSliderValue,
  onChangeCurrentDate,
  goForward6h,
  onResetCurrentDateTime,
  onDispalyCustomDateSelect,
}) => {
  const [now, setNow] = useState(new Date());
  const maxInFuture = !!(
    sliderDate && differenceInHours(sliderDate, new Date()) >= 18
  );

  const hasFutureDates = useMemo(() => {
    if (values?.length) {
      const firstValueInFuture = values.find(
        (value) => differenceInMilliseconds(new Date(), value.date) < 0,
      )?.value;
      if (!!firstValueInFuture || firstValueInFuture === 0) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }, [values]);

  const futurePercentage = useMemo(() => {
    if (values?.length) {
      const total = values.length;
      const firstValueInFuture = values.find(
        (value) => differenceInMinutes(new Date(), value.date) < 0,
      )?.value;
      if (firstValueInFuture === 0) {
        return 0;
      } else {
        if (firstValueInFuture) {
          return Math.round((firstValueInFuture / total) * 100);
        }
      }
    }
    return 100;
  }, [values]);

  const currentPercentage = useMemo(() => {
    if (values?.length) {
      const index = values.findIndex((value) => value.value === sliderValue);
      if (index > -1) {
        const total = index + 1;
        const firstValueInFuture = values.find(
          (value) => differenceInMinutes(now, value.date) < 0,
        )?.value;
        if (firstValueInFuture === 0) {
          return 0;
        } else {
          if (firstValueInFuture) {
            return (firstValueInFuture / total) * 100;
          }
        }
      }
    }
    return 100;
  }, [now, sliderValue, values]);

  useEffect(() => {
    const interval = setInterval(() => {
      setNow(new Date());
    }, 1000);
    return () => clearInterval(interval);
  });

  return (
    <Box>
      <Typography fontSize={16} fontWeight='bold' color='#006161'>
        Timeline
      </Typography>
      {hasFutureDates && (
        <Typography
          fontSize={10}
          color='#55CFBB'
          position='absolute'
          right={32}
          top={24}
        >
          Prediction
        </Typography>
      )}
      <StyledSlider
        futurePercentage={futurePercentage}
        currentPercentage={currentPercentage}
        valueLabelDisplay='auto'
        value={sliderValue}
        max={719}
        marks={values
          ?.filter((value) => value.date.getMinutes() === 0)
          .map((mark, index) => ({
            value: mark.value,
            label: (
              <Typography color='#659F9F' fontSize={10}>
                {index % 3 === 0 && format(mark.date, 'HH:mm')}
              </Typography>
            ),
          }))}
        valueLabelFormat={(value) => {
          const currentValue = values?.find(
            (mark) => mark.value === value,
          )?.date;
          if (!currentValue) {
            return '';
          }
          let label = 'NOW';
          const sameMin = isSameMinute(now, currentValue);
          if (!sameMin) {
            const diff = differenceInMilliseconds(now, currentValue);
            if (diff > 0) {
              label = 'PAST';
            } else if (diff < 0) {
              label = 'PREDICTION';
            }
          }
          return (
            <Box
              bgcolor='#F1FCFA'
              p={'8px'}
              borderRadius='4px'
              boxShadow='2px 2px 10px #00000029;'
              display='flex'
              alignItems='center'
              flexDirection='column'
            >
              <Typography
                border={
                  label === 'PREDICTION' ? '#55CFBB' : '1px solid #006161'
                }
                borderRadius='1px'
                width='max-content'
                padding='2px 8px'
                marginBottom='4px'
                fontSize='10px'
                color={label === 'PREDICTION' ? '#fff' : '#006161'}
                bgcolor={label === 'PREDICTION' ? '#56CFBB' : 'transparent'}
              >
                {label}
              </Typography>
              <Typography fontSize='10px' color='#006161'>
                {format(currentValue, 'dd/MM/yy HH:mm')}
              </Typography>
            </Box>
          );
        }}
        onChange={(_, value) => onUpdateSliderValue(value as number)}
        onChangeCommitted={(_, value) => onChangeCurrentDate(value as number)}
      />
      <Box display='flex' justifyContent='space-between'>
        <Box>
          {!maxInFuture && (
            <Tooltip title='Forward 6h'>
              <IconButton size='small' onClick={goForward6h}>
                <SvgIcon
                  sx={{
                    color: '#006161',
                    width: '22px',
                    height: '22px',
                    cursor: 'pointer',
                  }}
                >
                  <Forward6Icon />
                </SvgIcon>
              </IconButton>
            </Tooltip>
          )}
        </Box>
        <Box>
          {selectedDate && !isSameMinute(selectedDate, new Date()) && (
            <Tooltip title='Reset'>
              <IconButton size='small' onClick={onResetCurrentDateTime}>
                <SvgIcon
                  sx={{
                    color: '#006161',
                    width: '22px',
                    height: '22px',
                    cursor: 'pointer',
                  }}
                >
                  <ResetTimeIcon />
                </SvgIcon>
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title='Select a date'>
            <IconButton size='small' onClick={onDispalyCustomDateSelect}>
              <SvgIcon
                sx={{
                  color: '#006161',
                  width: '22px',
                  height: '22px',
                  cursor: 'pointer',
                }}
              >
                <CalendarIcon />
              </SvgIcon>
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </Box>
  );
};
