import { Box, Link, Typography } from '@mui/material';
import { Status } from 'src/components';

export const Overview: React.FC = () => {
  return (
    <Box>
      <Typography variant='h2' mb='16px'>
        Overview
      </Typography>
      <Box
        border='1px solid #FF9900'
        height='119px'
        width='693px'
        borderRadius='2px'
        padding='16px 32px'
        mb='14px'
      >
        <Status
          kind='warning'
          text='Data limit almost reached'
          fontSize={14}
          fontWeight='bold'
        />
        <Box ml='18px'>
          <Typography fontSize={12} fontWeight='bold' mt='2px'>
            Equipment to be blocked at 10.00GB.
          </Typography>
          <Typography fontSize={12}>
            According to your past consumption, we predict you&#39;ll reach your
            usage limit the 18/01/23.
          </Typography>
        </Box>
        <Box display='flex' justifyContent='flex-end' mt='6px'>
          <Link>Contact my admin</Link>
        </Box>
      </Box>
      <Box
        bgcolor='#F1FCFA'
        height='141px'
        width='693px'
        borderRadius='2px'
        boxShadow='0px 3px 6px #00000029'
        padding='25px 35px'
      >
        <Box display='flex' justifyContent='space-between' mb='10px'>
          <Typography fontWeight='bold'>Usage limit</Typography>
          <Typography color='#55CFBB' fontWeight='bold'>
            10.00 GB
          </Typography>
        </Box>

        <Box
          width={623}
          height={10}
          borderRadius={6}
          position='relative'
          sx={{
            background: '#CCCCCC',
          }}
        >
          <Box
            position='absolute'
            borderRadius='26px'
            width={400}
            height={20}
            top={-5}
            border='2px solid #fff'
            boxShadow='0px 3px 6px #00000029'
            sx={{
              background:
                'transparent linear-gradient(270deg, #55CFBB 0%, #1AAF97 100%) 0% 0% no-repeat padding-box',
            }}
          />
        </Box>

        <Box display='flex' justifyContent='space-between' mt='15px'>
          <Box display='flex'>
            <Typography fontWeight='bold' color='#888888' fontSize='12px'>
              Spent :
            </Typography>
            <Typography color='#888888' fontSize='12px' ml='3px'>
              7.35 GB
            </Typography>
          </Box>
          <Box>
            <Box display='flex'>
              <Typography fontWeight='bold' color='#888888' fontSize='12px'>
                Available :
              </Typography>
              <Typography color='#888888' fontSize='12px' ml='3px'>
                2.65 GB
              </Typography>
            </Box>
            <Box display='flex'>
              <Typography fontWeight='bold' color='#888888' fontSize='12px'>
                Until :
              </Typography>
              <Typography color='#888888' fontSize='12px' ml='3px'>
                11/11/11
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
