import { Box, Typography } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { percentageStyle, tableStyles } from '../tableStyles';

export const latencyColor = (latency: number, limits: number) => {
  function isWhatPercentOf (x: number, y: number) {
    return (x / y) * 100;
  }

  switch (true) {
    case (isWhatPercentOf(latency, limits) <= 70):
      return <Box>
        <Typography
          sx={tableStyles.latencyGreen}
        >{latency}<Typography sx={tableStyles.speedGreen}>ms</Typography>
          <CheckCircleIcon
            sx={{
              height: '15px!important',
              marginLeft: '15px',
            }} /></Typography>
      </Box>;
    case (isWhatPercentOf(latency, limits) <= 85 && isWhatPercentOf(latency, limits) > 70):
      return <Box>
        <Typography
          sx={tableStyles.latencyOrange}
        >{latency}<Typography
          sx={tableStyles.speedOrange}>ms</Typography><ErrorIcon
          sx={{
            height: '15px!important',
            marginLeft: '15px',
          }} /></Typography>
      </Box>;
    case (isWhatPercentOf(latency, limits) > 85):
      return <Box>
        <Typography
          sx={tableStyles.latencyRed}>
          {latency}<Typography sx={tableStyles.speedRed}>ms</Typography>
          <CancelIcon
            sx={{
              height: '15px!important',
              marginLeft: '15px',
            }} /></Typography>
      </Box>;
    default:
      return <Box>
        <Typography sx={percentageStyle}>{latency} ms</Typography>
      </Box>;
  }
};
