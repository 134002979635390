import React from 'react';
import { Box, Typography } from '@mui/material';
import { LocationFilled } from '@carbon/icons-react';

interface RenderedSiteInfoProps {
  siteName: string;
}

export const RenderedSiteInfo: React.FC<RenderedSiteInfoProps> = ({ siteName }) => (
  <>
    {siteName && (
      <Box display='flex' flexDirection='row' alignItems='center'>
        <LocationFilled fill='#006161' />
        <Typography
          sx={{
            marginLeft: '5px',
            color: '#006161',
            fontWeight: 'bold',
            fontSize: '14px',
          }}
        >
          {siteName}
        </Typography>
      </Box>
    )}
  </>
);
