import {
  Box,
  Button as MuiButton,
  ButtonGroup,
  Link,
  Typography,
} from '@mui/material';
import { styles } from './header-styles';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Button, TextField } from 'src/components';
import React, { useCallback, useEffect, useState } from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Breadcrumbs } from 'src/components/Breadcrumbs/index';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { consumptionCustomDateSchema } from 'src/yup-validations/consumption';

export const ConsumptionHeader = () => {
  const [params, setParams] = useSearchParams({
    timePeriod: 'FIVE_MIN',
  });

  const timePeriod = params.get('range') ?? 'FIVE_MIN';
  const startTime = params.get('startTime');
  const endTime = params.get('endTime');
  const criteria = params.get('criteria') ?? 'UPLINK';
  const apn = params.get('apn');
  const ues = params.get('ues');
  const navigate = useNavigate();
  const location = useLocation();

  const [displayCustomDateButtons, setDisplayCustomDateButtons] = useState(
    timePeriod === 'CUSTOM',
  );

  const {
    control,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm({
    defaultValues: {
      startTime: startTime ? new Date(startTime) : '',
      endTime: endTime ? new Date(endTime) : '',
    },
    resolver: yupResolver(consumptionCustomDateSchema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const handlePeriodChange = useCallback(
    (periodType: string) => {
      reset({
        endTime: '',
        startTime: '',
      });
      setDisplayCustomDateButtons(false);
      setParams({
        criteria,
        range: periodType,
        ...(apn && { apn }),
        ...(ues && { ues }),
      });
    },
    [reset, setParams, criteria, apn, ues],
  );

  useEffect(() => {
    if (timePeriod === 'FIVE_MIN') {
      reset({
        endTime: '',
        startTime: '',
      });
      setDisplayCustomDateButtons(false);
    }
  }, [reset, timePeriod]);

  const applyCustomRangeFilter = (data: {
    startTime: Date | string;
    endTime: Date | string;
  }) => {
    setParams({
      criteria,
      range: 'CUSTOM',
      startTime: (data.startTime as Date).toISOString() ?? '',
      endTime: (data.endTime as Date).toISOString() ?? '',
      ...(apn && { apn }),
      ...(ues && { ues }),
    });
  };

  return (
    <Box>
      <Breadcrumbs
        breadcrumbs={
          location.pathname === '/consumption/apn'
            ? [
                {
                  title: 'Overview',
                  url: '/',
                },
                {
                  title: 'Consumption',
                  url: '/consumption/site?criteria=UPLINK&range=FIVE_MIN',
                },
                {
                  title: String(apn),
                },
              ]
            : [
                {
                  title: 'Overview',
                  url: '/',
                },
                {
                  title: 'Consumption',
                },
              ]
        }
      />
      <Box
        display='flex'
        justifyContent='space-between'
        sx={{ paddingBottom: '4px' }}
      >
        <Typography sx={styles.consumption}>Consumption</Typography>
        <Box>
          <ButtonGroup variant='outlined' aria-label='text button group'>
            <MuiButton
              sx={
                timePeriod === 'FIVE_MIN' && !displayCustomDateButtons
                  ? styles.border
                  : styles.timeLineButton
              }
              onClick={() => {
                handlePeriodChange('FIVE_MIN');
              }}
            >
              5 Min
            </MuiButton>
            <MuiButton
              sx={
                timePeriod === 'FIFTEEN_MIN' && !displayCustomDateButtons
                  ? styles.border
                  : styles.timeLineButton
              }
              onClick={() => {
                handlePeriodChange('FIFTEEN_MIN');
              }}
            >
              15 Min
            </MuiButton>
            <MuiButton
              sx={
                timePeriod === 'HOUR' && !displayCustomDateButtons
                  ? styles.border
                  : styles.timeLineButton
              }
              onClick={() => {
                handlePeriodChange('HOUR');
              }}
            >
              Hour
            </MuiButton>
            <MuiButton
              sx={
                timePeriod === 'DAY' && !displayCustomDateButtons
                  ? styles.border
                  : styles.timeLineButton
              }
              onClick={() => {
                handlePeriodChange('DAY');
              }}
            >
              Day
            </MuiButton>
            <MuiButton
              sx={
                timePeriod === 'WEEK' && !displayCustomDateButtons
                  ? styles.border
                  : styles.timeLineButton
              }
              onClick={() => {
                handlePeriodChange('WEEK');
              }}
            >
              Week
            </MuiButton>
            <MuiButton
              sx={
                timePeriod === 'MONTH' && !displayCustomDateButtons
                  ? styles.border
                  : styles.timeLineButton
              }
              onClick={() => {
                handlePeriodChange('MONTH');
              }}
            >
              Year
            </MuiButton>
            <MuiButton
              sx={displayCustomDateButtons ? styles.border : styles.customize}
              onClick={() => {
                setDisplayCustomDateButtons(true);
              }}
            >
              Customize
            </MuiButton>
          </ButtonGroup>
        </Box>
      </Box>

      <Box
        display='flex'
        style={{
          justifyContent:
            location.pathname === '/consumption/apn'
              ? 'space-between'
              : 'flex-end',
        }}
        alignItems='center'
      >
        {location.pathname === '/consumption/apn' && (
          <Box display='flex' alignItems='center'>
            <ArrowBackIosIcon
              sx={{
                height: '16px',
                color: '#006161',
              }}
            />
            <Link
              sx={{
                fontSize: '16px',
                fontWeight: 'bold',
                color: '#006161',
              }}
              onClick={() =>
                navigate(
                  `/consumption/site?criteria=${criteria}&range=${timePeriod}`,
                )
              }
            >
              Back to APNs
            </Link>
          </Box>
        )}

        <Box height='58px'>
          {displayCustomDateButtons && (
            <Box display='flex' justifyContent='flex-end'>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Controller
                  name='startTime'
                  control={control}
                  render={({ field: { ...restFieldProps } }) => (
                    <DateTimePicker
                      label='Starting from'
                      inputFormat='dd/MM/yyyy HH:mm'
                      renderInput={({ error: _, ...rest }) => (
                        <TextField
                          sx={{ width: '236px', marginRight: '10px' }}
                          error={
                            errors.startTime?.message
                              ? errors.startTime?.message
                              : undefined
                          }
                          {...rest}
                        />
                      )}
                      {...restFieldProps}
                    />
                  )}
                />
                <Controller
                  name='endTime'
                  control={control}
                  render={({ field: { ...restFieldProps } }) => (
                    <DateTimePicker
                      label='Ending On'
                      inputFormat='dd/MM/yyyy HH:mm'
                      renderInput={({ error: _, ...rest }) => (
                        <TextField
                          sx={{ width: '236px' }}
                          error={
                            errors.endTime?.message
                              ? errors.endTime?.message
                              : undefined
                          }
                          {...rest}
                        />
                      )}
                      {...restFieldProps}
                    />
                  )}
                />
              </LocalizationProvider>
              <Box>
                <Button
                  kind='primary'
                  padding='12px 40px'
                  sx={{ ml: 1 }}
                  onClick={handleSubmit(applyCustomRangeFilter)}
                >
                  Save
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};
