import {
  Autocomplete,
  Box,
  ListItemText,
  MenuItem,
  Typography,
} from '@mui/material';
import { Main } from 'src/layouts/Main';
import { useMutation, useQuery } from '@tanstack/react-query';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Button,
  Checkbox,
  Chip,
  Loading,
  Select,
  TextField,
} from 'src/components';
import { StyledMenuItem } from 'src/views/Rules/rule/components/Specification/GeneralInformation/styles';
import { styles } from './styles';
import { QueryName } from 'src/enums/query';
import {
  addUEsToGroup,
  AddUEToGroupData,
  editUe,
  getAllUETypes,
  getUEDetails,
  removeUEsFromGroup,
} from 'src/apis/ue';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { AxiosError, AxiosResponse } from 'axios';
import {
  AppContext,
  AuthContext,
  LicenseContext,
  ToastContext,
} from 'src/context';
import { UEGroup, UpdateUE } from 'src/types/domain';
import { getAllGroups } from 'src/apis/group';
import { isArrayEqual } from 'src/utils/array';
import { getAllWatchDogs } from 'src/apis/watchdogs';
import { Device } from 'src/types/device';
import Map_, { MapRef, Marker, MarkerDragEvent } from 'react-map-gl';
import { FmdGood } from '@mui/icons-material';
import { GeocoderControl } from 'src/components/MapSearch';
import { Breadcrumbs } from 'src/components/Breadcrumbs/index';
import { getAllApnsData } from 'src/apis/apn';
import { getUsersNameList } from 'src/apis/access-management';
import { UsersList } from 'src/types/user';

type UeInputs = {
  name: string;
  type: string;
  brand: string;
  model: string;
  serial: string;
  groups: string[];
  watchdogs: string[];
  apn: string;
  user: string;
};

const initialViewState = {
  latitude: 54.61745492998754,
  longitude: -5.905370495296432,
  zoom: 10,
};

export const EditDevice: React.FC = () => {
  const { user } = useContext(AuthContext);
  const { hasNDOLicense } = useContext(LicenseContext);
  const { showToast } = useContext(ToastContext);
  const { sidebarOpen } = useContext(AppContext);
  const navigate = useNavigate();
  const { state } = useLocation();
  const mapRef = useRef<MapRef>(null);

  const [viewport, setViewport] = useState({
    latitude: 54.61745492998754,
    longitude: -5.905370495296432,
    flag: false,
  });

  const setCoordinates = (event: any) => {
    const coordinates = event?.result?.geometry?.coordinates;
    if (coordinates && coordinates.length === 2) {
      setViewport({
        longitude: coordinates[0],
        latitude: coordinates[1],
        flag: true,
      });
    }
  };

  const onMarkerDragEnd = useCallback((event: MarkerDragEvent) => {
    setViewport({
      longitude: event?.lngLat.lng,
      latitude: event?.lngLat.lat,
      flag: true,
    });
  }, []);

  const { organization, site, ueid } = useParams<{
    organization: string;
    site: string;
    ueid: string;
  }>();

  const { data: ueTypesData } = useQuery([QueryName.GET_ALL_UE_TYPES], () =>
    getAllUETypes(),
  );

  const { data: groupsData } = useQuery(
    [QueryName.GET_ALL_GROUPS],
    () => getAllGroups(organization as string, site as string),
    { enabled: !!organization && !!site },
  );

  const { data, isLoading, isFetching } = useQuery(
    [QueryName.GET_DEVICE_DETAILS, ueid],
    () => getUEDetails(organization as string, site as string, ueid as string),
    { enabled: !!organization && !!site && !!ueid },
  );

  const { data: watchdogsData } = useQuery(
    [QueryName.GET_ALL_WATCHDOGS],
    () => getAllWatchDogs(organization as string, site as string),
    { enabled: !!organization && !!site },
  );

  const { data: apnData } = useQuery(
    [QueryName.GET_ALL_APNS, user?.organizations?.[0]],
    () =>
      getAllApnsData(
        organization as string,
        site !== '' ? site : undefined,
        undefined,
        1,
        10,
      ),
    {
      enabled: !!user?.organizations?.length && !!site,
    },
  );

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      apn: '',
      type: '',
      name: '',
      brand: '',
      model: '',
      serial: '',
      groups: [] as string[],
      watchdogs: [] as string[],
      user: '',
    },
  });

  useEffect(() => {
    if (data) {
      const response = data.data;
      let user = '';
      if (response.ownerFirstName ?? response.ownerLastName) {
        if (response.ownerLastName) {
          user += `${response.ownerLastName}`;
        }
        if (response.ownerFirstName) {
          user += ` ${response.ownerFirstName}`;
        }
      }
      reset({
        type: response.type,
        name: response.name,
        brand: response.brand,
        model: response.model,
        serial: response.serialNumber,
        groups: response.ueGroups?.length ? response.ueGroups : [],
        apn: response.apn,
        watchdogs: response.linkedWatchDogs?.length
          ? response.linkedWatchDogs
          : [],
        user,
      });
      if (response.longitude && response.latitude) {
        setViewport({
          longitude: response.longitude,
          latitude: response.latitude,
          flag: true,
        });
      }
    }
  }, [data, reset]);

  useEffect(() => {
    setTimeout(() => {
      mapRef.current
        ?.setCenter({
          lat: viewport.latitude,
          lng: viewport.longitude,
        })
        .zoomTo(13);
    }, 300);
  }, [viewport]);

  useEffect(() => {
    setTimeout(() => {
      mapRef.current?.resize();
    }, 0);
  }, [sidebarOpen]);

  const editDeviceMutation = useMutation<AxiosResponse, AxiosError, UpdateUE>({
    mutationFn: editUe,
    onSuccess: () => {
      showToast('UE edited successfully!', 'success');
      navigate('/devices');
    },
    onError: () => {
      showToast('Failed to edit UE, Please try again', 'error');
    },
  });

  const { data: userList } = useQuery(
    [QueryName.GET_USERS_NAME, organization],
    () => getUsersNameList(organization as string),
    { enabled: !!organization },
  );

  const handleSave = async (input: UeInputs) => {
    const selectedUser =
      userList?.data?.users?.filter((user: UsersList) =>
        input.user.includes(user.fullName),
      ) ?? [];
    const ueData: UpdateUE = {
      organization: organization as string,
      site: site as string,
      name: input.name,
      type: input.type,
      brand: input.brand,
      model: input.model,
      serialNumber: input.serial,
      imsi: data?.data.imsi ?? '',
      latitude: viewport.latitude,
      longitude: viewport.longitude,
      vehicleUrl: '',
      linkedWatchDogs: input.watchdogs,
      ueGroups: input.groups,
      apn: input.apn,
      ownerLastName: selectedUser[0]?.lastName,
      ownerFirstName: selectedUser[0]?.firstName,
      ownerEmail: selectedUser[0]?.email,
    };
    if (!isArrayEqual(data?.data.ueGroups ?? [], input.groups)) {
      const addGroup: string[] = [];
      const removeGroup: string[] = [];
      const responseGroups: string[] = data?.data.ueGroups ?? [];
      input.groups.forEach((groupName) => {
        if (!responseGroups.includes(groupName)) {
          const groups: UEGroup[] = groupsData?.data.groups.filter(
            (groups: UEGroup) => groups.groupName === groupName,
          );
          groups.forEach((group) => {
            addGroup.push(group.id);
          });
        }
      });
      responseGroups.forEach((groupName) => {
        if (!input.groups.includes(groupName)) {
          const groups: UEGroup[] = groupsData?.data.groups.filter(
            (groups: UEGroup) => groups.groupName === groupName,
          );
          groups.forEach((group) => {
            removeGroup.push(group.id);
          });
        }
      });
      // eslint-disable-next-line @typescript-eslint/no-for-in-array
      for (const groupKey in addGroup) {
        const addUE: AddUEToGroupData = {
          id: addGroup[groupKey],
          organization: organization as string,
          site: site as string,
          ues: [data?.data.ueid as string],
        };
        await addUEsToGroupMutation.mutate(addUE);
      }
      // eslint-disable-next-line @typescript-eslint/no-for-in-array
      for (const groupKey in removeGroup) {
        const addUE: AddUEToGroupData = {
          id: removeGroup[groupKey],
          organization: organization as string,
          site: site as string,
          ues: [data?.data.ueid as string],
        };
        await removeUEsFromGroupMutation.mutate(addUE);
      }
    }
    editDeviceMutation.mutate(ueData);
  };

  const addUEsToGroupMutation = useMutation<
    AxiosResponse,
    AxiosError,
    AddUEToGroupData
  >({
    mutationFn: addUEsToGroup,
  });

  const removeUEsFromGroupMutation = useMutation<
    AxiosResponse,
    AxiosError,
    AddUEToGroupData
  >({
    mutationFn: removeUEsFromGroup,
  });
  return (
    <Main overrideStyles={{ padding: 0 }}>
      <Loading isLoading={isLoading || isFetching}>
        <Box
          id='selection-container'
          display='flex'
          width='50%'
          sx={styles.shadowBox}
        >
          <Box sx={styles.textBox}>
            <Breadcrumbs
              breadcrumbs={[
                {
                  title: 'Overview',
                  url: '/',
                },
                {
                  title: 'Device management',
                  url: '/devices',
                },
                { title: 'Edit ' + String(data?.data.name) },
              ]}
            />
            <Box display='flex' justifyContent='space-between'>
              <Box
                display='flex'
                alignItems='flex-start'
                flexDirection='column'
                width='100%'
              >
                <Typography variant='h1'>
                  {data?.data.ueid}{' '}
                  {data?.data.type ? ' | Edit ' + String(data?.data.type) : ''}
                  <Chip
                    sx={styles.chipBtn}
                    kind={data?.data.isOnline === true ? 'primary' : 'default'}
                    variant='outlined'
                    label={data?.data.isOnline === true ? 'ACTIVE' : 'INACTIVE'}
                  />
                </Typography>
                <Typography variant='h3' mt='40px' mb='15px'>
                  Equipment details
                </Typography>
                <Box width='100%'>
                  <Controller
                    control={control}
                    name='type'
                    render={({ field: { ...rest } }) => (
                      <Select
                        {...rest}
                        label='UE type'
                        width={238}
                        sx={styles.inputsMargin}
                        placeholder='Select UE type'
                      >
                        {ueTypesData?.data.map((type) => (
                          <MenuItem key={type} value={type}>
                            {type}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  <Controller
                    name='name'
                    control={control}
                    render={({ field }) => (
                      <TextField width={218} label='Object name' {...field} />
                    )}
                  />
                </Box>
                <Box mt='10px'>
                  <Controller
                    name='brand'
                    control={control}
                    render={({ field }) => (
                      <TextField
                        width={218}
                        sx={styles.inputsMargin}
                        label='Brand'
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name='model'
                    control={control}
                    render={({ field }) => (
                      <TextField width={218} label='Model' {...field} />
                    )}
                  />
                </Box>
                <Box mt='10px' mb='40px'>
                  <Controller
                    name='serial'
                    control={control}
                    render={({ field }) => (
                      <TextField width={218} label='Serial number' {...field} />
                    )}
                  />
                </Box>

                <Typography variant='h3' mb='14px'>
                  Networks
                </Typography>
                <Box mb='40px'>
                  <Controller
                    control={control}
                    name='apn'
                    render={({ field: { ...rest } }) => (
                      <Select
                        {...rest}
                        label='APN'
                        width={238}
                        sx={styles.inputsMargin}
                        placeholder='Select content here'
                      >
                        {apnData?.data?.apns.map((apn) => (
                          <MenuItem key={apn.name} value={apn.name}>
                            {apn.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </Box>
                <Typography variant='h3' mb='14px'>
                  Relationships
                </Typography>
                <Controller
                  name='groups'
                  control={control}
                  render={({ field: { onChange, ...rest } }) => (
                    <Autocomplete
                      multiple
                      disableCloseOnSelect
                      options={
                        groupsData?.data?.groups.map(
                          (group: UEGroup) => group.groupName,
                        ) ?? []
                      }
                      getOptionLabel={(option: string) => option}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label='Groups'
                          sx={styles.groupsInput}
                        />
                      )}
                      onChange={(_, value) => onChange(value)}
                      renderOption={(props, option, { selected }) => (
                        <StyledMenuItem {...props}>
                          <Checkbox checked={selected} />
                          <ListItemText primary={option} />
                        </StyledMenuItem>
                      )}
                      {...rest}
                    />
                  )}
                />
                {hasNDOLicense && (
                  <Controller
                    name='watchdogs'
                    control={control}
                    render={({ field: { onChange, ...rest } }) => (
                      <Autocomplete
                        multiple
                        disableCloseOnSelect
                        options={
                          watchdogsData?.data?.devices.map(
                            (device: Device) => device.ueid,
                          ) ?? []
                        }
                        getOptionLabel={(option: string) => option}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label='Watchdogs'
                            sx={styles.watchdogsInput}
                          />
                        )}
                        onChange={(_, value) => onChange(value)}
                        renderOption={(props, option, { selected }) => (
                          <StyledMenuItem {...props}>
                            <Checkbox checked={selected} />
                            <ListItemText primary={option} />
                          </StyledMenuItem>
                        )}
                        {...rest}
                      />
                    )}
                  />
                )}
                <Box mt='10px'>
                  <Controller
                    control={control}
                    name='user'
                    render={({ field: { ...rest } }) => (
                      <Select
                        {...rest}
                        label='Owner'
                        width={238}
                        sx={styles.inputsMargin}
                        placeholder='Owner'
                      >
                        {userList?.data?.users?.map((user) => (
                          <MenuItem key={user.userId} value={user.fullName}>
                            {user.fullName}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </Box>
                <Box
                  display='flex'
                  width='456px'
                  mt='30px'
                  flexDirection='row-reverse'
                >
                  <Button kind='primary' onClick={handleSubmit(handleSave)}>
                    Save
                  </Button>
                  <Button
                    kind='primary-ghost'
                    sx={styles.cancelBtn}
                    onClick={() => {
                      if (state?.back) {
                        navigate(-1);
                      } else {
                        navigate('/devices');
                      }
                    }}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          id='selection-container'
          display='flex'
          width='50%'
          sx={styles.mapContainer}
        >
          <Map_
            ref={mapRef}
            initialViewState={initialViewState}
            mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN_2}
            mapStyle='mapbox://styles/mapbox/satellite-streets-v9'
            style={{
              width: '100%',
              height: '100%',
            }}
          >
            <Marker
              longitude={viewport.longitude}
              latitude={viewport.latitude}
              anchor='bottom'
              draggable
              onDragEnd={onMarkerDragEnd}
            >
              <FmdGood sx={styles.marker} />
            </Marker>
            <GeocoderControl
              mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN_2 as string}
              position='top-left'
              onResult={setCoordinates}
            />
          </Map_>
        </Box>
      </Loading>
    </Main>
  );
};
