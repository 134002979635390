import { useContext } from 'react';
import { Box, Button, Autocomplete, TextField } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { getMetricNames } from 'src/apis/metrics';
import { QueryName } from 'src/enums/query';
import { AddWidgetContext } from 'src/context/AddWidgetContext';
import { KPIWidget } from 'src/types/widget';
import { WidgetFieldsContainer } from '../WidgetFieldsContainer';
import { addKpiWidgetSchemaStepMetric } from 'src/yup-validations/widget';

type FormPayload = {
  metric_name: string;
};

interface MetricProps {
  onPreviousStep: () => void;
  onNextStep: () => void;
}

export const Metric: React.FC<MetricProps> = ({
  onPreviousStep,
  onNextStep,
}) => {
  const { widget: _widget, updateWidget } = useContext(AddWidgetContext);

  const widget = _widget as KPIWidget;

  const {
    control,
    formState: { isValid },
    handleSubmit,
  } = useForm({
    defaultValues: {
      metric_name: widget?.metricName ?? '',
    },
    resolver: yupResolver(addKpiWidgetSchemaStepMetric),
    mode: 'onChange',
  });

  const { data: metricNamesData } = useQuery([QueryName.GET_METRIC_NAMES], () =>
    getMetricNames(widget?.widgetType as string, widget?.metricsLevel),
  );

  const onSubmit = (data: FormPayload) => {
    updateWidget({
      metricName: data.metric_name,
    });
    onNextStep();
  };

  return (
    <WidgetFieldsContainer
      title="Select Metric"
      actions={
        <>
          <Button onClick={onPreviousStep}>Previous Step</Button>
          <Button
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            disabled={!isValid}
          >
            Next Step
          </Button>
        </>
      }
    >
      <Box mt={1}>
        <Controller
          name="metric_name"
          control={control}
          render={({ field: { onChange, ...rest } }) => (
            <Autocomplete
              fullWidth
              id="metric_name"
              options={metricNamesData?.data ?? []}
              onChange={(_, value) => onChange(value)}
              {...rest}
              renderInput={(params) => (
                <TextField {...params} label="Metric Name" />
              )}
            />
          )}
        />
      </Box>
    </WidgetFieldsContainer>
  );
};
