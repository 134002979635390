import React, { useContext, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Box, RadioGroup, Tooltip, Typography } from '@mui/material';

import { Confirmable, Radio, Select } from 'src/components';
import { AppContext, AuthContext, ToastContext } from 'src/context';
import { QueryName } from 'src/enums/query';
import { getSiteNames } from 'src/apis/sites';
import { LocalStorage } from 'src/enums/localStorage';
import { stylesSelectSite } from './styles';
import { RenderedSiteInfo } from './SiteInfo';

export const SelectSite: React.FC = () => {
  const { user } = useContext(AuthContext);
  const { site, selectSite } = useContext(AppContext);
  const { showToast } = useContext(ToastContext);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [selectedTemporarySite, setSelectedTemporarySite] = useState(site);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const organization = user?.organizations?.[0] as string;

  const { data: sitesData } = useQuery(
    [QueryName.GET_SITE_NAMES, organization],
    () => getSiteNames(organization),
    {
      enabled: !!organization,
      onSuccess: (data) => {
        const initialSite = localStorage.getItem(LocalStorage.SITE_NAME);
        if (initialSite && data?.data?.includes(initialSite)) {
          selectSite(initialSite);
        } else {
          selectSite(data?.data[0] ?? '');
        }
      },
    },
  );
  const availableSites = sitesData?.data;

  const openDropdown = () => {
    setDropdownOpen(true);
    handleTooltip(false);
  };

  const closeDropdown = () => {
    setDropdownOpen(false);
  };

  const handleTooltip = (bool: boolean) => {
    setTooltipOpen(bool);
  };

  const handleSelectSite = (value: string) => {
    setConfirmModalOpen(true);
    setSelectedTemporarySite(value);
  };

  const handleConfirmChangeSite = () => {
    selectSite(selectedTemporarySite);
    setConfirmModalOpen(false);
    showToast(
      `Site changed to ${selectedTemporarySite} successfully`,
      'success',
    );
    setSelectedTemporarySite('');
    closeDropdown();
    setTimeout(() => {
      handleTooltip(false);
    }, 300);
  };

  const handleCancel = () => {
    setConfirmModalOpen(false);
    setSelectedTemporarySite('');
  };

  return (
    <>
      <Confirmable
        open={confirmModalOpen}
        title='Change site'
        subTitle='Are you sure you want to change current site?'
        onConfirm={handleConfirmChangeSite}
        onCancel={handleCancel}
      />
      <Tooltip title='Change Site' open={tooltipOpen}>
        <Select
          onOpen={openDropdown}
          onClose={closeDropdown}
          onMouseEnter={() => {
            if (!dropdownOpen) {
              handleTooltip(true);
            } else {
              handleTooltip(false);
            }
          }}
          onMouseLeave={() => {
            handleTooltip(false);
          }}
          open={dropdownOpen}
          menu={true}
          hoverBackground='rgba(0, 97, 97, 0.1) !important'
          sx={stylesSelectSite.selectbtn}
          width={150}
          placeholder='Site'
          labelId='select-site-label'
          id='select-site'
          label='Site'
          value={site}
          aria-haspopup='true'
          aria-expanded={dropdownOpen ? 'true' : undefined}
          renderValue={() => <RenderedSiteInfo siteName={site} />}
        >
          <Box
            display='flex'
            justifyContent='flex-start'
            ml={3}
            mr={3}
            sx={{ zIndex: 2 }}
          >
            <Typography
              sx={{
                color: '#006161',
                fontWeight: 'bold',
                fontSize: '14px',
              }}
            >
              Select a site
            </Typography>
          </Box>
          <RadioGroup
            onChange={(event) => handleSelectSite(event.target.value)}
          >
            {availableSites?.map((availableSite: string) => (
              <Box
                key={availableSite}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  margin: '0 30px',
                }}
              >
                <Radio
                  label={availableSite}
                  value={availableSite}
                  checked={availableSite === site}
                />
              </Box>
            ))}
          </RadioGroup>
        </Select>
      </Tooltip>
    </>
  );
};
