import { CloudUploadOutlined } from '@mui/icons-material';
import { Button } from '@mui/material';

import { styles } from './styles';

interface FileUploadProps {
  multiple?: boolean;
  onChange: (files: FileList) => void;
}

export const FileUpload: React.FC<FileUploadProps> = ({
  multiple,
  onChange,
}) => {
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      onChange(event.target.files);
    }
  };

  return (
    <Button variant="outlined" component="label">
      <CloudUploadOutlined fontSize="small" sx={styles.uploadIcon} />
      Upload
      <input
        hidden
        multiple={multiple}
        type="file"
        onChange={handleFileChange}
      />
    </Button>
  );
};
