export const styles = {
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    bgcolor: 'background.paper',
    borderRadius: 1,
  },
  leftcontent: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    bgcolor: 'background.paper',
    borderRadius: 1,
    width: '70%',
  },
  rightContent: {
    width: '30%',
  },
  ruleType: {
    paddingLeft: '16px',
    paddingRight: '16px',
    color: '#659F9F',
  },
  heading: {
    display: 'flex',
    justifyContent: 'flex-start',
    bgcolor: 'background.paper',
    borderRadius: 1,
    '&.MuiBox-root': {
      paddingBottom: '6px',
    },
    alignItems: 'center',
  },
  boldText: {
    textAlign: 'left',
    color: '#222222',
    fontWeight: 'bold',
    fontSize: '16px',
  },
  description: {
    display: 'flex',
    justifyContent: 'flex-start',
    bgcolor: 'background.paper',
    borderRadius: 1,
    width: '60%',
    '&.MuiBox-root': {
      paddingBottom: '7px',
      paddingTop: '14px',
    },
  },
  tabContent: {
    marginInline: 'auto',
    paddingInline: '24px',
  },
  priorityKey: {
    fontSize: 14,
    color: '#55CFBB',
    fontWeight: 'bold',
    marginRight: '5px',
  },
  descriptionExpandLink: {
    fontSize: '12px',
    textDecoration: 'underline',
    color: '#888888',
    textDecorationColor: '#888888',
    ':hover': {
      textDecoration: 'none',
    },
  },
  descriptionExpandText: {
    fontSize: '12px',
    color: '#888888',
  },
  showMoreIcon: {
    color: '#707070',
    marginLeft: '5px',
    size: '16px',
  },
  boxShow: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
};
