import { Radio as MuiRadio } from '@mui/material';

import { StyledFormControlLabel } from './styles';

interface RadioProps {
  label: string;
  value: string | number | boolean;
  checked?: boolean;
}

export const Radio: React.FC<RadioProps> = ({ label, value, checked }) => {
  return (
    <StyledFormControlLabel
      value={value}
      control={<MuiRadio size="small" />}
      label={label}
      checked={checked}
    />
  );
};
