import {
  DialogActions,
  DialogContent,
  Button,
  DialogTitle,
} from '@mui/material';
import { useState } from 'react';

interface TimeWidgetProps {
  goBack: () => void;
}

export const TimeWidget: React.FC<TimeWidgetProps> = ({ goBack }) => {
  const [activeStep, setActiveStep] = useState(0);

  const timeWidgetSteps = [
    {
      title: 'Customize Widget',
      component: <></>,
    },
  ];

  const handlePreviousStep = () => {
    if (activeStep === 0) {
      goBack();
      return;
    }
    setActiveStep((step) => step - 1);
  };

  const handleNextStep = () => {
    if (activeStep === timeWidgetSteps.length - 1) {
      console.log('last step, create widget');
      return;
    }
    setActiveStep((step) => step + 1);
  };

  return (
    <>
      <DialogTitle>{timeWidgetSteps[activeStep].title}</DialogTitle>
      <DialogContent
        sx={{
          minHeight: '300px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {timeWidgetSteps[activeStep].component}
      </DialogContent>
      <DialogActions>
        <DialogActions>
          <Button onClick={handlePreviousStep}>Previous Step</Button>
          <Button variant="contained" onClick={() => handleNextStep()}>
            {activeStep === timeWidgetSteps.length - 1
              ? 'Add Widget'
              : 'Next Step'}
          </Button>
        </DialogActions>
      </DialogActions>
    </>
  );
};
