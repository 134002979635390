import styled from '@emotion/styled';
import { Box, BoxProps } from '@mui/material';

interface StyleButtonContentContainerProps extends BoxProps {
  active: boolean;
}

export const StyleButtonContentContainer = styled(
  Box,
)<StyleButtonContentContainerProps>`
  & {
    width: 90px;
    height: 90px;
    border: 2px solid #073b4c;
    border-radius: 4px;
    transition: background 0.1s ease-in;
    background: ${({ active }) => (active ? '#073b4c' : '#color')};
    color: ${({ active }) => (active ? '#fff' : '#073b4c')};
    .MuiSvgIcon-root {
      color: ${({ active }) => (active ? '#fff' : '#073b4c')};
    }
    :hover {
      background: #073b4c;
      color: #fff;
      .MuiSvgIcon-root {
        color: #fff;
      }
    }
  }
`;

export const styles = {
  widgetIcon: {
    color: '#073B4C',
    fontSize: '34px',
  },
  widgetsFieldContainer: {
    minHeight: '300px',
    pt: '12px',
  },
  flexWidgetsFieldContainer: {
    minHeight: '300px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  widgetTypeButtonContainer: {
    mx: '10px',
  },
  closeIcon: {
    position: 'absolute',
    right: '16px',
    top: '16px',
  },
};
