import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import More from 'highcharts/highcharts-more';
import { useEffect, useState } from 'react';
import { format } from 'date-fns';

More(Highcharts);

interface PastTrendProps {
  trendData: {
    organization?: string;
    site?: string;
    metricType?: string;
    recommendedValue?: number;
    maxValues?: Array<{ time: string; value: number }>;
    averageValues?: Array<{ time: string; value: number }>;
  };
}

export const PastTrend: React.FC<PastTrendProps> = ({ trendData }) => {
  const [data, setData] = useState(trendData);
  useEffect(() => {
    setData(trendData);
  }, [trendData]);
  const { recommendedValue, maxValues, averageValues } = data;
  const maxValueFactor =
    maxValues?.reduce(
      (max, obj) => (obj.value > max ? obj.value : max),
      maxValues[0].value,
    ) ?? 1;

  function reformatArray(
    arr: Array<{ time: string; value: number }> | undefined,
  ) {
    return arr?.map((item: { time: string; value: number }) => {
      const date = new Date(item.time);
      const xvalue = format(date, 'dd/MM H:00');
      return {
        xvalue,
        time: item.time,
        value: (item.value / maxValueFactor) * 100,
      };
    });
  }

  const formattedMaxValues = reformatArray(maxValues);
  const formattedAverageValues = reformatArray(averageValues);

  const options: Highcharts.Options = {
    chart: {
      height: 330,
      zooming: {
        type: 'x',
        resetButton: {
          theme: {
            fill: '#fff',
            stroke: '#55CFBB',
            style: {
              color: '#55CFBB',
            },
          },
        },
      },
    },
    credits: {
      enabled: false,
    },
    accessibility: {
      enabled: false,
    },
    title: {
      text: '',
    },
    legend: {
      enabled: false,
    },
    xAxis: {
      lineColor: 'transparent',
      tickColor: 'transparent',
      type: 'category',
      labels: {
        style: {
          color: '#888888',
          fontSize: '10px',
        },
      },
    },
    yAxis: {
      title: {
        text: '',
      },
      min: 0,
      max: 100,
      tickAmount: 6,
      labels: {
        style: {
          color: '#888888',
          fontSize: '10px',
        },
      },
      gridLineColor: 'transparent',
      plotLines: [
        {
          value: recommendedValue ?? 0,
          width: 1,
          color: '#006161',
          zIndex: 4,
          dashStyle: 'Dash',
          label: {
            text: 'Threshold value',
            style: {
              color: '#659F9F',
              fontSize: '10px',
            },
          },
        },
      ],
    },
    tooltip: {
      useHTML: true,
      backgroundColor: '#F1FCFA',
      borderColor: 'transparent',
      shadow: {
        offsetX: 0,
        offsetY: 0,
        width: 6,
        color: '#00000029',
      },
      shape: 'square',
      style: {
        pointerEvents: 'auto',
      },
      formatter: function () {
        let content = `<p style='text-align: center; margin: 0; color: #222222; font-size: 14px;'>${this.y}</p>`;
        content +=
          '<a href="#" style="color: #007777; text-decoration: underline;">Copy data</a>';
        return content;
      },
    },
    series: [
      {
        type: 'area',
        data: formattedMaxValues?.reduce(
          (
            prev: Array<[string, number]>,
            curr: { xvalue: string; value: number },
          ) => {
            prev.push([curr.xvalue, curr.value]);
            return prev;
          },
          [],
        ),
        marker: { enabled: false },
        zones: [
          {
            color: '#56CFBB',
            fillColor: '#bbece4',
          },
        ],
      },
      {
        type: 'area',
        data: formattedAverageValues?.reduce(
          (
            prev: Array<[string, number]>,
            curr: { xvalue: string; value: any },
          ) => {
            prev.push([curr.xvalue, Number(curr.value)]);
            return prev;
          },
          [],
        ),
        marker: { enabled: false },
        zones: [
          {
            color: '#006161',
            fillColor: '#a8ded7',
          },
        ],
      },
    ],
  };
  return (
    <Box>
      <Typography variant='h2' mb='10px'>
        Past trend
      </Typography>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </Box>
  );
};
