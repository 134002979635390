import { FC, useContext, useEffect, useRef, useState } from 'react';
import { ConsumptionHeader } from '../top-bar/consumption-header';
import { ConsumptionGraph } from './consumption-graph/consumption-graph';
import { ConsumptionTable } from './consumption-table/consumptionTable';
import { Main } from 'src/layouts/Main';
import { Typography } from '@mui/material';
import { AuthContext } from 'src/context';

export const SiteConsumption: FC = () => {
  const { user } = useContext(AuthContext);
  const selectedOrganization = user?.organizations?.[0] as string;

  const [currentTime, setCurrentTime] = useState(new Date().getTime());

  const refreshDataInterval = useRef<ReturnType<typeof setInterval> | null>(
    null,
  );

  useEffect(() => {
    if (refreshDataInterval.current) {
      clearInterval(refreshDataInterval.current);
    }
    refreshDataInterval.current = setInterval(() => {
      setCurrentTime(new Date().getTime());
    }, 30000);
  }, []);

  return (
    <>
      <Main overrideStyles={{ paddingBottom: 0 }}>
        {/* <Typography
          style={{ color: '#888888', fontSize: '12px', marginBottom: '14px' }}
        >
          Breadcrumbs
        </Typography> */}
        <ConsumptionHeader />
      </Main>
      <ConsumptionGraph
        org={selectedOrganization}
        level='SITE'
        currentTime={currentTime}
      />
      <Main hideHeader hideSidebar overrideStyles={{ marginTop: 'unset' }}>
        <ConsumptionTable currentTime={currentTime} />
      </Main>
    </>
  );
};
