import { Search } from '@carbon/icons-react';
import { Box, Link, TableCell, TableRow, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useContext, useState } from 'react';
import { getAllApnsData } from 'src/apis/apn';
import { Loading, Pagination, Table, TextField } from 'src/components';
import { AppContext, AuthContext } from 'src/context';
import { QueryName } from 'src/enums/query';
import { Apn } from 'src/types/domain';
import { Page } from 'src/types/shared';
import { styles } from './styles';
import { useNavigate } from 'react-router-dom';

const columns = [
  {
    label: 'Object',
    key: 'name',
  },
  {
    label: 'Number of UE',
    key: 'numberOfUEs',
  },
  {
    label: 'Uplink limit',
    key: 'uplinkLimit',
  },
  {
    label: 'Downlink limit',
    key: 'downlinkLimit',
  },
];

export const DeviceNetworkListing: React.FC = () => {
  const initialPages = {
    pageStart: 1,
    pageEnd: 10,
  };
  const [searchText, setSearchQuery] = useState<string>('');
  const { user } = useContext(AuthContext);
  const { site } = useContext(AppContext);
  const navigate = useNavigate();

  const [page, setPage] = useState<Page>(initialPages);
  const organization = user?.organizations?.[0] as string;

  const { data, isLoading, isFetching } = useQuery(
    [
      QueryName.GET_ALL_APNS,
      organization,
      site,
      searchText,
      page.pageStart,
      page.pageEnd,
    ],
    () =>
      getAllApnsData(
        organization,
        site !== '' ? site : undefined,
        searchText ?? undefined,
        page.pageStart,
        page.pageEnd,
      ),
    {
      onSuccess: (data) => {
        if (page.pageEnd > data?.data?.total) {
          setPage(initialPages);
        }
      },
      enabled: !!user?.organizations?.length && !!site,
    },
  );

  const rows = data?.data.apns.map((apn: Apn) => ({
    name: apn.name,
    numberOfUEs: apn.imsisConfigured,
    uplinkLimit: apn.ambrUl + 'mbps',
    downlinkLimit: apn.ambrDl + 'mbps',
  }));

  let timer: ReturnType<typeof setTimeout>;
  const debounceSearch = (event: React.ChangeEvent) => {
    const value = (event.target as HTMLInputElement).value;
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      setSearchQuery(value);
    }, 500);
  };

  const handlePageChange = (pageStart: number, pageEnd: number) => {
    setPage({
      pageStart,
      pageEnd,
    });
  };

  return (
    <>
      <Box sx={styles.selectWrapper}>
        <Box sx={styles.inputBox}>
          <TextField
            isSearchField
            placeholder='Search'
            id='search-networks'
            InputProps={{ endAdornment: <Search size={20} color='#888888' /> }}
            onChange={debounceSearch}
          />
        </Box>
      </Box>
      <Loading isLoading={isLoading || isFetching}>
        <Table
          columns={columns}
          renderRows={() =>
            rows?.map((row) => {
              return (
                <TableRow hover sx={styles.tableRow} key={row.name}>
                  <TableCell padding='none'>
                    <Link
                      onClick={() =>
                        navigate(
                          `/networks/${organization}/${site}/${row.name}`,
                          {
                            state: { back: true },
                          },
                        )
                      }
                      sx={styles.deviceName}
                    >
                      {row.name}
                    </Link>
                  </TableCell>
                  <TableCell padding='none'>
                    <Typography>{row.numberOfUEs}</Typography>
                  </TableCell>
                  <TableCell padding='none'>
                    <Typography>{row.uplinkLimit}</Typography>
                  </TableCell>
                  <TableCell padding='none'>
                    <Typography>{row.downlinkLimit}</Typography>
                  </TableCell>
                </TableRow>
              );
            })
          }
        />
      </Loading>
      {data?.data.total && data?.data.total > 10 ? (
        <Pagination
          currentPage={page.pageStart}
          disabled={isLoading || isFetching}
          totalRecords={data?.data.total}
          onChange={handlePageChange}
        />
      ) : null}
    </>
  );
};
