import { SettingsLayer } from './SettingsLayer';
import { Main } from 'src/layouts/Main';

import { GeoMapContextProvider } from 'src/context/GeoMapContext';
import { Map } from './Map';
import { Timeline } from './Timeline';

export const Geomap: React.FC = () => {
  return (
    <GeoMapContextProvider>
      <Main
        overrideStyles={{
          padding: 0,
          marginInline: 'unset',
          height: 'calc(100vh - 50px)',
        }}
      >
        <SettingsLayer />
        <Map />
        <Timeline />
      </Main>
    </GeoMapContextProvider>
  );
};
