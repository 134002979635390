import { Box } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useContext, useState } from 'react';
import { Confirmable } from 'src/components/Modal';
import { ToastContext } from 'src/context';
import { QueryName } from 'src/enums/query';
import { bulkActionRule } from 'src/apis/rule';

interface BulkActionProps {
  organization: string;
  site: string;
  action: string;
  ids: string[];
  setAction: () => void;
}

export const BulkAction: React.FC<BulkActionProps> = ({
  organization,
  site,
  action,
  ids,
  setAction,
}) => {
  const [confirmModalOpen, setConfirmModalOpen] = useState(true);
  const queryClient = useQueryClient();
  const { showToast } = useContext(ToastContext);
  const mutation = useMutation<
    AxiosResponse<{
      failureCount: number;
      failed: string[];
    }>,
    AxiosError,
    { organization: string; site: string; action: string; ids: string[] }
  >({
    mutationFn: bulkActionRule,
    onSuccess: ({ data: { failureCount, failed } }) => {
      failureCount > 0
        ? showToast(
            `These selected rules ${failed} failed to ${action}!`,
            'error',
          )
        : showToast(`Selected rules ${action}d sucessfully!`, 'success');
      queryClient.invalidateQueries([QueryName.GET_ALL_RULES]);
    },
    onError: () => {
      showToast(`Selected rules failed to ${action}!`, 'error');
    },
  });

  const handleCancel = () => {
    setConfirmModalOpen(false);
    setAction();
  };

  return (
    <Box>
      <Confirmable
        open={confirmModalOpen}
        title={`Bulk ${action} rules`}
        subTitle={`Are you sure to ${action == 'Delete' ? 'delete' : (action == 'Activate' ? 'activate' : 'deactivate')} selected rules`}
        onConfirm={() => {
          mutation.mutate({
            organization,
            site,
            action,
            ids,
          });
          setConfirmModalOpen(false);
          setAction();
        }}
        onCancel={handleCancel}
      />
    </Box>
  );
};
