import styled from '@emotion/styled';
import { Dialog } from '@mui/material';

export const styles = {
  closeIcon: {
    position: 'absolute',
    right: '30px',
    top: '26px',
  },
  marker: {
    cursor: 'pointer',
    color: '#3CDECB',
    width: '34px',
    height: '34px',
    filter: 'drop-shadow(0px 3px 6px #00000029)',
    '&.MuiSvgIcon-root:hover': {
      color: '#2EA392',
    },
  },
  editTitleText: {
    paddingTop: '3px',
    fontSize: '14px',
    color: '#222222',
  },
};

export const StyledDialog = styled(Dialog)`
  & {
    .MuiDialog-container {
      .MuiPaper-root {
        width: 565px;
        padding: 36px;
        border-radius: 0px;
        overflow: overlay;
        position: absolute;
        top: 40px;
      }
      .MuiDialogContent-root {
        padding: 0px;
      }
      .MuiTypography-root.MuiTypography-h6 {
        padding: 0px;
      }
    }
  }
`;

export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 48 * 3 + 8,
      width: 236,
    },
  },
};
