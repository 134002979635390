import {
  Box,
  Collapse,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
} from '@mui/material';
import { CheckmarkFilled, Download, Misuse } from '@carbon/icons-react';
import React, { FC, RefObject, useContext, useRef } from 'react';
import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import { AuthContext, ToastContext } from 'src/context';
import { Button } from 'src/components';
import { ReactComponent as File } from 'src/assets/file.svg';
import { ProgressBar } from 'src/views/UserManagement/UploadUsers/ProgressBar';
import { styles } from './styles';
import { getFileTypeAndExtension } from 'src/utils/file';
import { getBulkAllocateDeviceReport } from 'src/apis/ue';

interface BulkAllocateDevicesStepTwoProps {
  handleCloseModal: () => void;
  selectedFile: File | null;
  isLoading?: boolean;
  result: {
    successfulUploads: string[];
    failedUploads: string[];
    errorReportPath: string;
  } | null;
  progress: number;
}

interface DeviceProps {
  device: string;
  status: boolean;
}

const Device: FC<DeviceProps> = ({ device, status }) => (
  <Box sx={styles.selectedFileBox}>
    <Box sx={styles.devicesTextWrapper}>
      <Typography sx={styles.devicesText}>{device}</Typography>
    </Box>
    <Box sx={styles.statusWrapper}>
      {status ? (
        <>
          <CheckmarkFilled style={styles.devicesCreated} />
          <Typography color='#00A410'>Created</Typography>
        </>
      ) : (
        <>
          <Misuse style={styles.devicesError} />
          <Typography color='#F20000'>Error</Typography>
        </>
      )}
    </Box>
  </Box>
);

export const BulkAllocateDevicesStepTwo: FC<
  BulkAllocateDevicesStepTwoProps
> = ({ handleCloseModal, selectedFile, isLoading, result, progress }) => {
  const { showToast } = useContext(ToastContext);
  const { user } = useContext(AuthContext);
  const linkRef: RefObject<HTMLAnchorElement> | undefined = useRef(null);

  const downloadReportFile = useMutation<
    AxiosResponse,
    AxiosError,
    { path: string; org: string }
  >({
    mutationFn: getBulkAllocateDeviceReport,
    onSuccess: (response: { data: BlobPart }) => {
      const { type, extension } = getFileTypeAndExtension(selectedFile);
      const blob = new Blob([response.data], { type });
      const url = URL.createObjectURL(blob);
      if (linkRef.current) {
        linkRef.current.href = url;
        linkRef.current.download = `report.${extension}`;
        linkRef.current.click();
      }
      URL.revokeObjectURL(url);
    },
    onError: () => {
      showToast('Failed to download file, Please try again', 'error');
    },
  });

  return (
    <>
      <DialogContent sx={styles.dialogContent}>
        <Box sx={styles.column}>
          <File fill='#006161' />
          <Typography sx={styles.fileName}>{selectedFile?.name}</Typography>
          <Box sx={{ height: '21px' }}>
            <Collapse in={isLoading}>
              {isLoading && (
                <Typography sx={styles.dropText}>Uploading...</Typography>
              )}
            </Collapse>
          </Box>
        </Box>
        <Box>
          <ProgressBar progress={progress} />
          <Collapse in={!!result}>
            {result ? (
              <Box sx={styles.devicesBox}>
                <Box sx={styles.devicesItem}>
                  {result?.failedUploads?.map((failedDevice: string) => (
                    <Device
                      key={failedDevice}
                      device={failedDevice}
                      status={false}
                    />
                  ))}
                  {result?.successfulUploads?.map((successDevice: string) => (
                    <Device
                      key={successDevice}
                      device={successDevice}
                      status={true}
                    />
                  ))}
                </Box>
              </Box>
            ) : null}
          </Collapse>

          <Box sx={styles.uploadInfo}>
            {result?.successfulUploads?.length ? (
              <Box sx={styles.created}>
                <CheckmarkFilled style={styles.checkMarkIcon} />
                <Typography color='#00A410'>
                  {result?.successfulUploads?.length}
                  {result?.successfulUploads?.length > 1
                    ? ' devices '
                    : ' device '}
                  created successfully
                </Typography>
              </Box>
            ) : null}
            {result?.failedUploads?.length ? (
              <Box sx={styles.errors}>
                <Misuse style={styles.checkMarkIcon} />
                <Typography color='#F20000'>
                  {result?.failedUploads?.length}
                  {result?.failedUploads?.length > 1 ? ' errors' : ' error'}
                </Typography>
                <IconButton sx={styles.iconButton}>
                  <Download style={styles.downloadIcon} />
                  <Typography
                    sx={styles.editIconText}
                    onClick={() => {
                      downloadReportFile.mutate({
                        path: result?.errorReportPath,
                        org: user?.organizations?.[0] as string,
                      });
                    }}
                  >
                    Download the report
                  </Typography>
                  <a ref={linkRef} style={{ display: 'none' }} />
                </IconButton>
              </Box>
            ) : null}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button kind='primary-ghost' onClick={handleCloseModal}>
          Close
        </Button>
      </DialogActions>
    </>
  );
};
