import styled from '@emotion/styled';
import { FormControlLabel, FormControlLabelProps } from '@mui/material';

export const StyledCheckboxContainer = styled(
  FormControlLabel,
)<FormControlLabelProps>`
  & {
    color: ${({ checked }) => (checked ? '#fff' : '#006161')};
    background: ${({ checked }) => (checked ? '#006161' : '#fff')};
    border: 1px solid #006161;
    margin-right: 18px;

    .MuiSvgIcon-root {
      color: ${({ checked }) => (checked ? '#fff' : '#006161')};
      width: 16px;
      height: 16px;
    }

    .MuiTypography-root {
      color: ${({ checked }) => (checked ? '#fff' : '#006161')};
      padding-right: 18px;
    }

    :hover {
      box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
        0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    }
  }
`;
