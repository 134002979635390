import { Box, Typography } from '@mui/material';
import { useContext } from 'react';
import { DragDropContext, Draggable, DropResult } from 'react-beautiful-dnd';

import { RulesEngineContext } from 'src/context';
import { Equipment } from './Equipment';
import { StrictModeDroppable } from './StrictModeDroppable';

export const PrioritizeEquipments: React.FC = () => {
  const { selectedEquipments, reOrderEquipments } =
    useContext(RulesEngineContext);

  const onDragEnd = (result: DropResult) => {
    const tempSelectedEquipments = selectedEquipments.map((selectedEquipment) =>
      Object.assign({}, selectedEquipment),
    );
    const item = tempSelectedEquipments.splice(result.source.index, 1);
    if (result.destination) {
      tempSelectedEquipments.splice(result.destination?.index, 0, item[0]);
      reOrderEquipments([...tempSelectedEquipments]);
    }
    // const itemsBeforeDestination = tempSelectedEquipments.slice(
    //   0,
    //   result.destination?.index,
    // );
    // const itemsAfterDestination = tempSelectedEquipments.slice(
    //   result.destination?.index,
    // );
    // reOrderEquipments([
    //   ...itemsBeforeDestination,
    //   ...item,
    //   ...itemsAfterDestination,
    // ]);
  };

  return (
    <Box>
      <Typography variant="h2" marginBottom="12px">
        Prioritize
      </Typography>
      <Typography fontSize={12} mb="26px" width="400px">
        Select which equipment should have prioritised access to the available
        network capacity and which should yield capacity when required.
      </Typography>
      <DragDropContext onDragEnd={onDragEnd}>
        <StrictModeDroppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {selectedEquipments.map((equipment, index) => (
                <Draggable
                  key={equipment.id}
                  draggableId={equipment.id}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{
                        marginBottom: '10px',
                        ...provided.draggableProps.style,
                      }}
                    >
                      <Equipment
                        equipment={equipment}
                        order={Number(index) + 1}
                        isDragging={snapshot.isDragging}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </StrictModeDroppable>
      </DragDropContext>
    </Box>
  );
};
