import React, { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Typography,
} from '@mui/material';
import { Close } from '@carbon/icons-react';
import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { TextField } from 'src/components/TextField';
import { Button } from 'src/components/Button';
import { RuleConfiguration } from 'src/types/rule';
import { renameRule, RenameRuleData } from 'src/apis/rule';
import { ToastContext } from 'src/context';
import { renameRuleSchema } from 'src/yup-validations/renameRuleValidation';
import { StyledInputDialogContainer, styles } from './styles';

interface RenameRuleModalProps {
  openDialog: boolean;
  onSave: () => void;
  onCancel: () => void;
  ruleConfiguration: RuleConfiguration;
}

export const RenameRuleModal: React.FC<RenameRuleModalProps> = ({
  ruleConfiguration,
  openDialog,
  onSave,
  onCancel,
}) => {
  const { showToast } = useContext(ToastContext);
  const [newRuleName, setNewRuleName] = useState<string | undefined>(
    ruleConfiguration?.name,
  );
  const [error, setError] = useState<boolean>(false);
  const {
    control,
    formState: { isValid, errors },
    handleSubmit,
    reset,
    clearErrors,
  } = useForm({
    resolver: yupResolver(renameRuleSchema),
    defaultValues: { rename_Rule: ruleConfiguration?.name },
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const handleChange = (value: string) => {
    setNewRuleName(value);
    setError(false);
  };

  const handleSave = () => {
    const finalData: RenameRuleData = {
      organization: ruleConfiguration.organization,
      site: ruleConfiguration.site,
      id: ruleConfiguration.id,
      oldName: ruleConfiguration.name,
      newName: newRuleName as string,
    };
    if (newRuleName === ruleConfiguration?.name) {
      setError(true);
    }else{
      renameRuleMutation.mutate(finalData);
      setError(false);
    }
  };

  const handleCancel = () => {
    clearErrors('rename_Rule');
    setNewRuleName(ruleConfiguration?.name);
    onCancel();
    setError(false);
  };

  const renameRuleMutation = useMutation<
    AxiosResponse,
    AxiosError,
    RenameRuleData
  >({
    mutationFn: renameRule,
    onSuccess: async () => {
      showToast('Rule renamed successfully!', 'success');
      reset({ rename_Rule: ruleConfiguration?.name });
      onSave();
    },
    onError: async () => {
      showToast('Failed to rename the rule, Please try again', 'error');
    },
  });

  return (
    <StyledInputDialogContainer open={openDialog}>
      <DialogTitle sx={styles.boldText}>
        Rename rule
        <IconButton sx={styles.iconClose} onClick={handleCancel}>
          <Close size='20' />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Controller
          name='rename_Rule'
          control={control}
          render={({ field: { onChange, ...field } }) => (
            <TextField
              {...field}
              sx={styles.textFieldRuleRename}
              width={333}
              label='Rename rule'
              onChange={({ target: { value } }) => {
                onChange(value);
                handleChange(value);
              }}
              value={newRuleName}
              inputProps={{
                maxLength: 40,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end' sx={styles.characterAdornment}>
                    <Typography sx={styles.characterAdornmentText}>
                      {newRuleName?.length}/40
                    </Typography>
                  </InputAdornment>
                ),
              }}
              error={
                !isValid && errors.rename_Rule?.message
                  ? errors.rename_Rule?.message
                  : (error ? 'Name already in use' : undefined)
              }
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button kind='primary-ghost' onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          kind='primary'
          onClick={handleSubmit(handleSave)}
        >
          Save
        </Button>
      </DialogActions>
    </StyledInputDialogContainer>
  );
};
