import {
  Box,
  Button,
  Fade,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Popper,
  Select,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import {
  AddToPhotosOutlined,
  EditOutlined,
  SaveOutlined,
  DeleteForeverOutlined,
  GetApp,
  FileUpload,
} from '@mui/icons-material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useCallback, useMemo, useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import { Menu } from 'src/components';
import { StyledButtonGroup, styles } from '../styles';

interface DashboardOptionsProps {
  timeSeriesFilters: {
    dataInterval: number;
    predefinedDateRange: number | null;
    customDateRange: {
      start: Date | null;
      end: Date | null;
    };
  };
  disableExport: boolean;
  onAddWidget: () => void;
  onSave: () => void;
  onRename: () => void;
  onDelete: () => void;
  onImport: () => void;
  onExport: () => void;
  onTimeSeriesFiltersChange: (
    interval: number,
    range: number | null,
    customDateRange: { start: Date | null; end: Date | null },
  ) => void;
}

export const DashboardOptions: React.FC<DashboardOptionsProps> = ({
  timeSeriesFilters,
  disableExport,
  onAddWidget,
  onSave,
  onRename,
  onDelete,
  onImport,
  onExport,
  onTimeSeriesFiltersChange,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);
  const [dateRange, setDateRange] = useState<{
    start: Date | null;
    end: Date | null;
  }>({
    start: null,
    end: null,
  });

  // ranges in mins
  const ranges = [
    { key: '1h', label: '1H', value: 60 },
    { key: '2h', label: '2H', value: 120 },
    { key: '3h', label: '3H', value: 180 },
    { key: '6h', label: '6H', value: 360 },
    { key: '1d', label: '1D', value: 1440 },
    { key: '1w', label: '1W', value: 10080 },
    { key: 'custom', label: 'custom', value: 'custom' },
  ];

  // data intervals in seconds
  const dataIntervals = useMemo(() => {
    return [
      { key: '1m', label: '1 Min', value: 60, validFor: ['hour'] },
      { key: '2m', label: '2 Min', value: 120, validFor: ['hour'] },
      { key: '5m', label: '5 Min', value: 300, validFor: ['hour'] },
      { key: '10m', label: '10 Min', value: 600, validFor: ['hour'] },
      { key: '30m', label: '30 Min', value: 1800, validFor: ['hour', 'day'] },
      { key: '1h', label: '1 Hour', value: 3600, validFor: ['day'] },
      { key: '6h', label: '6 Hour', value: 21600, validFor: ['day'] },
      { key: '1d', label: '1 Day', value: 86400, validFor: ['week'] },
    ];
  }, []);

  const getDataIntervals = useCallback(
    (range?: number) => {
      // less than a day
      if (Number(range) < 1440) {
        return dataIntervals.filter((interval) =>
          interval.validFor.includes('hour'),
        );
      }
      // is one day
      if (Number(range) === 1440) {
        return dataIntervals.filter((interval) =>
          interval.validFor.includes('day'),
        );
      }
      // is greater than one day
      if (Number(range) > 1440) {
        return dataIntervals.filter((interval) =>
          interval.validFor.includes('week'),
        );
      }
      return [...dataIntervals];
    },
    [dataIntervals],
  );

  const getDataInterval = useCallback(
    (range: number) => {
      const filteredDataIntervals = getDataIntervals(range);
      const selectedInterval = filteredDataIntervals.find(
        (interval) => interval.value === timeSeriesFilters.dataInterval,
      )?.value;
      if (selectedInterval) {
        return selectedInterval;
      } else {
        return filteredDataIntervals[0].value;
      }
    },
    [getDataIntervals, timeSeriesFilters.dataInterval],
  );

  const handleRangeTypeChange = (
    event: React.MouseEvent<HTMLButtonElement>,
    range: string | number,
  ) => {
    if (range === 'custom') {
      setAnchorEl(event.currentTarget);
      setOpen(true);
    } else {
      setOpen(false);
      onTimeSeriesFiltersChange(getDataInterval(Number(range)), Number(range), {
        start: null,
        end: null,
      });
    }
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleApply = () => {
    if (dateRange.start) {
      onTimeSeriesFiltersChange(timeSeriesFilters.dataInterval, null, {
        start: dateRange.start,
        end: dateRange.end,
      });
    }
    setOpen(false);
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      flexWrap="wrap"
      mt={2}
      mb={2}
    >
      <Box display="flex">
        <Button
          size="medium"
          sx={styles.dashboardButton}
          startIcon={<AddToPhotosOutlined sx={styles.dashboardButtonIcon} />}
          onClick={onAddWidget}
        >
          Add Widget
        </Button>

        <Button
          size="medium"
          sx={styles.dashboardButton}
          startIcon={<SaveOutlined sx={styles.dashboardButtonIcon} />}
          onClick={onSave}
        >
          Save
        </Button>
        <Menu
          title="Actions"
          actions={[
            {
              label: 'Rename Dashboard',
              action: onRename,
              icon: <EditOutlined sx={styles.dropdownIcon} />,
            },
            {
              label: 'Import Dashboard',
              action: onImport,
              icon: <FileUpload sx={styles.dropdownIcon} />,
            },
            {
              label: 'Export Dashboard',
              action: onExport,
              icon: <GetApp sx={styles.dropdownIcon} />,
              disabled: disableExport,
            },
            {
              label: 'Delete Dashboard',
              action: onDelete,
              styles: styles.deleteOptionText,
              icon: <DeleteForeverOutlined sx={styles.deleteOptionIcon} />,
            },
          ]}
          menuTextColor="#5f6c7b"
        />
      </Box>
      <Box display="flex">
        <FormControl sx={{ mr: 1, minWidth: 100 }} size="small">
          <InputLabel id="demo-select-small">Interval</InputLabel>
          <Select
            labelId="demo-select-small"
            id="demo-select-small"
            label="Interval"
            value={timeSeriesFilters.dataInterval}
            onChange={(event) =>
              onTimeSeriesFiltersChange(
                Number(event.target.value),
                timeSeriesFilters.predefinedDateRange,
                timeSeriesFilters.customDateRange,
              )
            }
          >
            {getDataIntervals(
              timeSeriesFilters.predefinedDateRange ?? undefined,
            ).map((interval) => (
              <MenuItem key={interval.key} value={interval.value}>
                {interval.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <StyledButtonGroup size="small" sx={{ mr: 1 }}>
          {ranges.map((range) => (
            <Button
              disableElevation
              key={range.key}
              variant={
                timeSeriesFilters.predefinedDateRange === range.value ||
                (range.key === 'custom' &&
                  !timeSeriesFilters.predefinedDateRange &&
                  !!timeSeriesFilters.customDateRange.start)
                  ? 'contained'
                  : 'outlined'
              }
              onClick={(event) => handleRangeTypeChange(event, range.value)}
            >
              {range.label}
            </Button>
          ))}
        </StyledButtonGroup>
        <Popper
          id="custom-date-selector-container"
          open={open}
          anchorEl={anchorEl}
          placement="bottom-end"
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper
                sx={{
                  p: '18px',
                  boxShadow:
                    '0px 0px 4px 1px rgb(0 0 0 / 25%), 0px 2px 4px 0px rgb(0 0 0 / 25%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
                }}
              >
                <Typography mb={2}>Select Dates:</Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    PopperProps={{ id: 'custom-date-selector-from' }}
                    label="From"
                    renderInput={(params: TextFieldProps) => (
                      <TextField
                        {...params}
                        size="small"
                        sx={{ width: 225, mr: 1 }}
                      />
                    )}
                    value={dateRange.start}
                    onChange={(date: Date | null) =>
                      setDateRange({ ...dateRange, start: date })
                    }
                  />
                  <DateTimePicker
                    PopperProps={{ id: 'custom-date-selector-to' }}
                    label="To (Optional)"
                    renderInput={(params: TextFieldProps) => (
                      <TextField {...params} size="small" sx={{ width: 225 }} />
                    )}
                    value={dateRange.end}
                    onChange={(date: Date | null) =>
                      setDateRange({ ...dateRange, end: date })
                    }
                  />
                </LocalizationProvider>
                <Box mt={2} display="flex" justifyContent="flex-end">
                  <Button sx={{ mr: 1 }} onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button variant="contained" onClick={handleApply}>
                    Apply
                  </Button>
                </Box>
              </Paper>
            </Fade>
          )}
        </Popper>
      </Box>
    </Box>
  );
};
