import { ChevronLeft, ChevronRight } from '@carbon/icons-react';
import { Box, IconButton, Typography } from '@mui/material';

import { Breadcrumbs } from 'src/components/Breadcrumbs';
import { Main } from 'src/layouts/Main';
import { Overview } from './Overview';
import { useCallback, useEffect } from 'react';
import { add, format, isSameMonth, isValid, sub } from 'date-fns';
import { useSearchParams } from 'react-router-dom';
import { Equipments } from './Equipments/Equipments';
import { Prediction } from './Prediction';

export const UserUsagePolicy: React.FC = () => {
  const [params, setParams] = useSearchParams({
    date: new Date().toDateString(),
  });

  const selectedDate = params.get('date');

  const prevMonth = useCallback(() => {
    if (selectedDate) {
      const prevDate = new Date(selectedDate);
      setParams({
        date: sub(prevDate, { months: 1 }).toDateString(),
      });
    }
  }, [selectedDate, setParams]);

  const nextMonth = useCallback(() => {
    if (selectedDate) {
      const prevDate = new Date(selectedDate);
      setParams({
        date: add(prevDate, { months: 1 }).toDateString(),
      });
    }
  }, [selectedDate, setParams]);

  const nextMonthButtonDisabled =
    !!selectedDate && isSameMonth(new Date(selectedDate), new Date());

  useEffect(() => {
    if (selectedDate && !isValid(new Date(selectedDate))) {
      setParams({
        date: new Date().toDateString(),
      });
    }
  }, [selectedDate, setParams]);

  return (
    <Main>
      <Breadcrumbs
        breadcrumbs={[
          { title: 'Overview', url: '/' },
          { title: 'My usage dashboard' },
        ]}
      />
      <Box display='flex' justifyContent='space-between'>
        <Typography variant='h1'>My usage dashboard</Typography>
        <Box display='flex' alignItems='center'>
          <IconButton onClick={prevMonth}>
            <ChevronLeft color='#006161' />
          </IconButton>
          <Typography variant='h2' mx={3}>
            {selectedDate &&
              isValid(new Date(selectedDate)) &&
              format(new Date(selectedDate), 'MMMM yyyy')}
          </Typography>
          <IconButton disabled={nextMonthButtonDisabled} onClick={nextMonth}>
            <ChevronRight
              color={nextMonthButtonDisabled ? '#888888' : '#006161'}
            />
          </IconButton>
        </Box>
      </Box>

      <Box mt='45px' display='flex'>
        <Box flexGrow={1}>
          <Overview />
          <Prediction
            trendData={[
              {
                xvalue: 'Thu 04',
                time: '2023-05-04T23:59:59Z',
                value: 20,
              },
              {
                xvalue: 'Fri 05',
                time: '2023-05-05T23:59:59Z',
                value: 8,
              },
              {
                xvalue: 'Sat 06',
                time: '2023-05-06T23:59:59Z',
                value: 42,
              },
              {
                xvalue: 'Sun 07',
                time: '2023-05-07T23:59:59Z',
                value: 34,
              },
              {
                xvalue: 'Mon 08',
                time: '2023-05-08T23:59:59Z',
                value: 40,
              },
              {
                xvalue: 'Tue 09',
                time: '2023-05-09T23:59:59Z',
                value: 89,
              },
              {
                xvalue: 'Wed 10',
                time: '2023-05-10T23:59:59Z',
                value: 70,
              },
            ]}
            prediction={[
              {
                xvalue: 'Wed 10',
                time: '2023-05-10T23:59:59Z',
                value: 70,
              },
            ]}
          />
        </Box>
        <Box width='396px'>
          <Equipments />
        </Box>
      </Box>
    </Main>
  );
};
