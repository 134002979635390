import { Box, Typography } from '@mui/material';
import { Breadcrumbs } from 'src/components/Breadcrumbs';
import { Main } from 'src/layouts/Main';

export const UserInfo: React.FC = () => {
  return (
    <Box>
      <Main>
        <Breadcrumbs
          breadcrumbs={[
            {
              title: 'Overview',
              url: '/',
            },
            {
              title: 'User management',
              url: '/users',
            },
            {
              title: 'User Info',
            },
          ]}
        />
        <Box mt='24px'>
          <Typography>User Info</Typography>
        </Box>
      </Main>
    </Box>
  );
};
