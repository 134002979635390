import styled from '@emotion/styled';
import { Drawer, DrawerProps } from '@mui/material';

export interface StyledDrawerProps extends DrawerProps {
  width?: number;
}

export const StyledDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'width',
})<StyledDrawerProps>`
  & {
    .MuiPaper-root {
      background-color: #ffffff;
      width: ${({ width = 400 }) => `${width}px`};
      color: #073b4c;
      padding: 16px 10px;
    }
  }
`;
