import { Box, Typography } from '@mui/material';
import { RuleConfiguration } from 'src/types/rule';

interface DisplayElementsProps {
  ruleConfiguration: RuleConfiguration;
}

export const DisplayElements: React.FC<DisplayElementsProps> = ({
  ruleConfiguration,
}) => {
  const getNames = (id: string) => {
    let groupName;
    if (ruleConfiguration.ueNames !== null) {
      Object.entries(ruleConfiguration.ueNames)
        .filter(([gId, _]) => gId === id)
        .forEach(([_, gName]) => {
          groupName = gName;
        });
    }
    return groupName == null ? id : groupName;
  };
  return (
    <Box width="40%">
      <Typography variant="h3" pt="51px">
        Equipment Concerned
      </Typography>
      {ruleConfiguration.apns && (
        <Typography variant="h4" pt="12px">
          Networks
        </Typography>
      )}
      {ruleConfiguration.apns?.map((apn) => (
        <Typography key={apn} variant="body1">
          {apn}
        </Typography>
      ))}

      <Typography variant="h4" mt="14px">
        Equipments
      </Typography>
      {ruleConfiguration.ues?.map((ue) => (
        <Typography key={ue} variant="body1">
          {getNames(ue)}
        </Typography>
      ))}
      {ruleConfiguration.ueGroups &&
        Object.entries(ruleConfiguration.ueGroups).map(([key, ueGroup]) => (
          <Typography key={key} variant="body1">
            {ueGroup}
          </Typography>
        ))}
    </Box>
  );
};
