import { Box, Typography } from '@mui/material';
import { FC, useContext } from 'react';
import { RulesEngineContext } from 'src/context';

export const SelectedNetworks: FC = () => {
  const { selectedAPNs } = useContext(RulesEngineContext);
  return (
    <Box mb="36px">
      <Typography variant="h2" mb="22px">
        Network concerned
      </Typography>
      <Box ml="15px">
        {selectedAPNs.map((selectedAPN) => (
          <Typography variant="body1" key={selectedAPN.name}>
            {selectedAPN.name}
          </Typography>
        ))}
      </Box>
    </Box>
  );
};
