import * as React from 'react';
import { ControlPosition, useControl } from 'react-map-gl';
import MapboxGeocoder, { GeocoderOptions } from '@mapbox/mapbox-gl-geocoder';

type GeocoderControlProps = Omit<
  GeocoderOptions,
  'accessToken' | 'mapboxgl' | 'marker'
> & {
  mapboxAccessToken: string;
  position: ControlPosition;
  onResult: (e: object) => void;
  container?: React.ReactNode | null,
};

export const GeocoderControl: React.FC<GeocoderControlProps> = ({
  mapboxAccessToken,
  position,
  onResult,
}) => {
  useControl<MapboxGeocoder>(
    () => {
      const ctrl = new MapboxGeocoder({
        marker: false,
        accessToken: mapboxAccessToken,
      });
      ctrl.on('result', (evt) => {
        onResult(evt);
      });
      return ctrl;
    },
    {
      position,
    },
  );
  return null;
};
