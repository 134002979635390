import { DeleteForeverOutlined } from '@mui/icons-material';
import { Box, Card, Skeleton, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { getInstantMetricsData } from 'src/apis/metrics';
import { Menu } from 'src/components';
import { QueryName } from 'src/enums/query';
import { KPIWidget, AlarmWidget, WeatherWidget } from 'src/types/widget';
import { styles } from './styles';

interface CurrentKpiWidgetProps {
  widget: KPIWidget | AlarmWidget | WeatherWidget;
  onDelete: () => void;
}

export const CurrentKpiWidget: React.FC<CurrentKpiWidgetProps> = ({
  widget,
  onDelete,
}) => {
  const _getAdditionalParams = () => {
    if (widget.metricsLevel === 'SITE') {
      return { site: widget.site };
    }
    if (widget.metricsLevel === 'IMSI') {
      return { imsi: (widget as KPIWidget).imsi, site: widget.site };
    }
    if (widget.metricsLevel === 'APN') {
      return {
        apn: (widget as KPIWidget | AlarmWidget).apn,
        site: widget.site,
      };
    }
    if (widget.metricsLevel === 'VLAN') {
      return {
        vlan: (widget as KPIWidget | AlarmWidget).vlan,
        site: widget.site,
      };
    }
    if (widget.metricsLevel === 'NODE') {
      return { node: (widget as AlarmWidget).node, site: widget.site };
    }
  };

  const { data, isLoading, isFetching } = useQuery(
    [QueryName.GET_INSTANT_METRICS_DATA, widget.id],
    () =>
      getInstantMetricsData({
        id: widget.id,
        metric: widget.metricName,
        organization: widget.org,
        level: widget.metricsLevel,
        ..._getAdditionalParams(),
      }),
    {
      enabled: !!widget.id,
    },
  );

  return (
    <Card sx={styles.cardContainer}>
      {isLoading || isFetching ? (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          sx={{ height: '100%' }}
        >
          <Skeleton width="70%" />
          <Skeleton width="40%" />
          <Skeleton width="80%" />
          <Skeleton width="80%" />
        </Box>
      ) : (
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography color="text.secondary" fontSize={14} noWrap>
              {widget.label}
            </Typography>
            <Menu
              actions={[
                {
                  label: 'Delete Widget',
                  action: () => onDelete(),
                  styles: styles.deleteOptionText,
                  icon: <DeleteForeverOutlined sx={styles.deleteOptionIcon} />,
                },
              ]}
            />
          </Box>
          <Box display="flex" justifyContent="center" my="6px">
            <Typography variant="h4">{data?.data.result.value ?? 0}</Typography>
          </Box>
          <Typography fontSize={12} noWrap>
            <span style={styles.label}>Org:</span> {widget.org}
          </Typography>
          <Typography fontSize={12} noWrap>
            <span style={styles.label}> Metric Level:</span>{' '}
            {widget.metricsLevel}
          </Typography>
          <Typography fontSize={12} noWrap>
            <span style={styles.label}>Metric Name:</span> {widget.metricName}
          </Typography>
        </>
      )}
    </Card>
  );
};
