export const styles = {
  closeIcon: {
    position: 'absolute',
    right: '15px',
    top: '15px',
  },
  uploadDeviceTitle: {
    paddingTop: '10px',
    fontSize: '16px',
    fontWeight: 'bold',
  },
  iconButton: {
    height: '40px',
    borderRadius: 0,
  },
  boxStepOne: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  downloadFormatText: {
    paddingLeft: '5px',
    fontSize: '14px',
    textDecoration: 'underline',
    color: '#006161',
    alignItems: 'left',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  downloadIcon: {
    color: '#006161',
    size: '24',
  },
  boxStyle: (dragging: boolean) => ({
    height: '225px',
    border: '2px dashed #006161',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    backgroundColor: dragging ? 'transparent' : '#F1FCFA',
    marginBottom: '10px',
    cursor: 'pointer',
  }),
  dropFilesText: {
    color: '#006161',
    fontWeight: 'bold',
    fontSize: '14px',
  },
  uploadIcon: {
    width: '100px',
    margin: '10px',
  },
  browseBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  browseText: {
    marginLeft: '5px',
    color: '#006161',
    textDecoration: 'underline',
    cursor: 'pointer',
    ':hover': { textDecoration: 'none' },
  },
  selectedFileBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  editIconText: {
    size: '24',
    paddingLeft: '6px',
    paddingRight: '19px',
    color: '#006161',
    fontSize: '14px',
    alignItems: 'left',
    textDecoration: 'underline',
    ':hover': { textDecoration: 'none' },
  },
  devicesTextWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '65%',
  },
  devicesText: {
    fontSize: '14px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  statusWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '35%',
  },
  devicesCreated: {
    margin: ' 0 10px',
    height: '16px',
    color: '#00A410',
  },
  devicesError: {
    margin: ' 0 10px',
    height: '16px',
    color: '#F20000',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    alignItems: 'center',
  },
  fileName: {
    fontSize: '14px',
    marginLeft: '5px',
    color: '#006161',
  },
  dropText: {
    marginLeft: '5px',
    color: '#006161',
    fontWeight: 'bold',
    ':hover': { textDecoration: 'none' },
  },
  devicesBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginBottom: '10px',
  },
  devicesItem: {
    width: '346px',
    height: '153px',
    overflowY: 'auto',
    border: '1px solid #CCCCCC',
    padding: '0 25px',
  },
  uploadInfo: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    width: '346px',
  },
  created: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    color: '#00A410',
  },
  errors: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    color: '#F20000',
  },
  errorText: {
    marginLeft: 0,
    marginTop: '3px',
    lineHeight: '1.143',
    fontSize: '10px',
    color: 'red',
  },
  checkMarkIcon: {
    margin: '0 5px 0 0',
  },
};
