import React, { useContext, useEffect } from 'react';
import { AppContext, RulesEngineContext } from 'src/context';
import { useSearchParams } from 'react-router-dom';

import { SpecificationThreshold } from './SpecificationThreshold/SpecificationThreshold';
import { SpecificationPrioritization } from './SpecificationPrioritization/SpecificationPrioritization';
import { SpecificationGeofence } from './SpecificationGeofence/SpecificationGeofence';
import { SpecificationCapacity } from './SpecificationCapacity/SpecificationCapacity';

interface SpecificationProps {
  onCancel: () => void;
}

export const Specification: React.FC<SpecificationProps> = ({ onCancel }) => {
  const { ruleType, resetGeneralInfo, removeAllAPNs, removeAllEquipments } =
    useContext(RulesEngineContext);
  const [, setParams] = useSearchParams();
  const { siteChanged } = useContext(AppContext);

  useEffect(() => {
    if (!ruleType) setParams({ step: 'type' });
  }, [ruleType, setParams]);

  useEffect(() => {
    if (siteChanged) {
      resetGeneralInfo();
      removeAllAPNs();
      removeAllEquipments();
      setParams({ step: 'type' });
    }
  }, [siteChanged]);

  const renderSpecification = () => {
    switch (ruleType) {
      case 'PRIORITIZATION':
        return <SpecificationPrioritization onCancel={onCancel} />;
      case 'THRESHOLD':
        return <SpecificationThreshold onCancel={onCancel} />;
      case 'GEO_FENCE':
        return <SpecificationGeofence onCancel={onCancel} />;
      case 'CAPACITY':
        return <SpecificationCapacity onCancel={onCancel} />;
      default:
        return null;
    }
  };
  return <>{renderSpecification()}</>;
};
