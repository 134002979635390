import React, { FC } from 'react';
import { Box, FormHelperText, MenuItem, Typography } from '@mui/material';
import { Controller, ControllerProps, FormState } from 'react-hook-form';

import { Select, Checkbox } from 'src/components';
import { UserDetails } from 'src/types/user';
import { useOrganizations, useSites } from '../../DeviceManagement/state';
import { MenuProps } from '../styles';
import { AddElement } from './AddElement';
import { styles } from './styles';

interface AffiliationProps {
  formState: FormState<UserDetails>;
  selectedOrganization: string;
}

export const Affiliation: FC<
  AffiliationProps & Pick<ControllerProps<UserDetails>, 'control'>
> = ({ control, selectedOrganization, formState }) => {
  const { data: organizationsResponse } = useOrganizations();

  const { data: sitesResponse, isLoading: isSitesLoading } =
    useSites(selectedOrganization);

  const sites = sitesResponse?.data ?? [];

  return (
    <Box display={'flex'}>
      <Box>
        <Typography sx={styles.subtitle}>Access</Typography>
        <Box display={'flex'}>
          <Box flex={1} sx={styles.dropdownsWrapper}>
            <Controller
              control={control}
              name='organizations'
              render={({ field }) => (
                <Select
                  fullWidth
                  width={264}
                  label='Organization'
                  MenuProps={MenuProps}
                  placeholder='Organization'
                  {...field}
                >
                  {organizationsResponse?.data?.map((organization) => (
                    <MenuItem key={organization} value={organization}>
                      {organization}
                    </MenuItem>
                  ))}
                  <AddElement link='/networks' />
                </Select>
              )}
            />
          </Box>
          <Box flex={1} sx={styles.dropdownsWrapper}>
            <Controller
              control={control}
              name='sites'
              render={({ field }) => (
                <>
                  <Select
                    fullWidth
                    width={264}
                    label='Sites'
                    MenuProps={MenuProps}
                    placeholder='Sites'
                    renderValue={(value) => {
                      const sites = value as string[];
                      return sites.join(', ') || 'Sites';
                    }}
                    multiple
                    disabled={!selectedOrganization || isSitesLoading}
                    {...field}
                  >
                    {sites?.map((site) => (
                      <MenuItem key={site} value={site}>
                        <Checkbox checked={field.value.includes(site)} />
                        {site}
                      </MenuItem>
                    ))}
                    <AddElement link='/networks' />
                  </Select>
                  {formState?.errors?.sites && (
                    <FormHelperText sx={styles.helperText}>
                      {formState.errors?.sites?.message}
                    </FormHelperText>
                  )}
                </>
              )}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
