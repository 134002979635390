export enum WidgetType {
  KPI = 'KPI',
  WEATHER = 'WEATHER',
  ALARM = 'ALARM',
  TIME = 'TIME',
}

export enum WidgetValueType {
  CURRENT = 'CURRENT',
  RANGE = 'RANGE',
}

export enum TimeSeriesChartType {
  LINE = 'LINE',
  COLUMN = 'COLUMN',
  GAUGE = 'GAUGE',
}
