import { Box, Typography } from '@mui/material';
import { useRef } from 'react';
import { DragIndicator } from '@mui/icons-material';

import { Equipment as EquipmentType } from 'src/context';
import { EquipmentContainer } from './styles';

interface EquipmentProps {
  order: number;
  equipment: EquipmentType;
  isDragging: boolean;
}

export const Equipment: React.FC<EquipmentProps> = ({
  order,
  equipment,
  isDragging,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <EquipmentContainer key={equipment.id} ref={ref} isDragging={isDragging}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <Typography fontSize={40} fontWeight={700} color="#55CFBB" mr="29px">
            {order}
          </Typography>
          <Box>
            <Typography variant="h4" marginBottom="2px">
              {equipment.name}
            </Typography>
            <Typography fontSize={12} fontWeight={400} color="#888888">
              {equipment.type === 'equipment'
                ? 'Equipment'
                : 'Group of equipment'}
            </Typography>
          </Box>
        </Box>
        <DragIndicator fontSize="small" />
      </Box>
    </EquipmentContainer>
  );
};
