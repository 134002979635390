import * as Yup from 'yup';

export const createEndpointSchema = Yup.object().shape({
  organization: Yup.string()
    .matches(
      /^[a-z0-9-_]*$/,
      'Text should only contain lowercase letters, numbers and (-_)',
    )
    .required('Organization is required'),
  organizationName: Yup.string().when('organization', {
    is: (organization: string) => organization === 'new',
    then: Yup.string()
      .matches(
        /^[a-z0-9-_]*$/,
        'Text should only contain lowercase letters, numbers and (-_)',
      )
      .required('Organization name is required'),
  }),
  site: Yup.string().when('organization', {
    is: (organization: string) => organization !== 'new',
    then: Yup.string()
      .matches(
        /^[a-z0-9-_]*$/,
        'Text should only contain lowercase letters, numbers and (-_)',
      )
      .required('Site is required'),
  }),
  endpoint: Yup.string()
    .matches(
      /^[a-z0-9-_]*$/,
      'Text should only contain lowercase letters, numbers and (-_)',
    )
    .max(50, 'Endpoint name must be shorter than 50 characters')
    .required('Endpoint name is required'),
  vendor: Yup.string()
    .matches(
      /^[a-zA-Z0-9-_,]*$/,
      'Text should only contain letters, numbers and (-_)',
    )
    .required('Endpoint vendor is required'),
  connectionInfo: Yup.object().when('vendor', {
    is: (vendor: string) =>
      vendor === 'ERICSSON_EP5G' || vendor === 'RHOMBUS_CCTV',
    then: Yup.object({
      url: Yup.string()
        .url('Url must be a valid url')
        .required('Url is required'),
      key: Yup.string().required('Key is required'),
    }),
  }),
  siteName: Yup.string().when(['site', 'organization'], {
    is: (site: string, organization: string) =>
      site === 'new' || organization === 'new',
    then: Yup.string()
      .matches(
        /^[a-z0-9-_]*$/,
        'Text should only contain lowercase letters, numbers and (-_)',
      )
      .required('Site name is required'),
  }),
  country: Yup.string().when('organization', {
    is: (organization: string) => organization === 'new',
    then: Yup.string()
      .matches(/^[a-zA-Z0-9-_ ]*$/)
      .required('Country is required'),
  }),
});
