import { api } from 'src/utils/axios';
import { ApiResponseType } from 'src/types/api';
import { Dashboard } from 'src/types/dashboard';
import { Widget } from 'src/types/widget';

export const getDashboardsName = () => {
  const url = 'dashboard/v1/list';
  return api.get<ApiResponseType<string[]>>(url);
};

export const getDashboard = (name: Dashboard['name']) => {
  const url = `dashboard/v1?name=${name}`;
  return api.get<ApiResponseType<Dashboard>>(url);
};

export const createDashboard = ({
  name,
  userId,
}: {
  name: string;
  userId: string;
}) => {
  const url = 'dashboard/v1';
  return api.post<unknown>(url, {
    name,
    userId,
  });
};

export const renameDashboard = ({
  oldName,
  newName,
}: {
  oldName: string;
  newName: string;
}) => {
  const url = 'dashboard/v1/rename';
  return api.post(url, {
    oldName,
    newName,
  });
};

export const updateDashboard = ({
  userId,
  dashboardName,
  dashboard,
}: {
  userId: string;
  dashboardName: string;
  dashboard: Record<string, unknown>;
}) => {
  const url = 'dashboard/v1';
  return api.patch(url, {
    userId,
    name: dashboardName,
    dashboard,
  });
};

export const deleteDashboard = ({ name }: { name: string }) => {
  const url = `dashboard/v1?name=${name}`;
  return api.delete(url);
};

export const exportDashboard = ({ name }: { name: string }) => {
  return api.get<ApiResponseType<{ [key: string]: Widget }>>(
    `dashboard/v1/export?name=${name}`,
  );
};

export const importDashboard = ({
  name,
  file,
}: {
  name: string;
  file: File;
}) => {
  const formData = new FormData();
  formData.append('file', file);

  return api.post(`dashboard/v1/import?name=${name}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};
