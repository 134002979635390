import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Confirmable } from 'src/components';
import { RulesEngineProvider } from 'src/context/RulesEngineContext';
import { RulesSelection } from './components/RulesSelection';
import { RuleType } from './components/RuleType';
import { Specification } from './components/Specification';

export const RulesEngine: React.FC = () => {
  const navigate = useNavigate();

  const [params] = useSearchParams({ step: 'type' });
  const step = params.get('step');

  const [displayDiscardModal, setDisplayDiscardModal] = useState(false);

  if (!['type', 'selection', 'specification'].includes(String(step))) {
    navigate('/rule?step=type');
  }
  return (
    <RulesEngineProvider>
      {step === 'type' && (
        <RuleType onCancel={() => setDisplayDiscardModal(true)} />
      )}
      {step === 'selection' && (
        <RulesSelection onCancel={() => setDisplayDiscardModal(true)} />
      )}
      {step === 'specification' && (
        <Specification onCancel={() => setDisplayDiscardModal(true)} />
      )}
      <Confirmable
        open={displayDiscardModal}
        title="Discard Changes"
        subTitle="Do you want to discard changes?"
        onCancel={() => setDisplayDiscardModal(false)}
        onConfirm={() => navigate('/rules')}
      />
    </RulesEngineProvider>
  );
};
