export const styles = {
  inputBox: {
    width: '200px',
    height: '30px',
    marginLeft: '20rem',
  },
  channelNameWrapper: {
    minWidth: '200px',
  },
  channelName: {
    fontWeight: 'bold',
    textDecoration: 'underline',
    color: '#006161',
    marginTop: '20px',
    ':hover': {
      textDecoration: 'none',
    },
  },
  description: {
    fontSize: '12px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    paddingRight: '10px',
    marginBottom: '20px',
  },
  channelEdit: {
    fontWeight: 'bold',
    textDecoration: 'underline',
    color: '#55CFBB',
    marginTop: '1px',
    cursor: 'pointer',
  },
  editIcon: {
    height: '15px',
    width: '20px',
    marginRight: '3px',
    color: '#55CFBB',
  },
  deleteIcon: {
    height: '15px',
    width: '20px',
    color: '#F20000',
    marginRight: '3px',
    marginLeft: '5rem',
  },
  channelDelete: {
    fontWeight: 'bold',
    textDecoration: 'underline',
    color: '#F20000',
    marginTop: '1px',
    cursor: 'pointer',
  },
  tableRow: {
    ':hover': {
      bgcolor: 'rgba(0, 0, 0, 0.04)',
    },
    '&:last-child': {
      borderBottom: '2px solid #CCCCCC',
    },
  },
};
