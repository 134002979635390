import { api } from 'src/utils/axios';
import { ApiResponseType } from 'src/types/api';
import { ConsumptionAnalyticsData } from 'src/types/consumption';

export const getConsumptionSiteData = ({
    id,
    organization,
    level,
    site,
    apn,
    vid,
    node,
    sortCriteria,
    sortDirection,
    range,
    startTime,
    endTime,
    instant,
  }: {
    id?: string;
    organization: string;
    level: string;
    site: string;
    apn?: string;
    vid?: number;
    node?: string;
    sortCriteria: string;
    sortDirection?: string;
    range: string;
    startTime?: string;
    endTime?: string;
    instant?: string;
  },
  pageStart?: number,
  pageEnd?: number) => {
  return api.post<ApiResponseType>('/pm/v1/consumption', {
      id,
      organization,
      level,
      site,
      apn,
      vid,
      node,
      sortCriteria,
      sortDirection,
      range,
      startTime,
      endTime,
      instant,
    },
    {
      headers: {
        pageStart,
        pageEnd,
      },
    });
};

export type ConsumptionAnalyticsCriteria = 'LATENCY' | 'UPLINK' | 'DOWNLINK';
export type ConsumptionAnalyticsRange = 'DAY' | 'WEEK' | 'MONTH' | 'CUSTOM';

export const getConsumptionAnalytics = ({
  organization,
  level,
  site,
  imsi,
  criteria,
  range,
  apn,
  startTime,
  endTime,
}: {
  organization: string;
  level: 'SITE' | 'APN' | 'IMSI';
  site: string;
  imsi?: string;
  criteria: ConsumptionAnalyticsCriteria;
  range: ConsumptionAnalyticsRange;
  apn?: string;
  startTime?: string | null;
  endTime?: string | null;
}) => {
  return api.post<ApiResponseType<ConsumptionAnalyticsData>>(
    '/pm/v1/analytics',
    {
      id: 'string',
      organization,
      level,
      site,
      imsi,
      criteria,
      includeLimit: true,
      includeRuleExecution: true,
      range,
      apn,
      startTime,
      endTime,
    },
  );
};
