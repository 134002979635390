import styled from '@emotion/styled';
import { Dialog } from '@mui/material';

export const StyledDialog = styled(Dialog)`
  & {
    .MuiDialog-container {
      .MuiPaper-root {
        width: 450px;
        padding-right: 20px;
        padding-bottom: 20px;
        .MuiDialogContent-root {
          padding-right: 8px;
        }
      }
    }
  }
`;

export const styles = {
  closeIconButton: {
    position: 'absolute',
    right: '15px',
    top: '15px',
  },
  closeIcon: {
    position: 'absolute',
    right: '15px',
    top: '10px',
  },
  iconClose: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    right: '15px',
    top: '10px',
    color: '#222222',
  },
  textFieldRuleRename: {
    height: '40px',
  },
  characterAdornment: {
    position: 'absolute',
    top: '5px',
    right: '14px',
    paddingTop: '5px',
  },
  characterAdornmentText: {
    fontSize: '9px',
    color: 'rgba(136, 136, 136, 1)'
  },
  boldText: {
    textAlign: 'left',
    color: '#222222',
    fontWeight: 'bold',
    fontSize: '16px',
  },
};

export const StyledInputDialogContainer = styled(Dialog)`
  & {
    .MuiDialog-container {
      .MuiPaper-root {
        min-width: 380px;
        padding-right: 20px;
        padding-bottom: 20px;
      }
    }
    .MuiDialogContent-root {
      padding-right: 8px;
    }
  }
`;
