import { DialogTitle, DialogContent, DialogActions } from '@mui/material';

import { styles } from './styles';

interface WidgetFieldsContainerProps {
  title: string;
  children: React.ReactNode;
  actions: React.ReactNode;
  isFlex?: boolean;
}

export const WidgetFieldsContainer: React.FC<WidgetFieldsContainerProps> = ({
  title,
  children,
  actions,
  isFlex,
}) => {
  return (
    <>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent
        sx={
          isFlex
            ? styles.flexWidgetsFieldContainer
            : styles.widgetsFieldContainer
        }
      >
        {children}
      </DialogContent>
      <DialogActions>{actions}</DialogActions>
    </>
  );
};
