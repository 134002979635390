import { Box, Link, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface UserUsageInfoProps {
  isLastItem: boolean;
  userId: string;
  firstName: string;
  lastName: string;
  dataAllocated?: number;
  dataUsed?: number;
  policyName?: string;
  renderExample?: string;
}

export const UserUsageInfo: React.FC<UserUsageInfoProps> = ({
  isLastItem,
  userId,
  firstName,
  lastName,
  dataAllocated,
  dataUsed,
  policyName,
  renderExample = 'normal',
}) => {
  const navigate = useNavigate();

  const getBackground = (): string => {
    const dataAvailable =
      (Number(dataUsed ?? 0) / Number(dataAllocated ?? 0)) * 100;
    if (dataAvailable < 80) {
      return 'transparent linear-gradient(270deg, #55CFBB 0%, #1AAF97 100%) 0% 0% no-repeat padding-box';
    } else if (dataAvailable >= 80 && dataAvailable < 90) {
      return '#FF9900';
    } else {
      return '#F20000';
    }
  };

  return (
    <Box
      display='flex'
      justifyContent='space-between'
      paddingY='20px'
      borderBottom={!isLastItem ? '1px solid #CCCCCC' : undefined}
    >
      <Box>
        <Link
          fontWeight='bold'
          onClick={() => navigate(`/usage-policy/user/${userId}`)}
        >
          {lastName} {firstName}
        </Link>
        <Typography color='#888888'>{policyName}</Typography>
      </Box>
      <Box width={285} paddingTop='6px'>
        <Box
          height={10}
          borderRadius={6}
          position='relative'
          sx={{
            background: '#CCCCCC',
          }}
        >
          <Box
            position='absolute'
            borderRadius='26px'
            width={`${
              (Number(dataUsed ?? 0) / Number(dataAllocated ?? 0)) * 100
            }%`}
            height={20}
            top={-5}
            border='2px solid #fff'
            boxShadow='0px 3px 6px #00000029'
            sx={{
              background: getBackground(),
            }}
          />
        </Box>

        <Box display='flex' justifyContent='space-between' mt='12px'>
          <Box display='flex' alignItems='center'>
            <Typography fontWeight='bold' color='#888888' fontSize='12px'>
              Available :
            </Typography>
            <Typography color='#888888' fontSize='12px' ml='3px'>
              {Number(dataAllocated ?? 0) - Number(dataUsed ?? 0)} GB
            </Typography>
          </Box>
          <Box>
            <Box display='flex'>
              <Typography
                color={
                  renderExample === 'normal'
                    ? '#55CFBB'
                    : renderExample === 'warning'
                    ? '#FF9900'
                    : '#F20000'
                }
                fontSize='14px'
                fontWeight='bold'
                ml='3px'
              >
                {dataAllocated} GB
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
