import * as Yup from 'yup';

export const createAddOrganizationSchema = Yup.object().shape({
  organization: Yup.string()
    .matches(
      /^[a-z0-9-_]*$/,
      'Text should only contain lowercase letters, numbers and (-_)',
    )
    .required('Organization is required'),
  country: Yup.string()
    .matches(/^[a-zA-Z0-9-_ ]*$/)
    .required('Country is required'),
});
