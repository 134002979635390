import styled from '@emotion/styled';
import { MenuItem } from '@mui/material';

export const styles = {
  input: {
    width: '185px',
    marginBottom: '10px',
    display: 'block',
    'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    'input[type=number]': {
      MozAppearance: 'textfield',
    },
  },
  characterAdornment: {
    position: 'absolute',
    top: '5px',
    right: '14px',
    paddingTop: '5px',
  },
  characterAdornmentText: {
    fontSize: '9px',
    color: 'rgba(136, 136, 136, 1)',
  },
};

export const StyledMenuItem = styled(MenuItem)`
  & {
    .MuiListItemText-root {
      .MuiTypography-root {
        font-size: 14px;
        color: #555555;
      }
    }
  }
  &.Mui-selected {
    background-color: #fff;
  }
  &.Mui-selected:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
  &.MuiAutocomplete-option[aria-selected='true'] {
    background-color: #fff;
  }
  &.MuiAutocomplete-option[aria-selected='true']:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;
