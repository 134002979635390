import { KeyboardArrowDown } from '@mui/icons-material';
import {
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@mui/material';
import React from 'react';

import {
  StyledSplitButtonProps,
  StyledButton,
  StyledButtonProps,
  StyledSplitButton,
} from './styles';

export const Button: React.FC<StyledButtonProps> = ({ children, ...rest }) => {
  return <StyledButton {...rest}>{children}</StyledButton>;
};

interface SplitButtonProps extends StyledSplitButtonProps {
  label: string;
  primaryAction: () => void;
  options: Array<{
    label: string;
    action: () => void;
    styles?: React.CSSProperties;
  }>;
}

export const SplitButton: React.FC<SplitButtonProps> = ({
  kind,
  label,
  primaryAction,
  options,
}) => {
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorEl?.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };
  return (
    <>
      <StyledSplitButton kind={kind}>
        <Button kind={kind} padding="8px 64px" onClick={primaryAction}>
          {label}
        </Button>
        <Button size="small" kind={kind} padding="0" onClick={handleToggle}>
          <KeyboardArrowDown />
        </Button>
      </StyledSplitButton>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorEl}
        transition
        disablePortal
        placement="bottom-end"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option.label}
                      onClick={() => {
                        option.action();
                        setOpen(false);
                      }}
                      sx={option.styles}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
