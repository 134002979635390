import { DeleteForeverOutlined } from '@mui/icons-material';
import { Box, Card, Skeleton, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';

import { getRangeMetricsData } from 'src/apis/metrics';
import { LineChart, Menu, ColumnChart } from 'src/components';
import { QueryName } from 'src/enums/query';
import { TimeSeriesChartType } from 'src/enums/widget';
import { KPIWidget, AlarmWidget, WeatherWidget } from 'src/types/widget';
import { styles } from './styles';

interface TimeSeriesWidgetProps {
  resize: number;
  widget: KPIWidget | AlarmWidget | WeatherWidget;
  timeSeriesFilters: {
    dataInterval: number;
    predefinedDateRange: number | null;
    customDateRange: {
      start: Date | null;
      end: Date | null;
    };
  };
  onDelete: () => void;
}

export const TimeSeriesWidget: React.FC<TimeSeriesWidgetProps> = ({
  resize,
  widget,
  timeSeriesFilters,
  onDelete,
}) => {
  const [chartStyles, setCharStyles] = useState({ height: 0, width: 0 });

  const _getAdditionalParams = () => {
    if (widget.metricsLevel === 'SITE') {
      return { site: widget.site };
    }
    if (widget.metricsLevel === 'IMSI') {
      return { imsi: (widget as KPIWidget).imsi, site: widget.site };
    }
    if (widget.metricsLevel === 'APN') {
      return {
        apn: (widget as KPIWidget | AlarmWidget).apn,
        site: widget.site,
      };
    }
    if (widget.metricsLevel === 'VLAN') {
      return {
        vid: Number((widget as KPIWidget | AlarmWidget).vlan),
        site: widget.site,
      };
    }
    if (widget.metricsLevel === 'NODE') {
      return { node: (widget as AlarmWidget).node, site: widget.site };
    }
  };

  const { data, isLoading, isFetching } = useQuery(
    [
      QueryName.GET_RANGE_METRICS_DATA,
      widget.id,
      timeSeriesFilters.dataInterval,
      timeSeriesFilters.predefinedDateRange,
      timeSeriesFilters.customDateRange,
    ],
    () =>
      getRangeMetricsData({
        id: widget.id,
        metric: widget.metricName,
        organization: widget.org,
        level: widget.metricsLevel,
        start:
          !timeSeriesFilters.predefinedDateRange &&
          timeSeriesFilters.customDateRange.start
            ? new Date(timeSeriesFilters.customDateRange.start).toISOString()
            : '',
        end:
          !timeSeriesFilters.predefinedDateRange &&
          timeSeriesFilters.customDateRange.end
            ? new Date(timeSeriesFilters.customDateRange.end).toISOString()
            : '',
        stepInSeconds: timeSeriesFilters.dataInterval,
        last: timeSeriesFilters.predefinedDateRange
          ? Number(timeSeriesFilters.predefinedDateRange)
          : undefined,
        ..._getAdditionalParams(),
      }),
    {
      enabled:
        !!widget.id &&
        (!!timeSeriesFilters.predefinedDateRange ||
          (!timeSeriesFilters.predefinedDateRange &&
            !!timeSeriesFilters.customDateRange.start)),
    },
  );

  useEffect(() => {
    setTimeout(() => {
      const parent = document.getElementById(`time-series-${widget.id}`);
      if (parent) {
        setCharStyles({
          height: parent.clientHeight - 40,
          width: parent.clientWidth - 20,
        });
      }
    }, 0);
  }, [resize, widget.id]);

  const xAxisLabelTimeFormat =
    timeSeriesFilters.dataInterval <= 21600 ? 'HH:mm' : 'MMM dd';

  return (
    <Card sx={styles.cardContainer}>
      {isLoading || isFetching ? (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          sx={{ height: '100%' }}
        >
          <Skeleton width="70%" />
          <Skeleton width="100%" height="100%" />
        </Box>
      ) : (
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography color="text.secondary" fontSize={14} noWrap>
              {widget.label}
            </Typography>
            <Menu
              actions={[
                {
                  label: 'Delete Widget',
                  action: () => onDelete(),
                  styles: styles.deleteOptionText,
                  icon: <DeleteForeverOutlined sx={styles.deleteOptionIcon} />,
                },
              ]}
            />
          </Box>
          {widget.timeSeriesChartType === TimeSeriesChartType.LINE && (
            <LineChart
              type="spline"
              height={chartStyles.height}
              width={chartStyles.width}
              xAxisLabels={data?.data?.results?.map((datapoint) =>
                format(new Date(datapoint.time), xAxisLabelTimeFormat),
              )}
              yAxisTitle={widget.metricName}
              tooltipLabel={widget.metricName}
              data={data?.data?.results?.map((datapoint) => datapoint.value)}
            />
          )}
          {widget.timeSeriesChartType === TimeSeriesChartType.COLUMN && (
            <ColumnChart
              height={chartStyles.height}
              width={chartStyles.width}
              xAxisLabels={data?.data?.results?.map((datapoint) =>
                format(new Date(datapoint.time), xAxisLabelTimeFormat),
              )}
              yAxisTitle={widget.metricName}
              tooltipLabel={widget.metricName}
              data={data?.data?.results?.map((datapoint) => datapoint.value)}
            />
          )}
        </>
      )}
    </Card>
  );
};
