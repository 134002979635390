import { api } from '../utils/axios';
import { ApiResponseType } from '../types/api';

export const getAllGroups = (
  organization: string,
  site: string,
  pageStart?: number,
  pageEnd?: number,
) => {
  return api.get<ApiResponseType>('/topology/v1/group/all', {
    params: {
      organization,
      site,
    },
    headers: {
      pageStart,
      pageEnd,
    },
  });
};

export const getAllUserGroups = (organization: string) => {
  return api.get<ApiResponseType<string[]>>('/accessmanagement/v1/group/list', {
    params: { organization },
  });
};

export const createUsersGroup = (data: {
  organization: string;
  groupName: string;
  userIds: string[];
}) => {
  return api.post('/accessmanagement/v1/group/user', {
    organization: data.organization,
    groupName: data.groupName,
    userIds: data.userIds,
  });
};
