import styled from '@emotion/styled';
import { Chip } from '@mui/material';
import { ReactElement } from 'react';
import { css } from '@emotion/react';

export interface StyledTagProps {
  label: string;
  onDelete?: () => void;
  deleteIcon?: ReactElement;
  textColor?: string;
  backgroundColor?: string;
  border?: string;
}

const getCSS = ({ textColor, backgroundColor, border }: StyledTagProps) => {
  return css`
    background: ${backgroundColor};
    color: ${textColor};
    border: ${border};
  `;
};

export const StyledTag = styled(Chip)<StyledTagProps>`
  height: 26px;
  margin: 5px;
  ${getCSS}
`;
