import { Box, FormControlLabel, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Search } from '@carbon/icons-react';
import { points, center } from '@turf/turf';

import {
  AppContext,
  AuthContext,
  RulesEngineContext,
  ToastContext,
} from 'src/context';
import { QueryName } from 'src/enums/query';
import { getAllUEGroups, getAllUes } from 'src/apis/ue';
import { UE, UEGroup } from 'src/types/domain';
import { Checkbox, Loading, TextField } from 'src/components';
import { getCoordinates } from 'src/apis/geo-service';

export const SelectEquipment: React.FC = () => {
  const { user } = useContext(AuthContext);
  const { site } = useContext(AppContext);
  const { showToast } = useContext(ToastContext);

  const { selectedEquipments, addNewEquipment, removeEquipment } =
    useContext(RulesEngineContext);

  const [filter, setFilter] = useState('');

  const { data: equipmentsData, isLoading: equipmentsLoading } = useQuery(
    [QueryName.GET_ALL_UES, site],
    () =>
      getAllUes(
        { organization: user?.organizations?.[0] as string, site },
        1,
        50,
      ),
    { enabled: !!user?.organizations?.[0] && !!site },
  );

  const { data: equipmentsGroupsData, isLoading: equipmentsGroupsLoading } =
    useQuery(
      [QueryName.GET_ALL_UE_GROUPS, site],
      () =>
        getAllUEGroups({
          organization: user?.organizations?.[0] as string,
          site,
        }),
      { enabled: !!user?.organizations?.[0] && !!site },
    );

  const { data: coordinatesData } = useQuery(
    [QueryName.GET_COORDINATES, site],
    () =>
      getCoordinates({
        organization: user?.organizations?.[0] as string,
        site,
        ueGroups: equipmentsGroupsData?.data.groups.map(
          (group) => group.groupName,
        ),
      }),
    {
      enabled:
        !!user?.organizations?.[0] &&
        !!site &&
        !!equipmentsGroupsData?.data.groups,
    },
  );

  const selectUE = (ue: UE) => {
    addNewEquipment({
      id: ue.ueid,
      name: ue.name,
      coordinates:
        ue.latitude && ue.longitude
          ? {
              latitude: ue.latitude,
              longitude: ue.longitude,
            }
          : null,
      type: 'equipment',
    });
  };

  const selectUEGroup = (group: UEGroup) => {
    let ueGroupCoordinates: {
      latitude: number;
      longitude: number;
    } | null = null;

    const groupUEs = coordinatesData?.data.ueGroups[group.groupName];
    if (groupUEs) {
      const groupUEsCoordinates: number[][] = [];

      Object.keys(groupUEs).forEach((ue) => {
        const ueCoordinates = groupUEs[ue].coordinates;
        if (ueCoordinates) {
          groupUEsCoordinates.push(ueCoordinates);
        }
      });

      if (groupUEsCoordinates.length > 0) {
        const features = points([...groupUEsCoordinates]);

        const {
          geometry: { coordinates },
        } = center(features);

        ueGroupCoordinates = {
          latitude: coordinates[1],
          longitude: coordinates[0],
        };
      }
    }
    addNewEquipment({
      id: group.id,
      name: group.groupName,
      coordinates: ueGroupCoordinates,
      type: 'equipment_group',
    });
  };

  return (
    <Box>
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        mb='10px'
      >
        <Typography variant='h2'>Equipment</Typography>
        <TextField
          isSearchField
          placeholder='Search'
          id='search-equipments'
          InputProps={{ endAdornment: <Search size={20} color='#888888' /> }}
          onChange={(event) => setFilter(event.target.value)}
        />
      </Box>
      <Box
        height={302}
        sx={{
          overflowX: 'hidden',
          overflowY: 'auto',
          border: '1px solid #0000003b',
        }}
        px='26px'
        py='16px'
      >
        <Box mb={2}>
          <Typography variant='h4' sx={{ padding: '4px 0px' }}>
            Groups of equipment
          </Typography>
          <Box px={3} py={1}>
            <Loading isLoading={equipmentsGroupsLoading}>
              <Box display='flex' flexDirection='column'>
                {equipmentsGroupsData?.data?.total !== 0 ? (
                  equipmentsGroupsData?.data?.groups
                    .filter((group) =>
                      group.groupName
                        .toLowerCase()
                        .includes(filter.toLowerCase()),
                    )
                    .map((group) => (
                      <FormControlLabel
                        key={group.id}
                        sx={{
                          background:
                            selectedEquipments.findIndex(
                              (selectedEquipment) =>
                                selectedEquipment.id === group.id,
                            ) > -1
                              ? '#F1FCFA'
                              : '#fff',
                          ':hover': { background: '#F1FCFA' },
                          height: '26px',
                          marginBottom: 1,
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                        }}
                        checked={
                          selectedEquipments.findIndex(
                            (selectedEquipment) =>
                              selectedEquipment.id === group.id,
                          ) > -1
                        }
                        onChange={(_, checked) => {
                          if (checked && !group?.ues?.length) {
                            showToast(
                              'Group of equipment has no coordinates',
                              'warning',
                            );
                          }
                          checked
                            ? selectUEGroup(group)
                            : removeEquipment(group.id);
                        }}
                        control={<Checkbox size='small' />}
                        label={
                          <Box display='flex' alignItems='center'>
                            <Typography fontSize='14px' color='#222222'>
                              {group.groupName}
                            </Typography>
                            <Typography
                              noWrap
                              fontSize={12}
                              sx={{ color: '#888888', marginLeft: 1 }}
                            >
                              {group.ues?.join(', ')}
                            </Typography>
                          </Box>
                        }
                      />
                    ))
                ) : (
                  <Typography fontSize='14px' color='#222222' mt='5px'>
                    No ue groups found
                  </Typography>
                )}
              </Box>
            </Loading>
          </Box>
        </Box>
        <Box>
          <Typography variant='h4' sx={{ padding: '4px 0px' }}>
            Units equipment
          </Typography>
          <Box px={3} py={1}>
            <Loading isLoading={equipmentsLoading}>
              <Box display='flex' flexDirection='column'>
                {equipmentsData?.data?.devices
                  .filter((equipment) =>
                    equipment.name
                      ?.toLowerCase()
                      .includes(filter.toLowerCase()),
                  )
                  .map((equipment) => (
                    <FormControlLabel
                      key={equipment.ueid}
                      sx={{
                        background:
                          selectedEquipments.findIndex(
                            (selectedEquipment) =>
                              selectedEquipment.id === equipment.ueid,
                          ) > -1
                            ? '#F1FCFA'
                            : '#fff',
                        ':hover': { background: '#F1FCFA' },
                        height: '26px',
                        marginBottom: 1,
                      }}
                      checked={
                        selectedEquipments.findIndex(
                          (selectedEquipment) =>
                            selectedEquipment.id === equipment.ueid,
                        ) > -1
                      }
                      onChange={(_, checked) => {
                        if (
                          checked &&
                          !equipment?.latitude &&
                          !equipment?.longitude
                        ) {
                          showToast('Equipment has no coordinates', 'warning');
                        }
                        checked
                          ? selectUE(equipment)
                          : removeEquipment(equipment.ueid);
                      }}
                      control={<Checkbox size='small' />}
                      label={
                        <Typography fontSize='14px' color='#222222'>
                          {equipment.name}
                        </Typography>
                      }
                    />
                  ))}
              </Box>
            </Loading>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
