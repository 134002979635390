import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Confirmable, Loading } from 'src/components';
import { RulesEngineProvider } from 'src/context/RulesEngineContext';
import { EditRulesSelection } from 'src/views/Rules/rule/components/RulesSelection/EditRulesSelection';
import { EditRulesSpecification } from 'src/views/Rules/rule/components/Specification/EditRulesSpecification/EditRulesSpecification';
import { QueryName } from 'src/enums/query';
import { useQuery } from '@tanstack/react-query';
import { getRulesInfo } from 'src/apis/rule';
import { AppContext, ToastContext } from 'src/context';

export const EditRule: React.FC = () => {
  const navigate = useNavigate();
  const [displayDiscardModal, setDisplayDiscardModal] = useState(false);
  const [params] = useSearchParams({ step: 'specification' });
  const { siteChanged, site: selectedSite } = useContext(AppContext);
  const { showToast } = useContext(ToastContext);

  const step = params.get('step');
  const { organization, site, id } = useParams<{
    organization: string;
    site: string;
    id: string;
  }>();
  const { data, isLoading, isFetching, isError } = useQuery(
    [QueryName.GET_RULE_DETAIL, site, id],
    () => getRulesInfo(organization ?? '', site ?? '', id ?? '', 'DAY'),
    { enabled: !!organization && !!site && !!id },
  );

  useEffect(() => {
    if (siteChanged) {
      navigate(`/rules/edit/${organization}/${selectedSite}/${id}`);
    }
    if (isError) {
      showToast('Unable to get rule details to edit the rule!', 'error');
      navigate('/rules');
    }
  }, [siteChanged, selectedSite, isError]);

  return (
    <RulesEngineProvider>
      <Loading isLoading={isLoading || isFetching}>
        {step === 'selection' && (
          <EditRulesSelection
            onCancel={() => setDisplayDiscardModal(true)}
            ruleName={data?.data?.configuration?.name ?? ''}
          />
        )}
        {step === 'specification' && data?.data?.configuration && (
          <EditRulesSpecification
            data={data?.data?.configuration}
            onCancel={() => setDisplayDiscardModal(true)}
          />
        )}
        <Confirmable
          open={displayDiscardModal}
          title='Discard Changes'
          subTitle='Do you want to discard changes?'
          onCancel={() => setDisplayDiscardModal(false)}
          onConfirm={() => navigate('/rules')}
        />
      </Loading>
    </RulesEngineProvider>
  );
};
