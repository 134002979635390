import createTheme from '@mui/material/styles/createTheme';

export const colors = {
  primary: '#55CFBB',
  primaryLight: '#F1FCFA',
  secondary: '#006161',
  secondaryLight: '#888888',
  black: '#222222',
  white: '#FFFFFF',
  success: '#00A410',
  danger: '#F20000',
  warn: '#FF9900',
};

export const theme = createTheme({
  typography: {
    fontFamily: '"Lato", sans-serif',
    body1: {
      fontSize: 14,
      color: '#222222',
      fontWeight: 'normal',
    },
    body2: {
      fontSize: 14,
      color: '#888888',
      fontWeight: 'normal',
    },
    caption: {
      fontSize: 12,
      color: '#222222',
      fontWeight: 'normal',
    },
    h1: {
      fontSize: 26,
      fontWeight: 'bold',
      color: colors.secondary,
    },
    h2: {
      fontSize: 22,
      fontWeight: 'bold',
      color: colors.secondary,
    },
    h3: {
      fontSize: 18,
      fontWeight: 'bold',
      color: colors.secondary,
    },
    h4: {
      fontSize: 14,
      color: '#222222',
      fontWeight: 'bold',
    },
  },
  palette: {
    common: {
      black: colors.black,
      white: colors.white,
    },
    primary: {
      main: colors.primary,
      contrastText: colors.white,
    },
    secondary: {
      main: colors.white,
      contrastText: colors.secondary,
    },
    success: {
      main: colors.success,
    },
    warning: {
      main: colors.warn,
    },
    error: {
      main: colors.danger,
    },
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: colors.primaryLight,
          color: colors.secondaryLight,
          width: 250,
          borderRight: 'unset',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        regular: {
          minHeight: 'inherit !important',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          height: 50,
          backgroundColor: '#fff',
          boxShadow: '0px 3px 6px #00000024',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: colors.secondary,
          cursor: 'pointer',
          textDecorationColor: colors.secondary,
          textDecoration: 'underline',
          ':hover': {
            textDecoration: 'none',
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: '#006161',
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: colors.secondary,
        },
      },
    },
  },
});
