import { useContext } from 'react';
import { Navigate, Route, Routes as Routes_ } from 'react-router-dom';

import { AuthContext } from 'src/context';
import { Login } from 'src/views/Auth';
import { Dashboard } from 'src/views/Dashboard';
import { ListAllChannels } from 'src/views/Channel';
import { ComponentsList } from 'src/views/ComponentsList';
import { RuleDetails } from 'src/views/Rules/RuleDetails';
import { EditRule } from 'src/views/Rules/rule/EditRule';
import { routes } from './routes';
import { RulesEngine } from 'src/views/Rules/rule';
import { DeviceListing } from 'src/views/DeviceManagement/Listing';
import { ManageNetwork } from 'src/views/DeviceManagement/ManageNetwork';
import { Rules } from 'src/views/Rules';
import { SiteConsumption } from 'src/views/Consumption/Site';
import { ApnConsumption } from 'src/views/Consumption/APN';
import { EditDevice } from 'src/views/Devices/EditDevice';
import { ViewDevice } from 'src/views/Devices/ViewDevice';
import { Geomap } from 'src/views/Geomap';
import { UserManagement } from 'src/views/UserManagement';
import { EditNetwork } from 'src/views/DeviceManagement/ManageNetwork/EditNetwork';
import { UserInfo } from 'src/views/UserManagement/ListUsers/UserInfo';
import { useUserRole } from 'src/utils/user';
import { UsageDashboard } from 'src/views/UsagePolicy/UsageDasboard';
import { UserUsagePolicy } from 'src/views/UsagePolicy/UserUsage';
import { CreatePolicy } from 'src/views/UsagePolicy/CreatePolicy';
import { EditPolicy } from 'src/views/UsagePolicy/EditPolicy';

export const Routes: React.FC = () => {
  const { loading, isLoggedIn } = useContext(AuthContext);
  if (loading) return null;
  return isLoggedIn ? <AuthenticatedRoutes /> : <NonAuthenticatedRoutes />;
};

function NonAuthenticatedRoutes() {
  return (
    <Routes_>
      <Route path={routes.login} element={<Login />} />
      {/* <Route path={routes.register} element={<Register />} />
      <Route path={routes.forgotPassword} element={<ForgotPassword />} /> */}
      <Route path='*' element={<Navigate to={routes.login} replace />} />
    </Routes_>
  );
}

function AuthenticatedRoutes() {
  const { isSuperUser } = useUserRole();
  return (
    <Routes_>
      <Route path={routes.home} element={<Dashboard />} />
      <Route path={routes.rule} element={<RulesEngine />} />
      <Route path={routes.rules} element={<Rules />} />
      <Route path={routes.ruleDetail} element={<RuleDetails />} />
      <Route path={routes.editRule} element={<EditRule />} />
      <Route path={routes.siteConsumption} element={<SiteConsumption />} />
      <Route path={routes.apnConsumption} element={<ApnConsumption />} />
      <Route path={routes.settings} element={<ListAllChannels />} />
      <Route path={routes.components} element={<ComponentsList />} />
      <Route path={routes.devices} element={<DeviceListing />} />
      <Route path={routes.userManagement} element={<UserManagement />} />
      <Route path={routes.user} element={<UserInfo />} />
      <Route path={routes.networks} element={<ManageNetwork />} />
      <Route path={routes.viewDevice} element={<ViewDevice />} />
      <Route path={routes.editDevice} element={<EditDevice />} />
      <Route path={routes.editNetwork} element={<EditNetwork />} />
      <Route path={routes.geomap} element={<Geomap />} />
      <Route path={routes.usagePolicy} element={<UsageDashboard />} />
      <Route path={routes.userUsagePolicy} element={<UserUsagePolicy />} />
      <Route path={routes.createUsagePolicy} element={<CreatePolicy />} />
      <Route path={routes.editUsagePolicy} element={<EditPolicy />} />
      {isSuperUser() ? (
        <Route
          path='*'
          element={<Navigate to={routes.userManagement} replace />}
        />
      ) : (
        <Route
          path='*'
          element={
            <Navigate
              to={`${routes.siteConsumption}?criteria=UPLINK&range=FIVE_MIN`}
              replace
            />
          }
        />
      )}
    </Routes_>
  );
}
