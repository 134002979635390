import { useQuery } from '@tanstack/react-query';
import { createContext, useContext, useState } from 'react';
import { getProductsLicence } from 'src/apis/licence';
import { Loading } from 'src/components';
import { QueryName } from 'src/enums/query';
import { AuthContext } from './AuthContext';

interface LicenseContextProviderProps {
  children: React.ReactNode;
}

const defaultValues = {
  hasNDOLicense: false,
  hasNDMLicense: false,
};

export const LicenseContext = createContext(defaultValues);

export const LicenseContextProvider: React.FC<LicenseContextProviderProps> = ({
  children,
}) => {
  const { isLoggedIn } = useContext(AuthContext);
  const [license, setLicense] = useState({
    ndoLicense: false,
    ndmLicense: false,
  });
  const { isFetching } = useQuery(
    [QueryName.GET_PRODUCTS_LICENSE, isLoggedIn],
    () => getProductsLicence(),
    {
      enabled: isLoggedIn,
      onSuccess: (data) => {
        setLicense({
          ndoLicense: data?.data?.products?.includes('NDO'),
          ndmLicense: data?.data?.products?.includes('NDM'),
        });
      },
    },
  );

  return (
    <Loading isLoading={isFetching}>
      <LicenseContext.Provider
        value={{
          hasNDOLicense: license.ndoLicense,
          hasNDMLicense: license.ndmLicense,
        }}
      >
        {children}
      </LicenseContext.Provider>
    </Loading>
  );
};
