import { useState } from 'react';
import {
  Menu as MUIMenu,
  MenuItem,
  Button,
  ListItemIcon,
  ListItemText,
  IconButton,
} from '@mui/material';
import { MoreVertOutlined } from '@mui/icons-material';

type MenuAction = {
  label: string;
  action: () => void;
  styles?: React.CSSProperties;
  icon?: React.ReactNode;
  disabled?: boolean;
};

interface MenuProps {
  title?: string;
  titleIcon?: React.ReactNode;
  actions: MenuAction[];
  menuTextColor?: string;
}

export const Menu: React.FC<MenuProps> = ({
  title,
  titleIcon,
  actions,
  menuTextColor,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const buttonColor = title === 'DELETE' ? '#FF0000' : '#3da9fc';

  return (
    <div>
      {title ? (
        <Button
          style={{
            textTransform: 'capitalize',
            color: menuTextColor ?? buttonColor,
          }}
          startIcon={
            titleIcon || (
              <MoreVertOutlined sx={{ fontSize: '24px', color: '#118AB2' }} />
            )
          }
          onClick={handleClick}
        >
          {title}
        </Button>
      ) : (
        <IconButton size="small" onClick={handleClick}>
          {titleIcon || (
            <MoreVertOutlined sx={{ fontSize: '22px', color: '#118AB2' }} />
          )}
        </IconButton>
      )}
      <MUIMenu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {actions.map((action) => (
          <MenuItem
            key={action.label}
            onClick={() => {
              action.action();
              handleClose();
            }}
            disabled={action.disabled}
          >
            {action.icon && <ListItemIcon>{action.icon}</ListItemIcon>}
            <ListItemText sx={action.styles}>{action.label}</ListItemText>
          </MenuItem>
        ))}
      </MUIMenu>
    </div>
  );
};
