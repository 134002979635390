import { yupResolver } from '@hookform/resolvers/yup';
import { Close } from '@mui/icons-material';
import {
  DialogContent,
  DialogActions,
  Box,
  DialogTitle,
  IconButton,
  MenuItem,
  ListItemText,
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import React, { useContext } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { allocateUE } from 'src/apis/ue';
import { Button, Loading, Select } from 'src/components';
import { AppContext, AuthContext, ToastContext } from 'src/context';
import { QueryName } from 'src/enums/query';
import { StyledDialog, styles } from './styles';
import { getUsersNameList } from 'src/apis/access-management';
import { allocateUESchema } from 'src/yup-validations/allocateUE';
import { Device } from 'src/types/device';

interface GroupModelProps {
  open: boolean;
  onCancel: () => void;
  selectedDevices: Device[];
}

type FormData = {
  userId: string;
};

export const AllocateUEModal: React.FC<GroupModelProps> = ({
  onCancel,
  open,
  selectedDevices,
}) => {
  const ueIds = selectedDevices.map((device: Device) => device.ueid);
  const { user } = useContext(AuthContext);
  const queryClient = useQueryClient();
  const organization = user?.organizations?.[0] as string;
  const { site } = useContext(AppContext);
  const { showToast } = useContext(ToastContext);
  const { data: userList, isLoading: userListLoading } = useQuery(
    [QueryName.GET_USERS_NAME, site, organization],
    () => getUsersNameList(organization),
    { enabled: open && !!site && !!organization },
  );

  const { mutate: allocateUEMutation } = useMutation<
    unknown,
    AxiosError,
    { organization: string; site: string; ueIds: string[]; ownerEmail: string }
  >({
    mutationFn: allocateUE,
    onSuccess: async () => {
      onCancel();
      reset();
      await queryClient.invalidateQueries([QueryName.GET_ALL_DEVICES]);
      showToast('Selected UE allocated to user successfully!', 'success');
    },
    onError: () => {
      showToast('Failed to perform the operation, try again', 'error');
    },
  });
  const handleSave = (data: FormData) => {
    allocateUEMutation({
      organization,
      site,
      ueIds: ueIds,
      ownerEmail: data.userId,
    });
  };
  const { control, formState, setValue, handleSubmit, reset } = useForm({
    defaultValues: {
      userId: '',
    },
    resolver: yupResolver(allocateUESchema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });
  const handleCancel = () => {
    onCancel();
    reset();
  };
  return (
    <StyledDialog open={open}>
      <DialogTitle>
        Allocate equipments
        <IconButton sx={styles.closeIconButton} onClick={onCancel}>
          <Close fontSize='medium' />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box ml='5px'>
          <ul style={{ paddingLeft: '25px' }}>
            {selectedDevices.map((device) => (
              <li key={device.ueid}>{device.ueid}</li>
            ))}
          </ul>
        </Box>
        <Loading isLoading={userListLoading}>
          <Box sx={{ mt: '4px', mb: '12px' }}>
            <Controller
              name='userId'
              control={control}
              render={({ field }) => (
                <Select
                  id='group-selected'
                  width={398}
                  label='User'
                  placeholder='User'
                  error={
                    !!formState.errors && formState.errors?.userId?.message
                  }
                  {...field}
                  onChange={(event) => {
                    field.onChange(event.target.value);
                    setValue('userId', event.target.value);
                  }}
                >
                  <MenuItem value='None'>None</MenuItem>
                  {userList?.data?.users?.map((user) => (
                    <MenuItem key={user.userId} value={user.email}>
                      <ListItemText
                        primary={user.lastName + ' ' + user.firstName}
                      />
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Box>
        </Loading>
      </DialogContent>
      <DialogActions>
        <Button kind='primary-ghost' onClick={handleCancel}>
          Cancel
        </Button>
        <Button kind={'primary'} onClick={handleSubmit(handleSave)}>
          Save
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};
