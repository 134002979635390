export const styles = {
  boxStyle: (dragging: boolean) => ({
    height: '225px',
    border: '2px dashed #006161',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    backgroundColor: dragging ? 'transparent' : '#F1FCFA',
    marginBottom: '10px',
    cursor: 'pointer',
  }),
  iconButton: {
    direction: 'rtl',
    height: '40px',
    borderRadius: 0,
    textDecoration: 'underline',
    ':hover': {
      textDecoration: 'none',
      backgroundColor: 'transparent',
    },
  },
  editIconText: {
    size: '24',
    paddingLeft: '11px',
    color: '#006161',
    fontSize: '14px',
    alignItems: 'left',
  },
  downloadIcon: {
    color: '#006161',
    size: '24',
  },
  closeIcon: {
    position: 'absolute',
    right: '30px',
    top: '26px',
  },
  templateButtons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  uploadIcon: {
    width: '100px',
    margin: '10px',
  },
  dropFiles: {
    display: 'flex',
    flexDirection: 'column',
  },
  browse: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  selectedFile: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  fileRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  emailText: {
    fontSize: '14px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  emailTextWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '65%',
  },
  statusWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '35%',
  },
  userCreated: {
    margin: ' 0 10px',
    height: '16px',
    color: '#00A410',
  },
  userError: {
    margin: ' 0 10px',
    height: '16px',
    color: '#F20000',
  },
  addFile: {
    marginLeft: '5px',
    color: '#006161',
    textDecoration: 'underline',
    cursor: 'pointer',
    ':hover': { textDecoration: 'none' },
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  dropText: {
    marginLeft: '5px',
    color: '#006161',
    fontWeight: 'bold',
    ':hover': { textDecoration: 'none' },
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    alignItems: 'center',
  },
  emailsBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginBottom: '10px',
  },
  emailItem: {
    width: '346px',
    height: '153px',
    overflowY: 'auto',
    border: '1px solid #CCCCCC',
    padding: '0 25px',
  },
  created: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    color: '#00A410',
  },
  errors: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    color: '#F20000',
  },
  uploadInfo: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    width: '346px',
  },
  containerStyles: {
    height: 19,
    width: '346px',
    backgroundColor: '#D3EEE9',
    borderRadius: 50,
    margin: '0 auto',
  },
  fillerStyles: (progress: number) => ({
    height: '100%',
    width: `${progress}%`,
    backgroundColor: '#55CFBB',
    borderRadius: 'inherit',
    textAlign: 'right',
    transition: 'width 0.2s ease',
  }),
  wrapper: {
    padding: '10px 0',
    width: '346px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '0 auto',
  },
  textWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  fileName: {
    marginLeft: '5px',
    color: '#006161',
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px 0 0 24px',
  },
  title: {
    fontSize: '16px',
    fontWeight: 'bold',
    maxWidth: '200px',
  },
  errorText: {
    marginLeft: 0,
    marginTop: '3px',
    lineHeight: '1.143',
    fontSize: '10px',
    color: 'red',
  },
  extensionFile: {
    textDecoration: 'underline',
    ':hover': { textDecoration: 'none' },
  },
};
