import { apnColumns } from './consumption-colDef';
import { Box, TableCell, TableRow, Typography } from '@mui/material';
import { Checkbox, MinimalAreaChart, Pagination, Table } from 'src/components';
import { ConsumptionConfiguration } from 'src/types/consumption';
import { percentageColor } from './column-props/colorPercentColor';
import { useContext, useEffect, useState } from 'react';
import { Order, Page } from 'src/types/shared';
import { AppContext, AuthContext, ToastContext } from 'src/context';
import { useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { QueryName } from 'src/enums/query';
import { getConsumptionSiteData } from 'src/apis/consumption';
import { latencyColor } from './column-props/latencyColor';
import { tableStyles } from './tableStyles';

interface ConsumptionTableProps {
  currentTime: number;
}

export const ConsumptionTable: React.FC<ConsumptionTableProps> = ({
  currentTime,
}) => {
  const [sortCriteria, setSortCriteria] = useState<string>('LATENCY');
  const [sortDirection, setSortDirection] = useState<Order>('DESC');
  const [page, setPage] = useState<Page>({
    pageStart: 1,
    pageEnd: 10,
  });
  const { user } = useContext(AuthContext);
  const organization = user?.organizations?.[0] as string;
  const { site } = useContext(AppContext);
  const { showToast } = useContext(ToastContext);
  const [params, setParams] = useSearchParams({
    range: 'DAY',
    startTime: '',
    endTime: '',
    apn: '',
    ues: '',
  });

  const range = params.get('range') ?? 'DAY';
  const timePeriod = params.get('range') ?? 'DAY';
  const startTime = params.get('startTime');
  const endTime = params.get('endTime');
  const criteria = params.get('criteria') ?? 'LATENCY';
  const apn = params.get('apn');
  const ues = params.get('ues');
  const instant = params.get('instant');

  const [selected, setSelected] = useState<string[]>(ues ? ues.split(',') : []);

  const {
    data,
    isLoading,
    isFetching,
  } = useQuery(
    [
      QueryName.GET_CONSUMPTION_APN_TABLE_DATA,
      'APN',
      site,
      timePeriod,
      startTime,
      endTime,
      sortCriteria,
      sortDirection,
      instant,
      apn,
      currentTime,
      page.pageStart,
      page.pageEnd,
    ],
    () =>
      getConsumptionSiteData({
        organization,
        level: 'APN',
        site,
        apn: apn ?? undefined,
        sortCriteria,
        sortDirection,
        range: timePeriod,
        startTime: startTime ?? undefined,
        endTime: endTime ?? undefined,
        instant: instant ?? undefined,
      }, page.pageStart, page.pageEnd
),
    { enabled: !!site && !!organization },
  );

  const isSelected = (ue: string) => selected.includes(ue);

  const toggleSelected = (ue: string) => {
    const selectedIndex = selected.indexOf(ue);
    const newSelected = [...selected];
    if (selectedIndex === -1) {
      newSelected.push(ue);
    } else {
      newSelected.splice(selectedIndex, 1);
    }
    return newSelected;
  };

  function handleClickCheckbox (ues: string) {
    const newSelected = toggleSelected(ues);
    setSelected(newSelected);
  }

  useEffect(() => {
    setParams({
      ...(startTime && { startTime }),
      ...(endTime && { endTime }),
      ...(criteria && { criteria }),
      ...(range && { range }),
      ...(apn && { apn }),
      ...(selected.length > 0 && { ues: selected.join(',') }),
    });
  }, [selected]);

  useEffect(() => {
    setSelected(ues ? ues.split(',') : []);
  }, [ues]);

  // TODO Add pagination when we will implement real data on the backend
  const handlePageChange = (pageStart: number, pageEnd: number) => {
    setPage({
      pageStart,
      pageEnd,
    });
    setSelected([]);
  };

  return (
    <Box
      sx={{
        height: 400,
        width: '100%',
      }}
    >
      <Table
        columns={apnColumns}
        sortCriteria={sortCriteria}
        setSortCriteria={setSortCriteria}
        sortDirection={sortDirection}
        setSortDirection={setSortDirection}
        renderRows={() =>
          // eslint-disable-next-line max-len
          data?.data?.result?.map(
            (row: ConsumptionConfiguration, index: number) => {
              const isItemSelected = isSelected(row.imsi);
              const labelId = `enhanced-table-checkbox-${index}`;
              return (
                <TableRow
                  key={row.imsi}
                  hover
                  role='checkbox'
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                >
                  <TableCell sx={tableStyles.tableFirstRow}>
                    <Checkbox
                      color='primary'
                      sx={tableStyles.tableCheckbox}
                      checked={isItemSelected}
                      onClick={() => {
                        handleClickCheckbox(row.imsi);
                        if (selected.length === 7) {
                          showToast('Maximum amount of UE is selected', 'info');
                        }
                      }}
                      inputProps={{ 'aria-labelledby': labelId }}
                      disabled={
                        selected.length > 7 && !selected.includes(row.imsi)
                      }
                    />
                    {row.imsi}
                  </TableCell>
                  <TableCell sx={{ paddingLeft: '0px!important' }}>
                    {percentageColor(
                      row.downlinkMaxPercentage,
                      row.downlinkMax,
                    )}
                  </TableCell>
                  <TableCell sx={{ paddingLeft: '0px!important' }}>
                    {percentageColor(
                      row.downlinkAveragePercentage,
                      row.downlinkAverage,
                    )}
                  </TableCell>
                  <TableCell sx={{ paddingLeft: '0px!important' }}>
                    {percentageColor(row.uplinkMaxPercentage, row.uplinkMax)}
                  </TableCell>
                  <TableCell sx={{ paddingLeft: '0px!important' }}>
                    {percentageColor(
                      row.uplinkAveragePercentage,
                      row.uplinkAverage,
                    )}
                  </TableCell>
                  <TableCell sx={{ paddingLeft: '0px!important' }}>
                    {latencyColor(row.latency, row.limits.latency)}
                    <Typography
                      sx={{
                        fontSize: '12px',
                        color: '#888888',
                      }}
                    >
                      {row.watchdogCount} watchdogs
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ paddingLeft: '0px!important' }}>
                    <MinimalAreaChart
                      data={row.latencyData?.map((trend) => trend.value)}
                      height={70}
                      width={200}
                    />
                  </TableCell>
                </TableRow>
              );
            },
          )
        }
      />
      {data?.data && data?.data.total > 10 && (
        <Pagination
          currentPage={page.pageStart}
          disabled={isLoading || isFetching}
          totalRecords={data?.data.total}
          onChange={handlePageChange}
        />
      )}
    </Box>
  );
};
