import { useContext, useState } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import { Layout } from 'react-grid-layout';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { v4 } from 'uuid';

import {
  deleteDashboard,
  exportDashboard,
  getDashboard,
  updateDashboard,
} from 'src/apis/dashboard';
import { InputConfirmable, Loading, NoData } from 'src/components';
import { QueryName } from 'src/enums/query';
import { Dashboard as DashboardType } from 'src/types/dashboard';
import { RenameDashboardDrawer } from './RenameDashboardDrawer';
import { WidgetsContainer } from './WidgetsContainer';
import { AddWidgetModal } from '../AddWidgetModal';
import { Widget } from 'src/types/widget';
import { ImportDashboardDrawer } from './ImportDashboardDrawer';
import { createJsonFile, downloadFile } from 'src/utils/file';
import { ToastContext } from 'src/context/ToastContext';
import { DashboardOptions } from './DashboardOptions';
import { AuthContext } from 'src/context';
import { LocalStorage } from 'src/enums/localStorage';

interface DashboardProps {
  updateDashboardName: (name: DashboardType['name']) => void;
  updateDashboardSavedStatus: (status: boolean) => void;
}

export const DashboardContainer: React.FC<DashboardProps> = ({
  updateDashboardName,
  updateDashboardSavedStatus,
}) => {
  const { user } = useContext(AuthContext);

  const { showToast } = useContext(ToastContext);

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [params] = useSearchParams();
  const dashboardId = params.get('dashboardId');

  const [renameDashboardDrawerOpen, setRenameDashboardDrawerOpen] =
    useState(false);

  const [deleteDashboardModalOpen, setDeleteDashboardModalOpen] =
    useState(false);

  const [importDashboardModalOpen, setImportDashboardModalOpen] =
    useState(false);

  const [addWidgetModalOpen, setAddWidgetModalOpen] = useState(false);

  const [widgetToDelete, setWidgetToDelete] = useState<Widget['id']>('');

  const [widgets, setWidgets] = useState<{ [key: string]: Widget }>({});

  const [timeSeriesFilters, setTimeSeriesFilters] = useState<{
    dataInterval: number;
    predefinedDateRange: number | null;
    customDateRange: {
      start: Date | null;
      end: Date | null;
    };
  }>({
    dataInterval: 60,
    predefinedDateRange: 60,
    customDateRange: {
      start: null,
      end: null,
    },
  });

  const { isLoading, isFetching, isError, data } = useQuery(
    [QueryName.GET_DASHBOARD_DETAILS, dashboardId],
    () => getDashboard(dashboardId as string),
    {
      enabled: !!dashboardId,
      onSuccess: ({ data: { name, dashboard } }) => {
        updateDashboardName(name);
        setWidgets(dashboard ?? {});
      },
    },
  );

  const { mutate: saveDashboard, isLoading: isSavingDashboard } = useMutation<
    unknown,
    AxiosError,
    {
      userId: string;
      dashboardName: string;
      dashboard: Record<string, unknown>;
    }
  >({
    mutationFn: updateDashboard,
    onSuccess: async () => {
      setAddWidgetModalOpen(false);
      updateDashboardSavedStatus(true);
      showToast('Dashboard saved successfully!', 'success');
      await queryClient.invalidateQueries([QueryName.GET_DASHBOARD_DETAILS]);
      await queryClient.invalidateQueries([QueryName.GET_INSTANT_METRICS_DATA]);
    },
  });

  const { mutate } = useMutation<unknown, AxiosError, { name: string }>({
    mutationFn: deleteDashboard,
    onSuccess: async () => {
      showToast('Dashboard deleted successfully!', 'success');
      localStorage.removeItem(LocalStorage.DASHBOARD_ID);
      setWidgets({});
      await queryClient.invalidateQueries([QueryName.GET_DASHBOARDS_LIST]);
      navigate('/');
    },
  });

  const { mutate: exportDashboardMutation } = useMutation<
    AxiosResponse<{ [key: string]: Widget }>,
    AxiosError,
    { name: string }
  >({
    mutationFn: exportDashboard,
    onSuccess: ({ data: apiData }) => {
      const apiWidgets = apiData;
      const newWidgets: { [key: string]: Widget } = {};
      for (const key in apiWidgets) {
        const newId = v4();
        newWidgets[newId] = { ...apiWidgets[key], id: newId };
      }
      const fileName = `${dashboardId}-${new Date().getTime()}.json`;
      const textFile = createJsonFile([JSON.stringify(newWidgets)]);
      downloadFile(fileName, textFile);
    },
  });

  const handleLayoutChange = (layouts: Layout[]) => {
    const tempWidgets = { ...widgets };
    layouts.forEach((layout) => {
      tempWidgets[layout.i] = {
        ...tempWidgets[layout.i],
        config: {
          x: layout.x,
          y: layout.y,
          w: layout.w,
          h: layout.h,
        },
      };
    });
    setWidgets({ ...tempWidgets });
    updateDashboardSavedStatus(false);
  };

  const handleDeleteWidget = () => {
    const tempWidgets = { ...widgets };
    delete tempWidgets[widgetToDelete];
    setWidgets({ ...tempWidgets });
    updateDashboardSavedStatus(false);
    setWidgetToDelete('');
  };

  return (
    <>
      {dashboardId ? (
        <Loading
          isLoading={isLoading || isFetching}
          error={isError ? 'Unable to get dashboard details' : undefined}
        >
          <DashboardOptions
            disableExport={Object.entries(widgets).length === 0}
            onAddWidget={() => setAddWidgetModalOpen(true)}
            onRename={() => setRenameDashboardDrawerOpen(true)}
            onSave={() => {
              if (user) {
                saveDashboard({
                  userId: user.email,
                  dashboardName: data?.data.name ?? '',
                  dashboard: widgets,
                });
              }
            }}
            onDelete={() => setDeleteDashboardModalOpen(true)}
            onImport={() => setImportDashboardModalOpen(true)}
            onExport={() => exportDashboardMutation({ name: dashboardId })}
            timeSeriesFilters={timeSeriesFilters}
            onTimeSeriesFiltersChange={(interval, range, customDateRange) =>
              setTimeSeriesFilters({
                dataInterval: interval,
                predefinedDateRange: range,
                customDateRange,
              })
            }
          />
          <WidgetsContainer
            widgets={widgets}
            timeSeriesFilters={timeSeriesFilters}
            onLayoutChange={handleLayoutChange}
            onDelete={(id) => setWidgetToDelete(id)}
          />
          <RenameDashboardDrawer
            name={data?.data.name ?? ''}
            open={renameDashboardDrawerOpen}
            onRename={(newName) => {
              showToast('Dashboard renamed successfully!', 'success');
              setRenameDashboardDrawerOpen(false);
              localStorage.setItem(LocalStorage.DASHBOARD_ID, newName);
              navigate(`/?dashboardId=${newName}`);
            }}
            onClose={() => setRenameDashboardDrawerOpen(false)}
          />
          <InputConfirmable
            open={deleteDashboardModalOpen}
            title="Delete Dashboard?"
            subTitle="Confirm the dashboard name to continue"
            verifyInputPlaceholder="Enter dashboard name"
            stringToVerify={data?.data.name ?? ''}
            onConfirm={() => {
              mutate({ name: dashboardId });
              setDeleteDashboardModalOpen(false);
            }}
            onCancel={() => setDeleteDashboardModalOpen(false)}
          />
          <AddWidgetModal
            open={addWidgetModalOpen}
            disableSubmit={isSavingDashboard}
            onAddWidget={(widget) => {
              if (user) {
                saveDashboard({
                  userId: user.email,
                  dashboardName: data?.data.name ?? '',
                  dashboard: { ...widgets, [widget.id as string]: widget },
                });
              }
            }}
            onClose={() => setAddWidgetModalOpen(false)}
          />
          <InputConfirmable
            open={!!widgetToDelete}
            title="Delete Widget"
            subTitle="Confirm the widget name to continue"
            verifyInputPlaceholder="Enter widget name"
            stringToVerify={widgets[widgetToDelete]?.label ?? ''}
            onConfirm={handleDeleteWidget}
            onCancel={() => setWidgetToDelete('')}
          />
          <ImportDashboardDrawer
            name={data?.data.name ?? ''}
            open={importDashboardModalOpen}
            onImport={async () => {
              setImportDashboardModalOpen(false);
              await queryClient.invalidateQueries([
                QueryName.GET_DASHBOARD_DETAILS,
              ]);
            }}
            onClose={() => setImportDashboardModalOpen(false)}
          />
        </Loading>
      ) : (
        <NoData
          message="Please select a dashboard from the dropdown"
          minHeight="200px"
        />
      )}
    </>
  );
};
