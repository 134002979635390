import React from 'react';
import { StyledTreeView } from './styles';
interface StyledTreeViewProps {
  children?: React.ReactNode;
  expanded?: string[];
  expandIcon: React.ReactNode;
  collapseIcon: React.ReactNode;
  defaultExpanded: string[];
}
export const TreeView: React.FC<StyledTreeViewProps> = ({
  children,
  expanded,
  expandIcon,
  collapseIcon,
  defaultExpanded,
}) => {
  return (
    <>
      <StyledTreeView
        aria-label='Tree View'
        expanded={expanded}
        defaultExpandIcon={expandIcon}
        defaultCollapseIcon={collapseIcon}
        defaultExpanded={defaultExpanded}
      >
        {children}
      </StyledTreeView>
    </>
  );
};
