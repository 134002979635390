import { IconButton, SvgIcon, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';
import { add, differenceInHours, sub } from 'date-fns';
import { ReactComponent as Forward6Icon } from 'src/assets/forward-6.svg';
import { ReactComponent as Backward6Icon } from 'src/assets/backward-6.svg';
import { ReactComponent as ResetTimeIcon } from 'src/assets/reset.svg';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Button, TextField } from 'src/components';
import { Close } from '@carbon/icons-react';

interface CustomDateSelectorProps {
  onApply: (date: Date) => void;
  onCancel: () => void;
}

export const CustomDateSelector: React.FC<CustomDateSelectorProps> = ({
  onApply,
  onCancel,
}) => {
  const [customDate, setCustomDate] = useState<Date | null>(new Date());

  // check whether less than 6 hours remaining out of max 24 hours
  const maxInFuture = !!(
    customDate && differenceInHours(customDate, new Date()) >= 18
  );

  const move6hCustomDate = (operation: 'ADD' | 'SUB') => {
    const tempDate = customDate ?? new Date();
    let newDateTime = null;
    if (operation === 'ADD') {
      newDateTime = add(tempDate, { hours: 6 });
    } else {
      newDateTime = sub(tempDate, { hours: 6 });
    }
    setCustomDate(newDateTime);
  };

  const resetCustomDate = () => {
    setCustomDate(new Date());
  };

  return (
    <Box>
      <Box display='flex' justifyContent='space-between' mb='13px'>
        <Box display='flex' alignItems='center'>
          <Typography color='#006161' fontSize={16} fontWeight='bold' mr='24px'>
            Select time range
          </Typography>
          <Tooltip title='Backward 6h'>
            <IconButton size='small' onClick={() => move6hCustomDate('SUB')}>
              <SvgIcon
                sx={{
                  color: '#006161',
                  width: '22px',
                  height: '22px',
                }}
              >
                <Backward6Icon />
              </SvgIcon>
            </IconButton>
          </Tooltip>
          <Tooltip title='Forward 6h'>
            <IconButton
              size='small'
              onClick={() => move6hCustomDate('ADD')}
              disabled={maxInFuture}
            >
              <SvgIcon
                sx={{
                  color: maxInFuture ? 'gray' : '#006161',
                  width: '22px',
                  height: '22px',
                }}
              >
                <Forward6Icon />
              </SvgIcon>
            </IconButton>
          </Tooltip>
          <Tooltip title='Reset'>
            <IconButton size='small' onClick={resetCustomDate}>
              <SvgIcon
                sx={{
                  color: '#006161',
                  width: '22px',
                  height: '22px',
                }}
              >
                <ResetTimeIcon />
              </SvgIcon>
            </IconButton>
          </Tooltip>
        </Box>
        <IconButton
          size='small'
          sx={{ position: 'absolute', right: '20px', top: '10px' }}
          onClick={onCancel}
        >
          <Close height={24} width={24} color='#222222' />
        </IconButton>
      </Box>
      <Box display='flex'>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            PopperProps={{ id: 'custom-date-selector' }}
            label='Date'
            renderInput={({ error: _, ...rest }) => (
              <TextField
                {...rest}
                size='small'
                sx={{ width: 273, mr: '10px' }}
              />
            )}
            maxDateTime={add(new Date(), { hours: 24 })}
            value={customDate}
            onChange={(date: Date | null) => date && setCustomDate(date)}
          />
        </LocalizationProvider>
        <Button
          kind='primary'
          padding='8px 22px'
          onClick={() => customDate && onApply(customDate)}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};
