import { Box, Typography } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { percentageStyle, speedStyle, tableStyles } from '../tableStyles';

export const percentageColor = (percentageValue: number, value?: number) => {
  switch (true) {
    case (percentageValue <= 70):
      return <Box>
        <Typography
          sx={tableStyles.green}
        >{percentageValue}
          <Typography sx={tableStyles.peakdownGreen}> %</Typography>
          <CheckCircleIcon
            sx={{
              height: '15px!important',
              marginLeft: '15px',
            }} />
        </Typography>
        <Typography sx={tableStyles.speedGreen}>{value}
          <Typography sx={tableStyles.peakdownGreen}> mbps</Typography>
        </Typography>
      </Box>;
    case (percentageValue <= 85 && percentageValue > 70):
      return <Box>
        <Typography
          sx={tableStyles.orange}>{percentageValue} <Typography
          sx={tableStyles.peakdownOrange}> %</Typography>
          <ErrorIcon
            sx={{
              height: '15px!important',
              marginLeft: '15px',
            }} /></Typography>
        <Typography
          sx={tableStyles.speedOrange}>{value} <Typography
          sx={tableStyles.peakdownOrange}> mbps</Typography>
        </Typography>
      </Box>;
    case (percentageValue > 85):
      return <Box>
        <Typography
          sx={tableStyles.red}>{percentageValue}<Typography
          sx={tableStyles.peakdownRed}> %</Typography>
          <CancelIcon
            sx={{
              height: '15px!important',
              marginLeft: '15px',
            }} /></Typography>
        <Typography
          sx={tableStyles.speedRed}>{value}
          <Typography
            sx={tableStyles.peakdownRed}> mbps</Typography></Typography>
      </Box>;
    default:
      return <Box>
        <Typography
          sx={percentageStyle}>{percentageValue}%</Typography>
        <Typography
          sx={speedStyle}>{value}mbps</Typography>
      </Box>;
  }
};
