import { Box } from '@mui/system';
import { add, differenceInMinutes, isSameMinute, sub } from 'date-fns';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { GeoMapContext } from 'src/context';
import { TimelineSlider } from './TimelineSlider';
import { CustomDateSelector } from './CustomDateSelector';

export const Timeline: React.FC = () => {
  const { selectedDate, sliderDate, selectSliderDate, selectDate } =
    useContext(GeoMapContext);

  const [sliderValue, setSliderValue] = useState(360);
  const [displayCustomDatePicker, setDisplayCustomDatePicker] = useState(false);
  const [isInNowState, setIsInNowState] = useState(true);

  useEffect(() => {
    const newNowDate = setInterval(() => {
      if (isInNowState) {
        const newDateTime = new Date();
        selectSliderDate(newDateTime);
        selectDate(newDateTime);
      }
    }, 10000);
    return () => clearInterval(newNowDate);
  });

  const values = useMemo(() => {
    if (sliderDate) {
      const sixHoursBehindDate = sub(sliderDate, { hours: 6 });
      return Array.from({ length: 720 }, (_, index) => {
        return {
          value: index,
          date: add(sixHoursBehindDate, { minutes: index }),
        };
      });
    }
  }, [sliderDate]);

  const handleChange = useCallback(
    (value: number) => {
      const newValue = values?.find((v) => v.value === value);
      if (newValue) {
        selectDate(newValue.date);
        const diff = parseInt(
          differenceInMinutes(newValue.date, new Date()).toString(),
        );
        setIsInNowState(diff === 0);
      }
    },
    [selectDate, values],
  );

  const goForward6h = () => {
    if (selectedDate && sliderDate) {
      const newDateTime = add(selectedDate, { hours: 6, minutes: 1 });
      selectSliderDate(newDateTime);
      selectDate(newDateTime);
      const diff = parseInt(
        differenceInMinutes(newDateTime, new Date()).toString(),
      );
      setIsInNowState(diff === 0);
    }
  };

  const resetCurrentDateTime = () => {
    const newDateTime = new Date();
    selectSliderDate(newDateTime);
    selectDate(newDateTime);
    setIsInNowState(true);
  };

  useEffect(() => {
    if (sliderDate) {
      const value = values?.find((value) =>
        isSameMinute(value.date, new Date()),
      )?.value;
      if (value) {
        setSliderValue(value);
      }
    }
  }, [sliderDate, values]);

  const applyCustomDate = (date: Date) => {
    if (date) {
      selectDate(date);
      selectSliderDate(date);
      setDisplayCustomDatePicker(false);
      const diff = parseInt(differenceInMinutes(date, new Date()).toString());
      setIsInNowState(diff === 0);
    }
  };

  const handleCustomDateCancel = () => {
    setDisplayCustomDatePicker(false);
  };

  return (
    <Box
      display='flex'
      justifyContent='center'
      width='100%'
      position='absolute'
      bottom={0}
      zIndex='1'
    >
      <Box
        width='500px'
        height='110px'
        bgcolor='#fff'
        boxShadow='0px 3px 6px #00000029'
        borderRadius='3px'
        padding='8px 32px'
        position='relative'
      >
        {!displayCustomDatePicker ? (
          <TimelineSlider
            values={values}
            sliderValue={sliderValue}
            selectedDate={selectedDate}
            sliderDate={sliderDate}
            onUpdateSliderValue={setSliderValue}
            onChangeCurrentDate={handleChange}
            goForward6h={goForward6h}
            onResetCurrentDateTime={resetCurrentDateTime}
            onDispalyCustomDateSelect={() => setDisplayCustomDatePicker(true)}
          />
        ) : (
          <CustomDateSelector
            onApply={applyCustomDate}
            onCancel={handleCustomDateCancel}
          />
        )}
      </Box>
    </Box>
  );
};
