import React, { useContext, useState } from 'react';
import {
  Confirmable,
  Loading,
  Pagination,
  Table,
  TextField,
} from 'src/components';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Box } from '@mui/system';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { QueryName } from 'src/enums/query';
import { Main } from 'src/layouts/Main';
import { Button } from 'src/components/Button';
import {
  ChannelManagementDialog,
} from 'src/components/Modal/ChannelManagementDialog';
import { deleteChannel, getChannels } from 'src/apis/channel';
import { AuthContext, ToastContext } from 'src/context';
import { Order, Page } from 'src/types/shared';
import { Channel } from 'src/types/channel';
import { Link, Typography } from '@mui/material';
import { styles } from './styles';
import { Edit, Search, TrashCan } from '@carbon/icons-react';
import { AxiosError, AxiosResponse } from 'axios';
import { Breadcrumbs } from 'src/components/Breadcrumbs';

const columns = [
  {
    key: 'NAME',
    label: 'Channel',
  },
  {
    key: 'TYPE',
    label: 'Chat',
    isSortable: true,
  },
  {
    key: 'USED_FOR',
    label: 'Used for',
  },
  {
    key: 'ACTIONS',
    label: 'Actions',
  },
];

export const ListAllChannels: React.FC = () => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>();
  const [type, setType] = useState<string>();
  const [sortCriteria, setSortCriteria] = useState<string>('NAME');
  const [sortDirection, setSortDirection] = useState<Order>('DESC');
  const [editChannel, setEditChannel] = useState<Channel>();
  const [deleteChannelId, setDeleteChannelId] = useState<string>();
  const { user } = useContext(AuthContext);
  const queryClient = useQueryClient();
  const { showToast } = useContext(ToastContext);
  const organization = user?.organizations?.[0] as string;
  const [page, setPage] = useState<Page>({
    pageStart: 1,
    pageEnd: 10,
  });
  const [channelNameToConfirm, setChannelNameToConfirm] = useState<string>('');

  const handlePageChange = (pageStart: number, pageEnd: number) => {
    setPage({
      pageStart,
      pageEnd,
    });
  };
  const {
    data,
    isLoading,
    isFetching,
    refetch: reFetchChannels,
  } = useQuery(
    [
      QueryName.GET_ALL_CHANNELS,
      searchText,
      type,
      sortDirection,
      sortCriteria,
      page.pageStart,
      page.pageEnd,
    ],
    () =>
      getChannels(
        organization,
        searchText,
        type,
        sortDirection,
        sortCriteria,
        page.pageStart,
        page.pageEnd,
      ),
  );
  const rows = data?.data.channels;
  const handleCreate = () => {
    setEditChannel(undefined);
    setOpenDialog(true);
  };
  const handleEdit = (row: Channel) => {
    setEditChannel(row);
    setOpenDialog(true);
  };
  const handleDelete = (row: Channel) => {
    setDeleteChannelId(row.id);
    setChannelNameToConfirm(row.name);
  };
  const confirmDeleteChannel = () => {
    deleteChannelMutation.mutate({
      organization,
      id: deleteChannelId as string,
    });
  };
  const deleteChannelMutation = useMutation<
    AxiosResponse,
    AxiosError,
    { organization: string; id: string }
  >({
    mutationFn: deleteChannel,
    onSuccess: async () => {
      showToast('Channel deleted successfully!', 'success');
      setDeleteChannelId(undefined);
      await queryClient.invalidateQueries([QueryName.GET_ALL_CHANNELS]);
    },
    onError: () => {
      showToast('Failed to delete the channel, Please try again', 'error');
    },
  });
  return (
    <Main>
      <Breadcrumbs breadcrumbs={[
        {
          title: 'Settings',
        },
      ]} />
      <Box display='flex' alignItems='center'>
        <Typography variant='h1'>Settings</Typography>
      </Box>
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        mb={1}
        mt={1}
      >
        <Box display='flex' alignItems='center'>
          <Typography variant='h3'>Notification channels</Typography>
        </Box>
        {(rows && rows?.length >= 10) && <Box sx={styles.inputBox}>
          <TextField
            isSearchField
            placeholder='Search'
            id='search-equipments'
            InputProps={{ endAdornment: <Search size={20} color='#888888' /> }}
            onChange={(event) => setSearchText(event.target.value)}
          />
        </Box> }
        <Box>
          <Button kind='primary' onClick={() => handleCreate()}>
            Add a Channel
          </Button>
        </Box>
      </Box>
      <Loading isLoading={isLoading || isFetching}>
        <Table
          checkbox={false}
          columns={columns}
          sortCriteria={sortCriteria}
          setSortCriteria={setSortCriteria}
          sortDirection={sortDirection}
          setSortDirection={setSortDirection}
          renderRows={() =>
            rows?.map((row: Channel) => {
              return (
                <TableRow key={row.id} sx={styles.tableRow}>
                  <TableCell
                    scope='row'
                    padding='none'
                    sx={styles.channelNameWrapper}
                  >
                    <Typography sx={styles.channelName}>{row.name}</Typography>
                    <Typography sx={styles.description} variant='body2'>
                      {row.url}
                    </Typography>
                  </TableCell>
                  <TableCell padding='none'>
                    <Typography variant='body1'>
                      {row?.type[0].toUpperCase() +
                        row?.type.toLowerCase().substring(1)}
                    </Typography>
                  </TableCell>
                  <TableCell padding='none'>
                    {row.ruleNames.map((rule: string, index: number) => {
                      return (
                        <Typography key={index} variant='body1'>
                          {rule}
                        </Typography>
                      );
                    })}
                  </TableCell>
                  <TableCell padding='none'>
                    <Box display='flex' alignItems='center'>
                      <Edit style={styles.editIcon} />
                      <Link
                        sx={styles.channelEdit}
                        onClick={() => handleEdit(row)}
                      >
                        Edit
                      </Link>
                      <TrashCan style={styles.deleteIcon} />
                      <Link
                        sx={styles.channelDelete}
                        onClick={() => handleDelete(row)}
                      >
                        Delete
                      </Link>
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })
          }
        />
      </Loading>
      {data?.data && data?.data.total > 10 && (
        <Pagination
          currentPage={page.pageStart}
          disabled={isLoading || isFetching}
          totalRecords={data?.data.total}
          onChange={handlePageChange}
        />
      )}
      <Confirmable
        open={!!deleteChannelId}
        title={'Delete a channel'}
        subTitle={`Are you sure you want to delete ${channelNameToConfirm} from channels?`}
        onConfirm={confirmDeleteChannel}
        onCancel={() => setDeleteChannelId(undefined)}
      />
      <ChannelManagementDialog
        channel={editChannel}
        open={openDialog}
        onCancel={() => {
          setOpenDialog(false);
        }}
        onSave={async () => {
          setOpenDialog(false);
          await reFetchChannels();
        }}
      />
    </Main>
  );
};
