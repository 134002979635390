import { useState } from 'react';
import { Box, Typography, Link } from '@mui/material';
import { Search, Download } from '@carbon/icons-react';

import { Networks } from './Networks';
import { GroupEquipment } from './GroupEquipment';
import { UnitsEquipment } from './UnitsEquipment';
import { Button, TextField } from 'src/components';
import { useSearchParams } from 'react-router-dom';

const criteriaFilters = [
  {
    label: 'Uplink',
    value: 'UPLINK',
  },
  {
    label: 'Latency',
    value: 'LATENCY',
  },
  {
    label: 'Downlink',
    value: 'DOWNLINK',
  },
];

export const SettingsLayer: React.FC = () => {
  const [filter, setFilter] = useState('');
  const [params, setParams] = useSearchParams({ criteria: 'UPLINK' });

  return (
    <Box
      bgcolor='#fff'
      height='396px'
      width='350px'
      px='21px'
      position='absolute'
      zIndex='1'
      ml='15px'
      boxShadow='0px 3px 6px #00000029'
    >
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        pt='19px'
      >
        <Typography variant='h3'>Settings</Typography>
        <Link display='flex' alignItems='center' underline='always'>
          <Download style={{ marginRight: 4 }} /> Survey report
        </Link>
      </Box>
      <Box pt='23px'>
        {criteriaFilters.map((criteriaFilter, index) => (
          <Button
            key={criteriaFilter.value}
            kind='custom'
            backgroundColor={
              params.get('criteria') === criteriaFilter.value
                ? '#659F9F'
                : '#fff'
            }
            textColor={
              params.get('criteria') === criteriaFilter.value
                ? '#fff'
                : '#006161'
            }
            hoverBackgroundColor={
              params.get('criteria') === criteriaFilter.value
                ? '#659F9F'
                : '#fff'
            }
            borderLeft={index === 0 ? '1px solid #659F9F' : undefined}
            borderTop='1px solid #659F9F'
            borderRight='1px solid #659F9F'
            borderBottom='1px solid #659F9F'
            padding='8px 20px'
            onClick={() =>
              setParams({
                criteria: criteriaFilter.value,
              })
            }
          >
            {criteriaFilter.label}
          </Button>
        ))}
      </Box>
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        pt='30px'
        pb='10px'
      >
        <Typography variant='h3' fontSize='14px'>
          Layers
        </Typography>
        <TextField
          isSearchField
          placeholder='Search'
          id='search-equipments'
          InputProps={{ endAdornment: <Search size={20} color='#888888' /> }}
          onChange={(event) => setFilter(event.target.value)}
          width={137}
        />
      </Box>
      <Box
        height={200}
        sx={{
          overflowX: 'hidden',
          overflowY: 'auto',
          border: '1px solid #0000003b',
        }}
      >
        <Networks filter={filter} />
        <GroupEquipment filter={filter} />
        <UnitsEquipment filter={filter} />
      </Box>
    </Box>
  );
};
