import { Box, Checkbox, Link, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useCallback, useContext } from 'react';
import { getAllApnsData } from 'src/apis/apn';
import { Loading } from 'src/components';
import {
  RulesEngineContext,
  AuthContext,
  AppContext,
  ToastContext,
} from 'src/context';
import { QueryName } from 'src/enums/query';
import { Apn } from 'src/types/domain';
import { StyledCheckboxContainer } from './styles';
import { circle } from '@turf/turf';
import { getCoordinates } from 'src/apis/geo-service';

type Polygon = {
  type: 'POLYGON';
  coordinates?: number[][];
};

type GeoMapAPN = {
  name: string;
  type: 'apn';
  geometry: Polygon | null;
};

export const SelectNetworks: React.FC = () => {
  const { user } = useContext(AuthContext);
  const { selectedAPNs, addNewAPN, addAllAPNs, removeAPN } =
    useContext(RulesEngineContext);
  const { site } = useContext(AppContext);
  const { showToast } = useContext(ToastContext);
  const organization = user?.organizations?.[0] as string;

  const { data: apnData, isLoading } = useQuery(
    [QueryName.GET_ALL_APNS, user?.organizations?.[0]],
    () =>
      getAllApnsData(
        organization,
        site !== '' ? site : undefined,
        undefined,
        1,
        10,
      ),
    {
      enabled: !!user?.organizations?.length && !!site,
    },
  );

  const { data: coordinatesData } = useQuery(
    [QueryName.GET_COORDINATES, site, 'apn_coordinates'],
    () =>
      getCoordinates({
        organization: user?.organizations?.[0] as string,
        site,
        apns: apnData?.data.apns.map((apn) => apn.name),
      }),
    {
      enabled:
        !!user?.organizations?.[0] && !!site && !!apnData?.data.apns.length,
    },
  );

  const selectAPN = (apn: Apn) => {
    addNewAPN({
      name: apn.name,
      type: 'apn',
      geometry: getGeometryData(apn.name),
    });
  };

  const selectAllAPN = () => {
    const tempAPNs: GeoMapAPN[] = [];
    apnData?.data?.apns?.forEach((apn) => {
      tempAPNs.push({
        name: apn.name,
        type: 'apn',
        geometry: getGeometryData(apn.name),
      });
    });
    addAllAPNs([...tempAPNs]);
  };

  const getGeometryData = useCallback(
    (apnName: string): Polygon | null => {
      const apn = coordinatesData?.data.apns[apnName];
      if (apn) {
        if (apn.geoType === 'POLYGON') {
          return {
            type: 'POLYGON',
            coordinates: apn.polygonGeometry?.coordinates,
          };
        } else {
          const coordinates =
            apn.pointGeometry?.coordinates.length === 2 &&
            apn.pointGeometry?.radius
              ? circle(
                  apn.pointGeometry?.coordinates,
                  apn.pointGeometry?.radius,
                  {
                    steps: 0,
                    units: 'kilometers',
                  },
                ).geometry.coordinates
              : undefined;
          return {
            type: 'POLYGON',
            coordinates: coordinates?.[0] ?? undefined,
          };
        }
      }
      return null;
    },
    [coordinatesData],
  );

  return (
    <Box mb='35px' width='100%'>
      <Box
        display='flex'
        justifyContent='flex-start'
        alignItems='baseline'
        mb='10px'
      >
        <Typography variant='h2'>Network</Typography>
        <Link
          sx={{
            cursor: 'pointer',
            fontSize: '14px',
            marginLeft: '15px',
            color: '#006161',
          }}
          onClick={selectAllAPN}
        >
          Select all
        </Link>
      </Box>
      <Loading isLoading={isLoading} minHeight='70px'>
        <Box display='flex' flexWrap='wrap' pl='12px' rowGap='7px'>
          {apnData?.data?.apns.map((apn) => (
            <StyledCheckboxContainer
              key={apn.name}
              sx={{
                borderRadius: '1px',
                height: '34px',
              }}
              checked={
                selectedAPNs.findIndex(
                  (selectedAPN) => selectedAPN.name === apn.name,
                ) > -1
              }
              onChange={(_, checked) => {
                if (apn?.coordinates?.length === 0) {
                  showToast('APN has no coordinates', 'warning');
                }
                checked ? selectAPN(apn) : removeAPN(apn.name);
              }}
              control={<Checkbox size='small' />}
              label={<Typography fontSize={14}>{apn.name}</Typography>}
            />
          ))}
        </Box>
      </Loading>
    </Box>
  );
};
