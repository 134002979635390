import { circle } from '@turf/turf';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';
import { Box, FormControlLabel, Typography } from '@mui/material';

import { Apn } from 'src/types/domain';
import { QueryName } from 'src/enums/query';
import { getAllApnsData } from 'src/apis/apn';
import { Checkbox, Loading } from 'src/components';
import { getCoordinates } from 'src/apis/geo-service';
import { GeoMapAPN, Polygon } from 'src/context/GeoMapContext';
import { AuthContext, AppContext, GeoMapContext } from 'src/context';

interface NetworksProps {
  filter: string;
}

export const Networks: React.FC<NetworksProps> = ({ filter }) => {
  const { user } = useContext(AuthContext);
  const { site } = useContext(AppContext);
  const { apns, addNewAPN, addAllAPNs, removeAPN } = useContext(GeoMapContext);
  const organization = user?.organizations?.[0] as string;
  const [params] = useSearchParams({
    criteria: 'LATENCY',
  });

  const criteria = params.get('criteria') ?? 'LATENCY';

  const [pageLoaded, setPageLoaded] = useState(false);

  const { data: apnData, isLoading } = useQuery(
    [QueryName.GET_ALL_APNS, user?.organizations?.[0], site, 'geomap_apns'],
    () => getAllApnsData(organization, site !== '' ? site : undefined),
    {
      enabled: !!user?.organizations?.length && !!site,
      cacheTime: 0,
      onSuccess: (data) => {
        if (data?.data?.apns?.length === 0) {
          addAllAPNs([]);
        } else {
          setPageLoaded(false);
        }
      },
    },
  );

  const { data: coordinatesData, isLoading: isCoordinatesDataLoading } =
    useQuery(
      [QueryName.GET_COORDINATES, site, 'apn_coordinates'],
      () =>
        getCoordinates({
          organization: user?.organizations?.[0] as string,
          site,
          apns: apnData?.data.apns.map((apn) => apn.name),
        }),
      {
        enabled:
          !!user?.organizations?.[0] && !!site && !!apnData?.data.apns.length,
        cacheTime: 0,
      },
    );

  const getGeometryData = useCallback(
    (apnName: string): Polygon | null => {
      const apn = coordinatesData?.data.apns[apnName];
      if (apn) {
        if (apn.geoType === 'POLYGON') {
          return {
            type: 'POLYGON',
            coordinates: apn.polygonGeometry?.coordinates,
          };
        } else {
          const coordinates =
            apn.pointGeometry?.coordinates.length === 2 &&
            apn.pointGeometry?.radius
              ? circle(
                  apn.pointGeometry?.coordinates,
                  apn.pointGeometry?.radius,
                  {
                    steps: 0,
                    units: 'kilometers',
                  },
                ).geometry.coordinates
              : undefined;
          return {
            type: 'POLYGON',
            coordinates: coordinates?.[0] ?? undefined,
          };
        }
      }
      return null;
    },
    [coordinatesData],
  );

  const selectAPN = (apn: Apn) => {
    addNewAPN({
      name: apn.name,
      type: 'apn',
      criteria,
      latency: 0,
      uplink: 0,
      downlink: 0,
      confidence: 0,
      limits: {
        latency: 0,
        uplink: 0,
        downlink: 0,
      },
      geometry: getGeometryData(apn.name),
    });
  };

  const selectAllAPN = useCallback(() => {
    const tempAPNs: GeoMapAPN[] = [];
    apnData?.data?.apns?.forEach((apn) => {
      tempAPNs.push({
        name: apn.name,
        type: 'apn',
        criteria,
        latency: 0,
        uplink: 0,
        downlink: 0,
        confidence: 0,
        limits: {
          latency: 0,
          uplink: 0,
          downlink: 0,
        },
        geometry: getGeometryData(apn.name),
      });
    });
    addAllAPNs([...tempAPNs]);
  }, [addAllAPNs, apnData?.data?.apns, criteria, getGeometryData]);

  useEffect(() => {
    if (!pageLoaded && apnData && coordinatesData) {
      setTimeout(() => {
        setPageLoaded(true);
        selectAllAPN();
      }, 0);
    }
  }, [pageLoaded, apnData, coordinatesData, selectAllAPN, addAllAPNs]);

  return (
    <Box>
      <Typography variant='h4' px='10px' pt='15px'>
        Networks
      </Typography>
      <Box pl='18px'>
        <Loading isLoading={isLoading}>
          <Box display='flex' flexDirection='column'>
            {!filter && (apnData?.data?.total as number) > 0 && (
              <FormControlLabel
                key='all'
                checked={apnData?.data?.total === apns.length}
                onChange={(_, checked) =>
                  checked ? selectAllAPN() : addAllAPNs([])
                }
                control={<Checkbox size='small' />}
                label={
                  <Typography fontSize='14px' color='#222222'>
                    All
                  </Typography>
                }
                disabled={isCoordinatesDataLoading}
              />
            )}
            {apnData?.data?.total === 0 && (
              <Typography fontSize='14px' color='#222222' mt='5px'>
                No networks found
              </Typography>
            )}
            {apnData?.data?.apns
              .filter((apn) =>
                apn.name.toLowerCase().includes(filter.toLowerCase()),
              )
              .map((apn) => (
                <FormControlLabel
                  key={apn.name}
                  checked={
                    apns.findIndex((tempAPN) => tempAPN.name === apn.name) > -1
                  }
                  onChange={(_, checked) =>
                    checked ? selectAPN(apn) : removeAPN(apn.name)
                  }
                  control={<Checkbox size='small' />}
                  label={
                    <Typography fontSize='14px' color='#222222'>
                      {apn.name}
                    </Typography>
                  }
                  disabled={isCoordinatesDataLoading}
                />
              ))}
          </Box>
        </Loading>
      </Box>
    </Box>
  );
};
