import { createContext, useCallback, useEffect, useRef, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import { LocalStorage } from 'src/enums/localStorage';
import { getErrorMessage } from 'src/utils/login';
import { generateToken, refreshAccessToken } from 'src/apis/auth';
import { Login, RefreshToken } from 'src/types/auth';
import { User } from 'src/types/user';

interface AuthContextProviderProps {
  children: React.ReactNode;
}

const defaultValues: {
  loading: boolean;
  user: User | null;
  isLoggedIn: boolean;
  authState: {
    error: string;
    loggingIn: boolean;
  };
  handleLogin: (email: string, password: string) => void;
  handleLogout: () => void;
} = {
  loading: true,
  user: null,
  isLoggedIn: false,
  authState: {
    error: '',
    loggingIn: false,
  },
  handleLogin: () => {},
  handleLogout: () => {},
};

export const AuthContext = createContext(defaultValues);

export const AuthContextProvider: React.FC<AuthContextProviderProps> = ({
  children,
}) => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<User | null>(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [authState, setAuthState] = useState({
    error: '',
    loggingIn: false,
  });

  const refreshTokenInterval = useRef<ReturnType<typeof setInterval> | null>(
    null,
  );

  const handleLoginSuccess = (accessToken: string, refreshToken: string) => {
    localStorage.setItem(
      LocalStorage.ACCESS_TOKEN,
      JSON.stringify(accessToken),
    );
    localStorage.setItem(
      LocalStorage.REFRESH_TOKEN,
      JSON.stringify(refreshToken),
    );
    const userDecoded = JSON.parse(atob(accessToken.split('.')[1]));
    handleRefreshToken(userDecoded.exp);
    setUser({ ...userDecoded });
    setIsLoggedIn(true);
  };

  const { mutate: loginMutate } = useMutation<
    AxiosResponse<Login>,
    AxiosError,
    { username: string; password: string }
  >({
    mutationFn: generateToken,
    onMutate: () => setAuthState({ error: '', loggingIn: true }),
    onSuccess: ({ data: { accessToken, refreshToken } }) => {
      setAuthState({ error: '', loggingIn: false });
      handleLoginSuccess(accessToken, refreshToken);
    },
    onError: (error) =>
      setAuthState({ error: getErrorMessage(error), loggingIn: false }),
  });

  const { mutate: refreshTokenMutate } = useMutation<
    AxiosResponse<RefreshToken>,
    AxiosError,
    { username: string; refreshToken: string }
  >({
    mutationFn: refreshAccessToken,
    onSuccess: ({ data: { accessToken, refreshToken } }) =>
      handleLoginSuccess(accessToken, refreshToken),
    retry: 2,
    retryDelay: 8000,
  });

  const handleLogin = (email: string, password: string) => {
    loginMutate({ username: email, password });
  };

  const handleRefreshToken = useCallback(
    (expiryTime: number) => {
      if (refreshTokenInterval.current) {
        clearInterval(refreshTokenInterval.current);
      }
      refreshTokenInterval.current = setInterval(() => {
        const now = Math.floor(new Date().getTime() / 1000);
        const diff = Math.ceil((expiryTime - now) / 60);
        if (diff < 5) {
          if (refreshTokenInterval.current) {
            clearInterval(refreshTokenInterval.current);
          }
          const refreshToken = localStorage.getItem(LocalStorage.REFRESH_TOKEN);
          if (user?.email && refreshToken) {
            refreshTokenMutate({
              username: user.email,
              refreshToken: JSON.parse(refreshToken),
            });
          }
        }
      }, 1000);
    },
    [refreshTokenMutate, user?.email],
  );

  const handleLogout = () => {
    if (refreshTokenInterval.current) {
      clearInterval(refreshTokenInterval.current);
    }
    localStorage.removeItem(LocalStorage.ACCESS_TOKEN);
    localStorage.removeItem(LocalStorage.REFRESH_TOKEN);
    setUser(null);
    setIsLoggedIn(false);
    localStorage.removeItem(LocalStorage.DASHBOARD_ID);
    localStorage.removeItem(LocalStorage.SITE_NAME);
  };

  useEffect(() => {
    const accessToken = localStorage.getItem(LocalStorage.ACCESS_TOKEN);
    if (accessToken) {
      const userDecoded = JSON.parse(atob(accessToken.split('.')[1]));
      const now = Math.floor(Date.now() / 1000);
      const expiryTime = userDecoded.exp;

      if (expiryTime > now) {
        setUser({ ...userDecoded });
        setIsLoggedIn(true);
        handleRefreshToken(expiryTime);
      } else {
        localStorage.removeItem(LocalStorage.ACCESS_TOKEN);
        localStorage.removeItem(LocalStorage.REFRESH_TOKEN);
      }
    }
    setLoading(false);
  }, [handleRefreshToken]);

  return (
    <AuthContext.Provider
      value={{
        loading,
        user,
        isLoggedIn,
        authState,
        handleLogin,
        handleLogout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
