export const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    margin: '20px 20px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  element: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    margin: 0,
    height: '6px',
  },
  arrowStyle: (
    isActive: boolean,
    direction: string | undefined,
    arrowDirection: 'up' | 'down') => {
    const initialStyle = {
      margin: 0,
      border: 'solid rgb(149, 150, 155)',
      borderWidth: '0 1px 1px 0',
      display: 'inline-block',
      transform: arrowDirection === 'up' ? 'rotate(-135deg)' : 'rotate(45deg)',
      padding: '3px',
    }

    if (isActive) {
      if (direction === 'ASC' && arrowDirection === 'up') {
        return {
          ...initialStyle,
          border: 'solid rgb(0, 0, 0)',
        }
      } else if (direction === 'DESC' && arrowDirection === 'down') {
        return {
          ...initialStyle,
          border: 'solid rgb(0, 0, 0)',
        }
      }
    }
    return initialStyle
  },
}
