import React, { FC, useContext, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { Close, Search } from '@carbon/icons-react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import { Button, TextField } from 'src/components';
import { QueryName } from 'src/enums/query';
import { allocateUE, getUEDetails } from 'src/apis/ue';
import { AppContext, AuthContext, ToastContext } from 'src/context';
import { styles } from './styles';
import { DeviceComponent } from './DeviceComponent';

interface AddEquipmentModalProps {
  showModal: boolean;
  closeModal: () => void;
}

export const AddEquipmentModal: FC<AddEquipmentModalProps> = ({
  showModal,
  closeModal,
}) => {
  const debounceTimeInMs = 300;
  const [searchText, setSearchQuery] = useState<string>('');

  const { user } = useContext(AuthContext);
  const { site } = useContext(AppContext);
  const { showToast } = useContext(ToastContext);

  const organization = (user?.organizations?.[0] as string) || '';
  const queryClient = useQueryClient();

  const { data, error } = useQuery(
    [QueryName.GET_DEVICE_DETAILS, searchText],
    () => getUEDetails(organization, site, searchText),
    { enabled: !!organization && !!site && !!searchText },
  );

  const { mutate: allocateUEMutation } = useMutation<
    AxiosResponse,
    AxiosError,
    { organization: string; site: string; ueIds: string[]; ownerEmail: string }
  >({
    mutationFn: allocateUE,
    onSuccess: async () => {
      handleCloseModal();
      await queryClient.invalidateQueries([QueryName.GET_ALL_DEVICES]);
      showToast('Selected UE allocated to user successfully!', 'success');
    },
    onError: () => {
      showToast('Failed to perform the operation, try again', 'error');
    },
  });

  const handleSave = () => {
    allocateUEMutation({
      organization,
      site,
      ueIds: [data?.data?.ueid ?? ''],
      ownerEmail: user?.email ?? '',
    });
  };
  const handleCloseModal = () => {
    closeModal();
    setSearchQuery('');
  };

  let timer: ReturnType<typeof setTimeout>;
  const debounceSearch = (event: React.ChangeEvent) => {
    const value = (event.target as HTMLInputElement).value;
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      setSearchQuery(value);
    }, debounceTimeInMs);
  };

  const showAddButton =
    data?.data?.ownerEmail !== user?.email && data?.data?.ownerEmail === null;

  return (
    <Dialog open={showModal}>
      <DialogTitle sx={styles.titleWrapper}>
        <Typography sx={styles.title}>Add equipment</Typography>
        <IconButton sx={styles.closeIcon} onClick={handleCloseModal}>
          <Close size='24' color='#222222' />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={styles.contentWrapper}>
        <TextField
          isSearchField
          placeholder='Search'
          id='search-equipments'
          InputProps={{ endAdornment: <Search size={20} color='#888888' /> }}
          onChange={debounceSearch}
          width={330}
        />
        <DeviceComponent device={data?.data} error={error} />
      </DialogContent>
      <DialogActions sx={styles.dialogActions}>
        <Button kind='primary-ghost' onClick={handleCloseModal}>
          Cancel
        </Button>
        {showAddButton && (
          <Button kind='primary' type='submit' onClick={handleSave}>
            Add
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
