import React from 'react';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import { Box } from '@mui/material';

import { StyledChip, StyledChipProps } from './styles';

export const ChipIcon: React.FC<StyledChipProps> = ({
  kind,
  label,
  ...rest
}) => {
  return (
    <>
      <Box display="flex" alignItems="center">
        {kind === 'primary-ghost' ? (
          <StyledChip kind={kind} label={label} icon={<DoneIcon />} />
        ) : kind === 'danger-ghost' ? (
          <StyledChip kind={kind} label={label} icon={<ClearIcon />} />
        ) : (
          <StyledChip
            kind={kind}
            label={label}
            icon={<PanoramaFishEyeIcon />}
          />
        )}
      </Box>
    </>
  );
};
