import { Stepper, Step, Box } from '@mui/material';

import { StyledStepButton } from './styles';

interface StepsProps {
  activeStep: number;
  steps: Array<{ title: string; value: string | number }>;
  onClick: (step: string | number) => void;
}

export const Steps: React.FC<StepsProps> = ({ activeStep, steps, onClick }) => {
  return (
    <Box sx={{ width: '70%' }} mb="52px" mx="auto">
      <Stepper activeStep={activeStep}>
        {steps.map((step, index) => (
          <Step key={step.value} completed={index < activeStep}>
            <StyledStepButton onClick={() => onClick(step.value)}>
              {step.title}
            </StyledStepButton>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};
