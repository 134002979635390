import { CircularProgress, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ErrorOutlineOutlined } from '@mui/icons-material';
import { styles } from './styles';
import React from 'react';

interface LoadingProps {
  children: React.ReactNode;
  isLoading: boolean;
  error?: string;
  minHeight?: string;
}

export const Loading: React.FC<LoadingProps> = ({
  children,
  isLoading,
  error,
  minHeight,
}: LoadingProps) => {
  return (
    <>
      {isLoading || !!error ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight={minHeight ?? '150px'}
        >
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Box display="flex" alignItems="center">
              <ErrorOutlineOutlined style={styles.errorIcon} />
              <Typography color="#C73E1D">{error}</Typography>
            </Box>
          )}
        </Box>
      ) : (
        children
      )}
    </>
  );
};
