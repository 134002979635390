import * as React from 'react';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { TextFieldProps } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Control, Controller } from 'react-hook-form';
import { DateFormat } from '../../enums/date';
import { DatePickerField as DatePickerFieldType } from '../../types/filter';
import { styles } from './styles';

interface DatePickerProps {
  control: Control;
  field: DatePickerFieldType;
}

export const DatePicker: React.FC<DatePickerProps> = ({
  field: selectField,
  control,
}) => (
  <LocalizationProvider dateAdapter={AdapterDateFns}>
    <Stack sx={styles.dataPickerContainer}>
      <Controller
        name={selectField.name}
        control={control}
        key={selectField.name}
        render={({ field: { onChange, value } }) => (
          <DesktopDateTimePicker
            label={selectField.label}
            inputFormat={DateFormat.DATE_AND_HOUR}
            value={value || null}
            showDaysOutsideCurrentMonth
            onChange={onChange}
            renderInput={(field: TextFieldProps) => (
              <TextField
                variant="filled"
                size="small"
                placeholder="date"
                sx={styles.dataPickerField}
                {...field}
              />
            )}
          />
        )}
      />
    </Stack>
  </LocalizationProvider>
);
