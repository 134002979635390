import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@mui/material';
import { stylesSidebar } from './styles';
import { FC } from 'react';

interface TooltipProps {
  item: any;
  sidebarOpen: boolean;
}

export const TooltipItem: FC<TooltipProps> = ({ item, sidebarOpen }) => {
  return (
    <Tooltip
      title={!sidebarOpen ? item.label : ''}
      placement='right'
      key={item.label}
    >
      <ListItem
        sx={[
          {
            ...(sidebarOpen && stylesSidebar.navItems),
            ...(!sidebarOpen && stylesSidebar.navItemsCollapsed),
          },
          sidebarOpen &&
            location.pathname.includes(item.path) &&
            stylesSidebar.activeNavItem,

          !sidebarOpen &&
            location.pathname.includes(item.path) &&
            stylesSidebar.activeNavItemCollapsed,
        ]}
        key={item.label}
        id={`sidebar-${item.path}`}
        onClick={item.action}
      >
        <ListItemButton sx={stylesSidebar.navItemBtn}>
          <ListItemIcon sx={stylesSidebar.listItemIcon}>
            {item.icon}
          </ListItemIcon>
          {sidebarOpen && (
            <ListItemText
              sx={[
                location.pathname.includes(item.path)
                  ? stylesSidebar.labelTextSideBarActive
                  : stylesSidebar.labelTextSideBar,
              ]}
              primary={item.label}
            />
          )}
        </ListItemButton>
      </ListItem>
    </Tooltip>
  );
};
