export const styles = {
  editBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    textDecoration: 'underline',
    ':hover': {
      cursor: 'pointer',
      textDecoration: 'none',
    },
  },
  editText: {
    fontSize: '14px',
    color: '#006161',
    marginLeft: '10px',
  },
  input: {
    width: '185px',
    marginBottom: '10px',
    display: 'block',
    'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    'input[type=number]': {
      MozAppearance: 'textfield',
    },
  },
};
