import { RuleConfiguration } from 'src/types/rule';
import { CapacityType } from './CapacityType';
import { GeofenceType } from './GeofenceType';
import { PrioritizationType } from './PrioritizationType';
import { ThresholdType } from './ThresholdType';

interface RuleTabProps {
  ruleConfiguration: RuleConfiguration;
  readMoreShown: boolean;
}

export const RuleTab: React.FC<RuleTabProps> = ({
  ruleConfiguration,
  readMoreShown,
}) => {
  const renderWidget = () => {
    switch (ruleConfiguration.type) {
      case 'PRIORITIZATION':
        return (
          <PrioritizationType
            ruleConfiguration={ruleConfiguration}
            readMoreShown={readMoreShown}
          />
        );
      case 'THRESHOLD':
        return (
          <ThresholdType
            ruleConfiguration={ruleConfiguration}
            readMoreShown={readMoreShown}
          />
        );
      case 'GEO_FENCE':
        return (
          <GeofenceType
            ruleConfiguration={ruleConfiguration}
            readMoreShown={readMoreShown}
          />
        );
      case 'CAPACITY':
        return (
          <CapacityType
            ruleConfiguration={ruleConfiguration}
            readMoreShown={readMoreShown}
          />
        );
      default:
        return (
          <PrioritizationType
            ruleConfiguration={ruleConfiguration}
            readMoreShown={readMoreShown}
          />
        );
    }
  };
  return <>{renderWidget()}</>;
};
