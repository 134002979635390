import * as Yup from 'yup';

export const addKpiWidgetSchemaStepLevel = Yup.object().shape({
  org: Yup.string().required(),
  metrics_level: Yup.string().required(),
  apn: Yup.string().when(['metrics_level'], {
    is: 'APN',
    then: Yup.string().required(),
  }),
  imsi: Yup.string().when(['metrics_level'], {
    is: 'IMSI',
    then: Yup.string().required(),
  }),
  site: Yup.string().when(['metrics_level'], {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    is: (metrics_level: string) =>
      ['SITE', 'IMSI', 'APN', 'VLAN', 'NODE'].includes(metrics_level),
    then: Yup.string().required(),
  }),
  vlan: Yup.string().when(['metrics_level'], {
    is: 'VLAN',
    then: Yup.string().required(),
  }),
  node: Yup.string().when(['metrics_level'], {
    is: 'NODE',
    then: Yup.string().required(),
  }),
});

export const addKpiWidgetSchemaStepMetric = Yup.object().shape({
  metric_name: Yup.string().required(),
});

export const addKpiWidgetSchemaStepCustomize = Yup.object().shape({
  metric_label: Yup.string().required(),
});
