import React, { SetStateAction } from 'react';
import {
  Box,
  Paper,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
} from '@mui/material';
import { styles } from './styles';
import { SortingArrow, Checkbox } from 'src/components';
import TableRow from '@mui/material/TableRow';
import { Column, Order } from 'src/types/shared';

interface TableProps {
  checkbox?: boolean;
  handleSelectAllCheckboxes?: (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => void;
  sortCriteria?: string;
  sortDirection?: Order;
  columns: Column[];
  setSortCriteria?: (value: SetStateAction<string>) => void;
  setSortDirection?: (value: SetStateAction<Order>) => void;
  checkedAllCheckboxes?: boolean;
  indeterminate?: boolean;
}

interface TableBodyProps extends TableProps {
  renderRows: () => React.ReactNode;
}

const TableHead_: React.FC<TableProps> = (props) => {
  const {
    handleSelectAllCheckboxes,
    columns,
    checkbox,
    sortCriteria,
    sortDirection,
    setSortCriteria,
    setSortDirection,
    checkedAllCheckboxes,
    indeterminate,
  } = props;
  const carbonIconStyles = {
    margin: '0',
    padding: '0',
  };
  return (
    <TableHead>
      <TableRow sx={styles.tableRow}>
        {checkbox && (
          <TableCell padding="none">
            <Checkbox
              color="primary"
              checked={checkedAllCheckboxes}
              onChange={handleSelectAllCheckboxes}
              indeterminate={indeterminate}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
              sx={{ padding: '14px' }}
            />
          </TableCell>
        )}
        {columns.map((headCell, index) => (
          <TableCell
            key={headCell.key}
            align="left"
            padding="none"
            sx={styles.tableHeadCell(index, sortCriteria === headCell.key)}
            sortDirection={
              sortCriteria === headCell.key
                ? sortDirection === 'ASC'
                  ? 'asc'
                  : 'desc'
                : false
            }
            onClick={() => {
              if (headCell.isSortable) {
                if (sortCriteria === headCell.key) {
                  if (setSortDirection) {
                    sortDirection === 'ASC'
                      ? setSortDirection('DESC')
                      : setSortDirection('ASC');
                  }
                } else {
                  if (setSortCriteria) {
                    setSortCriteria(headCell.key);
                  }
                  if (setSortDirection) {
                    setSortDirection('DESC');
                  }
                }
              }
            }}
          >
            <Box sx={styles.cell}>
              {headCell.label}
              {headCell.isSortable && (
                <SortingArrow
                  sortDirection={sortDirection}
                  active={sortCriteria === headCell.key}
                />
              )}
            </Box>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export const Table: React.FC<TableBodyProps> = ({
  columns,
  renderRows,
  checkbox,
  sortCriteria,
  sortDirection,
  setSortCriteria,
  setSortDirection,
  checkedAllCheckboxes,
  handleSelectAllCheckboxes,
  indeterminate,
}) => {
  return (
    <TableContainer component={Paper} elevation={0}>
      <MuiTable aria-label="table">
        <TableHead_
          columns={columns}
          checkbox={checkbox}
          sortCriteria={sortCriteria}
          sortDirection={sortDirection}
          setSortCriteria={setSortCriteria}
          setSortDirection={setSortDirection}
          checkedAllCheckboxes={checkedAllCheckboxes}
          handleSelectAllCheckboxes={handleSelectAllCheckboxes}
          indeterminate={indeterminate}
        />
        <TableBody>{renderRows()}</TableBody>
      </MuiTable>
    </TableContainer>
  );
};
