import { api } from 'src/utils/axios';
import { EndpointConnectionInfo, EndpointsInfo } from 'src/types/domain';
import { ApiResponseType } from '../types/api';

export const addEndpoint = ({
  organization,
  site,
  description,
  endpoint,
  vendor,
  connectionInfo,
}: {
  organization: string;
  site: string;
  endpoint: string;
  description?: string;
  vendor: string;
  connectionInfo?: EndpointConnectionInfo;
}) => {
  const url = 'topology/v1/endpoint';
  return api.post(url, {
    organization,
    site,
    description,
    endpoint,
    vendor,
    connectionInfo,
  });
};
export const getAllEndpoints = (org: string, site: string) => {
  return api.get<ApiResponseType<EndpointsInfo>>('/topology/v1/endpoint/all', {
    params: {
      organization: org,
      site,
    },
  });
};
export const editEndpoint = ({
  organization,
  site,
  endpoint,
  vendor,
  description,
  connectionInfo,
}: {
  organization: string;
  site: string;
  endpoint: string;
  vendor: string;
  description?: string;
  connectionInfo?: EndpointConnectionInfo;
}) => {
  const url = `topology/v1/endpoint?organization=${organization}&site=${site}&endpoint=${endpoint}`;
  return api.patch(url, {
    organization,
    site,
    endpoint,
    vendor,
    description,
    connectionInfo,
  });
};
