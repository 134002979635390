import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Divider, TextField, Typography, Box } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useForm, Controller } from 'react-hook-form';

import { renameDashboard } from 'src/apis/dashboard';
import { Drawer } from 'src/components';
import { QueryName } from 'src/enums/query';
import { createDashboardSchema } from 'src/yup-validations/dashboard';
import { styles } from '../styles';

type FormPayload = {
  dashboard_name: string;
};

interface RenameDashboardDrawerProps {
  name: string;
  open: boolean;
  onRename: (dashboardID: string) => void;
  onClose: () => void;
}

export const RenameDashboardDrawer: React.FC<RenameDashboardDrawerProps> = ({
  name,
  open,
  onRename,
  onClose,
}) => {
  const queryClient = useQueryClient();

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
    resetField,
    trigger,
  } = useForm({
    defaultValues: { dashboard_name: name },
    resolver: yupResolver(createDashboardSchema),
    mode: 'onChange',
  });

  const { mutate, isLoading } = useMutation<
    unknown,
    AxiosError,
    { oldName: string; newName: string }
  >({
    mutationFn: renameDashboard,
    onSuccess: (_, { newName }) => {
      onRename(newName);
      resetField('dashboard_name');
      queryClient.invalidateQueries([QueryName.GET_DASHBOARDS_LIST]);
    },
  });

  const onSubmit = (data: FormPayload) => {
    mutate({ oldName: name, newName: data.dashboard_name });
  };

  const handleCancel = () => {
    setValue('dashboard_name', name);
    trigger('dashboard_name');
    onClose();
  };

  return (
    <Drawer open={open} onClose={onClose}>
      <Typography variant="h5" gutterBottom>
        Rename Dashboard
      </Typography>
      <Divider />
      <Typography variant="body2" style={styles.drawerSubTitle}>
        This option allows you to rename a dashboard. This Dashboard will be
        private and configurable by you alone, until it is shared.
      </Typography>
      <Box mt={3} display="flex" flexDirection="column" flexGrow={1}>
        <Controller
          name="dashboard_name"
          control={control}
          render={({ field }) => (
            <TextField
              id="dashboard-name"
              label="Dashboard Name"
              size="small"
              fullWidth
              helperText="Name can only contain Alphabets, Numbers and '-, _'"
              FormHelperTextProps={{ style: styles.fieldHelperText }}
              error={!!errors.dashboard_name}
              {...field}
            />
          )}
        />
      </Box>
      <Box display="flex" justifyContent="space-evenly">
        <Button
          disableElevation
          variant="contained"
          sx={styles.addButton}
          onClick={handleSubmit(onSubmit)}
          disabled={isLoading || !isValid}
        >
          Rename
        </Button>
        <Button sx={styles.resetButton} onClick={handleCancel}>
          Cancel
        </Button>
      </Box>
    </Drawer>
  );
};
