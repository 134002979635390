import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { CSSProperties } from 'react';

export const styles = {
  arrowContainer: (left: boolean, disabled: boolean): CSSProperties => ({
    marginRight: left ? '30px' : '0px',
    marginLeft: left ? '0px' : '30px',
    cursor: disabled ? 'not-allowed' : 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    right: '1%',
    userSelect: 'none',
  }),
};

export const ExecutionTrendContainer = styled(Box)`
  & {
    .react-horizontal-scrolling-menu--wrapper {
      margin-left: 14px;
    }
    .react-horizontal-scrolling-menu--scroll-container {
      overflow: hidden;
    }
  }
`;
