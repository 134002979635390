import {
  Box,
  Card,
  CardActions,
  CardContent,
  Grid,
  Link,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { QueryName } from 'src/enums/query';
import { getAllPolicies } from 'src/apis/policyManagement';
import { useContext } from 'react';
import { AppContext, AuthContext } from 'src/context';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Loading } from 'src/components';
import { useNavigate } from 'react-router-dom';
import { UnAllocatedDevice } from './UnAllocatedDevice';

export const Policies: React.FC = () => {
  const { site } = useContext(AppContext);
  const { user } = useContext(AuthContext);
  const organization = user?.organizations?.[0] as string;
  const navigate = useNavigate();

  const { data, isLoading, isFetching } = useQuery(
    [QueryName.GET_ALL_POLICIES, organization, site],
    () => getAllPolicies(organization, site),
    { enabled: !!organization && !!site },
  );

  return (
    <Box display='flex'>
      <Box sx={{ width: '730px' }}>
        <Typography variant='h2' sx={{ padding: '0px 0px 10px 5px' }}>
          Active Policies
        </Typography>
        <Loading isLoading={isLoading || isFetching}>
          <Grid container spacing={2}>
            {data?.data.policies.map((policy) => (
              <Card
                key={policy.policyName}
                sx={{
                  background: '#F1FCFA',
                  margin: '13px',
                  width: '345px',
                }}
              >
                <CardContent>
                  <Link
                    sx={{
                      fontSize: '22px',
                      fontWeight: 'bold',
                      color: '#222222',
                    }}
                      onClick={() => {
                    navigate(`/usage-policy/edit/${policy.id}`, {
                      state: { back: true },
                    });
                  }}
                  >
                    {policy.policyName}
                    <ArrowForwardIcon
                      sx={{
                        paddingLeft: '15px',
                        fontSize: '30px',
                        verticalAlign: 'top',
                      }}
                    />
                  </Link>
                  <Typography
                    sx={{
                      fontSize: '15px',
                      fontWeight: 'bold',
                      display: 'flex',
                    }}
                  >
                    {policy.totalDataUsed}
                    <Typography
                      sx={{
                        paddingLeft: '5px',
                        alignSelf: 'center',
                      }}
                    >
                      GB
                    </Typography>
                  </Typography>
                </CardContent>
                <CardActions>
                  <Typography
                    sx={{
                      fontSize: '13px',
                      color: '#888888',
                      paddingLeft: '10px',
                    }}
                  >
                    {policy.totalUsers} users
                  </Typography>
                </CardActions>
              </Card>
            ))}{' '}
          </Grid>
        </Loading>
      </Box>
      <UnAllocatedDevice />
    </Box>
  );
};
