import styled from '@emotion/styled';
import { InputLabel, Select } from '@mui/material';

export const StyledInputLabel = styled(InputLabel)`
  & {
    top: -5px;

    :is([data-shrink='true']) {
      top: -4px;
      font-size: 14px;
    }
  }
`;

export const StyledSelect = styled(Select)`
  & {
    .MuiSelect-select {
      padding-top: 14px;
      font-size: 14px;
    }
  }
`;

export const styles = {
  filtersContainer: {
    height: '82%',
    overflowY: 'auto',
  },
  selectField: {
    mx: 1,
    minWidth: 220,
    marginBottom: '10px',
  },
  dataPickerField: {
    height: '41px',
    padding: 0,
    backgroundColor: '#f0f0f0',
    marginBottom: '10px',
  },
  dataPickerContainer: {
    padding: '0 8px',
    marginTop: 0,
    marginBottom: '5px',
  },
  addButton: {
    borderRadius: 0,
    flex: '50%',
  },
  resetButton: {
    flex: '50%',
    bgcolor: '#073B4C',
    color: 'white',
    borderRadius: 0,
    ':hover': {
      bgcolor: '#0c3644',
    },
  },
  selectedFilter: {
    textTransform: 'capitalize',
  },
  selectMenuList: {
    maxHeight: '200px',
  },

};
