import * as Yup from 'yup';

export const createUserSchema = Yup.object().shape({
  lastName: Yup.string().required('Last Name is required'),
  firstName: Yup.string().required('First Name is required'),
  userId: Yup.string().required('Email is required'),
  password: Yup.string()
    .required('Email is required')
    .min(8, 'Password should be minimum of 8 characters')
    .max(20, 'Password must be shorter than 50 characters'),
});
