export const styles = {
  editBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    textDecoration: 'underline',
    ':hover': { cursor: 'pointer', textDecoration: 'none' },
  },
  editText: { fontSize: '14px', color: '#006161', marginLeft: '10px' },
};
