import styled from '@emotion/styled';
import { ButtonGroup } from '@mui/material';

export const styles = {
  dashboardTitle: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: '400px',
    marginRight: '8px',
  },
  dashboardButton: {
    textTransform: 'capitalize',
    color: '#5f6c7b',
    marginRight: 1,
  },
  dashboardButtonIcon: {
    fontSize: '24px',
    color: '#118AB2',
  },
  dropdownIcon: {
    fontSize: '22px',
    color: '#118AB2',
  },
  deleteOptionText: {
    color: '#C73E1D',
  },
  deleteOptionIcon: {
    fontSize: '22px',
    color: '#C73E1D',
  },
  selectDashboardField: {
    width: 200,
  },
  drawerSubTitle: {
    marginTop: 8,
  },
  fieldHelperText: {
    color: '#073B4C',
  },
  addButton: {
    borderRadius: 0,
    flex: '50%',
  },
  resetButton: {
    flex: '50%',
    bgcolor: '#073B4C',
    color: 'white',
    borderRadius: 0,
    ':hover': {
      bgcolor: '#0c3644',
    },
  },
};

export const StyledButtonGroup = styled(ButtonGroup)`
  & {
    .MuiButton-containedPrimary,
    .MuiButton-containedPrimary:hover {
      background-color: #108ab2;
    }
    .MuiButton-outlinedPrimary {
      border-color: #108ab2;
      color: #108ab2;
    }
  }
`;
