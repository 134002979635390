import { Close } from '@mui/icons-material';
import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  IconButton,
} from '@mui/material';
import React, { useState } from 'react';
import { StyledInputDialogContainer, styles } from './styles';
import { Button } from '../Button';
import { TextField } from '../TextField';

interface InputConfirmableProps {
  open: boolean;
  title: string;
  subTitle: string;
  verifyInputPlaceholder: string;
  stringToVerify: string;
  onConfirm: () => void;
  onCancel: () => void;
}

export const InputConfirmable: React.FC<InputConfirmableProps> = ({
  open,
  title,
  subTitle,
  verifyInputPlaceholder,
  stringToVerify,
  onConfirm,
  onCancel,
}) => {
  const [userInput, setUserInput] = useState('');

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserInput(event.target.value);
  };

  const handleConfirm = () => {
    setUserInput('');
    onConfirm();
  };

  const handleCancel = () => {
    setUserInput('');
    onCancel();
  };

  return (
    <StyledInputDialogContainer open={open}>
      <DialogTitle>
        {title}
        <IconButton sx={styles.closeIcon} onClick={onCancel}>
          <Close fontSize="medium" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText mb={2}>
          {subTitle}
          {': '}
          <Typography fontWeight="bold" component="span">
            {stringToVerify}
          </Typography>
        </DialogContentText>
        <TextField
          label={verifyInputPlaceholder}
          value={userInput}
          onChange={handleOnChange}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button kind="primary-ghost" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          kind="primary"
          onClick={handleConfirm}
          disabled={stringToVerify !== userInput}
        >
          Confirm
        </Button>
      </DialogActions>
    </StyledInputDialogContainer>
  );
};
