import {
  Autocomplete,
  Box,
  InputAdornment,
  ListItemText,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import React, { FC, useContext, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AddCircleOutlineOutlined } from '@mui/icons-material';

import {
  Button,
  ChannelManagementDialog,
  Checkbox,
  Radio,
  Steps,
  Switch,
  TextField,
} from 'src/components';
import {
  AppContext,
  AuthContext,
  GeneralInfo,
  RulesEngineContext,
  ToastContext,
} from 'src/context';
import { specificationGeofenceValidationSchema } from 'src/yup-validations/rules-engine';
import { Main } from 'src/layouts/Main';
import { QueryName } from 'src/enums/query';
import { getAllUserEmails } from 'src/apis/access-management';
import { getChannels } from 'src/apis/channel';
import { inbound, outbound } from 'src/views/Rules/rule/assets';
import { createRule, CreateRuleData } from 'src/apis/rule';
import { StyledMenuItem, styles } from '../GeneralInformation/styles';
import { SelectedNetworksAndEquipments } from './SelectedNetworksAndEquipments';
import { GeofenceTypeCard } from './type';
import { Breadcrumbs } from 'src/components/Breadcrumbs/index';

const steps = [
  {
    title: 'Type',
    value: 'type',
  },
  {
    title: 'Selection',
    value: 'selection',
  },
  {
    title: 'Specification',
    value: 'specification',
  },
];

type GeoFenceData = {
  id: string;
  organization: string;
  site: string;
  name: string;
  description: string;
  type: string;
  state: string;
  from?: string;
  to?: string;
  createdBy: string;
  apns: string[];
  ues: string[];
  ueGroups: { [key: string]: string };
  ueNames: { [key: string]: string };
  priorityMap: { [key: number]: string };
  geoFenceType?: string;
  notifyOnce?: boolean;
  notification: {
    email: boolean;
    emailContacts: string[];
    slack: boolean;
    slackChannels: { [key: string]: string };
    teams: boolean;
    teamsChannels: { [key: string]: string };
  };
};

interface SpecificationGeofenceProps {
  onCancel: () => void;
}

export const SpecificationGeofence: FC<SpecificationGeofenceProps> = ({
  onCancel,
}) => {
  const [, setParams] = useSearchParams();
  const navigate = useNavigate();

  const { showToast } = useContext(ToastContext);
  const { user } = useContext(AuthContext);
  const { site } = useContext(AppContext);

  const [enableNotifications, setEnableNotifications] = useState({
    email: false,
    slack: false,
    teams: false,
  });
  const [addChannelDialogVisible, setAddChannelDialogVisible] = useState(false);
  const [activeType, setActiveType] = useState('INBOUND_BREACH');

  const {
    generalInfo,
    ruleType,
    selectedAPNs,
    selectedEquipments,
    addGeneralInfo,
  } = useContext(RulesEngineContext);

  const onSubmit = (data: GeneralInfo) => {
    addGeneralInfo({ ...data });
    const finalData: GeoFenceData = {
      id: 'string',
      organization: user?.organizations?.[0] as string,
      site,
      name: data.ruleName,
      description: data.description,
      type: ruleType,
      state: data.state,
      from: data.startingFrom
        ? new Date(data.startingFrom).toISOString()
        : undefined,
      to: data.endingOn ? new Date(data.endingOn).toISOString() : undefined,
      createdBy: user?.name as string,
      apns: selectedAPNs.map((apn) => apn.name),
      ues: selectedEquipments
        .filter((ue) => ue.type === 'equipment')
        .map((ue) => ue.id),
      ueNames: selectedEquipments
        .filter((ue) => ue.type === 'equipment')
        .reduce((prev, curr) => {
          return {
            ...prev,
            [curr.id]: curr.name,
          };
        }, {}),
      ueGroups: selectedEquipments
        .filter((ue) => ue.type === 'equipment_group')
        .reduce((prev, curr) => {
          return {
            ...prev,
            [curr.id]: curr.name,
          };
        }, {}),
      priorityMap: selectedEquipments.reduce((prev, curr, index) => {
        return {
          ...prev,
          [index + 1]: curr.id,
        };
      }, {}),
      notifyOnce: data?.notifyOnce,
      geoFenceType: activeType,
      notification: {
        email: data.notificationEmails.length > 0,
        emailContacts: data.notificationEmails,
        slack: data.slackChannels.length > 0,
        slackChannels: data.slackChannels.reduce((prev, curr) => {
          return {
            ...prev,
            [curr.id]: curr.name,
          };
        }, {}),
        teams: data.teamsChannels.length > 0,
        teamsChannels: data.teamsChannels.reduce((prev, curr) => {
          return {
            ...prev,
            [curr.id]: curr.name,
          };
        }, {}),
      },
    };
    createRuleMutation.mutate(finalData);
  };

  const {
    control,
    formState: { isValid, errors },
    setValue,
    handleSubmit,
  } = useForm({
    defaultValues: {
      ruleName: generalInfo.ruleName,
      description: generalInfo.description,
      startingFrom: generalInfo.startingFrom ?? new Date(),
      state: generalInfo.state,
      endingOn: generalInfo.endingOn,
      notificationEmails: generalInfo.notificationEmails,
      slackChannels: generalInfo.slackChannels,
      teamsChannels: generalInfo.teamsChannels,
      geoFenceType: 'INBOUND_BREACH',
      notifyOnce: false,
    },
    resolver: yupResolver(specificationGeofenceValidationSchema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const [ruleName, setRuleName] = useState(generalInfo.ruleName);

  const createRuleMutation = useMutation<
    AxiosResponse,
    AxiosError<{ title: string }>,
    CreateRuleData
  >({
    mutationFn: createRule,
    onSuccess: () => {
      showToast('Rule created successfully!', 'success');
      navigate('/rules');
    },
    onError: (error) =>
      showToast(
        error.response?.data?.title
          ? error.response?.data.title
          : 'An error occurred while creating a rule. Please try again later',
        'error',
      ),
  });

  const { data: emailsData } = useQuery(
    [QueryName.GET_ALL_USER_EMAILS],
    () => getAllUserEmails(user?.organizations?.[0] as string),
    {
      enabled: !!user?.organizations?.[0],
      onError: () =>
        showToast('Unable to get users list. Please try again later!', 'error'),
    },
  );

  const { data: channelsData, refetch: refetchChannels } = useQuery(
    [QueryName.GET_ALL_CHANNELS],
    () => getChannels(user?.organizations?.[0] as string),
    {
      enabled: !!user?.organizations?.[0],
    },
  );

  return (
    <Main>
      <Breadcrumbs breadcrumbs={[
        {
          title: 'Overview',
          url: '/',
        },
        {
          title: 'Rules',
          url: '/rules',
        },
        {
          title: 'Create a rule',
          url: '/rule?step=type',
        },
        {
          title: 'Selection',
          url: '/rule?step=selection',
        },
        {
          title: 'Specification',
        },
      ]}/>
      <Typography variant='h1' sx={{ mb: '26px' }}>
        Geofence
      </Typography>
      <Box display='flex' justifyContent='center'>
        <Box sx={{ width: '700px' }}>
          <Steps
            activeStep={2}
            steps={steps}
            onClick={(step) => setParams({ step: step.toString() })}
          />
        </Box>
      </Box>
      <Box display='flex' justifyContent='space-between'>
        <Box width='50%' mr={2}>
          <Stack
            display='flex'
            flexDirection='row'
            justifyContent='space-between'
          >
            <SelectedNetworksAndEquipments />
          </Stack>
          <Typography variant='h2' my='15px'>
            Geofence type
          </Typography>
          <Box
            display='flex'
            flexDirection='row'
            justifyContent='space-between'
            width='460px'
            mb='30px'
          >
            <GeofenceTypeCard
              title={'Inbound'}
              description={
                <Typography mt={0}>
                  send a notification when an equipment
                  <strong> enters</strong> the network boundary
                </Typography>
              }
              image={inbound}
              active={activeType === 'INBOUND_BREACH'}
              onSelect={() => setActiveType('INBOUND_BREACH')}
            />
            <GeofenceTypeCard
              title={'Outbound'}
              description={
                <Typography mt={0}>
                  send a notification when an equipment
                  <strong> comes out</strong> the network boundary
                </Typography>
              }
              image={outbound}
              active={activeType === 'OUTBOUND_BREACH'}
              onSelect={() => setActiveType('OUTBOUND_BREACH')}
            />
          </Box>
          <Typography variant='h4' mb='5px' mt='15px'>
            Notification frequency
          </Typography>
          <Controller
            name='notifyOnce'
            control={control}
            render={({ field }) => (
              <RadioGroup row {...field}>
                <Radio
                  label='Recurring (each time a rule is executed)'
                  value={false}
                />
                <Radio label='Once' value={true} />
              </RadioGroup>
            )}
          />
        </Box>
        <Box width='50%' ml={2}>
          <Box mr='95px'>
            <Typography variant='h2' mb='27px'>
              General information
            </Typography>
            <Controller
              name='ruleName'
              control={control}
              render={({ field: { onChange, ...field } }) => (
                <TextField
                  label='Rule name'
                  size='small'
                  onChange={({ target: { value } }) => {
                    onChange(value);
                    setRuleName(value);
                  }}
                  InputProps={{
                    sx: {
                      width: '236px',
                    },
                    endAdornment: (
                      <InputAdornment
                        position='end'
                        sx={styles.characterAdornment}
                      >
                        <Typography sx={styles.characterAdornmentText}>
                          {ruleName.length}/40
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    maxLength: 40,
                  }}
                  error={
                    !isValid && errors.ruleName?.message
                      ? errors.ruleName?.message
                      : undefined
                  }
                  sx={{ display: 'block', marginBottom: '10px' }}
                  {...field}
                />
              )}
            />

            <Controller
              name='description'
              control={control}
              render={({ field }) => (
                <TextField
                  label='Description'
                  size='small'
                  multiline
                  rows={2}
                  InputProps={{
                    sx: {
                      width: '485px',
                    },
                  }}
                  error={
                    !isValid && errors.description?.message
                      ? errors.description?.message
                      : undefined
                  }
                  sx={{ display: 'block', marginBottom: '10px' }}
                  {...field}
                />
              )}
            />

            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Box display='flex' justifyContent='space-between'>
                <Controller
                  name='startingFrom'
                  control={control}
                  render={({ field }) => (
                    <DateTimePicker
                      label='Starting from (optional)'
                      inputFormat='dd/MM/yyyy HH:mm'
                      renderInput={({ error: _, ...rest }) => (
                        <TextField
                          sx={{ width: '236px' }}
                          error={
                            !isValid && errors.startingFrom?.message
                              ? errors.startingFrom?.message
                              : undefined
                          }
                          {...rest}
                        />
                      )}
                      {...field}
                    />
                  )}
                />

                <Controller
                  name='endingOn'
                  control={control}
                  render={({ field }) => (
                    <DateTimePicker
                      label='Ending on (optional)'
                      inputFormat='dd/MM/yyyy HH:mm'
                      renderInput={({ error: _, ...rest }) => (
                        <TextField
                          sx={{ width: '236px' }}
                          error={
                            !isValid && errors.endingOn?.message
                              ? errors.endingOn?.message
                              : undefined
                          }
                          {...rest}
                        />
                      )}
                      {...field}
                    />
                  )}
                />
              </Box>
            </LocalizationProvider>
            <Typography variant='h4' mt='35px' mb='7px'>
              Rule status
            </Typography>
            <Controller
              name='state'
              control={control}
              render={({ field }) => (
                <RadioGroup row {...field}>
                  <Radio label='Active' value='ACTIVE' />
                  <Radio label='Inactive' value='INACTIVE' />
                </RadioGroup>
              )}
            />

            <Box
              display='flex'
              alignItems='baseline'
              marginBottom='22px'
              mt='36px'
            >
              <Typography variant='h2' sx={{ marginRight: '30px' }}>
                Notifications
              </Typography>
              <Box
                display='flex'
                alignItems='center'
                sx={{ cursor: 'pointer' }}
                onClick={() => setAddChannelDialogVisible(true)}
              >
                <AddCircleOutlineOutlined
                  sx={{
                    height: 16,
                    fill: '#006161',
                  }}
                />
                <Typography
                  fontSize={14}
                  color='#006161'
                  sx={{
                    textDecoration: 'underline',
                    ':hover': { textDecoration: 'none' },
                  }}
                >
                  Add a channel
                </Typography>
              </Box>
            </Box>
            <Box display='flex' alignItems='center' mb='12px'>
              <Typography mr='64px' color='#222222'>
                Email
              </Typography>
              <Switch
                checked={enableNotifications.email}
                onChange={(_, checked) => {
                  setEnableNotifications({
                    ...enableNotifications,
                    email: checked,
                  });
                  setValue('notificationEmails', []);
                }}
              />
            </Box>
            {enableNotifications.email && (
              <Controller
                name='notificationEmails'
                control={control}
                render={({ field: { onChange, ...rest } }) => (
                  <Autocomplete
                    multiple
                    disableCloseOnSelect
                    options={emailsData?.data ?? []}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label='Contacts'
                        sx={{ width: '486px' }}
                      />
                    )}
                    onChange={(_, value) => onChange(value)}
                    renderOption={(props, option, { selected }) => (
                      <StyledMenuItem {...props}>
                        <Checkbox checked={selected} />
                        <ListItemText primary={option} />
                      </StyledMenuItem>
                    )}
                    {...rest}
                  />
                )}
              />
            )}
            <Box mt='26px'>
              <Typography variant='h4'>Push notifications</Typography>
              <Box display='flex' alignItems='center' mt='12px'>
                <Typography color='#222222' mr='64px'>
                  Slack
                </Typography>
                <Switch
                  checked={enableNotifications.slack}
                  onChange={(_, checked) => {
                    setEnableNotifications({
                      ...enableNotifications,
                      slack: checked,
                    });
                    setValue('slackChannels', []);
                  }}
                />
              </Box>
              {enableNotifications.slack && (
                <Controller
                  name='slackChannels'
                  control={control}
                  render={({ field: { onChange, ...rest } }) => (
                    <Autocomplete
                      multiple
                      disableCloseOnSelect
                      options={
                        channelsData?.data?.channels.filter(
                          (channel) => channel.type === 'SLACK',
                        ) ?? []
                      }
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label='Channels'
                          sx={{ width: '486px' }}
                        />
                      )}
                      onChange={(_, value) => onChange(value)}
                      renderOption={(props, option, { selected }) => (
                        <StyledMenuItem {...props}>
                          <Checkbox checked={selected} value={option.id} />
                          <ListItemText primary={option.name} />
                        </StyledMenuItem>
                      )}
                      {...rest}
                    />
                  )}
                />
              )}
              <Box display='flex' alignItems='center' mt='6px' mb='6px'>
                <Typography color='#222222' mr='56px'>
                  Teams
                </Typography>
                <Switch
                  checked={enableNotifications.teams}
                  onChange={(_, checked) => {
                    setEnableNotifications({
                      ...enableNotifications,
                      teams: checked,
                    });
                    setValue('teamsChannels', []);
                  }}
                />
              </Box>
              {enableNotifications.teams && (
                <Controller
                  name='teamsChannels'
                  control={control}
                  render={({ field: { onChange, ...rest } }) => (
                    <Autocomplete
                      multiple
                      disableCloseOnSelect
                      options={
                        channelsData?.data?.channels.filter(
                          (channel) => channel.type === 'TEAMS',
                        ) ?? []
                      }
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label='Channels'
                          sx={{ width: '486px' }}
                        />
                      )}
                      onChange={(_, value) => onChange(value)}
                      renderOption={(props, option, { selected }) => (
                        <StyledMenuItem {...props}>
                          <Checkbox checked={selected} />
                          <ListItemText primary={option.name} />
                        </StyledMenuItem>
                      )}
                      {...rest}
                    />
                  )}
                />
              )}
            </Box>
            <ChannelManagementDialog
              open={addChannelDialogVisible}
              onSave={async () => {
                setAddChannelDialogVisible(false);
                await refetchChannels();
              }}
              onCancel={() => setAddChannelDialogVisible(false)}
            />
          </Box>
          <Box mt='36px' mb='43px' display='flex' justifyContent='flex-end'>
            <Button kind='primary-ghost' onClick={onCancel}>
              Cancel
            </Button>
            <Button
              sx={{ ml: '15px' }}
              padding='8px 46px'
              kind='primary'
              disabled={createRuleMutation.isLoading}
              onClick={handleSubmit(onSubmit)}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </Main>
  );
};
