import { useContext } from 'react';
import { TextField, Box, Button } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { v4 } from 'uuid';

import { AddWidgetContext } from 'src/context/AddWidgetContext';
import { KPIWidget } from 'src/types/widget';
import { WidgetFieldsContainer } from '../WidgetFieldsContainer';
import { yupResolver } from '@hookform/resolvers/yup';
import { addKpiWidgetSchemaStepCustomize } from 'src/yup-validations/widget';
import {
  kpiCurrentWidgetDefaultPosition,
  kpiTimeSeriesWidgetDefaultPosition,
} from 'src/config/widget';
import { WidgetValueType } from 'src/enums/widget';

type FormPayload = {
  metric_label: string;
};

interface CustomizeProps {
  disableSubmit: boolean;
  onPreviousStep: () => void;
  onAddWidget: (data: Partial<KPIWidget>) => void;
}

export const Customize: React.FC<CustomizeProps> = ({
  disableSubmit,
  onPreviousStep,
  onAddWidget,
}) => {
  const { widget: _widget, updateWidget } = useContext(AddWidgetContext);

  const widget = _widget as KPIWidget;

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    defaultValues: {
      metric_label: widget?.label ?? '',
    },
    resolver: yupResolver(addKpiWidgetSchemaStepCustomize),
    mode: 'onChange',
  });

  const onSubmit = (data: FormPayload) => {
    updateWidget({
      label: data.metric_label,
    });
    onAddWidget({
      ...widget,
      id: v4(),
      label: data.metric_label,
      config: {
        ...(widget.type === WidgetValueType.CURRENT
          ? kpiCurrentWidgetDefaultPosition
          : kpiTimeSeriesWidgetDefaultPosition),
      },
    });
  };

  return (
    <WidgetFieldsContainer
      title="Customize Widget"
      actions={
        <>
          <Button onClick={onPreviousStep}>Previous Step</Button>
          <Button
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            disabled={!isValid || !widget?.type || disableSubmit}
          >
            Add Widget
          </Button>
        </>
      }
    >
      <Box mt={1}>
        <Controller
          name="metric_label"
          control={control}
          render={({ field }) => (
            <TextField
              id="widget-label"
              label="Widget Label"
              fullWidth
              {...field}
            />
          )}
        />
      </Box>
    </WidgetFieldsContainer>
  );
};
