export const styles = {
  tableRow: {
    height: '76px',
    ':hover': {
      bgColor: 'rgba(0, 0, 0, 0.04)',
    },
    '&:last-child': {
      borderBottom: '2px solid #CCCCCC',
    },
  },
  userId: {
    fontWeight: 'bold',
    textDecoration: 'underline',
    color: '#006161',
    cursor: 'pointer',
    ':hover': {
      textDecoration: 'none',
    },
    paddingRight: '15px',
  },
  tableColumn: {
    paddingRight: '15px',
    color: '#888888',
  },
  bulkDeleteBtn: {
    display: 'flex-end',
    textColor: '#F20000',
    backgroundColor: '#FFFFFF',
    border: '1px solid #F20000',
    hoverBackgroundColor: '#EEF0F2',
    padding: '5px 24px',
    height: '33px',
  },
  bulkActionBox: {
    margin: 0,
    padding: 0,
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
  },
  header: {
    height: '80px',
  },
  lowerHeaderBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    height: '80px',
  },
  tagBox: {
    display: 'flex',
    flexDirection: 'row',
  },
  openModalButton: {
    margin: '15px 0',
    padding: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
  },
  downloadIcon: {
    color: '#006161',
    size: '24',
  },
  iconText: {
    size: '24',
    paddingLeft: '11px',
    color: '#006161',
    fontSize: '14px',
    alignItems: 'left',
    textDecoration: 'underline',
    ':hover': {
      textDecoration: 'none',
    },
  },
};

export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 48 * 3 + 8,
      width: 200,
    },
  },
};
