import { ApiResponseType } from 'src/types/api';
import { InstantMetricsData, RangeMetricsData } from 'src/types/metric';
import { Widget } from 'src/types/widget';
import { api } from 'src/utils/axios';

export const getMetricsLevel = (category: string) => {
  return api.get<ApiResponseType<string[]>>('/pm/v1/metrics/level', {
    params: {
      category,
    },
  });
};

export const getMetricNames = (category: string, level: string) => {
  return api.get<ApiResponseType<string[]>>('/pm/v1/metrics/name', {
    params: {
      category,
      level,
    },
  });
};

export const getInstantMetricsData = (param: {
  id: Widget['id'];
  metric: string;
  organization: string;
  level: string;
  site?: string;
  apn?: string;
  vid?: number;
  imsi?: string;
  node?: string;
  time?: string;
}) => {
  return api.post<ApiResponseType<InstantMetricsData>>(
    '/pm/v1/metrics/instant',
    {
      ...param,
    },
  );
};

export const getRangeMetricsData = (param: {
  id: Widget['id'];
  metric: string;
  organization: string;
  level: string;
  site?: string;
  apn?: string;
  vid?: number;
  imsi?: string;
  node?: string;
  start: string;
  end?: string;
  last?: number;
  stepInSeconds: number;
}) => {
  return api.post<ApiResponseType<RangeMetricsData>>('/pm/v1/metrics/range', {
    ...param,
  });
};
