import { useEffect, useState } from 'react';
import {
  FormControl,
  MenuItem,
  Select,
  Typography,
  InputLabel,
  Button,
  Divider,
  Chip,
} from '@mui/material';
import { NoteAddOutlined } from '@mui/icons-material';
import { Box } from '@mui/system';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { CreateDashboardDrawer } from './CreateDashboardDrawer';
import { useQuery } from '@tanstack/react-query';
import { QueryName } from 'src/enums/query';
import { getDashboardsName } from 'src/apis/dashboard';
import { DashboardContainer } from './DashboardContainer';
import { styles } from './styles';
import { Main } from 'src/layouts/Main';
import { LocalStorage } from 'src/enums/localStorage';

export const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();
  const dashboardId = params.get('dashboardId');

  const [dashboardSaved, setDashboardSaved] = useState(true);
  const [dashboardName, setDashboardName] = useState('Dashboard');

  const { data: dashboardsList } = useQuery(
    [QueryName.GET_DASHBOARDS_LIST],
    () => getDashboardsName(),
  );

  const [createDashboardDrawerVisible, setCreateDashboardDrawerVisible] =
    useState(false);

  useEffect(() => {
    if (!dashboardId) {
      setDashboardName('Dashboard');
    }
  }, [dashboardId]);

  useEffect(() => {
    const previouslyOpenedId = localStorage.getItem(LocalStorage.DASHBOARD_ID);
    if (previouslyOpenedId) {
      setParams({ dashboardId: previouslyOpenedId });
    }
  }, [setParams]);

  return (
    <Main>
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        mb={1}
      >
        <Box display='flex' alignItems='center'>
          <Typography
            fontSize={28}
            color='#073b4c'
            fontWeight='bold'
            sx={styles.dashboardTitle}
          >
            {dashboardName}
          </Typography>
          {!dashboardSaved && <Chip label='Unsaved!' color='warning' />}
        </Box>
        <Box display='flex' alignItems='center'>
          <Button
            size='large'
            sx={styles.dashboardButton}
            startIcon={<NoteAddOutlined sx={styles.dashboardButtonIcon} />}
            onClick={() => setCreateDashboardDrawerVisible(true)}
          >
            New Dashboard
          </Button>
          <FormControl size='small' sx={styles.selectDashboardField}>
            <InputLabel id='select-dashboard-label'>
              Select Dashboard
            </InputLabel>
            <Select
              labelId='select-dashboard-label'
              id='select-dashboard'
              label='Select Dashboard'
              value={dashboardId ?? ''}
              onChange={(event) => {
                setParams({ dashboardId: event.target.value });
                localStorage.setItem(
                  LocalStorage.DASHBOARD_ID,
                  event.target.value,
                );
              }}
            >
              {dashboardsList?.data?.map((dashboard) => (
                <MenuItem key={dashboard} value={dashboard}>
                  {dashboard}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Divider />
      <DashboardContainer
        updateDashboardName={(updatedName) => {
          setDashboardName(updatedName);
          setDashboardSaved(true);
        }}
        updateDashboardSavedStatus={(status) => setDashboardSaved(status)}
      />
      <CreateDashboardDrawer
        open={createDashboardDrawerVisible}
        onAdd={(newDashboardId) => {
          localStorage.setItem(LocalStorage.DASHBOARD_ID, newDashboardId);
          navigate(`/?dashboardId=${newDashboardId}`);
          setCreateDashboardDrawerVisible(false);
        }}
        onClose={() => setCreateDashboardDrawerVisible(false)}
      />
    </Main>
  );
};
