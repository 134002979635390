export const styles = {
  apnHoverName: {
    paddingLeft: '2px',
    fontSize: '14px',
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#FFFFFF',
  },
  apnHoverText: {
    fontSize: '11px',
    textAlign: 'center',
    color: '#FFFFFF',
  },
  headingIconBox: {
    justifyContent: 'center',
    paddingRight: '8px',
    display: 'flex',
    alignItems: 'center',
  },
}
