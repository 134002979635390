import { useContext } from 'react';
import { AuthContext } from 'src/context';

export const useUserRole = () => {
  const { user } = useContext(AuthContext);

  const isSuperUser = () => user?.realm_access.roles.includes('SUPER_USER');

  const isAdminUser = () => user?.realm_access.roles.includes('ADMIN');

  const isReadyOnlyUser = () => user?.realm_access.roles.includes('READ_ONLY');

  return { isSuperUser, isAdminUser, isReadyOnlyUser };
};
