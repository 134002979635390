import {
  Box,
  Checkbox,
  DialogActions,
  DialogContent,
  IconButton,
  InputAdornment,
  MenuItem,
  Typography,
} from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button, Select, TextField } from 'src/components';
import { MenuProps } from '../styles';
import { useOrganizations, useSites } from 'src/views/DeviceManagement/state';
import { AddElement } from './AddElement';
import { RoleButton } from './RoleButtons';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { createUser } from 'src/apis/access-management';
import { ToastContext } from 'src/context';
import { Close, View, ViewFilled } from '@carbon/icons-react';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { createUserSchema } from 'src/yup-validations/addUser';
import { StyledDialog } from 'src/views/DeviceManagement/AddElement/styles';
import { styles } from 'src/views/DeviceManagement/Listing/GroupModal/styles';

interface CreateUserProps {
  open: boolean;
  close: () => void;
}

export interface UserForm {
  lastName: string;
  firstName: string;
  userId: string;
  password: string;
  roles: string;
  organization: string;
  sites: string[];
}

export const CreateUser: FC<CreateUserProps> = ({
  open,
  close,
}) => {
  const {
    handleSubmit,
    control,
    setValue,
    watch,
    reset,
    formState,
    formState: { isSubmitted },
  } = useForm<UserForm>({
    defaultValues: {
      firstName: '',
      lastName: '',
      userId: '',
      password: '',
      roles: '',
      organization: '',
      sites: [],
    },
    resolver: yupResolver(createUserSchema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => setShowPassword((show) => !show);

  const { showToast } = useContext(ToastContext);

  const selectedOrganization = watch('organization');
  const {
    roles: currentRole,
  } = watch();

  useEffect(() => {
    setValue('sites', []);
  }, [selectedOrganization, setValue]);

  const { data: organizationsResponse } = useOrganizations();
  const organizations = organizationsResponse?.data ?? [];

  const {
    data: sitesResponse,
    isLoading: isSitesLoading,
  } =
    useSites(selectedOrganization);
  const sites = sitesResponse?.data ?? [];

  const {
    mutate: createUserMutation,
    error: createUserMutationError,
  } =
    useMutation<
      unknown,
      AxiosError,
      {
        userId: string;
        firstName: string;
        lastName: string;
        password: string;
        enabled: boolean;
        roles: string[];
        organizations?: string[];
        sites?: string[];
        createDate: number;
        lastAccess: string;
      }
    >({
      mutationFn: createUser,
      onSuccess: async () => {
        reset();
        showToast('User created successfully!', 'success');
      },
      onError: (error) => {
        const title =
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          error?.response?.data?.title ?? 'An error occurred, try again';
        showToast(title, 'error');
      },
    });

  const onSubmit = (data: UserForm) => {
    createUserMutation({
      userId: data.userId,
      firstName: data.firstName,
      lastName: data.lastName,
      password: data.password,
      enabled: true,
      roles: [data.roles],
      ...(data.roles !== 'SUPER_USER' && {
        organizations: [data.organization],
        sites: data.sites,
      }),
      createDate: Date.now(),
      lastAccess: '',
    });
  };
  const generatePassword = () => {
    const newPassword = Math.random().toString(36).slice(2);
    setValue('password', newPassword);
  };

  const handleClose = () => {
    reset();
    setValue('organization', '');
    close();
  };

  return (
    <StyledDialog open={open}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 'bold',
              paddingBottom: '20px',
            }}
          >Create a new user</Typography>
          <IconButton sx={styles.closeIcon} onClick={handleClose}>
            <Close size='24' color='#222222' />
          </IconButton>
          <Box width='100%'>
            <Box display={'flex'}>
              <Box flex={1}>
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 'bold',
                    color: '#006161',
                    paddingBottom: '10px',
                  }}
                >
                  Identify
                </Typography>

                <Controller
                  name='lastName'
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label='Last name'
                      sx={{
                        paddingBottom: '10px',
                        paddingRight: '10px',
                      }}
                      error={
                        !!formState.errors && formState.errors?.lastName?.message
                      }
                    />
                  )}
                />

                <Controller
                  name='firstName'
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label='First name'
                      sx={{
                        paddingBottom: '10px',
                        paddingRight: '10px',
                      }}
                      error={
                        !!formState.errors &&
                        formState.errors?.firstName?.message
                      }
                    />
                  )}
                />
              </Box>
              <Box flex={1}>
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 'bold',
                    color: '#006161',
                    paddingBottom: '10px',
                  }}
                >
                  Account
                </Typography>

                <Controller
                  name='userId'
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label='E-mail address'
                      sx={{
                        paddingBottom: '10px',
                      }}
                      error={
                        !!formState.errors && formState.errors?.userId?.message
                      }
                    />
                  )}
                />
                <Controller
                  name='password'
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label='Password'
                      sx={{
                        paddingBottom: '2px',
                        width: '274px',
                      }}
                      error={!!formState.errors && formState.errors?.password?.message}
                      type={showPassword ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <IconButton
                              aria-label='toggle password visibility'
                              onClick={toggleShowPassword}
                              edge='end'
                            >
                              {showPassword ? <ViewFilled /> : <View />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
                <br />
                <Typography sx={{
                  fontSize: '14px',
                  color: '#006161',
                  textDecoration: 'underline',
                  padding: 0,
                  ':hover': {
                    cursor: 'pointer',
                    textDecoration: 'none'
                  },
                }}
                            onClick={generatePassword}>
                  Generate a new password
                </Typography>
              </Box>
            </Box>
            <RoleButton control={control} currentRole={currentRole} />
            {(currentRole === 'READ_ONLY' || currentRole === 'ADMIN') && (
              <Box>
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 'bold',
                    color: '#006161',
                    paddingBottom: '10px',
                    paddingTop: '20px',
                  }}
                >
                  Access
                </Typography>
                <Box display={'flex'}>
                  <Box
                    sx={{
                      paddingBottom: '10px',
                      paddingRight: '10px',
                    }}
                  >
                    <Controller
                      control={control}
                      name='organization'
                      render={({ field }) => (
                        <Select
                          sx={{ width: '236px' }}
                          label='Organization'
                          MenuProps={MenuProps}
                          placeholder='Organization'
                          {...field}
                        >
                          {organizations.map((organization) => (
                            <MenuItem key={organization} value={organization}>
                              {organization}
                            </MenuItem>
                          ))}
                          <AddElement
                            href='/networks'
                            state='isAddElement: true'
                          />
                        </Select>
                      )}
                    />
                  </Box>

                  <Box
                    sx={{
                      paddingBottom: '10px',
                      paddingRight: '10px',
                    }}
                  >
                    <Controller
                      control={control}
                      name='sites'
                      render={({ field }) => (
                        <Select
                          fullWidth
                          width={236}
                          label='Sites'
                          MenuProps={MenuProps}
                          placeholder='Sites'
                          renderValue={(value) => {
                            const sites = value as string[];
                            return sites.join(', ') || 'Sites';
                          }}
                          multiple
                          disabled={!selectedOrganization || isSitesLoading}
                          {...field}
                        >
                          {sites.map((site) => (
                            <MenuItem key={site} value={site}>
                              <Checkbox checked={field.value.includes(site)} />
                              {site}
                            </MenuItem>
                          ))}
                          <AddElement
                            href='/networks/'
                            state='isAddElement: true'
                          />
                        </Select>
                      )}
                    />
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </DialogContent>
        <DialogActions sx={{ paddingTop: '30px' }}>
          <Button kind='primary-ghost' onClick={handleClose}>
            Cancel
          </Button>
          <Button kind='primary' type='submit'>
            Create
          </Button>
        </DialogActions>
      </form>
    </StyledDialog>
  );
};
