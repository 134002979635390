import React, { useContext, useEffect, useState } from 'react';
import TreeItem from '@mui/lab/TreeItem';
import { Edit, TrashCan, ChevronUp, ChevronDown } from '@carbon/icons-react';
import { IconButton, Stack, Typography, Box, Link } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { QueryName } from 'src/enums/query';
import { getAllEndpoints } from 'src/apis/endpoint';
import {
  deleteEndpoint,
  deleteOrganization,
  deleteSite,
  getAllOrganizationNames,
} from 'src/apis/org';
import { getAllSiteNames } from 'src/apis/sites';
import { TreeView } from 'src/components/TreeView';
import { Confirmable, Loading } from 'src/components';
import { styles } from 'src/views/DeviceManagement/ManageNetwork/styles';
import { ToastContext } from 'src/context';
import { AxiosError } from 'axios';
import { AddElement } from '../AddElement';
import { useUserRole } from 'src/utils/user';

type Site = {
  [key: string]: {
    endpoints: string[];
  };
};
type Topology = {
  [key: string]: {
    sites: Site;
  };
};
export const TreeViewList: React.FC = () => {
  const { showToast } = useContext(ToastContext);
  const { isSuperUser, isAdminUser } = useUserRole();

  const [hoverItem, setHoverItem] = useState<string>('');
  const [topology, setTopology] = useState<Topology | null>(null);
  const [selectedOrg, setSelectedOrg] = useState<string>('');
  const [selectedSite, setSelectedSite] = useState<string>('');
  const [expandedNode, setExpandedNode] = useState<string[]>([]);
  const [defaultExpandedNode, setDefaultExpandedNode] = useState<string[]>([
    '',
  ]);
  const [deleteOrgModalOpen, setDeleteOrgModalOpen] = useState<boolean>(false);
  const [deleteSiteModalOpen, setDeleteSiteModalOpen] =
    useState<boolean>(false);
  const [orgToManage, setOrgToManage] = useState('');
  const [siteToManage, setSiteToManage] = useState('');
  const [endpointToDelete, setEndpointToDelete] = useState('');
  const queryClient = useQueryClient();
  const [isAddElement, setAddElement] = useState<boolean>(false);
  const [elementsToManage, setElementsToManage] = useState('');
  const [endpointToManage, setEndpointToManage] = useState('');
  const [treeViewLoaded, setTreeViewLoaded] = useState('');
  const [treeLoading, setTreeLoading] = useState<boolean>(true);

  useQuery(
    [QueryName.GET_ORG_NAMES, treeViewLoaded],
    () => getAllOrganizationNames(),
    {
      enabled: true,
      onSuccess: (data) => {
        if (data?.data) {
          const tempData: Topology = {};
          data.data.forEach((org) => {
            tempData[org] = {
              sites: {},
            };
          });
          setTopology(tempData);
          setTreeLoading(false);
          if (expandedNode.length > 0) {
            setExpandedNode([]);
            setSelectedOrg('');
            setSelectedSite('');
          }
        }
      },
      onError: async () => {
        showToast('Error fetching data. Please refresh the page', 'error');
      },
    },
  );

  useQuery(
    [QueryName.GET_SITE_NAMES, selectedOrg],
    () => getAllSiteNames(selectedOrg),
    {
      enabled: !!selectedOrg,
      onSuccess: (data) => {
        if (data?.data) {
          const tempSiteData: Site = {};
          data.data.forEach((site) => {
            tempSiteData[site] = {
              endpoints: [],
            };
          });
          if (topology) {
            const tempTopology = Object.assign(topology, {});
            tempTopology[selectedOrg].sites = tempSiteData;
            setTopology(tempTopology);
            setExpandedNode([`org-${selectedOrg}`]);
          }
          setSelectedSite('');
        }
      },
      onError: async () => {
        showToast('Error fetching data. Please refresh the page', 'error');
      },
    },
  );

  useEffect(() => {
    if (topology && !selectedOrg && !selectedSite && !treeViewLoaded) {
      setSelectedOrg(Object.keys(topology)[0]);
      setDefaultExpandedNode([`org-${Object.keys(topology)[0]}`]);
    }
  }, [topology]);

  const { isFetching: isFetchingEndpoint } = useQuery(
    [QueryName.GET_ENDPOINTS, selectedSite],
    () => getAllEndpoints(selectedOrg, selectedSite),
    {
      enabled: !!selectedOrg && !!selectedSite,
      onSuccess: (data) => {
        if (data.data) {
          const siteEndpoints =
            data.data.endpoints.map((endpoint) => endpoint.endpoint) ?? [];
          if (topology) {
            const tempTopology = Object.assign(topology, {});
            tempTopology[selectedOrg].sites[selectedSite].endpoints =
              siteEndpoints;
            setTopology(tempTopology);
            setExpandedNode([
              `org-${selectedOrg}`,
              `site-${selectedOrg}-${selectedSite}`,
            ]);
          }
        }
      },
      onError: async () => {
        showToast('Error fetching data. Please refresh the page', 'error');
      },
    },
  );

  const deleteOrgMutation = useMutation<
    unknown,
    AxiosError,
    { organization: string }
  >({
    mutationFn: deleteOrganization,
    onSuccess: async () => {
      showToast('Organization deleted successfully!', 'success');
      await queryClient.invalidateQueries([QueryName.GET_ORG_NAMES]);
    },
    onError: () => {
      showToast('Failed to delete the organization, Please try again', 'error');
    },
  });

  const deleteSiteMutation = useMutation<
    unknown,
    AxiosError,
    { organization: string; site: string }
  >({
    mutationFn: deleteSite,
    onSuccess: async () => {
      showToast('Site deleted successfully!', 'success');
      await queryClient.invalidateQueries([QueryName.GET_SITE_NAMES]);
    },
    onError: () => {
      showToast('Failed to delete the site, Please try again', 'error');
    },
  });

  const deleteEndpointMutation = useMutation<
    unknown,
    AxiosError,
    { organization: string; site: string; endpoint: string }
  >({
    mutationFn: deleteEndpoint,
    onSuccess: async () => {
      showToast('Endpoint deleted successfully!', 'success');
      await queryClient.invalidateQueries([QueryName.GET_ENDPOINTS]);
    },
    onError: () => {
      showToast('Failed to delete the endpoint, Please try again', 'error');
    },
  });

  const onOrgSelect = (nodeId: string) => {
    const newNodeId = `org-${nodeId}`;
    if (expandedNode?.[0] === newNodeId) {
      setExpandedNode(['']);
      setSelectedOrg('');
      setSelectedSite('');
    } else {
      setSelectedOrg(nodeId);
    }
  };

  const onSiteSelect = (org: string, site: string) => {
    if (isSuperUser()) {
      return;
    }
    const newNodeId = `site-${org}-${site}`;
    const expandedOrg = expandedNode?.[0];
    setExpandedNode([expandedOrg]);

    if (expandedNode?.[1] === newNodeId) {
      setSelectedSite('');
      setExpandedNode([expandedOrg]);
    } else {
      setExpandedNode([expandedOrg]);
      setSelectedOrg('');
      setSelectedSite('');
      setSelectedOrg(org);
      setSelectedSite(site);
    }
  };

  const handleEditOrgModal = (
    org: string,
    event: React.MouseEvent<HTMLElement>,
  ) => {
    event.stopPropagation();
    setAddElement(true);
    setOrgToManage(org);
    setElementsToManage('organization');
  };

  const handleEditSiteModal = (
    org: string,
    site: string,
    event: React.MouseEvent<HTMLElement>,
  ) => {
    event.stopPropagation();
    setAddElement(true);
    setOrgToManage(org);
    setSiteToManage(site);
    setElementsToManage('site');
  };

  const handleEditEndpointModal = (
    org: string,
    site: string,
    endpoint: string,
    event: React.MouseEvent<HTMLElement>,
  ) => {
    setAddElement(true);
    setOrgToManage(org);
    setSiteToManage(site);
    setEndpointToManage(endpoint);
    setElementsToManage('endpoint');
    event.stopPropagation();
  };

  const handleDeleteOrgModal = (
    org: string,
    event: React.MouseEvent<HTMLElement>,
  ) => {
    event.stopPropagation();
    setDeleteOrgModalOpen(true);
    setOrgToManage(org);
  };

  const handleDeleteSiteModal = (
    event: React.MouseEvent<HTMLElement>,
    org: string,
    site: string,
  ) => {
    event.stopPropagation();
    setDeleteSiteModalOpen(true);
    setOrgToManage(org);
    setSiteToManage(site);
  };

  const handleDeleteEndpointModal = (
    event: React.MouseEvent<HTMLElement>,
    org: string,
    site: string,
    endpoint: string,
  ) => {
    event.stopPropagation();
    setOrgToManage(org);
    setSiteToManage(site);
    setEndpointToDelete(endpoint);
  };

  return (
    <>
      <Loading isLoading={treeLoading} minHeight='70px'>
        <Typography variant={'h2'} sx={styles.treeTitle}>
          Tree View
        </Typography>
      </Loading>
      <TreeView
        aria-label='file system navigator'
        expandIcon={<ChevronUp size={20} />}
        collapseIcon={<ChevronDown size={20} />}
        expanded={expandedNode}
        defaultExpanded={defaultExpandedNode}
      >
        {topology &&
          Object.keys(topology).map((org) => (
            <TreeItem
              key={`org-${org}`}
              nodeId={`org-${org}`}
              sx={styles.orgLabel}
              label={
                <Stack
                  direction={'row'}
                  sx={styles.stack}
                  onMouseOver={() => setHoverItem(`org-${org}`)}
                  onMouseOut={() => setHoverItem('')}
                >
                  <Typography sx={styles.OrgTitle}>{org}</Typography>
                  {isSuperUser() && (
                    <Box
                      sx={
                        hoverItem === `org-${org}`
                          ? styles.box
                          : styles.boxHidden
                      }
                    >
                      <IconButton
                        sx={styles.iconButton}
                        onClick={(event: React.MouseEvent<HTMLElement>) =>
                          handleEditOrgModal(org, event)
                        }
                      >
                        <Link sx={styles.editIconText}>Edit</Link>
                        <Edit style={styles.editIcon} />
                      </IconButton>
                      <IconButton
                        sx={styles.iconButton}
                        onClick={(event: React.MouseEvent<HTMLElement>) =>
                          handleDeleteOrgModal(org, event)
                        }
                      >
                        <Link sx={styles.binIconText}>Delete</Link>
                        <TrashCan style={styles.binIcon} />
                      </IconButton>
                    </Box>
                  )}
                </Stack>
              }
              onClick={() => onOrgSelect(org)}
            >
              {Object.keys(topology[org].sites).length !== 0 ? (
                Object.keys(topology[org].sites).map((site) => (
                  <TreeItem
                    key={`site-${org}-${site}`}
                    nodeId={`site-${org}-${site}`}
                    label={
                      <Stack
                        direction={'row'}
                        sx={styles.stack}
                        onMouseOver={() => setHoverItem(`site-${org}-${site}`)}
                        onMouseOut={() => setHoverItem('')}
                      >
                        <Typography sx={styles.siteTitle}>{site}</Typography>
                        {isSuperUser() && (
                          <Box
                            sx={
                              hoverItem === `site-${org}-${site}`
                                ? styles.box
                                : styles.boxHidden
                            }
                          >
                            <IconButton
                              sx={styles.iconButton}
                              onClick={(event: React.MouseEvent<HTMLElement>) =>
                                handleEditSiteModal(org, site, event)
                              }
                            >
                              <Link sx={styles.editIconText}>Edit</Link>
                              <Edit style={styles.editIcon} />
                            </IconButton>
                            <IconButton
                              sx={styles.iconButton}
                              onClick={(event: React.MouseEvent<HTMLElement>) =>
                                handleDeleteSiteModal(event, org, site)
                              }
                            >
                              <Link sx={styles.binIconText}>Delete</Link>
                              <TrashCan style={styles.binIcon} />
                            </IconButton>
                          </Box>
                        )}
                      </Stack>
                    }
                    onClick={() => onSiteSelect(org, site)}
                  >
                    {!isSuperUser() && (
                      <>
                        {Object.values(topology[org].sites[site].endpoints)
                          .length !== 0 ? (
                          !isFetchingEndpoint &&
                          Object.values(
                            topology[org].sites[site].endpoints,
                          ).map((endpoint) => (
                            <TreeItem
                              key={`endpoint-${org}-${site}-${endpoint}`}
                              nodeId={`endpoint-${org}-${site}-${endpoint}`}
                              label={
                                <Stack
                                  direction={'row'}
                                  sx={styles.stack}
                                  onMouseOver={() =>
                                    setHoverItem(
                                      `endpoint-${org}-${site}-${endpoint}`,
                                    )
                                  }
                                  onMouseOut={() => setHoverItem('')}
                                >
                                  <Typography
                                    variant='body1'
                                    sx={styles.endpointTitle}
                                  >
                                    {endpoint}
                                  </Typography>
                                  {isAdminUser() && (
                                    <Box
                                      sx={
                                        hoverItem ===
                                        `endpoint-${org}-${site}-${endpoint}`
                                          ? styles.box
                                          : styles.boxHidden
                                      }
                                    >
                                      <IconButton
                                        sx={styles.iconButton}
                                        onClick={(event) =>
                                          handleEditEndpointModal(
                                            org,
                                            site,
                                            endpoint,
                                            event,
                                          )
                                        }
                                      >
                                        <Link sx={styles.editIconText}>
                                          Edit
                                        </Link>
                                        <Edit style={styles.editIcon} />
                                      </IconButton>
                                      <IconButton
                                        sx={styles.iconButton}
                                        onClick={(
                                          event: React.MouseEvent<HTMLElement>,
                                        ) =>
                                          handleDeleteEndpointModal(
                                            event,
                                            org,
                                            site,
                                            endpoint,
                                          )
                                        }
                                      >
                                        <Link sx={styles.binIconText}>
                                          Delete
                                        </Link>
                                        <TrashCan style={styles.binIcon} />
                                      </IconButton>
                                    </Box>
                                  )}
                                </Stack>
                              }
                            />
                          ))
                        ) : (
                          <TreeItem
                            nodeId={`endpoint-${org}-${site}-noEndpoint`}
                            label={
                              <Typography sx={styles.noEndpointsText}>
                                No endpoints
                              </Typography>
                            }
                            disabled={true}
                          />
                        )}
                      </>
                    )}
                  </TreeItem>
                ))
              ) : (
                <TreeItem
                  nodeId={`${org}-noSite`}
                  label={
                    <Typography sx={styles.noSitesText}>No sites</Typography>
                  }
                  disabled={true}
                />
              )}
            </TreeItem>
          ))}
      </TreeView>
      <Confirmable
        open={deleteOrgModalOpen}
        title='Delete Organization'
        subTitle={`Are you sure to delete ${orgToManage} from network?`}
        onConfirm={() => {
          setSelectedOrg('')
          setExpandedNode([]);
          deleteOrgMutation.mutate({ organization: orgToManage });
          setDeleteOrgModalOpen(false);
        }}
        onCancel={() => setDeleteOrgModalOpen(false)}
      />
      <Confirmable
        open={deleteSiteModalOpen}
        title='Delete Site'
        subTitle={`Are you sure to delete ${siteToManage} from network?`}
        onConfirm={() => {
          deleteSiteMutation.mutate({
            organization: orgToManage,
            site: siteToManage,
          });
          setDeleteSiteModalOpen(false);
        }}
        onCancel={() => setDeleteSiteModalOpen(false)}
      />
      <Confirmable
        open={!!endpointToDelete}
        title='Delete endpoint'
        subTitle={`Are you sure to delete ${endpointToDelete} from network?`}
        onConfirm={() => {
          deleteEndpointMutation.mutate({
            organization: orgToManage,
            site: siteToManage,
            endpoint: endpointToDelete,
          });
          setEndpointToDelete('');
        }}
        onCancel={() => setEndpointToDelete('')}
      />
      {isAddElement && (
        <AddElement
          open={isAddElement}
          onCancel={() => setAddElement(false)}
          elementsToManage={elementsToManage}
          organizationName={orgToManage}
          siteName={siteToManage}
          endpointName={endpointToManage}
        />
      )}
    </>
  );
};
