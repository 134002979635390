import { colors } from 'src/config/theme';
import styled from '@emotion/styled';

export const EquipmentWrapper = styled.div``;

export const styles = {
  title: {
    fontWeight: 'bold',
    fontSize: '22px',
    color: colors.secondary,
    marginBottom: '16px',
  },
  searchBox: {
    width: '187px',
    position: 'relative',
    right: '0',
    height: '33px',
    marginRight: '29px',
  },
  addEquipmentBox: {
    position: 'relative',
    width: '173px',
    height: '33px',
  },
  optionsWraperBox: {
    display: 'flex',
  },
  addEquipmentBtn: {
    width: '173px',
  },
  equipmentListWrapper: {
    marginTop: '31px',
  },
  equipmentItemWrapperActive: {
    display: 'flex',
    width: '396px',
    height: '84px',
    background: '#F2FAF3',
    marginBottom: '16px',
  },
  equipmentItemWrapperInactive: {
    display: 'flex',
    width: '396px',
    height: '84px',
    background: '#F6F6F6',
    marginBottom: '16px',
  },
  equipmentIdNameWrapper: {
    display: 'flex',
    margin: '19px 30px 0 30px',
    width: '100%',
    Height: '18px',
    float: 'left',
  },
  equipmentId: {
    display: 'flex',
    fontSize: '14px',
    color: '#222222',
    fontWeight: 'bold',
    textDecoration: 'underline',
    marginRight: '7px',
  },
  equipmentName: {
    fontSize: '14px',
    color: '#222222',
  },
  equipmentUsage: {
    float: 'right',
    fontSize: '14px',
    color: '#222222',
    fontWeight: 'bold',
    right: '0px',
    marginLeft: 'auto',
  },
  equipmentStatusWrapper: {
    float: 'left',
    margin: '10px auto 20px 30px',
    height: '22px',
  },
};
