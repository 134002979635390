import { api } from 'src/utils/axios';
import { ApiResponseType } from 'src/types/api';

const baseUrl = `${process.env.REACT_APP_BACKEND_URL}`;

export const getVlanNamesByOrg = (org: string) => {
  const url = `${baseUrl}topology/v1/metadata/vlan/name?organization=${org}`;
  return api.get<ApiResponseType<string[]>>(url);
};

export const getAllVlans = (
  {
    organization,
    site,
  }: {
    organization: string;
    site?: string;
  },
  pageStart: number,
  pageEnd: number,
) => {
  return api.get<ApiResponseType>('/topology/v1/vlan/all', {
    params: {
      organization,
      site,
    },
    headers: {
      pageStart,
      pageEnd,
    },
  });
};
