import styled from '@emotion/styled';
import { StepButton } from '@mui/material';

export const StyledStepButton = styled(StepButton)`
  & {
    .MuiStepIcon-root.Mui-active {
      border: 1px solid #55cfbb;
      border-radius: 50%;
      padding: 2px;
    }
    .MuiStepLabel-label.Mui-completed {
      text-decoration: underline;
      color: #006161;
    }
    .MuiStepLabel-label:hover {
      text-decoration: none;
    }
    .MuiStepLabel-label.Mui-active {
      color: #006161;
    }
    .MuiStepLabel-iconContainer.Mui-disabled {
      .MuiSvgIcon-root {
        color: #fff;
        border: 1px solid #888888;
        border-radius: 50%;
        .MuiStepIcon-text {
          fill: #888888;
        }
      }
    }
  }
`;
