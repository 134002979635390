import { format } from 'date-fns';

export const dateToISO8601 = (date?: string) => {
  if (date) {
    return new Date(date).toISOString().slice(0, 19);
  }
  return '';
};

export const dateToUTCWithISO8601 = (date?: string) => {
  if (date) {
    const toConvert = new Date(date).toUTCString();
    return dateToISO8601(toConvert);
  }
  return ''
}

export const formatDate = (date: string) => {
  if (date) {
    return format(new Date(date), 'dd/MM/yyyy HH:mm');
  }
};
