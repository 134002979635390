export const styles = {
  stack: {
    direction: 'ltr',
  },
  box: {
    position: 'absolute',
    right: '15px',
  },
  boxHidden: {
    position: 'absolute',
    right: '15px',
    opacity: 0,
  },
  iconButton: {
    direction: 'rtl',
    width: '85px',
    height: '40px',
    borderRadius: 0,
  },
  binIconText: {
    paddingLeft: '11px',
    color: '#F20000',
    fontSize: '14px',
    alignItems: 'left',
  },
  editIconText: {
    paddingLeft: '11px',
    color: '#006161',
    fontSize: '14px',
    alignItems: 'left',
  },
  editIcon: {
    color: '#006161',
    size: '24',
  },
  binIcon: {
    color: '#F20000',
    size: '24',
  },
  OrgTitle: {
    paddingTop: '10px',
    direction: 'ltr',
    paddingLeft: '26px',
    color: '#006161',
    fontWeight: 'bold',
    fontSize: '14px',
  },
  siteTitle: {
    paddingTop: '10px',
    paddingLeft: '47px',
    color: '#222222',
    fontWeight: 'regular',
    fontSize: '14px',
    alignSelf: 'center',
    textDecoration: 'underline',
  },
  endpointTitle: {
    paddingTop: '10px',
    paddingLeft: '68px',
  },
  treeTitle: {
    paddingTop: '43px',
    paddingBottom: '16px',
  },
  noEndpointsText: {
    paddingLeft: '68px',
    color: '#555555',
    fontWeight: 'regular',
    fontSize: '14px',
    alignSelf: 'center',
    paddingTop: '10px',
  },
  noSitesText: {
    paddingLeft: '47px',
    color: '#555555',
    fontWeight: 'regular',
    fontSize: '14px',
    alignSelf: 'center',
    paddingTop: '10px',
  },
  orgLabel: {
    marginTop: '8px',
  },
};
