import { createContext, useState } from 'react';
import { LocalStorage } from 'src/enums/localStorage';

interface AppContextProviderProps {
  children: React.ReactNode;
}

const defaultValues = {
  sidebarOpen: true,
  toggleSidebar: () => {},
  site: '',
  selectSite: (name: string) => {},
  siteChanged: false,
};

export const AppContext = createContext(defaultValues);

export const AppContextProvider: React.FC<AppContextProviderProps> = ({
  children,
}) => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [site, setSite] = useState('');
  const [siteChanged, setSiteChanged] = useState<boolean>(false);

  const toggleSidebar = () => setSidebarOpen((prev) => !prev);

  const selectSite = (name: string) => {
    localStorage.setItem(LocalStorage.SITE_NAME, name);
    setSite(name);
    if (site && site !== name) {
      setSiteChanged(true);
    } else {
      setSiteChanged(false);
    }
  };

  return (
    <AppContext.Provider
      value={{
        sidebarOpen,
        toggleSidebar,
        site,
        selectSite,
        siteChanged,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
