import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Chip, ChipProps } from '@mui/material';

export interface StyledChipProps extends Omit<ChipProps, 'color'> {
  kind:
    | 'primary'
    | 'primary-ghost'
    | 'danger'
    | 'danger-ghost'
    | 'default'
    | 'default-ghost'
    | 'custom';
  textColor?: string;
  backgroundColor?: string;
  hoverTextColor?: string;
  hoverBackgroundColor?: string;
  border?: string;
  padding?: string;
  rounded?: boolean;
}

const getCSS = ({
  kind,
  textColor,
  backgroundColor,
  hoverTextColor,
  hoverBackgroundColor,
  border,
  rounded,
}: StyledChipProps) => {
  switch (kind) {
    case 'custom':
      return css`
      .MuiChip-label {
        color: ${textColor};
      }
      background: ${backgroundColor};
      border: ${border ?? 'none'};
      border-radius: ${rounded ? '10px' : '1px'};
      :hover {
        color: ${hoverTextColor};
        background: ${hoverBackgroundColor};
      }
      `;
    case 'primary':
      return css`
        .MuiChip-label {
          color: #ffffff;
        }
        background: #00a410;
        border: ${border ?? 'none'};
        border-radius: ${rounded ? '10px' : '1px'};
      `;
    case 'primary-ghost':
      return css`
        .MuiChip-label {
          color: #00a410;
        }
        background-color: ${backgroundColor ?? 'transparent'};;
        border: ${border ?? 'none'};
        border-radius: ${rounded ? '10px' : '1px'};
      `;
    case 'danger':
      return css`
        .MuiChip-label {
          color: #ffffff;
        }
        background: #f20000;
        border: ${border ?? 'none'};
        border-radius: ${rounded ? '10px' : '1px'};
      `;
    case 'danger-ghost':
      return css`
        .MuiChip-label {
          color: #f20000;
        }
        background-color: ${backgroundColor ?? 'transparent'};;
        border: ${border ?? '1px solid #f20000;'};
        border-radius: ${rounded ? '50%' : '1px'};
      `;
    case 'default':
      return css`
        .MuiChip-label {
          color: #ffffff;
        }
        background: #888888;
        border: none;
        border-radius: ${rounded ? '50%' : '1px'};
      `;
    case 'default-ghost':
      return css`
        .MuiChip-label {
          color: #888888;
        }
        background-color: ${backgroundColor ?? 'transparent'};;
        border: ${border ?? 'none'};
        border-radius: ${rounded ? '50%' : '1px'};
      `;
    default:
      return css``;
  }
};

export const StyledChip = styled(Chip)<StyledChipProps>`
  & {
    font-size: 12px;
    text-transform: capitalize;
    height:20px;
    .MuiChip-label {
      padding: ${({ padding = '2px 8px' }) => padding};
      text-align: center;
      text-transform: uppercase;
    }
    ${getCSS}
  }
`;
