import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import {
  Box,
  Link,
  ListItemText,
  MenuItem,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

import { styles } from './styles';
import {
  Checkbox,
  Chip,
  Loading,
  MinimalAreaChart,
  Pagination,
  Select,
  Table,
} from 'src/components';
import { formatDate } from 'src/utils/date';
import { Order, Page } from 'src/types/shared';
import { QueryName } from 'src/enums/query';
import { getAllAuthors, getAllRules, getAllRuleTypes } from 'src/apis/rule';
import { RuleConfiguration } from 'src/types/rule';
import { AppContext, AuthContext, ToastContext } from 'src/context';
import { Tag } from 'src/components/Tag';
import {
  replaceSpacesWithUnderscores,
  replaceUnderscoresWithSpaces,
  showStatusChip,
  toTitleCase,
} from 'src/utils/rule';
import { BulkActionMenu } from './BulkActionMenu';

const columns = [
  {
    key: 'rule',
    label: 'Rule',
  },
  {
    key: 'type',
    label: 'Rule type',
  },
  {
    key: 'ACTIVATION_STATUS',
    label: 'Active',
    isSortable: true,
  },
  {
    key: 'EXECUTION_STATUS',
    label: 'Last execution',
    isSortable: true,
  },
  {
    key: 'weekly_trend',
    label: 'Weekly trend',
  },
];

export const RulesTable: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const { site } = useContext(AppContext);
  const { showToast } = useContext(ToastContext);

  const organization = (user?.organizations?.[0] as string) || '';

  const location = useLocation();
  const [params] = useSearchParams();

  const [authors, setAuthors] = useState<string[]>([]);
  const [selectedTemporaryAuthors, setSelectedTemporaryAuthors] = useState<
    string[]
  >([]);
  const [selectedRules, setSelectedRules] = useState<string[]>(() => {
    const queryParams = new URLSearchParams(location.search);
    const rules = queryParams.get('rules');
    if (rules) {
      return rules.split(',');
    }
    return [];
  });
  const [openRulesMenu, setOpenRulesMenu] = useState(false);
  const [openAuthorsMenu, setOpenAuthorsMenu] = useState(false);
  const [selectedTemporaryRules, setSelectedTemporaryRules] = useState<
    string[]
  >([]);
  const [sortCriteria, setSortCriteria] = useState<string>('ACTIVATION_STATUS');
  const [sortDirection, setSortDirection] = useState<Order>('ASC');
  const [selected, setSelected] = useState<string[]>([]);
  const [page, setPage] = useState<Page>({
    pageStart: 1,
    pageEnd: 10,
  });
  const { data: rulesAuthors } = useQuery(
    [QueryName.GET_ALL_AUTHORS, { organization }],
    () => getAllAuthors(organization),
    { enabled: !!organization },
  );

  const { data: ruleTypes } = useQuery([QueryName.GET_ALL_RULE_TYPES], () =>
    getAllRuleTypes(),
  );

  const isSelected = (name: string) => selected.includes(name);

  const toggleSelected = (name: string) => {
    const selectedIndex = selected.indexOf(name);
    const newSelected = [...selected];
    if (selectedIndex === -1) {
      newSelected.push(name);
    } else {
      newSelected.splice(selectedIndex, 1);
    }
    return newSelected;
  };
  const handleClickCheckbox = useCallback(
    (
      event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
      name: string,
    ) => {
      const newSelected = toggleSelected(name);
      setSelected(newSelected);
      checkSelection(newSelected);
    },
    [selected, toggleSelected],
  );

  const checkSelection = (selected: string | any[]) => {
    const isAnySelectedRuleExpired = rows?.some(
      (rule: RuleConfiguration) =>
        rule.state === 'EXPIRED' && selected.includes(rule.id),
    );
    if (isAnySelectedRuleExpired) {
      showToast('Expired rules can not be Activated or Deactivated', 'warning');
    }
  };

  const handleSelectAllCheckboxes = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows?.map((row: RuleConfiguration) => row.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };
  const handlePageChange = (pageStart: number, pageEnd: number) => {
    setPage({
      pageStart,
      pageEnd,
    });
  };

  const { data, isLoading, isFetching } = useQuery(
    [
      QueryName.GET_ALL_RULES,
      organization,
      site,
      authors,
      selectedRules,
      sortCriteria,
      sortDirection,
      page.pageStart,
      page.pageEnd,
    ],
    () =>
      getAllRules(
        organization,
        site,
        authors,
        selectedRules,
        sortCriteria,
        sortDirection,
        page.pageStart,
        page.pageEnd,
      ),
    { enabled: !!organization },
  );
  const rows = data?.data.rules;
  const checkedAllCheckboxes =
    rows?.length > 0 && selected?.length === rows?.length;

  const indeterminate = selected?.length > 0 && selected?.length < rows?.length;

  const deleteAuthor = (author: string) => {
    setAuthors((prev) => prev.filter((item) => item !== author));
    setSelectedTemporaryAuthors((prev) =>
      prev.filter((item) => item !== author),
    );
  };
  const deleteRule = (rule: string) => {
    setSelectedRules((prev) => prev.filter((item) => item !== rule));
    setSelectedTemporaryRules((prev) => prev.filter((item) => item !== rule));
  };
  const handleChangeRules = (event: ChangeEvent<{ value: string[] }>) => {
    const [selectedOption] = event.target.value;
    setSelectedTemporaryRules((prev) => {
      if (prev.includes(selectedOption)) {
        return prev.filter((option) => option !== selectedOption);
      }
      return [...prev, selectedOption];
    });
  };
  const handleChangeAuthors = (event: ChangeEvent<{ value: string[] }>) => {
    const [selectedOption] = event.target.value;
    setSelectedTemporaryAuthors((prev) => {
      if (prev.includes(selectedOption)) {
        return prev.filter((option) => option !== selectedOption);
      }
      return [...prev, selectedOption];
    });
  };
  const handleSubmitRules = () => {
    setSelectedRules(selectedTemporaryRules);
    handleCloseRulesMenu();
  };
  const handleSubmitAuthors = () => {
    setAuthors(selectedTemporaryAuthors);
    handleCloseAuthorsMenu();
  };
  const handleOpenRulesMenu = () => {
    setOpenRulesMenu(true);
  };
  const handleCloseRulesMenu = () => {
    setOpenRulesMenu(false);
  };
  const handleOpenAuthorsMenu = () => {
    setOpenAuthorsMenu(true);
  };
  const handleCloseAuthorsMenu = () => {
    setOpenAuthorsMenu(false);
  };

  useEffect(() => {
    const authorsQuery = authors.map(replaceSpacesWithUnderscores).join(',');
    const rulesQuery = selectedRules
      .map(replaceSpacesWithUnderscores)
      .join(',');
    navigate(
      `${location.pathname}?authors=${authorsQuery}&rules=${rulesQuery}`,
    );
  }, [authors, location.pathname, navigate, selectedRules, user]);

  useEffect(() => {
    const authors = params.get('authors');
    const authorsArray = authors?.split(',') ?? [];
    const currentUser = user?.name;
    if (authorsArray.length > 0) {
      setAuthors(authorsArray.map(replaceUnderscoresWithSpaces));
    }
    return setAuthors(
      currentUser ? [replaceUnderscoresWithSpaces(currentUser)] : [],
    );
  }, []);

  const getByState = (state: string) => {
    if (state === 'ACTIVE') {
      return 'primary';
    } else if (state === 'INACTIVE') {
      return 'danger';
    } else {
      return 'default';
    }
  };

  useEffect(() => {
    const authors = params.get('authors');
    const authorsArray = authors ? authors.split(',') : [];
    const currentUser = user?.name;
    let updatedAuthors: React.SetStateAction<string[]> = [];

    if (authorsArray.length > 0) {
      updatedAuthors = authorsArray.map(replaceUnderscoresWithSpaces);
    }
    if (currentUser) {
      updatedAuthors = [replaceUnderscoresWithSpaces(currentUser)];
    }
    setAuthors(updatedAuthors);
    setSelectedTemporaryAuthors(updatedAuthors);
  }, [user?.name]);

  return (
    <>
      <Box sx={styles.selectWrapper}>
        <Box>
          <Select
            renderValue={() => 'Authors'}
            sx={{ margin: '5px' }}
            width={180}
            placeholder='Authors'
            labelId='select-author-label'
            id='select-author'
            label='Authors'
            onChange={handleChangeAuthors}
            open={openAuthorsMenu}
            onOpen={handleOpenAuthorsMenu}
            onClose={handleCloseAuthorsMenu}
            checkboxes={true}
            handleSubmit={handleSubmitAuthors}
          >
            {rulesAuthors?.data.map((author: string) => (
              <MenuItem key={author} value={author}>
                <Checkbox
                  checked={selectedTemporaryAuthors?.includes(author)}
                />
                <ListItemText primary={toTitleCase(author)} />
              </MenuItem>
            ))}
          </Select>

          <Select
            open={openRulesMenu}
            renderValue={() => 'Rules'}
            onOpen={handleOpenRulesMenu}
            onClose={handleCloseRulesMenu}
            checkboxes={true}
            sx={{ margin: '5px' }}
            width={180}
            labelId='select-type-label'
            id='select-type'
            label='Types'
            onChange={handleChangeRules}
            handleSubmit={handleSubmitRules}
          >
            {ruleTypes?.data.map((rule) => (
              <MenuItem key={rule} value={rule}>
                <Checkbox checked={selectedTemporaryRules.includes(rule)} />
                <ListItemText primary={toTitleCase(rule)} />
              </MenuItem>
            ))}
          </Select>
        </Box>
        {selected.length > 0 && (
          <BulkActionMenu
            rules={rows}
            selectedRuleIds={selected}
            organization={organization}
            site={site}
          />
        )}
      </Box>
      <Box sx={styles.authorsWrapper}>
        {authors?.map((author, idx) => (
          <Tag
            key={`${author}-${idx}`}
            label={author}
            onDelete={() => deleteAuthor(author)}
            deleteIcon={<ClearIcon />}
          />
        ))}
        {selectedRules?.map((rule, idx) => (
          <Tag
            key={`${rule}-${idx}`}
            label={toTitleCase(rule)}
            onDelete={() => deleteRule(rule)}
            deleteIcon={<ClearIcon />}
          />
        ))}
        {(authors.length > 0 || selectedRules.length > 0) && (
          <Tag
            label={'Remove all'}
            onDelete={() => {
              setAuthors([]);
              setSelectedRules([]);
              setSelectedTemporaryRules([]);
              setAuthors([]);
              setSelectedTemporaryAuthors([]);
            }}
            deleteIcon={<ClearIcon />}
            textColor={'#fff'}
            backgroundColor={'#888'}
          />
        )}
      </Box>
      <Loading isLoading={isLoading || isFetching}>
        <Table
          checkbox={true}
          checkedAllCheckboxes={checkedAllCheckboxes}
          columns={columns}
          handleSelectAllCheckboxes={handleSelectAllCheckboxes}
          sortCriteria={sortCriteria}
          setSortCriteria={setSortCriteria}
          sortDirection={sortDirection}
          setSortDirection={setSortDirection}
          indeterminate={indeterminate}
          renderRows={() =>
            rows?.map((row: RuleConfiguration, index: number) => {
              const isItemSelected = isSelected(row.id);
              const labelId = `enhanced-table-checkbox-${index}`;
              return (
                <TableRow
                  hover
                  onClick={(event: React.MouseEvent<HTMLTableRowElement>) =>
                    handleClickCheckbox(event, row.id)
                  }
                  role='checkbox'
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.id}
                  sx={styles.tableRow}
                >
                  <TableCell padding='checkbox'>
                    <Checkbox
                      color='primary'
                      checked={isItemSelected}
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </TableCell>
                  <TableCell
                    scope='row'
                    padding='none'
                    sx={styles.ruleNameWrapper}
                  >
                    <Link
                      sx={styles.ruleName}
                      onClick={() =>
                        navigate(
                          `/rules/${row.organization}/${row.site}/${row.id}`,
                        )
                      }
                    >
                      {row.name}
                    </Link>
                    <Typography sx={styles.description}>
                      {row.description}
                    </Typography>
                  </TableCell>
                  <TableCell sx={styles.ruleTypeWrapper} padding='none'>
                    {toTitleCase(row.type)}
                  </TableCell>
                  <TableCell padding='none'>
                    <Chip
                      kind={getByState(row.state)}
                      variant='outlined'
                      label={row.state}
                    />
                    <Box sx={styles.dateAndTimeWrapper}>
                      <Typography sx={styles.dateAndTime}>
                        {row?.from && `From: ${formatDate(row?.from)}`}
                      </Typography>
                      <Typography sx={styles.dateAndTime}>
                        {row?.to && `To: ${formatDate(row?.to)}`}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell sx={styles.executionStatus} padding='none'>
                    {showStatusChip(row.executionStatus)}
                  </TableCell>
                  <TableCell padding='none'>
                    <MinimalAreaChart
                      data={row?.trendData?.map((trend) => Number(trend.value))}
                      height={70}
                      width={200}
                    />
                  </TableCell>
                </TableRow>
              );
            })
          }
        />
      </Loading>
      {data?.data && data?.data.total > 10 && (
        <Pagination
          currentPage={page.pageStart}
          disabled={isLoading || isFetching}
          totalRecords={data?.data.total}
          onChange={handlePageChange}
        />
      )}
    </>
  );
};
