import { FC, useContext, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Autocomplete, TextField } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Controller, useForm } from 'react-hook-form';

import { getApnNamesByOrg } from 'src/apis/apn';
import { getIMSINamesByOrg } from 'src/apis/imsi';
import { getMetricsLevel } from 'src/apis/metrics';
import { getOrgNames } from 'src/apis/org';
import { getSiteNames } from 'src/apis/sites';
import { getVlanNamesByOrg } from 'src/apis/vlan';
import { AddWidgetContext } from 'src/context/AddWidgetContext';
import { QueryName } from 'src/enums/query';
import { AlarmWidget, KPIWidget, WeatherWidget } from 'src/types/widget';
import { addKpiWidgetSchemaStepLevel } from 'src/yup-validations/widget';
import { WidgetFieldsContainer } from '../WidgetFieldsContainer';
import { styles } from './styles';
import { getNodeNamesByOrg } from 'src/apis/node';

type FormPayload = {
  org: string;
  metrics_level: string;
  apn: string;
  imsi: string;
  site: string;
  vlan: string;
  node: string;
};

interface LevelProps {
  onPreviousStep: () => void;
  onNextStep: () => void;
}

export const Level: FC<LevelProps> = ({ onPreviousStep, onNextStep }) => {
  const { widget: _widget, updateWidget } = useContext(AddWidgetContext);

  const widget = _widget as KPIWidget | WeatherWidget | AlarmWidget;

  const {
    control,
    formState: { isValid },
    watch,
    setValue,
    handleSubmit,
  } = useForm({
    defaultValues: {
      org: widget?.org ?? '',
      metrics_level: widget?.metricsLevel ?? '',
      apn: (widget as KPIWidget | AlarmWidget)?.apn ?? '',
      imsi: (widget as KPIWidget)?.imsi ?? '',
      site: widget?.site ?? '',
      vlan: (widget as KPIWidget | AlarmWidget)?.vlan ?? '',
      node: (widget as AlarmWidget)?.node ?? '',
    },
    resolver: yupResolver(addKpiWidgetSchemaStepLevel),
    mode: 'onChange',
  });

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { org, metrics_level } = watch();

  useEffect(() => {
    if (metrics_level === 'ORGANIZATION') {
      setValue('apn', '');
      setValue('imsi', '');
      setValue('site', '');
      setValue('vlan', '');
      setValue('node', '');
    }
  }, [metrics_level, setValue]);

  const { data: metricsLevelData } = useQuery(
    [QueryName.GET_METRICS_LEVEL, widget?.widgetType],
    () => getMetricsLevel(widget?.widgetType as string),
  );

  const { data: orgNamesData } = useQuery([QueryName.GET_ORG_NAMES], () =>
    getOrgNames(),
  );

  const { data: apnNamesData } = useQuery(
    [QueryName.GET_APN_NAMES_BY_ORG, org],
    () => getApnNamesByOrg(org),
    {
      enabled: !!org && metrics_level === 'APN',
    },
  );

  const { data: siteNamesData } = useQuery(
    [QueryName.GET_SITES_BY_ORG, org],
    () => getSiteNames(org),
    {
      enabled:
        !!org &&
        ['SITE', 'IMSI', 'APN', 'VLAN', 'NODE'].includes(metrics_level),
    },
  );

  const { data: imsiNamesData } = useQuery(
    [QueryName.GET_IMSI_NAMES_BY_ORG, org],
    () => getIMSINamesByOrg(org),
    {
      enabled: !!org && metrics_level === 'IMSI',
    },
  );

  const { data: vlanNamesData } = useQuery(
    [QueryName.GET_VLAN_NAMES_BY_ORG, org],
    () => getVlanNamesByOrg(org),
    {
      enabled: !!org && metrics_level === 'VLAN',
    },
  );

  const { data: nodeNamesData } = useQuery(
    [QueryName.GET_NODE_NAMES_BY_ORG, org],
    () => getNodeNamesByOrg(org),
    {
      enabled: !!org && metrics_level === 'NODE',
    },
  );

  const onSubmit = (data: FormPayload) => {
    updateWidget({
      org: data.org,
      metricsLevel: data.metrics_level,
      apn: data.apn,
      imsi: data.imsi,
      site: data.site,
      vlan: data.vlan,
      node: data.node,
    });
    onNextStep();
  };

  return (
    <WidgetFieldsContainer
      title="Select Level"
      actions={
        <>
          <Button onClick={onPreviousStep}>Previous Step</Button>
          <Button
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            disabled={!isValid}
          >
            Next Step
          </Button>
        </>
      }
    >
      <Box mt={1}>
        <Controller
          name="org"
          control={control}
          render={({ field: { onChange, ...rest } }) => (
            <Autocomplete
              fullWidth
              id="org"
              options={orgNamesData?.data ?? []}
              onChange={(_, value) => onChange(value)}
              {...rest}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Organization"
                  sx={styles.selectField}
                />
              )}
            />
          )}
        />
        <Controller
          name="metrics_level"
          control={control}
          render={({ field: { onChange, ...rest } }) => (
            <Autocomplete
              fullWidth
              id="metrics-level"
              options={metricsLevelData?.data ?? []}
              onChange={(_, value) => onChange(value)}
              {...rest}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Metrics Level"
                  sx={styles.selectField}
                />
              )}
            />
          )}
        />
        {['SITE', 'IMSI', 'APN', 'VLAN', 'NODE'].includes(metrics_level) && (
          <Controller
            name="site"
            control={control}
            render={({ field: { onChange, ...rest } }) => (
              <Autocomplete
                fullWidth
                id="site"
                options={siteNamesData?.data ?? []}
                onChange={(_, value) => onChange(value)}
                {...rest}
                renderInput={(params) => (
                  <TextField {...params} label="Site" sx={styles.selectField} />
                )}
              />
            )}
          />
        )}
        {metrics_level === 'APN' && (
          <Controller
            name="apn"
            control={control}
            render={({ field: { onChange, ...rest } }) => (
              <Autocomplete
                fullWidth
                id="apn"
                options={apnNamesData?.data ?? []}
                onChange={(_, value) => onChange(value)}
                {...rest}
                renderInput={(params) => (
                  <TextField {...params} label="APN" sx={styles.selectField} />
                )}
              />
            )}
          />
        )}
        {metrics_level === 'IMSI' && (
          <Controller
            name="imsi"
            control={control}
            render={({ field: { onChange, ...rest } }) => (
              <Autocomplete
                fullWidth
                id="imsi"
                options={imsiNamesData?.data ?? []}
                onChange={(_, value) => onChange(value)}
                {...rest}
                renderInput={(params) => <TextField {...params} label="IMSI" />}
              />
            )}
          />
        )}
        {metrics_level === 'VLAN' && (
          <Controller
            name="vlan"
            control={control}
            render={({ field: { onChange, ...rest } }) => (
              <Autocomplete
                fullWidth
                id="vlan"
                options={vlanNamesData?.data ?? []}
                onChange={(_, value) => onChange(value)}
                {...rest}
                renderInput={(params) => <TextField {...params} label="VLAN" />}
              />
            )}
          />
        )}
        {metrics_level === 'NODE' && (
          <Controller
            name="node"
            control={control}
            render={({ field: { onChange, ...rest } }) => (
              <Autocomplete
                fullWidth
                id="node"
                options={nodeNamesData?.data ?? []}
                onChange={(_, value) => onChange(value)}
                {...rest}
                renderInput={(params) => <TextField {...params} label="NODE" />}
              />
            )}
          />
        )}
      </Box>
    </WidgetFieldsContainer>
  );
};
