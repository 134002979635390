/* eslint-disable @typescript-eslint/no-var-requires */
const alertType = require('./alert.svg').default as string;
const boundaryType = require('./boundary.svg').default as string;
const capacityType = require('./capacity.svg').default as string;
const prioritizeType = require('./prioritize.svg').default as string;
const inbound = require('./inbound.svg').default as string;
const outbound = require('./outbound.svg').default as string;

export {
  alertType,
  boundaryType,
  capacityType,
  prioritizeType,
  inbound,
  outbound,
};
