import { Box, Typography } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Main } from 'src/layouts/Main';
import { RulesEngineContext } from 'src/context/RulesEngineContext';
import { Button, Steps } from 'src/components';
import { Map } from './Map';
import { SelectNetworks } from './SelectNetworks';
import { SelectEquipment } from './SelectEquipment';
import { AppContext } from 'src/context';
import { Breadcrumbs } from 'src/components/Breadcrumbs/index';

const steps = [
  {
    title: 'Type',
    value: 'type',
  },
  {
    title: 'Selection',
    value: 'selection',
  },
  {
    title: 'Specification',
    value: 'specification',
  },
];

interface RulesSelectionProps {
  onCancel: () => void;
}

export const RulesSelection: React.FC<RulesSelectionProps> = ({ onCancel }) => {
  const [, setParams] = useSearchParams();
  const {
    selectedAPNs,
    selectedEquipments,
    ruleType,
    resetGeneralInfo,
    removeAllAPNs,
    removeAllEquipments,
  } = useContext(RulesEngineContext);
  const { siteChanged } = useContext(AppContext);

  useEffect(() => {
    if (!ruleType) setParams({ step: 'type' });
  }, [ruleType, setParams]);

  useEffect(() => {
    if (siteChanged) {
      resetGeneralInfo();
      removeAllAPNs();
      removeAllEquipments();
      setParams({ step: 'type' });
    }
  }, [siteChanged]);

  const showTitle = (type: string) => {
    switch (type) {
      case 'GEO_FENCE':
        return 'Geofence';
      case 'THRESHOLD':
        return 'Threshold';
      case 'PRIORITIZATION':
        return 'Prioritization';
      case 'LATENCY':
        return 'Latency';
      default:
        return null;
    }
  };

  const showNextStepButton = () => {
    if (ruleType === 'THRESHOLD') {
      return selectedAPNs.length === 0 && selectedEquipments.length === 0;
    }
    if (ruleType === 'CAPACITY') {
      return selectedAPNs.length === 0;
    }
    return selectedAPNs.length === 0 || selectedEquipments.length === 0;
  };
  const isNextStepDisabled = showNextStepButton();

  return (
    <Main overrideStyles={{ padding: 0 }} renderOutsideWrapper={<Map />}>
      <Box
        id='selection-container'
        display='flex'
        width='70%'
        sx={{
          height: 'calc(100vh - 50px)',
          boxShadow: '8px 0 5px -3px rgb(0 0 0 / 25%)',
          clipPath: 'inset(0px -15px 0px 0px)',
          position: 'relative',
          zIndex: 1,
          overflowY: 'auto',
          padding: '24px',
        }}
      >
        <Box sx={{ width: '609px' }}>
          <Breadcrumbs
            breadcrumbs={[
              {
                title: 'Overview',
                url: '/',
              },
              {
                title: 'Rules',
                url: '/rules',
              },
              {
                title: 'Create a rule',
                url: '/rule?step=type',
              },
              {
                title: 'Selection',
              },
            ]}
          />
          <Typography variant='h1' sx={{ mb: '26px' }}>
            {showTitle(ruleType)}
          </Typography>
          <Steps
            activeStep={1}
            steps={steps}
            onClick={(step) => setParams({ step: step.toString() })}
          />
          {ruleType === 'CAPACITY' ? (
            <SelectNetworks />
          ) : (
            <>
              <SelectNetworks />
              <SelectEquipment />
            </>
          )}
          <Box mt='50px' mb='43px' display='flex' justifyContent='flex-end'>
            <Button kind='primary-ghost' onClick={onCancel}>
              Cancel
            </Button>
            <Button
              sx={{ ml: '15px' }}
              kind='primary'
              disabled={isNextStepDisabled}
              onClick={() => setParams({ step: 'specification' })}
            >
              Next step
            </Button>
          </Box>
          <Box height={20} />
        </Box>
      </Box>
    </Main>
  );
};
