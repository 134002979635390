import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Tabs } from 'src/components';
import { Main } from 'src/layouts/Main';
import { DeviceNetworkListing } from './DevicesNetwork';
import { DevicesTable } from './DevicesTable';
import { Breadcrumbs } from 'src/components/Breadcrumbs/index';
import { Upload } from '@carbon/icons-react';
import { styles } from 'src/views/DeviceManagement/Listing/styles';
import { BulkAllocateDevicesModal } from './BulkAllocateDevices';
import { useUserRole } from 'src/utils/user';

const menuOption = [
  {
    label: 'EQUIPMENT',
    value: 'Equipment',
  },
  {
    label: 'NETWORK',
    value: 'Network',
  },
];

export const DeviceListing: React.FC = () => {
  const navigate = useNavigate();
  const { isAdminUser } = useUserRole();

  const [selectedTab, setSelectedTab] = useState('Equipment');
  const [bulkAllocateDevicesModal, setBulkAllocateDevicesModal] =
    useState(false);
  const handleChange = (tab: string) => {
    setSelectedTab(tab);
  };
  const handleBulkAllocateDevicesModal = () => {
    setBulkAllocateDevicesModal((prev) => !prev);
  };

  return (
    <>
      <Main>
        <Breadcrumbs
          breadcrumbs={[
            {
              title: 'Overview',
              url: '/',
            },
            {
              title: 'Device Management',
            },
          ]}
        />
        <Box
          sx={{
            marginBottom: '0',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingBottom: '10px',
          }}
        >
          <Typography variant='h1'>Device Management</Typography>
          <Button kind='primary' onClick={() => navigate('/networks')}>
            Manage Network
          </Button>
        </Box>
        {isAdminUser() && (
          <Box sx={styles.uploadBox}>
            <Upload style={styles.uploadIcon} />
            <Typography
              sx={styles.uploadDeviceText}
              onClick={handleBulkAllocateDevicesModal}
            >
              Upload device file
            </Typography>
          </Box>
        )}
        <BulkAllocateDevicesModal
          openModal={bulkAllocateDevicesModal}
          closeModal={handleBulkAllocateDevicesModal}
        />
      </Main>
      <Tabs
        value={selectedTab}
        action={(tab) => {
          handleChange(tab);
        }}
        options={menuOption}
      />
      <Main
        hideSidebar
        hideHeader
        overrideStyles={{ marginTop: 'unset', paddingBottom: '34px' }}
      >
        {selectedTab === 'Equipment' ? (
          <DevicesTable />
        ) : (
          <DeviceNetworkListing />
        )}
      </Main>
    </>
  );
};
