import { Box, Typography } from '@mui/material';
import { Main } from 'src/layouts/Main';
import { useQuery } from '@tanstack/react-query';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Button, Chip, Loading } from 'src/components';
import { styles } from './styles';
import { QueryName } from 'src/enums/query';
import { getUEDetails } from 'src/apis/ue';
import { useNavigate, useParams } from 'react-router-dom';
import { AppContext, AuthContext } from 'src/context';
import Map_, { MapRef, Marker } from 'react-map-gl';
import { FmdGood } from '@mui/icons-material';
import { Breadcrumbs } from 'src/components/Breadcrumbs/index';
import { TrendChart } from './TrendChart';

const initialViewState = {
  latitude: 54.61745492998754,
  longitude: -5.905370495296432,
  zoom: 10,
};

export const ViewDevice: React.FC = () => {
  const { user } = useContext(AuthContext);
  const { sidebarOpen } = useContext(AppContext);
  const navigate = useNavigate();
  const mapRef = useRef<MapRef>(null);

  const [viewport, setViewport] = useState({
    latitude: 54.61745492998754,
    longitude: -5.905370495296432,
    flag: false,
  });

  const { organization, site, ueid } = useParams<{
    organization: string;
    site: string;
    ueid: string;
  }>();

  const { data, isLoading, isFetching } = useQuery(
    [QueryName.GET_DEVICE_DETAILS, ueid],
    () => getUEDetails(organization as string, site as string, ueid as string),
    { enabled: !!organization && !!site && !!ueid },
  );

  const getOwnerName = useCallback(() => {
    let name = '';
    if (!!data?.data.ownerFirstName || !!data?.data.ownerLastName) {
      if (data?.data.ownerLastName) {
        name += `${data.data.ownerLastName}`;
      }
      if (data?.data.ownerFirstName) {
        name += ` ${data.data.ownerFirstName}`;
      }
      if (user?.email === data?.data.ownerEmail) {
        return `Me (${name.trim()})`;
      }
      return name.trim();
    } else {
      return '-';
    }
  }, [data?.data, user?.email]);

  useEffect(() => {
    if (data) {
      if (data.data.longitude && data.data.latitude) {
        setViewport({
          longitude: data.data.longitude,
          latitude: data.data.latitude,
          flag: true,
        });
      }
    }
  }, [data]);

  useEffect(() => {
    setTimeout(() => {
      mapRef.current
        ?.setCenter({
          lat: viewport.latitude,
          lng: viewport.longitude,
        })
        .zoomTo(13);
    }, 300);
  }, [viewport]);

  useEffect(() => {
    setTimeout(() => {
      mapRef.current?.resize();
    }, 0);
  }, [sidebarOpen]);

  return (
    <Main overrideStyles={{ padding: 0 }}>
      <Loading isLoading={isLoading || isFetching}>
        <Box
          id='selection-container'
          display='flex'
          width='50%'
          sx={styles.shadowBox}
        >
          <Box sx={styles.textBox}>
            <Breadcrumbs
              breadcrumbs={[
                {
                  title: 'Overview',
                  url: '/',
                },
                {
                  title: 'Device management',
                  url: '/devices',
                },
                {
                  title: String(
                    data?.data.name ? data?.data.name : data?.data.ueid,
                  ),
                },
              ]}
            />

            <Box display='flex' justifyContent='space-between'>
              <Box
                display='flex'
                alignItems='flex-start'
                flexDirection='column'
                width='100%'
              >
                <Box display='flex' justifyContent='space-between' width='100%'>
                  <Box>
                    <Typography variant='h1'>
                      {data?.data.ueid}{' '}
                      {data?.data.type ? ' | ' + String(data?.data.type) : ''}
                      <Chip
                        sx={styles.chipBtn}
                        kind={
                          data?.data.isOnline === true ? 'primary' : 'default'
                        }
                        variant='outlined'
                        label={
                          data?.data.isOnline === true ? 'ACTIVE' : 'INACTIVE'
                        }
                      />
                    </Typography>
                  </Box>
                  <Button
                    kind='primary'
                    sx={{ marginRight: '45px', height: '33px' }}
                    onClick={() =>
                      navigate(`/devices/edit/${organization}/${site}/${ueid}`)
                    }
                  >
                    Edit
                  </Button>
                </Box>

                <TrendChart
                  trendData={[
                    {
                      xvalue: 'Thu 04',
                      time: '2023-05-04T23:59:59Z',
                      value: 60,
                    },
                    {
                      xvalue: 'Fri 05',
                      time: '2023-05-05T23:59:59Z',
                      value: 75,
                    },
                    {
                      xvalue: 'Sat 06',
                      time: '2023-05-06T23:59:59Z',
                      value: 54,
                    },
                    {
                      xvalue: 'Sun 07',
                      time: '2023-05-07T23:59:59Z',
                      value: 89,
                    },
                    {
                      xvalue: 'Mon 08',
                      time: '2023-05-08T23:59:59Z',
                      value: 34,
                    },
                    {
                      xvalue: 'Tue 09',
                      time: '2023-05-09T23:59:59Z',
                      value: 56,
                    },
                    {
                      xvalue: 'Wed 10',
                      time: '2023-05-10T23:59:59Z',
                      value: 87,
                    },
                  ]}
                />

                <Typography variant='h3' mt='40px' mb='15px'>
                  Equipment details
                </Typography>
                <Box width='100%' display='flex'>
                  <Box width='50%'>
                    <Typography fontWeight='bold'>UE type</Typography>
                    <Typography>{data?.data.type ?? '-'}</Typography>
                  </Box>
                  <Box width='50%'>
                    <Typography fontWeight='bold'>Object name</Typography>
                    <Typography>{data?.data.name ?? '-'}</Typography>
                  </Box>
                </Box>

                <Box width='100%' display='flex' mt='16px'>
                  <Box width='50%'>
                    <Typography fontWeight='bold'>Brand</Typography>
                    <Typography>{data?.data.brand ?? '-'}</Typography>
                  </Box>
                  <Box width='50%'>
                    <Typography fontWeight='bold'>Model</Typography>
                    <Typography>{data?.data.model ?? '-'}</Typography>
                  </Box>
                </Box>
                <Box width='100%' display='flex' mt='16px'>
                  <Box width='50%'>
                    <Typography fontWeight='bold'>Serial number</Typography>
                    <Typography>{data?.data.serialNumber ?? '-'}</Typography>
                  </Box>
                  <Box width='50%'>
                    <Typography fontWeight='bold'>Owner</Typography>
                    <Typography>{getOwnerName()}</Typography>
                  </Box>
                </Box>

                <Box width='100%' display='flex' mt='30px'>
                  <Box width='50%'>
                    <Typography variant='h3' mb='14px'>
                      Relationships
                    </Typography>
                    <Typography fontWeight='bold'>Groups</Typography>
                    <Typography>
                      {data?.data.ueGroups?.length
                        ? data?.data.ueGroups.join(', ')
                        : '-'}
                    </Typography>

                    <Typography fontWeight='bold' mt='16px'>
                      Watchdogs
                    </Typography>
                    <Typography>
                      {data?.data.linkedWatchDogs?.length
                        ? data?.data.linkedWatchDogs?.join(', ')
                        : '-'}
                    </Typography>
                  </Box>
                  <Box width='50%'>
                    <Typography variant='h3' mb='14px'>
                      Networks
                    </Typography>
                    <Typography fontWeight='bold'>APN</Typography>
                    <Typography>{data?.data.apn ?? '-'}</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          id='selection-container'
          display='flex'
          width='50%'
          sx={styles.mapContainer}
        >
          <Map_
            ref={mapRef}
            initialViewState={initialViewState}
            mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN_2}
            mapStyle='mapbox://styles/mapbox/satellite-streets-v9'
            style={{
              width: '100%',
              height: '100%',
            }}
          >
            <Marker
              longitude={viewport.longitude}
              latitude={viewport.latitude}
              anchor='bottom'
            >
              <FmdGood sx={styles.marker} />
            </Marker>
          </Map_>
        </Box>
      </Loading>
    </Main>
  );
};
