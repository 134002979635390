import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { useCallback } from 'react';
import {
  Box,
  InputAdornment,
  OutlinedInput,
  Typography,
  Stack,
} from '@mui/material';
import {
  StyledCoverageSlider,
  styles,
} from 'src/views/DeviceManagement/ManageNetwork/EditNetwork/styles';

type AreaControlProps = {
  onCoordinatesChange: (coordinates: {
    longitude: number;
    latitude: number;
  }) => void;
  radius: number;
  onRadiusChange: (radius: number) => void;
};

export const AreaControl: React.FC<AreaControlProps> = ({
  onCoordinatesChange,
  radius,
  onRadiusChange,
}) => {
  const setRef = useCallback(
    (ref: HTMLDivElement | null) => {
      if (ref == null) {
        return;
      }
      if (ref.firstChild) {
        ref.removeChild(ref.firstChild);
      }

      const ctrl = new MapboxGeocoder({
        marker: false,
        accessToken: process.env.REACT_APP_MAPBOX_TOKEN_2 as string,
      });

      ctrl.on('result', (event) => {
        const coordinates = event?.result?.geometry?.coordinates;
        if (coordinates && coordinates.length === 2) {
          onCoordinatesChange({
            longitude: coordinates[0],
            latitude: coordinates[1],
          });
        }
      });

      ctrl.addTo(ref);
    },
    [onCoordinatesChange],
  );

  return (
    <Box
      className='select-area-control'
      sx={{
        display: 'flex',
        paddingTop: '15px',
      }}
    >
      <div className='geocoder' ref={setRef}></div>
      <div className='radius-selector'>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={styles.coverageAreaTitle}>Coverage radius</Typography>
          <OutlinedInput
            id='outlined-adornment-weight'
            label='Radius'
            sx={{ width: '85px', height: '30px' }}
            value={radius}
            endAdornment={<InputAdornment position='end'>m</InputAdornment>}
            onChange={(event) => onRadiusChange(+event.target.value)}
          />
        </Box>
        <Stack
          spacing={2}
          direction='row'
          sx={{ mb: 1, paddingLeft: '25px' }}
          alignItems='center'
        >
          <Typography sx={styles.radiusLimits}>10m</Typography>
          <StyledCoverageSlider
            aria-label='Radius'
            value={radius}
            max={500}
            sx={{ width: '200px' }}
            onChange={(event, value) => onRadiusChange(value as number)}
          />
          <Typography sx={styles.radiusLimits}>500m</Typography>
        </Stack>
      </div>
    </Box>
  );
};
