export const styles = {
  selectField: {
    mb: 2,
  },
  selectTypeHeading: {
    mt: 3,
  },
  widgetTypeButtonContainer: {
    mx: '10px',
  },
  widgetTypeButtonIcon: {
    color: '#073B4C',
    fontSize: '34px',
  },
};
