import { ApiResponseType } from 'src/types/api';
import { api } from 'src/utils/axios';
import { BulkAction } from 'src/enums/bulkActions';
import { AxiosProgressEvent } from 'axios';
import { Dispatch, SetStateAction } from 'react';
import { UserDetails, UsersList } from 'src/types/user';

export const getAllUserEmails = (org: string) => {
  return api.get<ApiResponseType<string[]>>(
    '/accessmanagement/v1/user/id/list',
    {
      params: {
        organization: org,
      },
    },
  );
};

export const getAllRoles = () => {
  return api.get<ApiResponseType<string[]>>('/accessmanagement/v1/role/list');
};

export const getAllUsers = (
  organization?: string,
  site?: string,
  role?: string,
  groupName?: string,
  searchText?: string,
  pageStart?: number,
  pageEnd?: number,
) => {
  return api.get<ApiResponseType>('/accessmanagement/v1/user/list', {
    params: {
      organization,
      site,
      searchText,
      role,
      groupName,
    },
    headers: {
      pageStart,
      pageEnd,
    },
  });
};

export const getUsersNameList = (
  organization: string,
  fetchPolicyInfo?: boolean,
) => {
  return api.get<ApiResponseType<{ users: UsersList[] }>>(
    '/accessmanagement/v1/user/name/list',
    {
      params: {
        organization,
        fetchPolicyInfo,
      },
    },
  );
};

export const bulkActionUsers = ({
  selectedUserIds,
  BulkAction,
}: {
  selectedUserIds: string[];
  BulkAction: BulkAction;
}) => {
  return api.post<ApiResponseType>('accessmanagement/v1/bulk', {
    action: BulkAction,
    users: selectedUserIds,
  });
};

export const uploadBulkUsers = ({
  file,
  onProgress,
}: {
  file: File;
  onProgress: Dispatch<SetStateAction<number>>;
}) => {
  const formData = new FormData();
  formData.append('file', file);

  if (file) {
    formData.append('file', file);
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress: (progressEvent: AxiosProgressEvent) => {
      const progress =
        (progressEvent.loaded / (progressEvent.total ?? 1)) * 100;
      onProgress(progress);
    },
  };
  return api.post<ApiResponseType<string[]>>(
    '/accessmanagement/v1/bulk/user',
    formData,
    config,
  );
};

export const getReport = async (path: string) => {
  return await api.post(
    `/accessmanagement/v1/bulk/report?filePath=${path}`,
    {},
    {
      responseType: 'blob',
    },
  );
};

export const getUserDetails = (userId: string) => {
  return api.get<ApiResponseType<UserDetails>>('/accessmanagement/v1/user', {
    params: {
      userId,
    },
  });
};

export const editUser = ({
  uuid,
  userId,
  firstName,
  lastName,
  enabled,
  groupName,
  roles,
  password,
  organizations,
  sites,
  createDate,
  lastAccess,
  fullName,
}: {
  uuid: string;
  userId: string;
  firstName: string;
  lastName: string;
  enabled: boolean;
  groupName: string;
  roles: string[];
  password?: string;
  organizations: string[];
  sites: string[];
  createDate: number;
  lastAccess: number;
  fullName: string;
}) => {
  return api.put('/accessmanagement/v1/user', {
    uuid,
    userId,
    firstName,
    lastName,
    enabled,
    groupName,
    roles,
    password,
    organizations,
    sites,
    createDate,
    lastAccess,
    fullName,
  });
};

export const createUser = ({
  userId,
  firstName,
  lastName,
  password,
  enabled,
  roles,
  organizations,
  sites,
  createDate,
  lastAccess,
}: {
  userId: string;
  firstName: string;
  lastName: string;
  password: string;
  enabled: boolean;
  roles: string[];
  organizations?: string[];
  sites?: string[];
  createDate: number;
  lastAccess: string;
}) => {
  const url = '/accessmanagement/v1/user';
  return api.post(url, {
    userId,
    firstName,
    lastName,
    password,
    enabled,
    roles,
    organizations,
    sites,
    createDate,
    lastAccess,
  });
};

export const deleteUser = (userId: string) => {
  return api.delete('/accessmanagement/v1/user', {
    params: {
      userId,
    },
  });
};
export const changePassword = ({
  uuid,
  userId,
  currentPassword,
  newPassword,
}: {
  uuid: string;
  userId: string;
  currentPassword: string;
  newPassword: string;
}) => {
  return api.patch('/accessmanagement/v1/user/password', {
    uuid,
    userId,
    currentPassword,
    newPassword,
  });
};
