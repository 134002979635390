import { ErrorTypes } from 'src/enums/errors';

export const getErrorMessage = (error: { message: string }) => {
  let message;
  switch (error.message) {
    case 'Request failed with status code 404':
      message = ErrorTypes.USER_NOT_EXIST;
      break;
    case 'Request failed with status code 500':
      message = ErrorTypes.INTERNAL_SERVER_ERROR;
      break;
    case 'Request failed with status code 401':
      message = ErrorTypes.CREDENTIAL_ERROR;
      break;
    case 'Request failed with status code 403':
      message = ErrorTypes.DISABLED_USER;
      break;
    case 'Request failed with status code 409':
      message = ErrorTypes.DUPLICATE_USER;
      break;
    default:
      message = ErrorTypes.FAILED;
      break;
  }
  return message;
};
