import { Box, Typography } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

import { Steps } from 'src/components';
import { Main } from 'src/layouts/Main';
import { GeneralInformation } from '../GeneralInformation';
import { PrioritizeEquipments } from '../PrioritizeEquipments';
import { SelectedNetworks } from '../SelectedNetworks';
import { Breadcrumbs } from 'src/components/Breadcrumbs/index';
import React from 'react';

const steps = [
  {
    title: 'Type',
    value: 'type',
  },
  {
    title: 'Selection',
    value: 'selection',
  },
  {
    title: 'Specification',
    value: 'specification',
  },
];

interface SpecificationPrioritizationProps {
  onCancel: () => void;
}

export const SpecificationPrioritization: React.FC<
  SpecificationPrioritizationProps
> = ({ onCancel }) => {
  const [, setParams] = useSearchParams();

  return (
    <Main>
      <Breadcrumbs breadcrumbs={[
        {
          title: 'Overview',
          url: '/',
        },
        {
          title: 'Rules',
          url: '/rules',
        },
        {
          title: 'Create a rule',
          url: '/rule?step=type',
        },
        {
          title: 'Selection',
          url: '/rule?step=selection',
        },
        {
          title: 'Specification',
        },
      ]}/>
      <Typography variant='h1' sx={{ mb: '26px' }}>
        Prioritization
      </Typography>
      <Box display='flex' justifyContent='center'>
        <Box sx={{ width: '700px' }}>
          <Steps
            activeStep={2}
            steps={steps}
            onClick={(step) => setParams({ step: step.toString() })}
          />
        </Box>
      </Box>
      <Box display='flex' justifyContent='space-between'>
        <Box>
          <SelectedNetworks />
          <PrioritizeEquipments />
        </Box>
        <GeneralInformation onCancel={onCancel} />
      </Box>
    </Main>
  );
};
