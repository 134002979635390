import { Close } from '@carbon/icons-react';
import {
  DialogTitle,
  IconButton,
  Typography,
  RadioGroup,
  Box,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { Radio } from 'src/components';
import { AddOrg } from './AddOrg';
import { AddSite } from './AddSite';
import { AddEndpoint } from './AddEndpoint';
import { StyledDialog, styles } from './styles';
import { useUserRole } from 'src/utils/user';

interface AddElementProps {
  open: boolean;
  onCancel: () => void;
  elementsToManage?: string;
  organizationName?: string;
  siteName?: string;
  endpointName?: string;
}

export const AddElement: React.FC<AddElementProps> = ({
  open,
  onCancel,
  elementsToManage,
  organizationName,
  siteName,
  endpointName,
}) => {
  const { isSuperUser, isAdminUser } = useUserRole();
  const [selectedAction, setSelectedAction] = useState<string | undefined>(
    elementsToManage,
  );

  useEffect(() => {
    if (elementsToManage) {
      setSelectedAction(elementsToManage);
      return;
    }
    if (isSuperUser() && !elementsToManage) {
      setSelectedAction('site');
      return;
    }
    if (isAdminUser() && !elementsToManage) {
      setSelectedAction('endpoint');
    }
  }, []);

  const renderAddElement = useCallback(() => {
    switch (elementsToManage ?? selectedAction) {
      case 'organization':
        return (
          <AddOrg onCancel={onCancel} organizationName={organizationName} />
        );
      case 'site':
        return (
          <AddSite
            onCancel={onCancel}
            organizationName={organizationName}
            siteName={siteName}
          />
        );
      case 'endpoint':
        return (
          <AddEndpoint
            onCancel={onCancel}
            organizationName={organizationName}
            siteName={siteName}
            endpointName={endpointName}
          />
        );
    }
  }, [
    elementsToManage,
    endpointName,
    onCancel,
    organizationName,
    selectedAction,
    siteName,
  ]);

  return (
    <StyledDialog open={open}>
      <DialogTitle>
        <Typography fontSize='16px' fontWeight='bold'>
          {elementsToManage ? 'Edit an element' : 'Add an element'}
        </Typography>
        <IconButton sx={styles.closeIcon} onClick={onCancel}>
          <Close size='24' color='#222222' />
        </IconButton>
        {organizationName ? (
          ''
        ) : (
          <>
            <Box mt='24px'>
              <Typography variant='h3' fontSize='14px'>
                Type
              </Typography>
            </Box>
            <Box pt='6px'>
              <RadioGroup
                row
                defaultValue={
                  elementsToManage ?? isSuperUser() ? 'site' : 'endpoint'
                }
                onChange={(_, value) => {
                  setSelectedAction(value);
                }}
              >
                {!isSuperUser() && <Radio label='Endpoint' value='endpoint' />}
                {isSuperUser() && (
                  <>
                    <Radio label='Site' value='site' />
                    <Radio label='Organization' value='organization' />
                  </>
                )}
              </RadioGroup>
            </Box>
          </>
        )}
      </DialogTitle>
      {renderAddElement()}
    </StyledDialog>
  );
};
