import { Box, Typography } from '@mui/material';
import { Main } from 'src/layouts/Main';
import { RuleConfiguration } from 'src/types/rule';
import { Coordinates } from 'src/types/coordinates';
import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { Map } from './Map';
import { styles } from './styles';
import { getCoordinates } from 'src/apis/geo-service';
import { ToastContext } from 'src/context';
import { useContext, useEffect } from 'react';
import { RuleNotification } from './Notification';

interface PrioritizationProps {
  ruleConfiguration: RuleConfiguration;
  readMoreShown: boolean;
}

export const PrioritizationType: React.FC<PrioritizationProps> = ({
  ruleConfiguration,
  readMoreShown,
}) => {
  const { showToast } = useContext(ToastContext);
  const getUEType = (id: string) => {
    if (ruleConfiguration.ueGroups !== null) {
      return id in ruleConfiguration.ueGroups
        ? 'Group of equipment'
        : 'Equipment';
    }
  };
  const getNames = (id: string) => {
    if (
      ruleConfiguration.ueNames !== null &&
      ruleConfiguration.ues?.includes(id)
    ) {
      return ruleConfiguration.ueNames[
        id as keyof typeof ruleConfiguration.ueNames
      ];
    }
    if (
      ruleConfiguration.ueGroups !== null &&
      !ruleConfiguration.ues?.includes(id)
    ) {
      return ruleConfiguration.ueGroups[
        id as keyof typeof ruleConfiguration.ueGroups
      ];
    }
    return id;
  };

  const { mutate, data } = useMutation<
    AxiosResponse<Coordinates>,
    AxiosError,
    {
      organization: string;
      site: string;
      apns: string[];
      ues: string[];
      ueGroups: string[];
    }
  >({
    mutationFn: getCoordinates,
    onError: () => {
      showToast('Unable to fetch the coordinates, please try again!', 'error');
    },
  });
  useEffect(() => {
    mutate({
      organization: ruleConfiguration.organization,
      site: ruleConfiguration.site,
      apns: ruleConfiguration.apns,
      ues: ruleConfiguration.ues,
      ueGroups: ruleConfiguration.ueGroups
        ? Object.values(ruleConfiguration.ueGroups)
        : [],
    });
  }, [mutate, ruleConfiguration]);

  return (
    <Main
      hideHeader
      hideSidebar
      overrideStyles={{ padding: 0, marginTop: 'unset' }}
      renderOutsideWrapper={
        <Map data={data?.data as Coordinates} readMoreShown={readMoreShown} />
      }
    >
      <Box
        id='selection-container'
        display='flex'
        width='50%'
        sx={{
          height: 'calc(100vh - 50px)',
          padding: '24px',
          overflowY: 'auto',
          boxShadow: '8px 0 5px -3px rgb(0 0 0 / 25%)',
          zIndex: 1,
          position: 'relative',
        }}
      >
        <Box sx={{ width: '600px' }}>
          <Box display='flex' justifyContent='space-between'>
            <Box
              display='flex'
              alignItems='flex-start'
              flexDirection='column'
              width='60%'
            >
              <Typography variant='h2' pt='11px'>
                Parameters
              </Typography>
              <Typography variant='h3' mt='14px' mb='11px'>
                Prioritization
              </Typography>
              {ruleConfiguration.priorityMap &&
                Object.entries(ruleConfiguration.priorityMap).map(
                  ([key, priorityValue]) => (
                    <Box
                      key={key}
                      display='flex'
                      alignItems='flex-start'
                      pb='6px'
                    >
                      <Typography sx={styles.priorityKey}>{key}</Typography>
                      <Typography variant='h4' mt='1px'>
                        {getNames(priorityValue)}
                      </Typography>
                      <Typography variant='body2' ml='8px'>
                        {getUEType(priorityValue)}
                      </Typography>
                    </Box>
                  ),
                )}
            </Box>
            <Box width='40%'>
              <Typography variant='h3' mt='52px' mb='10px'>
                Network concerned
              </Typography>
              {ruleConfiguration.apns?.map((apn) => (
                <Typography key={apn} variant='body1'>
                  {apn}
                </Typography>
              ))}
            </Box>
          </Box>
          {(ruleConfiguration.notification?.email ||
            ruleConfiguration.notification?.slack ||
            ruleConfiguration.notification?.teams) && (
            <RuleNotification notification={ruleConfiguration.notification} />
          )}
        </Box>
      </Box>
    </Main>
  );
};
