export const kpiCurrentWidgetDefaultPosition = {
  x: 0,
  y: 0,
  h: 1,
  w: 2,
};

export const kpiTimeSeriesWidgetDefaultPosition = {
  x: 0,
  y: 0,
  h: 2,
  w: 2,
};

export const widgetRules = {
  KPI: {
    CURRENT: {
      minW: 2,
      minH: 1,
      maxH: 1,
      isResizable: true,
    },
    RANGE: {
      minW: 2,
      minH: 1,
      isResizable: true,
    },
  },
  ALARM: {
    CURRENT: {
      minW: 2,
      minH: 1,
      maxH: 1,
      isResizable: true,
    },
    RANGE: {
      minW: 2,
      minH: 1,
      isResizable: true,
    },
  },
  WEATHER: {
    CURRENT: {
      minW: 2,
      minH: 1,
      maxH: 1,
      isResizable: true,
    },
    RANGE: {
      minW: 2,
      minH: 1,
      isResizable: true,
    },
  },
  TIME: {
    CURRENT: {
      minW: 2,
      minH: 1,
      maxH: 1,
      isResizable: true,
    },
  },
};
