import { ChevronLeft, ChevronRight } from '@carbon/icons-react';
import { Box, IconButton, Typography } from '@mui/material';

import { Breadcrumbs } from 'src/components/Breadcrumbs';
import { Main } from 'src/layouts/Main';
import { useCallback, useEffect, useState } from 'react';
import { add, format, isSameMonth, isValid, sub } from 'date-fns';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Tabs } from 'src/components';
import { Usage } from './Usage';
import { Policies } from './Policies';

export const UsageDashboard: React.FC = () => {
  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState('usage');
  const [params, setParams] = useSearchParams({
    date: new Date().toDateString(),
  });

  const selectedDate = params.get('date');

  const prevMonth = useCallback(() => {
    if (selectedDate) {
      const prevDate = new Date(selectedDate);
      setParams({
        date: sub(prevDate, { months: 1 }).toDateString(),
      });
    }
  }, [selectedDate, setParams]);

  const nextMonth = useCallback(() => {
    if (selectedDate) {
      const prevDate = new Date(selectedDate);
      setParams({
        date: add(prevDate, { months: 1 }).toDateString(),
      });
    }
  }, [selectedDate, setParams]);

  const nextMonthButtonDisabled =
    !!selectedDate && isSameMonth(new Date(selectedDate), new Date());

  useEffect(() => {
    if (selectedDate && !isValid(new Date(selectedDate))) {
      setParams({
        date: new Date().toDateString(),
      });
    }
  }, [selectedDate, setParams]);

  return (
    <>
      <Main>
        <Breadcrumbs
          breadcrumbs={[
            { title: 'Overview', url: '/' },
            { title: 'Usage policy' },
          ]}
        />
        <Box display='flex' justifyContent='space-between'>
          <Typography variant='h1'>Usage policy</Typography>
          <Box display='flex'>
            <Box display='flex' alignItems='center'>
              <IconButton onClick={prevMonth}>
                <ChevronLeft color='#006161' />
              </IconButton>
              <Typography variant='h2' mx={3}>
                {selectedDate &&
                  isValid(new Date(selectedDate)) &&
                  format(new Date(selectedDate), 'MMMM yyyy')}
              </Typography>
              <IconButton
                disabled={nextMonthButtonDisabled}
                onClick={nextMonth}
              >
                <ChevronRight
                  color={nextMonthButtonDisabled ? '#888888' : '#006161'}
                />
              </IconButton>
            </Box>
            <Button
              kind='primary'
              sx={{ ml: '20px' }}
              onClick={() => navigate('/create-usage-policy')}
            >
              Create a policy
            </Button>
          </Box>
        </Box>
      </Main>
      <Tabs
        value={selectedTab}
        options={[
          { label: 'USAGE', value: 'usage' },
          { label: 'POLICIES', value: 'policies' },
        ]}
        action={(tab) => setSelectedTab(tab)}
      />
      <Main hideHeader hideSidebar overrideStyles={{ marginTop: 0 }}>
        {selectedTab === 'usage' && <Usage />}
        {selectedTab === 'policies' && <Policies />}
      </Main>
    </>
  );
};
