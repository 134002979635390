import * as Yup from 'yup';

export const createGroupSchema = Yup.object().shape({
  group_site: Yup.string().required(),
  group_name: Yup.string()
    .matches(
      /^[a-zA-Z0-9-_ ]*$/,
      'Text should only contain lowercase, uppercase letters, numbers and (-_)',
    )
    .max(50)
    .when('group_selected', {
      is: '0',
      then: Yup.string().required('Please enter a valid group name'),
    }),
  group_selected: Yup.string().required('Please choose a group from the list'),
  group_ues: Yup.array().of(Yup.string()).min(1).required(),
});

export const createUserGroupSchema = Yup.object().shape({
  groupSelected: Yup.string().required('Please choose a group from the list'),
  groupName: Yup.string().when('groupSelected', {
    is: 'New Group',
    then: Yup.string()
      .required('Group Name is required')
      .not(['New Group'], 'Please enter a different name'),
  }),
});
