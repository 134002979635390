import { api } from 'src/utils/axios';
import { ApiResponseType } from 'src/types/api';
import { Apn, UpdateApn } from 'src/types/domain';

const baseUrl = `${process.env.REACT_APP_BACKEND_URL}`;

export const getApnNamesByOrg = (org: string) => {
  const url = `${baseUrl}topology/v1/metadata/apn/name?organization=${org}`;
  return api.get<ApiResponseType<string[]>>(url);
};
export const getApnDetails = (
  organization: string,
  site: string,
  apn: string,
) => {
  return api.get<ApiResponseType<Apn>>('/topology/v1/apn/',
    {
      params: {
        organization,
        site,
        apn,
      },
    });
};

export const editApn = (data: UpdateApn) => {
  return api.patch(`/topology/v1/apn?apn=${data.apn}`, data);
};

export const getAllApnsData = (
  organization: string,
  site?: string,
  searchText?: string,
  pageStart?: number,
  pageEnd?: number,
) => {
  return api.get<ApiResponseType<{ apns: Apn[]; total: number }>>(
    '/topology/v1/apn/all',
    {
      params: {
        organization,
        site,
        searchText,
      },
      headers: {
        pageStart,
        pageEnd,
      },
    },
  );
};
