import React, { useContext, useState } from 'react';
import {
  Box,
  Divider,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
  Avatar,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { AppContext, AuthContext } from 'src/context';
import { StyledAppBar, stylesHeader } from './styles';
import {
  Logout,
  Notification,
  Settings,
  UserAvatar,
} from '@carbon/icons-react';
import { colors } from 'src/config/theme';
import { useUserRole } from 'src/utils/user';
import { SelectSite } from './SelectSite';
import { UserProfile } from './UserProfile';

export const Header: React.FC = () => {
  const [showProfileModal, setShowProfileModal] = useState(false);
  const { sidebarOpen } = useContext(AppContext);
  const { handleLogout } = useContext(AuthContext);
  const { user } = useContext(AuthContext);
  const { isSuperUser } = useUserRole();

  const fullName = user ? user.given_name + ' ' + user.family_name : '';
  const initialsName = user ? user.given_name[0] + user.family_name[0] : '';
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const openProfileModal = () => {
    setShowProfileModal(true);
  };

  const closeProfileModal = () => {
    setShowProfileModal(false);
  };

  return (
    <>
      <UserProfile
        isModalOpen={showProfileModal}
        closeModal={closeProfileModal}
      />
      <StyledAppBar
        sx={stylesHeader.appBar}
        position='static'
        open={sidebarOpen}
      >
        <Toolbar
          disableGutters
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {!isSuperUser() && <SelectSite />}
            <Divider
              sx={stylesHeader.vertDivider}
              orientation='vertical'
              variant='middle'
              flexItem
            />
            <Tooltip title='Notification'>
              <IconButton
                sx={stylesHeader.notificationButton}
                aria-label='Notification'
                component='label'
                onClick={() => navigate('/')}
              >
                <Notification size={24} />
              </IconButton>
            </Tooltip>
            {!isSuperUser() && (
              <Tooltip title='Settings'>
                <IconButton
                  sx={stylesHeader.settingsButton}
                  aria-label='Settings'
                  component='label'
                  onClick={() => navigate('/settings')}
                >
                  <Settings size={24} />
                </IconButton>
              </Tooltip>
            )}
            <Box sx={stylesHeader.profileBtnBox}>
              <Tooltip title='Account'>
                <IconButton
                  sx={stylesHeader.iconBtn}
                  onClick={handleClick}
                  aria-controls={open ? 'account-menu' : undefined}
                  aria-haspopup='true'
                  aria-expanded={open ? 'true' : undefined}
                >
                  <UserAvatar size={24} />
                </IconButton>
              </Tooltip>
              <Box sx={stylesHeader.iconBtn.givenNameBox}>
                <Typography sx={stylesHeader.iconBtn.givenNameBox.typography}>
                  {user?.given_name}
                </Typography>
              </Box>
            </Box>
            <Menu
              anchorEl={anchorEl}
              id='account-menu'
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: stylesHeader.paperProps,
              }}
              transformOrigin={{
                horizontal: 'right',
                vertical: 'top',
              }}
              anchorOrigin={{
                horizontal: 'right',
                vertical: 'bottom',
              }}
              sx={stylesHeader.profileMenu}
            >
              <Box sx={stylesHeader.profileBox}>
                <Box sx={stylesHeader.profileBox.avatarBox}>
                  <Avatar sx={stylesHeader.profileBox.avatarInitials}>
                    {initialsName}
                  </Avatar>
                </Box>
                <Box sx={stylesHeader.profileBox.profileInfo}>
                  <Typography sx={stylesHeader.profileBox.profileInfo.fullName}>
                    {fullName}
                  </Typography>
                  <Typography sx={stylesHeader.profileBox.profileInfo.email}>
                    {user?.email}
                  </Typography>
                  <Typography
                    sx={stylesHeader.profileBox.profileInfo.myProfileText}
                    onClick={openProfileModal}
                  >
                    My profile
                  </Typography>
                </Box>
              </Box>
              <Divider />
              <MenuItem sx={stylesHeader.logoutMenuItem}>
                <ListItemButton
                  sx={stylesHeader.logoutMenuItem.logoutBtn}
                  onClick={() => handleLogout()}
                >
                  <ListItemIcon>
                    {<Logout size={16} color={colors.secondary} />}
                  </ListItemIcon>
                  <ListItemText sx={stylesHeader.logoutMenuItem.logoutText}>
                    <Typography sx={stylesHeader.logoutMenuItem.logoutText}>
                      Logout
                    </Typography>
                  </ListItemText>
                </ListItemButton>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </StyledAppBar>
    </>
  );
};
