import { Box } from '@mui/material';
import { useContext } from 'react';

import { AppContext } from 'src/context';
import { Header } from './components/Header';
import { Sidebar } from './components/Sidebar';
import { StyledMain } from './styles';

interface MainProps {
  overrideParentStyles?: React.CSSProperties;
  overrideStyles?: React.CSSProperties;
  renderOutsideWrapper?: React.ReactNode;
  children: React.ReactNode;
  hideSidebar?: boolean;
  hideHeader?: boolean;
}

const drawerWidthOpened = 250;
const drawerWidthClosed = 60;

export const Main: React.FC<MainProps> = ({
  overrideParentStyles,
  overrideStyles,
  renderOutsideWrapper,
  children,
  hideSidebar,
  hideHeader,
}) => {
  const { sidebarOpen } = useContext(AppContext);

  return (
    <Box>
      {!hideHeader && <Header />}
      {!hideSidebar && <Sidebar />}
      <Box
        sx={{
          marginLeft: sidebarOpen
            ? `${drawerWidthOpened}px`
            : `${drawerWidthClosed}px`,
          position: 'relative',
          ...overrideParentStyles,
        }}
      >
        <StyledMain style={overrideStyles} open={sidebarOpen}>
          {children}
        </StyledMain>
      </Box>
      {renderOutsideWrapper}
    </Box>
  );
};
