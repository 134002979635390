import styled from '@emotion/styled';
import { TextField, TextFieldProps } from '@mui/material';

interface StyledTextFieldProps {
  width?: number;
  isSearchField?: boolean;
}

export const StyledTextField = styled(TextField)<
  StyledTextFieldProps & Omit<TextFieldProps, 'error'>
>`
  & {
    width: ${({ width }) => width}px;

    .MuiInputBase-root {
      background: #fff !important;
      border: 1px solid ${({ error }) => (error ? '#F20000' : '#cccccc')};
      border-radius: 2px;
      :hover {
        background: #fff;
        border-color: ${({ error }) => (error ? '#F20000' : '#006161')};
      }
    }

    .MuiFilledInput-root,
    .MuiFilledInput-root:hover {
      ::before,
      ::after {
        border-bottom: unset !important;
      }
    }

    .MuiFormLabel-root {
      color: ${({ error }) => (error ? '#F20000' : '#888888')};
      top: -2px;
    }

    .MuiFormLabel-root.Mui-focused {
      top: 0px;
      font-size: 12px;
      color: ${({ error }) => (error ? '#F20000' : '#006161')};
    }

    .MuiFormLabel-root.MuiFormLabel-filled,
    .MuiFormLabel-root[data-shrink='true'] {
      top: 0px;
      font-size: 12px;
    }

    :hover {
      border-color: ${({ error }) => (error ? '#F20000' : '#006161')};
    }

    .MuiFilledInput-root input {
      ${({ isSearchField }) =>
        !isSearchField
          ? 'padding-top: 16px;'
          : `
          padding-top: 4px;
          padding-bottom: 6px;
          `};
    }

    .MuiFormHelperText-root {
      margin-left: 0;
      margin-top: 3px;
      line-height: 1.143;
      font-size: 10px;
    }
  }
`;
