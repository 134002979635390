export const styles = {
  tableRow: {
    borderBottom: '2px solid #CCCCCC',
    ':hover': {
      bgcolor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  tableHeadCell: (index: number, isSortable?: boolean | undefined) => ({
    padding: 0,
    margin: 0,
    fontSize: '12px',
    maxWidth: '370px',
    minWidth: index === 0 ? '200px' : '140px',
    color: '#888888',
    height: '20px',
    borderBottom: isSortable ? '2px solid #000000' : '2px solid #CCCCCC',
    ':hover': {
      bgcolor: 'rgba(80,80,80,0.1)',
    },
  }),
  tableHeadCellSortable: {
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'row',
  },
  ruleName: {
    fontWeight: 'bold',
    textDecoration: 'underline',
    color: '#006161',
  },
  description: {
    fontSize: '12px',
    maxWidth: '200px',
  },
  dateAndTime: {
    fontSize: '12px',
    color: '#888888',
  },
  selectWrapper: {
    margin: 0,
    padding: 0,
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    height: '50px',
  },
  authorsWrapper: {
    display: 'flex-start',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    minHeight: '40px',
  },
  cell: {
    display: 'flex',
    alignItems: 'center',
    margin: 0,
    padding: 0,
  },
  dashboardTitle: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: '400px',
  },
  dashboardButton: {
    textTransform: 'capitalize',
    color: '#5f6c7b',
    marginRight: 1,
  },
  dashboardButtonIcon: {
    fontSize: '24px',
    color: '#118AB2',
  },
  dropdownIcon: {
    fontSize: '22px',
    color: '#118AB2',
  },
  deleteOptionText: {
    color: '#C73E1D',
  },
  deleteOptionIcon: {
    fontSize: '22px',
    color: '#C73E1D',
  },
  selectDashboardField: {
    width: 200,
    marginLeft: '10px',
  },
  drawerSubTitle: {
    marginTop: 8,
  },
  fieldHelperText: {
    color: '#073B4C',
  },
  addButton: {
    borderRadius: 0,
    flex: '50%',
  },
  resetButton: {
    flex: '50%',
    bgcolor: '#073B4C',
    color: 'white',
    borderRadius: 0,
    ':hover': {
      bgcolor: '#0c3644',
    },
  },
};
