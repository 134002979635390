import styled from '@emotion/styled';
import { Dialog } from '@mui/material';

export const StyledDialog = styled(Dialog)`
  & {
    .MuiDialog-container {
      .MuiPaper-root {
        width: 450px;
        padding-right: 20px;
        padding-bottom: 20px;
        .MuiDialogContent-root {
          padding-right: 8px;
        }
      }
    }
  }
  & {
    .MuiTypography-root {
      padding-bottom: 0px;
    }
  }
`;

export const styles = {
  closeIconButton: {
    position: 'absolute',
    right: '15px',
    top: '15px',
  },
  closeIcon: {
    position: 'absolute',
    right: '15px',
    top: '10px',
  },
};
