export const routes = {
  home: '/',
  login: '/login',
  register: '/register',
  newPassword: '/changePassword',
  forgotPassword: '/forgotPassword',
  rules: '/rules',
  rule: '/rule',
  components: '/components',
  ruleDetail: '/rules/:organization/:site/:id',
  editRule: '/rules/edit/:organization/:site/:id',
  siteConsumption: '/consumption/site',
  apnConsumption: '/consumption/apn',
  settings: '/settings',
  devices: '/devices',
  networks: '/networks',
  viewDevice: '/devices/:organization/:site/:ueid',
  editDevice: '/devices/edit/:organization/:site/:ueid/',
  editNetwork: '/networks/:organization/:site/:apn',
  geomap: '/geomap',
  userManagement: '/users',
  user: '/user/:userId',
  usagePolicy: '/usage-policy',
  userUsagePolicy: '/usage-policy/user/:userId',
  createUsagePolicy: '/create-usage-policy',
  editUsagePolicy: '/usage-policy/edit/:policyId',
};
