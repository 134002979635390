import React, { useContext } from 'react';
import { Box, Link, Typography } from '@mui/material';
import {
  CheckboxCheckedFilled,
  CheckmarkFilled,
  WarningFilled,
} from '@carbon/icons-react';

import { DeviceType } from 'src/types/device';
import { AuthContext } from 'src/context';
import { styles } from './styles';

interface DeviceComponentProps {
  device: DeviceType | undefined;
  error: unknown;
}

export const DeviceComponent: React.FC<DeviceComponentProps> = ({
  device,
  error,
}) => {
  const { user } = useContext(AuthContext);

  const deviceNotFound = !device && error;
  const deviceOwnedByLoggedInUser = device?.ownerEmail === user?.email;
  const deviceOwned = !!device?.ownerEmail;

  if (deviceNotFound) {
    return (
      <Box mb='15px'>
        <Typography sx={styles.noDevice}>No device found</Typography>{' '}
      </Box>
    );
  }

  return deviceOwned ? (
    <Box mb='15px'>
      <Box display='flex' m='16px 0' justifyContent='space-between'>
        <Box display='flex' flexDirection='column'>
          <Typography sx={styles.ueId}>{device?.ueid}</Typography>
          <Typography sx={styles.name}>{device?.name}</Typography>
        </Box>
        <Box>
          <Box sx={styles.ownedWrapper}>
            {deviceOwnedByLoggedInUser ? (
              <>
                <CheckmarkFilled style={styles.checkIcon} />
                <Typography sx={styles.ownedByLoggedUser}>
                  Owned by you
                </Typography>
              </>
            ) : (
              <>
                <WarningFilled style={styles.warningIcon} />
                <Typography sx={styles.owned}>Owned by another user</Typography>
              </>
            )}
          </Box>
          {!deviceOwnedByLoggedInUser && (
            <Link ml='36px'>Contact my admin</Link>
          )}
        </Box>
      </Box>
    </Box>
  ) : (
    <Box mb='15px'>
      <Box display='flex' m='16px 0'>
        <Box display='flex' flexDirection='column'>
          <Box display='flex' justifyContent='center' alignItems='center'>
            {device && (
              <CheckboxCheckedFilled size='20' style={styles.checkboxIcon} />
            )}
            <Typography sx={styles.ueId}>{device?.ueid}</Typography>
          </Box>
          <Typography sx={styles.ueName}>{device?.name}</Typography>
        </Box>
      </Box>
    </Box>
  );
};
