import React, { useMemo, useCallback } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@carbon/icons-react';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { formatInTimeZone } from 'date-fns-tz';
import { useSearchParams } from 'react-router-dom';

import { ExecutionTrendContainer, styles } from './styles';
import { Rule } from 'src/types/rule';

interface ExectionTrendProps {
  executionData: Rule['executionData'];
}

export const ExectionTrend: React.FC<ExectionTrendProps> = ({
  executionData,
}) => {
  const maxValue = useMemo(() => {
    let max = 0;
    executionData?.forEach((data) => {
      if (data.value > max) {
        max = data.value;
      }
    });
    return max;
  }, [executionData]);

  return (
    <ExecutionTrendContainer mt='20px'>
      <Typography variant='h2' mb='35px'>
        Execution trend
      </Typography>
      <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
        {executionData ? (
          executionData.map((data, index) => (
            <Bar data={data} key={index} max={maxValue} />
          ))
        ) : (
          <></>
        )}
      </ScrollMenu>
    </ExecutionTrendContainer>
  );
};

function Arrow({
  left,
  children,
  disabled,
  onClick,
}: {
  left: boolean;
  children: React.ReactNode;
  disabled: boolean;
  onClick: VoidFunction;
}) {
  return (
    <Box style={{ ...styles.arrowContainer(left, disabled) }}>
      <IconButton
        disabled={disabled}
        onClick={onClick}
        sx={{
          border: `1px solid ${disabled ? '#CCCCCC' : '#006161'}`,
          borderRadius: '50%',
        }}
        size='small'
      >
        {children}
      </IconButton>
    </Box>
  );
}

function LeftArrow() {
  const { isFirstItemVisible, scrollPrev } =
    React.useContext(VisibilityContext);

  return (
    <Arrow left disabled={isFirstItemVisible} onClick={() => scrollPrev()}>
      <ChevronLeft color={isFirstItemVisible ? '#CCCCCC' : '#006161'} />
    </Arrow>
  );
}

function RightArrow() {
  const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);

  return (
    <Arrow
      left={false}
      disabled={isLastItemVisible}
      onClick={() => scrollNext()}
    >
      <ChevronRight color={isLastItemVisible ? '#CCCCCC' : '#006161'} />
    </Arrow>
  );
}

function Bar({
  data,
  max,
}: {
  data: { xvalue: string; time: string; value: number };
  max: number;
}) {
  const [params] = useSearchParams({ filter: 'DAY' });

  const filterLabel = useCallback(() => {
    const currentFilter = params.get('filter');
    if (currentFilter === 'MONTH') {
      return 'MMM yy';
    } else if (currentFilter === 'WEEK') {
      return 'iii dd';
    } else {
      return 'HH: mm';
    }
  }, [params]);

  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
      mr='43px'
      width='max-content'
    >
      <Box
        width={24}
        height={110}
        borderRadius={26}
        position='relative'
        sx={{
          background:
            data.value > 7
              ? '#FF9900'
              : 'linear-gradient(180deg, rgba(39,175,153,1) 0%, rgba(0,97,97,1) 100%)',
        }}
      >
        <Box
          position='absolute'
          right={3}
          bottom={data.value === 1 ? 4 : 3}
          width={data.value === 1 ? 17 : 18}
          height={
            data.value > 0
              ? `calc(${
                  data.value === 1 ? 12 : (data.value / max) * 100
                }% - 6%)`
              : 0
          }
          borderRadius='26px'
          bgcolor={data.value > 7 ? '#FFF1DB' : '#F1FCFA'}
        />
      </Box>
      <Typography
        color={data.value > 7 ? '#FF9900' : '#006161'}
        fontSize={20}
        fontWeight='bold'
      >
        {data.value}
      </Typography>
      <Typography color='#888888' fontSize={10}>
        {formatInTimeZone(
          data.time,
          Intl.DateTimeFormat().resolvedOptions().timeZone,
          filterLabel(),
        )}
      </Typography>
    </Box>
  );
}
