import { ArrowRight } from '@carbon/icons-react';
import { Box, Link, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAllUnallocatedDevices } from 'src/apis/ue';
import { Loading } from 'src/components';
import { AppContext, AuthContext } from 'src/context';
import { QueryName } from 'src/enums/query';

export const UnAllocatedDevice: React.FC = () => {
  const { user } = useContext(AuthContext);
  const { site } = useContext(AppContext);
  const organization = user?.organizations?.[0] as string;
  const navigate = useNavigate();

  const { data, isLoading } = useQuery(
    [QueryName.GET_ALL_UNALLOCATED_UES, organization, site],
    () =>
      getAllUnallocatedDevices({
        organization,
        site,
      }),
    {
      enabled: !!organization && !!site,
    },
  );

  const uesData = data?.data?.devices;
  return (
    <Loading isLoading={isLoading}>
      {uesData && uesData?.length > 0 && (
        <Box
          width='395px'
          mt='32px'
          ml='20px'
          pl='40px'
          sx={{
            background: '#FFF4E4',
            height: 'fit-content',
          }}
        >
          <Box display='flex' mb='15px'>
            <Box mr='55px' mt='25px'>
              <Typography fontSize='18px' color='#FF8800'>
                {uesData?.length} devices non allocated
              </Typography>
            </Box>
            <Box mt='28px'>
              <Link
                color='#006161'
                fontWeight='bold'
                onClick={() =>
                  navigate('/devices', { state: { unAllocatedUes: uesData } })
                }
              >
                Allocate
              </Link>
            </Box>
            <ArrowRight
              color='#006161'
              size='16'
              style={{ margin: '30px 0 0 13px' }}
            />
          </Box>
          <Box pb='30px'>
            {uesData?.map((ue) => (
              <Typography
                fontSize='14px'
                key={ue.ueid}
                sx={{ textDecoration: 'underline' }}
              >
                {ue.ueid}
              </Typography>
            ))}
          </Box>
        </Box>
      )}
    </Loading>
  );
};
