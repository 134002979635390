import { columns } from './consumption-colDef';
import { Box, Link, TableCell, TableRow, Typography } from '@mui/material';
import { MinimalAreaChart, Pagination, Table } from 'src/components';
import { ConsumptionConfiguration } from 'src/types/consumption';
import { percentageColor } from './column-props/colorPercentColor';
import React, { useContext, useState } from 'react';
import { Order, Page } from 'src/types/shared';
import { AppContext, AuthContext } from 'src/context';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { QueryName } from 'src/enums/query';
import { getConsumptionSiteData } from 'src/apis/consumption';
import { latencyColor } from './column-props/latencyColor';
import { tableStyles } from './tableStyles';

interface ConsumptionTableProps {
  currentTime: number;
}

export const ConsumptionTable: React.FC<ConsumptionTableProps> = ({
  currentTime,
}) => {
  const [sortCriteria, setSortCriteria] = useState<string>('LATENCY');
  const [sortDirection, setSortDirection] = useState<Order>('DESC');
  const [page, setPage] = useState<Page>({
    pageStart: 1,
    pageEnd: 10,
  });
  const { user } = useContext(AuthContext);
  const organization = user?.organizations?.[0] as string;
  const { site } = useContext(AppContext);
  const [params] = useSearchParams({
    range: 'FIVE_MIN',
    startTime: '',
    endTime: '',
  });

  const timePeriod = params.get('range') ?? 'FIVE_MIN';
  const startTime = params.get('startTime');
  const endTime = params.get('endTime');
  const navigate = useNavigate();
  const instant = params.get('instant');
  const criteria = params.get('criteria');

  const {
    data,
    isLoading,
    isFetching,
  } = useQuery(
    [
      QueryName.GET_CONSUMPTION_SITE_TABLE_DATA,
      'SITE',
      site,
      timePeriod,
      startTime,
      endTime,
      instant,
      sortCriteria,
      sortDirection,
      currentTime,
      page.pageStart,
      page.pageEnd,
    ],
    () =>
      getConsumptionSiteData({
          organization,
          level: 'SITE',
          site,
          sortCriteria,
          sortDirection,
          range: timePeriod,
          instant: instant ?? undefined,
          startTime: startTime ?? undefined,
          endTime: endTime ?? undefined,
        }, page.pageStart, page.pageEnd,
      ),
    { enabled: !!site && !!organization },
  );

  const handlePageChange = (pageStart: number, pageEnd: number) => {
    setPage({
      pageStart,
      pageEnd,
    });
  };

  return (
    <Box
      sx={{
        height: 400,
        width: '100%',
      }}
    >
      <Table
        columns={columns}
        sortCriteria={sortCriteria}
        setSortCriteria={setSortCriteria}
        sortDirection={sortDirection}
        setSortDirection={setSortDirection}
        renderRows={() =>
          data?.data?.result?.map((row: ConsumptionConfiguration) => (
            <TableRow key={row.apn}>
              <TableCell
                sx={{
                  fontWeight: 'bold',
                  fontSize: '16px',
                  color: '#006161',
                  padding: '0px!important',
                }}
              >
                <Link
                  sx={tableStyles.link}
                  onClick={() =>
                    navigate(
                      // eslint-disable-next-line max-len
                      `/consumption/apn?criteria=${criteria}&range=${timePeriod}&apn=${row.apn}`,
                    )
                  }
                >
                  {row.apn}
                </Link>
              </TableCell>
              <TableCell sx={{ paddingLeft: '0px!important' }}>
                {percentageColor(row.downlinkMaxPercentage, row.downlinkMax)}
              </TableCell>
              <TableCell sx={{ paddingLeft: '0px!important' }}>
                {percentageColor(
                  row.downlinkAveragePercentage,
                  row.downlinkAverage,
                )}
              </TableCell>
              <TableCell sx={{ paddingLeft: '0px!important' }}>
                {percentageColor(row.uplinkMaxPercentage, row.uplinkMax)}
              </TableCell>
              <TableCell sx={{ paddingLeft: '0px!important' }}>
                {percentageColor(
                  row.uplinkAveragePercentage,
                  row.uplinkAverage,
                )}
              </TableCell>
              <TableCell sx={{ paddingLeft: '0px!important' }}>
                {latencyColor(row.latency, row.limits.latency)}
                <Typography
                  sx={{
                    fontSize: '12px',
                    color: '#888888',
                  }}
                >
                  {row.watchdogCount} watchdogs
                </Typography>
              </TableCell>
              <TableCell sx={{ paddingLeft: '0px!important' }}>
                <MinimalAreaChart
                  data={row.latencyData?.map((trend) => trend.value)}
                  height={70}
                  width={200}
                />
              </TableCell>
            </TableRow>
          ))
        }
      />
      {data?.data && data?.data.total > 10 && (
        <Pagination
          currentPage={page.pageStart}
          disabled={isLoading || isFetching}
          totalRecords={data?.data.total}
          onChange={handlePageChange}
        />
      )}
    </Box>
  );
};
