import { useState } from 'react';
import { Layout, Responsive, WidthProvider } from 'react-grid-layout';

import { CurrentKpiWidget, TimeSeriesWidget } from 'src/components';
import { widgetRules } from 'src/config/widget';
import { WidgetType, WidgetValueType } from 'src/enums/widget';
import { Widget } from 'src/types/widget';

const ResponsiveGridLayout = WidthProvider(Responsive);

interface WidgetsContainerProps {
  widgets: { [key: string]: Widget };
  timeSeriesFilters: {
    dataInterval: number;
    predefinedDateRange: number | null;
    customDateRange: {
      start: Date | null;
      end: Date | null;
    };
  };
  onLayoutChange: (layouts: Layout[]) => void;
  onDelete: (id: Widget['id']) => void;
}

export const WidgetsContainer: React.FC<WidgetsContainerProps> = ({
  widgets,
  timeSeriesFilters,
  onLayoutChange,
  onDelete,
}) => {
  const [resize, setResize] = useState(new Date().getTime());

  const handleResize = (layout: Layout[]) => {
    onLayoutChange(layout);
    setResize(new Date().getTime());
  };

  return (
    <ResponsiveGridLayout
      preventCollision
      compactType={null}
      breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
      cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
      onDragStop={(layout) => onLayoutChange(layout)}
      onResizeStop={handleResize}
    >
      {Object.values(widgets).map((widget) =>
        widget.type === WidgetValueType.CURRENT ? (
          <div
            key={widget.id}
            data-grid={{
              ...widget.config,
              minW: widgetRules[widget.widgetType][widget.type].minW,
              minH: widgetRules[widget.widgetType][widget.type].minH,
              maxH: widgetRules[widget.widgetType][widget.type].maxH,
              isResizable:
                widgetRules[widget.widgetType][widget.type].isResizable,
            }}
          >
            {widget.widgetType !== WidgetType.TIME && (
              <CurrentKpiWidget
                widget={widget}
                onDelete={() => onDelete(widget.id)}
              />
            )}
          </div>
        ) : (
          <div
            id={`time-series-${widget.id}`}
            key={widget.id}
            data-grid={{
              ...widget.config,
              minW: widgetRules[widget.widgetType][widget.type].minW,
              minH: widgetRules[widget.widgetType][widget.type].minH,
              isResizable:
                widgetRules[widget.widgetType][widget.type].isResizable,
            }}
          >
            <TimeSeriesWidget
              resize={resize}
              widget={widget}
              timeSeriesFilters={timeSeriesFilters}
              onDelete={() => onDelete(widget.id)}
            />
          </div>
        ),
      )}
    </ResponsiveGridLayout>
  );
};
