import {
  Box,
  Collapse,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
} from '@mui/material';
import { CheckmarkFilled, Download, Misuse } from '@carbon/icons-react';
import { FC, RefObject, useContext, useRef } from 'react';
import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import { getReport } from 'src/apis/access-management';
import { ToastContext } from 'src/context';
import { Button } from 'src/components';
import { ReactComponent as File } from 'src/assets/file.svg';
import { ProgressBar } from './ProgressBar';
import { styles } from './styles';
import { getFileTypeAndExtension } from 'src/utils/file';

interface UploadStepTwoProps {
  isLoading?: boolean;
  selectedFile: File | null;
  result: {
    successfulUploads: string[];
    failedUploads: string[];
    errorReportPath: string;
  } | null;
  progress: number;
  handleClose: () => void;
}

interface UsersEmailProps {
  userEmail: string;
  status: boolean;
}

const UsersEmail: FC<UsersEmailProps> = ({ userEmail, status }) => (
  <Box sx={styles.fileRow}>
    <Box sx={styles.emailTextWrapper}>
      <Typography sx={styles.emailText}>{userEmail}</Typography>
    </Box>
    <Box sx={styles.statusWrapper}>
      {status ? (
        <>
          <CheckmarkFilled style={styles.userCreated} />
          <Typography color='#00A410'>Created</Typography>
        </>
      ) : (
        <>
          <Misuse style={styles.userError} />
          <Typography color='#F20000'>Error</Typography>
        </>
      )}
    </Box>
  </Box>
);

export const UploadStepTwo: FC<UploadStepTwoProps> = ({
  isLoading,
  selectedFile,
  result,
  progress,
  handleClose,
}) => {
  const { showToast } = useContext(ToastContext);

  const linkRef: RefObject<HTMLAnchorElement> | undefined = useRef(null);

  const { mutate: downloadReportFile } = useMutation<
    AxiosResponse,
    AxiosError,
    string
  >({
    mutationFn: getReport,
    onSuccess: (response: { data: BlobPart }) => {
      const { type, extension } = getFileTypeAndExtension(selectedFile);
      const blob = new Blob([response.data], { type });
      const url = URL.createObjectURL(blob);
      if (linkRef.current) {
        linkRef.current.href = url;
        linkRef.current.download = `report.${extension}`;
        linkRef.current.click();
      }
      URL.revokeObjectURL(url);
    },
    onError: () => {
      showToast('Failed to download file, Please try again', 'error');
    },
  });

  return (
    <>
      <DialogContent sx={styles.dialogContent}>
        <Box sx={styles.column}>
          <File fill='#006161' />
          <Typography sx={styles.fileName}>{selectedFile?.name}</Typography>
          <Box sx={{ height: '21px' }}>
            <Collapse in={isLoading}>
              {isLoading && (
                <Typography sx={styles.dropText}>Uploading...</Typography>
              )}
            </Collapse>
          </Box>
        </Box>
        <Box>
          <ProgressBar progress={progress} />
          <Collapse in={!!result}>
            {result ? (
              <Box sx={styles.emailsBox}>
                <Box sx={styles.emailItem}>
                  {result?.failedUploads?.map((failedUser: string) => (
                    <UsersEmail
                      key={failedUser}
                      userEmail={failedUser}
                      status={false}
                    />
                  ))}
                  {result?.successfulUploads?.map((successUser: string) => (
                    <UsersEmail
                      key={successUser}
                      userEmail={successUser}
                      status={true}
                    />
                  ))}
                </Box>
              </Box>
            ) : null}
          </Collapse>

          <Box sx={styles.uploadInfo}>
            {result?.successfulUploads?.length ? (
              <Box sx={styles.created}>
                <CheckmarkFilled style={{ margin: '0 5px 0 0' }} />
                <Typography color='#00A410'>
                  {result?.successfulUploads?.length}{' '}
                  {result?.successfulUploads?.length > 1 ? 'users' : 'user'}{' '}
                  created successfully
                </Typography>
              </Box>
            ) : null}
            {result?.failedUploads?.length ? (
              <Box sx={styles.errors}>
                <Misuse style={{ margin: '0 5px 0 0' }} />
                <Typography color='#F20000'>
                  {result?.failedUploads?.length}{' '}
                  {result?.failedUploads?.length > 1 ? 'errors' : 'error'}
                </Typography>
                <IconButton sx={styles.iconButton}>
                  <Typography
                    sx={styles.editIconText}
                    onClick={() => downloadReportFile(result?.errorReportPath)}
                  >
                    Download the report
                  </Typography>
                  <a ref={linkRef} style={{ display: 'none' }} />
                  <Download style={styles.downloadIcon} />
                </IconButton>
              </Box>
            ) : null}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button kind='primary-ghost' onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </>
  );
};
