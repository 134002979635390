import { FC, useContext, useState } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { Button, Confirmable } from 'src/components';
import { UserDetails, UserInfo } from 'src/types/user';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Close, TrashCan } from '@carbon/icons-react';
import { AxiosError, AxiosResponse } from 'axios';

import {
  deleteUser,
  editUser,
  getUserDetails,
} from 'src/apis/access-management';
import { editUserSchema } from 'src/yup-validations/editUser';
import { ToastContext } from 'src/context';
import { QueryName } from 'src/enums/query';
import { Roles } from './Roles';
import { Credentials } from './Credentials';
import { styles } from './styles';
import { Affiliation } from './Affiliation';

interface EditUserModalProps {
  userInfo: UserInfo | null;
  showModal: boolean;
  closeModal: () => void;
}

export const EditUserModal: FC<EditUserModalProps> = ({
  userInfo,
  showModal,
  closeModal,
}) => {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const { showToast } = useContext(ToastContext);
  const queryClient = useQueryClient();

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    reset,
    getValues,
    formState,
  } = useForm<UserDetails>({
    defaultValues: {
      uuid: '',
      firstName: '',
      lastName: '',
      userId: '',
      roles: '',
      organizations: '',
      sites: [],
      groupName: '',
      createDate: 0,
      lastAccess: 0,
      fullName: '',
    },
    resolver: yupResolver(editUserSchema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  useQuery(
    [QueryName.GET_USER_DETAILS, userInfo?.userId],
    () => getUserDetails(userInfo?.userId ?? ''),
    {
      enabled: !!userInfo,
      onSuccess: (data) =>
        reset({
          uuid: data?.data?.uuid,
          userId: data?.data?.userId,
          firstName: data?.data?.firstName,
          lastName: data?.data?.lastName,
          enabled: data?.data?.enabled,
          groupName: data?.data?.groupName,
          roles: (data?.data?.roles)[0],
          organizations: data?.data?.organizations[0],
          sites: data?.data?.sites,
          createDate: data?.data?.createDate,
          lastAccess: data?.data?.lastAccess,
          fullName: data?.data?.fullName,
        }),
    },
  );

  const { organizations: selectedOrganization, roles: currentRole } = watch();

  const generatePassword = () => {
    const generatedPassword = Math.random().toString(36).slice(2);
    setValue('password', generatedPassword, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  const openConfirmModal = () => {
    setConfirmModalOpen(true);
  };

  const closeConfirmModal = () => {
    setConfirmModalOpen(false);
    reset();
  };

  const handleDeleteUser = () => {
    closeConfirmModal();
    closeModal();
    deleteMutation.mutate(userInfo?.userId ?? '');
    reset();
  };
  const { mutate: createUserMutation } = useMutation<
    AxiosResponse,
    AxiosError,
    {
      uuid: string;
      userId: string;
      firstName: string;
      lastName: string;
      enabled: boolean;
      groupName: string;
      roles: string[];
      password?: string;
      organizations: string[];
      sites: string[];
      createDate: number;
      lastAccess: number;
      fullName: string;
    }
  >({
    mutationFn: editUser,
    onSuccess: async () => {
      showToast('User edited successfully!', 'success');
      closeModal();
      await queryClient.invalidateQueries([QueryName.GET_ALL_USERS]);
    },
    onError: (error) => {
      const title =
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        error?.response?.data?.title ?? 'An error occurred, try again';
      showToast(title, 'error');
    },
  });

  const deleteMutation = useMutation<AxiosResponse, AxiosError, string>({
    mutationFn: deleteUser,
    onSuccess: async () => {
      showToast('User deleted successfully!', 'success');
      closeModal();
      await queryClient.invalidateQueries([QueryName.GET_ALL_USERS]);
    },
    onError: () => {
      showToast('Cannot delete user, please try again', 'error');
    },
  });

  const onSubmit = (data: UserDetails) => {
    const payload = {
      ...getValues(),
      userId: data.userId,
      firstName: data.firstName,
      lastName: data.lastName,
      enabled: true,
      roles: [data.roles],
      organizations: [data.organizations],
      sites: data.sites,
      groupName: data.groupName,
    };
    if (data.password === '') {
      delete payload.password;
    }
    createUserMutation(payload);
  };

  return (
    <>
      <Confirmable
        open={confirmModalOpen}
        title='Delete the user ?'
        subTitle={`Are you sure to delete ${userInfo?.firstName}
         ${userInfo?.lastName} from the users?`}
        onConfirm={handleDeleteUser}
        onCancel={closeConfirmModal}
      />
      <Dialog open={showModal}>
        <DialogTitle sx={styles.titleWrapper}>
          <Typography sx={styles.title}>Edit user</Typography>
          <IconButton onClick={closeModal}>
            <Close size='24' color='#222222' />
          </IconButton>
        </DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent sx={styles.contentWrapper}>
            <Box width='100%' mb='10px'>
              <Credentials
                control={control}
                formState={formState}
                generatePassword={generatePassword}
              />
              <Roles control={control} currentRole={currentRole} />
              <Affiliation
                control={control}
                formState={formState}
                selectedOrganization={selectedOrganization}
              />
            </Box>
            <Button
              kind='custom'
              textColor='#F20000'
              backgroundColor='#FFFFFF'
              border='1px solid #F20000'
              hoverBackgroundColor='#EEF0F2'
              padding='6px 24px'
              onClick={openConfirmModal}
            >
              <TrashCan style={{ margin: '0 10px 0 0' }} />
              Delete this user
            </Button>
          </DialogContent>
          <DialogActions sx={styles.dialogActions}>
            <Button kind='primary-ghost' onClick={closeModal}>
              Cancel
            </Button>
            <Button kind='primary' type='submit'>
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
