import * as Yup from 'yup';

export const generalInfoValidationSchema = Yup.object().shape({
  ruleName: Yup.string().required('Rule name is required'),
  description: Yup.string().required('Description is required'),
  startingFrom: Yup.date().when(['endingOn'], {
    is: (endingOn: Yup.DateSchema) => !!endingOn,
    then: Yup.date()
      .max(Yup.ref('endingOn'), "start date can't be after end date")
      .typeError('invalid date'),
    otherwise: Yup.date().nullable(),
  }),
  endingOn: Yup.date()
    .min(Yup.ref('startingFrom'), "end date can't be before start date")
    .typeError('invalid date')
    .nullable(),
});

export const specificationThresholdValidationSchema = Yup.object().shape({
  ruleName: Yup.string().required('Rule name is required'),
  description: Yup.string().required('Description is required'),
  startingFrom: Yup.date().when(['endingOn'], {
    is: (endingOn: Yup.DateSchema) => !!endingOn,
    then: Yup.date()
      .max(Yup.ref('endingOn'), "start date can't be after end date")
      .typeError('invalid date'),
    otherwise: Yup.date().nullable(),
  }),
  endingOn: Yup.date()
    .min(Yup.ref('startingFrom'), "end date can't be before start date")
    .typeError('invalid date')
    .nullable(),
  threshold: Yup.number()
    .transform((value) =>
      isNaN(value) || value === null || value === undefined ? undefined : value,
    )
    .min(0)
    .required('Threshold is required'),
});
export const specificationCapacityValidationSchema = Yup.object().shape({
  ruleName: Yup.string().required('Rule name is required'),
  description: Yup.string().required('Description is required'),
  startingFrom: Yup.date().when(['endingOn'], {
    is: (endingOn: Yup.DateSchema) => !!endingOn,
    then: Yup.date()
      .max(Yup.ref('endingOn'), "start date can't be after end date")
      .typeError('invalid date'),
    otherwise: Yup.date().nullable(),
  }),
  endingOn: Yup.date()
    .min(Yup.ref('startingFrom'), "end date can't be before start date")
    .typeError('invalid date')
    .nullable(),
  threshold: Yup.number()
    .transform((value) =>
      isNaN(value) || value === null || value === undefined ? undefined : value,
    )
    .min(0)
    .required('Threshold is required'),
});

export const specificationGeofenceValidationSchema = Yup.object().shape({
  ruleName: Yup.string().required('Rule name is required'),
  description: Yup.string().required('Description is required'),
  startingFrom: Yup.date().when(['endingOn'], {
    is: (endingOn: Yup.DateSchema) => !!endingOn,
    then: Yup.date()
      .max(Yup.ref('endingOn'), "start date can't be after end date")
      .typeError('invalid date'),
    otherwise: Yup.date().nullable(),
  }),
  endingOn: Yup.date()
    .min(Yup.ref('startingFrom'), "end date can't be before start date")
    .typeError('invalid date')
    .nullable(),
  geoFenceType: Yup.string().required(),
  notifyOnce: Yup.boolean().required(),
});
