import Highcharts from 'highcharts';

import HighchartsReact from 'highcharts-react-official';

interface MinimalAreaChartProps
  extends Pick<Highcharts.SeriesAreaOptions, 'data'> {
  height: string | number;
  width: string | number;
}

export const MinimalAreaChart: React.FC<MinimalAreaChartProps> = ({
  height,
  width,
  data,
}) => {
  const options: Highcharts.Options = {
    chart: {
      height: height || 57,
      width: width || 124,
      backgroundColor: 'rgba(0, 0, 0, 0)',
    },
    credits: {
      enabled: false,
    },
    accessibility: {
      enabled: false,
    },
    title: {
      text: '',
    },
    legend: {
      enabled: false,
    },
    xAxis: {
      visible: false,
    },
    yAxis: {
      visible: false,
      max: 100
    },
    tooltip: {
      enabled: false,
    },
    series: [
      {
        type: 'area',
        states: {
          hover: {
            enabled: false,
          },
        },
        data,
        marker: { enabled: false },
        zones: [
          {
            value: 4000,
            color: '#56CFBB',
            fillColor: '#B3EAE1',
          },
          {
            color: '#006161',
            fillColor: '#6FB4AF',
          },
        ],
      },
    ],
  };
  return <HighchartsReact highcharts={Highcharts} options={options} />;
};
