import { ReactNode } from 'react';
import { Box, Typography } from '@mui/material';

import { StyledContainer } from './styles';

interface GeofenceTypeCardProps {
  title: string;
  description: ReactNode;
  image: string;
  active: boolean;
  onSelect: () => void;
}

export const GeofenceTypeCard: React.FC<GeofenceTypeCardProps> = ({
  title,
  description,
  image,
  active,
  onSelect,
}) => {
  return (
    <StyledContainer
      display='flex'
      width={220}
      height='300px'
      flexDirection='column'
      alignItems='center'
      p='20px'
      active={active}
      onClick={onSelect}
    >
      <img src={image} />
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
      >
        <Typography
          variant='h3' mt='10px'
          color={active ? '#006161' : '#000000'} fontWeight='bold'>
          {title}
        </Typography>
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
        >
          {description}
        </Box>
      </Box>
    </StyledContainer>
  );
};
