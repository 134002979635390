export const styles = {
  mapContainer: {
    position: 'absolute',
    right: '0px',
    height: 'calc(100vh - 50px)',
  },
  equipmentMarker: {
    cursor: 'pointer',
    color: '#3CDECB',
    width: '34px',
    height: '34px',
  },
  apnMarker: {
    cursor: 'pointer',
    color: '#3CDECB',
    width: '34px',
    height: '34px',
  },
};
