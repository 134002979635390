import { FC } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Box } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';

import { styles } from './styles';

interface AddElementProps {
  link: string;
}

export const AddElement: FC<AddElementProps> = ({ link }) => {
  const navigate = useNavigate();
  return (
    <Box sx={styles.addElementWrapper} onClick={() => navigate(link)}>
      <AddCircleOutlineIcon fontSize='small' style={styles.addElementIcon} />
      <Typography sx={styles.addElementText}>Add an element</Typography>
    </Box>
  );
};
