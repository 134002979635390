import { Box } from '@mui/material';
import Map_, { Marker, Popup, Layer, MapRef, Source } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { FmdGood } from '@mui/icons-material';
import { points, bbox, center, circle } from '@turf/turf';
import { GeoJSONSourceOptions } from 'mapbox-gl';
import { styles } from './styles';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { AppContext } from 'src/context';
import { Coordinates } from 'src/types/coordinates';

type Polygon = {
  type: 'POLYGON';
  coordinates?: number[][];
};

type GeoMapAPN = {
  name: string;
  type: 'apn';
  geometry: Polygon | null;
};

export type Equipment = {
  name: string;
  coordinates: {
    latitude: number;
    longitude: number;
  };
  type: 'equipment' | 'equipment_group';
};
interface MapProps {
  data: Coordinates;
  readMoreShown: boolean;
}
export const Map: React.FC<MapProps> = ({ data, readMoreShown }) => {
  const { sidebarOpen } = useContext(AppContext);
  const [selectedEquipments, setSelectedEquipments] = useState<Equipment[]>([]);
  const [apns, setApns] = useState<GeoMapAPN[]>([]);

  const addEquipment = (ue: Equipment) =>
    setSelectedEquipments((prev) => [...prev, ue]);
  const addAllAPNs = (apns: GeoMapAPN[]) => setApns([...apns]);

  const [hoveredStateId, setHoveredStateId] = useState<number | null>(null);
  const [hoverInfo, setHoverInfo] = useState<{
    x: number;
    y: number;
    name: string;
  } | null>(null);
  const [cursor, setCursor] = useState<string>('default');

  const geomapData: GeoJSONSourceOptions['data'] = {
    type: 'FeatureCollection',
    features:
      apns.map((apn, index) => ({
        id: index + 1,
        type: 'Feature',
        properties: {
          apnName: apn.name,
          color: '#55CFBB',
        },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[(apn.geometry?.coordinates ?? []) as []]],
        },
      })) ?? [],
  };

  const getApnCoordinates = useCallback((data: Coordinates) => {
    if (data.apns) {
      const tempAPNs: GeoMapAPN[] = [];
      Object.entries(data.apns).forEach(([apn, value]) => {
        const geometryData = (): Polygon | null => {
          const tempAPN = value;
          if (tempAPN) {
            if (tempAPN.geoType === 'POLYGON') {
              return {
                type: 'POLYGON',
                coordinates: tempAPN.polygonGeometry?.coordinates,
              };
            } else {
              const coordinates =
                tempAPN.pointGeometry?.coordinates.length === 2 &&
                tempAPN.pointGeometry?.radius
                  ? circle(
                      tempAPN.pointGeometry?.coordinates,
                      tempAPN.pointGeometry?.radius,
                      {
                        steps: 0,
                        units: 'kilometers',
                      },
                    ).geometry.coordinates
                  : undefined;
              return {
                type: 'POLYGON',
                coordinates: coordinates?.[0] ?? undefined,
              };
            }
          }
          return null;
        };
        tempAPNs.push({
          name: apn,
          type: 'apn',
          geometry: geometryData(),
        });
      });
      addAllAPNs([...tempAPNs]);
    }
  }, []);

  const getUeCoordinates = useCallback((data: Coordinates) => {
    if (data.ues) {
      Object.entries(data.ues).forEach(([ue, value]) => {
        const point = value;
        if (point?.coordinates[1] && point.coordinates[0]) {
          addEquipment({
            name: ue,
            coordinates: {
              latitude: point.coordinates[1] ?? 0,
              longitude: point.coordinates[0] ?? 0,
            },
            type: 'equipment',
          });
        }
      });
    }
  }, []);

  const getUeGroupsCoordinates = useCallback((data: Coordinates) => {
    if (data.ueGroups) {
      Object.entries(data.ueGroups).forEach(([group, groupUEs]) => {
        let ueGroupCoordinates: {
          latitude: number;
          longitude: number;
        } | null = null;
        if (groupUEs) {
          const groupUEsCoordinates: number[][] = [];

          Object.keys(groupUEs).forEach((ue) => {
            const ueCoordinates = groupUEs[ue].coordinates;
            if (ueCoordinates) {
              groupUEsCoordinates.push(ueCoordinates);
            }
          });

          if (groupUEsCoordinates.length > 0) {
            const features = points([...groupUEsCoordinates]);

            const {
              geometry: { coordinates },
            } = center(features);

            ueGroupCoordinates = {
              latitude: coordinates[1],
              longitude: coordinates[0],
            };
          }
          if (ueGroupCoordinates) {
            addEquipment({
              name: group,
              coordinates: ueGroupCoordinates,
              type: 'equipment_group',
            });
          }
        }
      });
    }
  }, []);

  useEffect(() => {
    if (data != null) {
      getApnCoordinates(data);
      getUeCoordinates(data);
      getUeGroupsCoordinates(data);
    }
  }, [data, getApnCoordinates, getUeCoordinates, getUeGroupsCoordinates]);

  useEffect(() => {
    const container = document.getElementById('selection-container');
    if (container) {
      if (window.scrollY > 0) {
        setTop(container.getBoundingClientRect().top + window.scrollY);
      } else {
        setTop(container.getBoundingClientRect().top);
      }
    }
  }, [readMoreShown]);

  const mapRef = useRef<MapRef>(null);

  const [width, setWidth] = useState(0);
  const [top, setTop] = useState(0);
  const [resizing, setResizing] = useState(false);
  const [popup, setPopup] = useState<{
    name: string;
    coordinates: {
      latitude: number;
      longitude: number;
    };
  } | null>(null);

  const fitBounds = useCallback(() => {
    const total = selectedEquipments.length + apns.length;

    if (total === 0) {
      return;
    }
    if (apns.length >= 1 && selectedEquipments.length === 0) {
      if (apns[0].geometry == null) {
        return;
      }
    }
    const apnsCoordinates = apns.reduce<number[][]>((prev, curr) => {
      let tempArr = [];
      if (curr.geometry?.coordinates) {
        tempArr = [...prev, ...curr.geometry?.coordinates];
      } else {
        tempArr = [...prev];
      }
      return tempArr;
    }, []);

    const equipmentCoordinates = selectedEquipments.reduce<number[][]>(
      (prev, curr) => {
        const tempArr = [
          ...prev,
          [curr.coordinates.longitude, curr.coordinates.latitude],
        ];
        return tempArr;
      },
      [],
    );

    if (apnsCoordinates.length === 0 && selectedEquipments.length === 1) {
      if (selectedEquipments[0]?.coordinates) {
        mapRef.current
          ?.setCenter({
            lat: selectedEquipments[0].coordinates.latitude,
            lng: selectedEquipments[0].coordinates.longitude,
          })
          .zoomTo(13);
      }
      return;
    }

    const features = points([...apnsCoordinates, ...equipmentCoordinates]);
    const [minLng, minLat, maxLng, maxLat] = bbox(features);

    mapRef.current?.fitBounds(
      [
        [minLng, minLat],
        [maxLng, maxLat],
      ],
      { padding: 50, duration: 1000 },
    );
  }, [apns, selectedEquipments]);

  const handleOnMapLoad = () => {
    fitBounds();
    setResizing(false);
  };

  useEffect(() => {
    fitBounds();
  }, [fitBounds]);

  useEffect(() => {
    setTimeout(() => {
      mapRef.current?.resize();
      fitBounds();
    }, 0);
  }, [fitBounds, width]);

  useEffect(() => {
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
    const platform = navigator?.userAgent || navigator?.platform;
    setResizing(true);
    const container = document.getElementById('selection-container');
    if (container) {
      if (windowsPlatforms.some((value) => platform.includes(value))) {
        setWidth(
          Math.floor(
            window.screen.width - container.getBoundingClientRect().right - 16,
          ),
        );
      } else {
        setWidth(
          Math.floor(
            window.screen.width - container.getBoundingClientRect().right,
          ),
        );
      }
    }
  }, [sidebarOpen]);
  return (
    <Box width={width} top={top} sx={styles.mapContainer}>
      <Map_
        ref={mapRef}
        initialViewState={{
          longitude: -5.905370495296432,
          latitude: 54.61745492998754,
          zoom: 13,
        }}
        cursor={cursor}
        dragPan={false}
        dragRotate={false}
        scrollZoom={false}
        keyboard={false}
        doubleClickZoom={false}
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN_2}
        style={{
          width: '100%',
          height: '100%',
          opacity: resizing ? 0.5 : 1,
          transition: 'opacity 0.25s ease-in',
          marginTop: 'unset',
        }}
        attributionControl={false}
        mapStyle='mapbox://styles/mapbox/satellite-streets-v9'
        onLoad={handleOnMapLoad}
        onResize={() => setResizing(false)}
        onDragStart={() => setCursor('grabbing')}
        onDragEnd={() => setCursor('grab')}
        onMouseMove={(event) => {
          if (event.features && event.features.length > 0) {
            if (hoveredStateId !== null) {
              mapRef.current?.setFeatureState(
                { source: 'source', id: hoveredStateId },
                { hover: false },
              );
            }
            mapRef.current?.setFeatureState(
              { source: 'source', id: event.features[0].id },
              { hover: true },
            );
            setCursor('default');
            setHoveredStateId(event.features[0].id as number);
            setHoverInfo({
              x: event.point.x,
              y: event.point.y,
              name: event.features[0].properties?.apnName,
            });
          }
        }}
        onMouseLeave={() => {
          if (hoveredStateId !== null) {
            mapRef.current?.setFeatureState(
              { source: 'source', id: hoveredStateId },
              { hover: false },
            );
          }
          setCursor('default');
          setHoveredStateId(null);
          setHoverInfo(null);
        }}
        interactiveLayerIds={['data']}
      >
        <Source id='source' type='geojson' data={geomapData}>
          <Layer
            {...{
              id: 'data',
              type: 'fill',
              paint: {
                'fill-color': '#55CFBB',
                'fill-opacity': [
                  'case',
                  ['boolean', ['feature-state', 'hover'], false],
                  0.4,
                  0.3,
                ],
              },
            }}
          />
          <Layer
            {...{
              id: 'outline',
              type: 'line',
              paint: {
                'line-color': '#55CFBB',
                'line-width': 2,
              },
            }}
          />
        </Source>
        {selectedEquipments.map((equipment) => (
          <Marker
            key={equipment.name}
            longitude={equipment.coordinates.longitude}
            latitude={equipment.coordinates.latitude}
            anchor='bottom'
          >
            <FmdGood
              sx={styles.equipmentMarker}
              onClick={() =>
                setPopup({
                  name: equipment.name,
                  coordinates: {
                    latitude: equipment.coordinates.latitude,
                    longitude: equipment.coordinates.longitude,
                  },
                })
              }
            />
          </Marker>
        ))}
        {popup && (
          <Popup
            latitude={popup.coordinates.latitude}
            longitude={popup.coordinates.longitude}
            onClose={() => setPopup(null)}
            closeOnClick={false}
            style={{ paddingBottom: '45px' }}
          >
            {popup.name}
          </Popup>
        )}
        {hoverInfo && (
          <div
            className='tooltip'
            style={{ left: hoverInfo.x, top: hoverInfo.y }}
          >
            <div>{hoverInfo.name}</div>
          </div>
        )}
      </Map_>
    </Box>
  );
};
