import { Box, Typography } from '@mui/material';
import { RuleConfiguration } from 'src/types/rule';
import { GeneralInformation } from '../EditGeneralInformation';
import { PrioritizeEquipments } from '../PrioritizeEquipments';
import { SelectedNetworks } from '../SelectedNetworks';
import { ChooseItem } from '@carbon/icons-react';
import { useSearchParams } from 'react-router-dom';
import { styles } from './styles';
interface SpecificationPrioritizationProps {
  onCancel: () => void;
  ruleConfiguration: RuleConfiguration;
}

export const EditSpecificationPrioritization: React.FC<
  SpecificationPrioritizationProps
> = ({ onCancel, ruleConfiguration }) => {
  const [, setParams] = useSearchParams();
  return (
    <Box>
      <Box display='flex' justifyContent='space-between' mt='48px'>
        <Box>
          <Box
            display='flex'
            justifyContent='flex-start'
            alignItems='center'
            mb='30px'
          >
            <Typography variant='h2'>Rule</Typography>
            <Box
              sx={styles.editBox}
              onClick={() => setParams({ step: 'selection' })}
            >
              <Box ml='25px' mt='8px'>
                <ChooseItem size='16' color='#006161' />
              </Box>
              <Typography fontSize='14px' color='#006161' ml='10px'>
                Edit selection
              </Typography>
            </Box>
          </Box>
          <SelectedNetworks />
          <PrioritizeEquipments />
        </Box>
        <Box>
          <GeneralInformation
            onCancel={onCancel}
            ruleConfiguration={ruleConfiguration}
          />
        </Box>
      </Box>
    </Box>
  );
};
