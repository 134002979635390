import * as Yup from 'yup';

export const createSiteSchema = Yup.object().shape({
  organization: Yup.string()
    .matches(
      /^[a-z0-9-_]*$/,
      'Text should only contain lowercase letters, numbers and (-_)',
    )
    .required('Organization is required'),
  organizationName: Yup.string().when('organization', {
    is: (organization: string) => organization === 'new',
    then: Yup.string()
      .matches(
        /^[a-z0-9-_]*$/,
        'Text should only contain lowercase letters, numbers and (-_)',
      )
      .required('Organization name is required'),
  }),
  country: Yup.string().when('organization', {
    is: (organization: string) => organization === 'new',
    then: Yup.string()
      .matches(/^[a-zA-Z0-9-_ ]*$/)
      .required('Country is required'),
  }),
  site: Yup.string()
    .matches(
      /^[a-z0-9-_]*$/,
      'Text should only contain lowercase letters, numbers and (-_)',
    )
    .max(50, 'Site name must be shorter than 50 characters ')
    .required('Site name is required'),
});
