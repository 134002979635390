import React, { useState } from 'react';
import { Upload } from '@carbon/icons-react';
import { Box, Typography } from '@mui/material';

import { Main } from 'src/layouts/Main';
import { Button } from 'src/components';
import { Breadcrumbs } from 'src/components/Breadcrumbs';
import { ListUsers } from './ListUsers';
import { UploadUsersModal } from './UploadUsers';
import { styles } from './styles';
import { CreateUser } from './CreateUser/CreateUser';
import { useUserRole } from 'src/utils/user';

export const UserManagement = () => {
  const [showUploadFileModal, setShowUploadFileModal] = useState(false);
  const { isSuperUser } = useUserRole();
  const toggleShowUploadFileModal = () => {
    setShowUploadFileModal((prev) => !prev);
  };
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Main>
      {!isSuperUser() && (
        <Breadcrumbs
          breadcrumbs={[
            {
              title: 'Overview',
              url: '/',
            },
            {
              title: 'User management',
            },
          ]}
        />
      )}
      <Box
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        mb='23px'
      >
        <Typography variant='h1'>User Management</Typography>
        <Button kind='primary' onClick={handleClickOpen}>
          Create user
        </Button>
        <CreateUser open={open} close={() => handleClose()} />
      </Box>
      <Box>
        <Box sx={styles.openModalButton}>
          <Upload style={styles.downloadIcon} />
          <Typography sx={styles.iconText} onClick={toggleShowUploadFileModal}>
            Upload users file
          </Typography>
        </Box>
        <ListUsers />
        <UploadUsersModal
          showModal={showUploadFileModal}
          closeModal={toggleShowUploadFileModal}
        />
      </Box>
    </Main>
  );
};
