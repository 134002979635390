import { Box } from '@mui/material';

import { styles } from './styles';

interface AuthLayoutProps {
  children: React.ReactNode;
}

export const AuthLayout: React.FC<AuthLayoutProps> = ({ children }) => {
  return <Box sx={styles.authLayoutContainer}>{children}</Box>;
};
