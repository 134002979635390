import { Download } from '@carbon/icons-react';
import { Link, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useMutation } from '@tanstack/react-query';
import { format } from 'date-fns';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';

import { downloadRecentEventLogs } from 'src/apis/rule';
import { ChipIcon, Timeline } from 'src/components';
import { ToastContext } from 'src/context';
import { Rule } from 'src/types/rule';
import { downloadFile } from 'src/utils/file';

interface RecentEventLogsProps {
  recentEventLogs: Rule['eventLogs'];
}

export const RecentEventLogs: React.FC<RecentEventLogsProps> = ({
  recentEventLogs,
}) => {
  const { showToast } = useContext(ToastContext);

  const params = useParams();

  const downloadLogs = useMutation({
    mutationFn: downloadRecentEventLogs,
    onMutate: () => showToast('Downloading file...', 'info'),
    onSuccess: (data) => {
      const fileName = `recent-event-logs-${new Date().getTime()}.log`;
      downloadFile(fileName, data.data);
    },
  });

  const timeline =
    recentEventLogs?.map((event) => {
      return {
        oppositeContent: (
          <Box>
            <Typography color="#659F9F" fontSize={18} fontWeight="bold">
              {format(new Date(event.executionTime), 'dd')}
            </Typography>
            <Typography color="#659F9F" fontSize={14} mt="-7px">
              {format(new Date(event.executionTime), 'LLL')}
            </Typography>
          </Box>
        ),
        content: (
          <Box display="flex" alignItems="flex-start" columnGap="18px">
            <Box>
              <Typography color="#222222" fontSize={14} fontWeight="bold">
                {event.logName}
              </Typography>
              <Typography color="#888888" fontSize={12}>
                {format(new Date(event.executionTime), 'HH:mm')}
              </Typography>
            </Box>
            <ChipIcon
              kind={
                event.executionStatus === 'SUCCESS'
                  ? 'primary-ghost'
                  : 'danger-ghost'
              }
              label={event.executionStatus}
            />
          </Box>
        ),
      };
    }) ?? [];
  return (
    <Box>
      <Box display="flex" alignItems="center">
        <Typography variant="h2" mr="38px">
          Recent event logs
        </Typography>
        {recentEventLogs && recentEventLogs.length > 0 && (
          <Link
            display="flex"
            alignItems="center"
            underline="hover"
            onClick={() =>
              downloadLogs.mutate({
                organization: params.organization as string,
                site: params.site as string,
                id: params.id as string,
              })
            }
          >
            <Download style={{ marginRight: 4 }} /> Download
          </Link>
        )}
      </Box>
      {recentEventLogs && recentEventLogs.length > 0 ? (
        <Timeline timeline={timeline} />
      ) : (
        <Typography mt={2} color="gray">
          No logs found
        </Typography>
      )}
    </Box>
  );
};
