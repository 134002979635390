import { ApiResponseType } from 'src/types/api';
import { Login, RefreshToken } from 'src/types/auth';
import { api } from 'src/utils/axios';

export const generateToken = ({
  username,
  password,
}: {
  username: string;
  password: string;
}) => {
  return api.post<ApiResponseType<Login>>(
    'accessmanagement/v1/token/generate',
    {
      username,
      password,
    },
  );
};

export const refreshAccessToken = ({
  username,
  refreshToken,
}: {
  username: string;
  refreshToken: string;
}) => {
  return api.post<ApiResponseType<RefreshToken>>(
    'accessmanagement/v1/token/refresh',
    {
      username,
      refreshToken,
    },
  );
};
