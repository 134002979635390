export const styles = {
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: '0',
  },
  contentWrapper: {
    padding: '0 24px',
  },
  title: {
    fontSize: '16px',
    fontWeight: 'bold',
    maxWidth: '200px',
  },
  subtitle: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#006161',
    paddingBottom: '10px',
    paddingTop: '20px',
  },
  dropdownsWrapper: {
    paddingBottom: '10px',
    paddingRight: '10px',
  },
  textInputs: {
    paddingBottom: '10px',
  },
  passwordInput: {
    paddingBottom: '2px',
    paddingRight: '10px',
  },
  generatePassword: {
    fontSize: '14px',
    color: '#006161',
    textDecoration: 'underline',
    marginLeft: '10px',
    padding: 0,
    ':hover': {
      cursor: 'pointer',
      textDecoration: 'none',
    },
  },
  addElementWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    margin: 0,
    height: '35px',
    width: '100%',
    background: '#EFF5F5 0% 0% no-repeat padding-box',
    cursor: 'pointer',
  },
  addElementText: {
    color: '#006161',
    marginLeft: '5px',
  },
  addElementIcon: {
    color: '#006161',
  },
  helperText: {
    color: 'red',
    fontSize: '12px',
  },
  passwordIcon: {
    color: '#006161',
  },
  dialogActions: {
    padding: '10px 34px 42px 34px',
  },
  roleWrapper: (isCurrentRole: boolean) => ({
    backgroundColor: isCurrentRole ? '#F1FCFA' : 'white',
    border: isCurrentRole ? '2px solid #006161' : '1px solid #707070',
    width: '130px',
    height: '130px',
    marginRight: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '15px 5px',
    whiteSpace: 'normal',
    ':hover': {
      cursor: 'pointer',
      boxShadow: '0px 5px 15px #00000029',
    },
  }),
  roleTitle: (isCurrentRole: boolean) => ({
    color: isCurrentRole ? '#006161' : '#222222',
    fontWeight: 'bold',
    fontSize: '16px',
    fontFamily: '"Lato"',
    marginTop: '5px',
    marginBottom: '5px',
  }),
  roleDescription: (isCurrentRole: boolean) => ({
    color: isCurrentRole ? '#006161' : '#888888',
    fontSize: '12px',
    fontFamily: '"Lato"',
    margin: 0,
    padding: 0,
    textAlign: 'center',
  }),
  roleIcon: (isCurrentRole: boolean) => ({
    height: '32px',
    color: isCurrentRole ? '#006161' : '#222222',
  }),
};
