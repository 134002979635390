import { api } from '../utils/axios';
import { ApiResponseType } from '../types/api';

export const getAllWatchDogs = (
  organization: string,
  site: string,
) => {
  return api.get<ApiResponseType>('/topology/v1/watchdogs/all',
    {
      params: {
        organization,
        site
      },
    });
};
