import { Box, Stack } from '@mui/material'
import { FC } from 'react'
import { styles } from './styles'

interface SortingArrowProps {
  sortDirection: string | undefined;
  active: boolean;
}

export const SortingArrow: FC<SortingArrowProps> = ({
  sortDirection,
  active,
}) => (
  <Stack sx={styles.wrapper}>
    <Box sx={styles.element}>
      <Box sx={styles.arrowStyle(active, sortDirection, 'up')} />
    </Box>
    <Box sx={styles.element}>
      <Box sx={styles.arrowStyle(active, sortDirection, 'down')} />
    </Box>
  </Stack>
)
