import { ApiResponseType } from 'src/types/api';
import { api } from 'src/utils/axios';

export const getNodeNamesByOrg = (org: string) => {
  const url = `/topology/v1/metadata/node/name?organization=${org}`;
  return api.get<ApiResponseType<string[]>>(url);
};

export const getAllNodes = (
  {
    organization,
    site,
  }: {
    organization: string;
    site?: string;
  },
  pageStart: number,
  pageEnd: number,
) => {
  return api.get<ApiResponseType>('/topology/v1/node/all', {
    params: {
      organization,
      site,
    },
    headers: {
      pageStart,
      pageEnd,
    },
  });
};

export const editNode = ({
  nodeName,
  latitude,
  longitude,
  organization,
  site,
}: {
  nodeName: string;
  latitude: number;
  longitude: number;
  organization: string;
  site: string;
}) => {
  return api.patch(`/topology/v1/node?nodeName=${nodeName}`, {
    latitude,
    longitude,
    organization,
    site,
  });
};
