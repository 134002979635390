import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { formatInTimeZone } from 'date-fns-tz';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import More from 'highcharts/highcharts-more';
import { Rule } from 'src/types/rule';

More(Highcharts);

interface PredictionProps {
  trendData: Rule['trendData'];
  prediction: Rule['trendData'];
}

export const Prediction: React.FC<PredictionProps> = ({
  trendData,
  prediction,
}) => {
  const formatData = () => {
    const data = trendData?.reduce((prev: Array<[string, number]>, curr) => {
      prev.push([
        formatInTimeZone(
          curr.time,
          Intl.DateTimeFormat().resolvedOptions().timeZone,
          'dd/MM',
        ),
        curr.value,
      ]);
      return prev;
    }, []);
    const predictionData = prediction?.reduce(
      (prev: Array<[string, number]>, curr) => {
        prev.push([
          formatInTimeZone(
            curr.time,
            Intl.DateTimeFormat().resolvedOptions().timeZone,
            'dd/MM',
          ),
          curr.value,
        ]);
        return prev;
      },
      [],
    );
    return [...(data ?? []), ...(predictionData ?? [])];
  };

  const options: Highcharts.Options = {
    chart: {
      height: 370,
      width: 693,
      zooming: {
        type: 'x',
        resetButton: {
          theme: {
            fill: '#fff',
            stroke: '#55CFBB',
            style: {
              color: '#55CFBB',
            },
          },
        },
      },
    },
    credits: {
      enabled: false,
    },
    accessibility: {
      enabled: false,
    },
    title: {
      text: '',
    },
    legend: {
      enabled: false,
    },
    xAxis: {
      lineColor: 'transparent',
      tickColor: 'transparent',
      type: 'category',
      labels: {
        formatter: function () {
          return `<span style="color: ${
            this.pos > 5 ? '#55CFBB' : '#888888'
          };">${this.value}</span>`;
        },
        style: {
          fontSize: '10px',
        },
      },
    },
    yAxis: {
      title: {
        text: '',
      },
      min: 0,
      max: 100,
      tickAmount: 6,
      labels: {
        style: {
          color: '#888888',
          fontSize: '10px',
        },
      },
      gridLineColor: 'transparent',
      plotLines: [
        {
          value: 70,
          width: 1,
          color: '#006161',
          zIndex: 4,
          dashStyle: 'Dash',

          label: {
            text: 'Data limit',
            style: {
              color: '#006161',
              fontSize: '10px',
            },
          },
        },
      ],
    },
    tooltip: {
      useHTML: true,
      backgroundColor: '#F1FCFA',
      borderColor: 'transparent',
      shadow: {
        offsetX: 0,
        offsetY: 0,
        width: 6,
        color: '#00000029',
      },
      shape: 'square',
      style: {
        pointerEvents: 'auto',
      },
      formatter: function () {
        // eslint-disable-next-line max-len
        return `<p style="text-align: center; margin: 0; color: #222222; font-size: 14px;">${this.y}</p>`;
      },
    },
    series: [
      {
        type: 'area',
        data: formatData(),
        marker: { enabled: false },
        zoneAxis: 'x',
        zones: [
          {
            value: 5,
            color: '#006161',
            fillColor: '#DFECEC',
          },
          {
            color: '#55CFBB',
            fillColor: '#DDF4F1',
          },
        ],
      },
    ],
  };
  return (
    <Box>
      <Typography variant='h2' mb='20px' mt='43px'>
        Prediction
      </Typography>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </Box>
  );
};
