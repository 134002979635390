import { Box, MenuItem, ListItemText } from '@mui/material';
import { getAllRoles, bulkActionUsers } from 'src/apis/access-management';
import {
  Select,
  TextField,
  Checkbox,
  Button,
  Confirmable,
} from 'src/components';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { QueryName } from 'src/enums/query';
import { UserFilterData, UserInfo } from 'src/types/user';
import React, { useContext, useEffect, useState, ChangeEvent } from 'react';
import { useOrganizations, useSites } from 'src/views/DeviceManagement/state';
import { MenuProps, styles } from '../styles';
import ClearIcon from '@mui/icons-material/Clear';
import { Tag } from 'src/components/Tag';
import { toTitleCase } from 'src/utils/rule';
import { Search, TrashCan } from '@carbon/icons-react';
import { ToastContext } from '../../../context';
import { AxiosError, AxiosResponse } from 'axios';
import { BulkAction } from 'src/enums/bulkActions';
import { UserGroupModal } from './UserGroupModal';

interface UserFilterProps {
  onApply: (filter: UserFilterData) => void;
  selected: UserInfo[];
}
export const ListUsersHeader: React.FC<UserFilterProps> = ({
  onApply,
  selected,
}) => {
  const [selectedSite, setSelectedSite] = useState<string[]>([]);
  const [filters, setFilters] = useState<UserFilterData>({
    organization: '',
    site: [],
    searchText: '',
    role: '',
    groupName: '',
  });
  const [selectedUserIds, setSelectedUserIds] = useState(['']);
  const { showToast } = useContext(ToastContext);
  const queryClient = useQueryClient();
  const [confirmBulkActionModal, setConfirmBulkActionModal] = useState(false);
  const [action, setAction] = useState<BulkAction>();
  const [openSiteMenu, setOpenSiteMenu] = useState(false);
  const [groupUsersModalVisible, setGroupUsersModalVisible] = useState(false);

  const { data: rolesList } = useQuery([QueryName.GET_ALL_ROLES], () =>
    getAllRoles(),
  );

  const organizationList = useOrganizations();
  const siteList = useSites(filters.organization);

  useEffect(() => {
    if (organizationList.data?.data.length) {
      if (!filters.organization) {
        setFilters((prevFilters) => ({
          ...prevFilters,
          organization: organizationList.data.data[0],
        }));
      }
    }
  }, [organizationList.data]);

  useEffect(() => {
    onApply(filters);
  }, [filters, onApply]);

  const filterUsersToDelete = () => {
    const selectedFilteredUsers = selected.map(({ userId }) => userId);
    setSelectedUserIds(selectedFilteredUsers);
  };

  const handleBulkDelete = () => {
    setConfirmBulkActionModal(true);
    setAction(BulkAction.DELETE);
    filterUsersToDelete();
  };

  const bulkDeleteUsersMutation = useMutation<
    AxiosResponse<{
      failureCount: number;
      failed: string[];
    }>,
    AxiosError,
    { selectedUserIds: string[]; BulkAction: BulkAction }
  >({
    mutationFn: bulkActionUsers,
    onSuccess: async ({ data: { failureCount, failed } }) => {
      if (failureCount > 0) {
        const deleteFailureMessage = `${failed.length > 1 ? 'Users' : 'User'}
      ${failed.join(',')} failed to delete, try again`;
        showToast(deleteFailureMessage, 'error');
      } else {
        showToast('The selected users were deleted successfully!', 'success');
        await queryClient.invalidateQueries([QueryName.GET_ALL_USERS]);
        setSelectedUserIds([]);
      }
    },
    onError: () => {
      showToast('Selected users failed to delete, try again', 'error');
    },
  });

  let timer: ReturnType<typeof setTimeout>;
  const onSearch = (event: React.ChangeEvent) => {
    const value = (event.target as HTMLInputElement).value;
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      setFilters({
        ...filters,
        searchText: value,
      });
    }, 300);
  };

  const handleChangeSites = (event: ChangeEvent<{ value: string[] }>) => {
    const [selectedOption] = event.target.value;
    setSelectedSite((prev) => {
      if (prev.includes(selectedOption)) {
        return prev.filter((option) => option !== selectedOption);
      }
      return [...prev, selectedOption];
    });
  };

  const handleSubmit = () => {
    setOpenSiteMenu(false);
    setFilters({
      ...filters,
      site: selectedSite,
    });
  };

  return (
    <>
      <Box sx={styles.header}>
        <Box display='flex' mb='14px' ml='4px' flexWrap='nowrap'>
          <Select
            width={200}
            menu
            renderValue={() => 'Organization'}
            onChange={(event) => {
              setFilters({
                ...filters,
                organization: event.target.value,
              });
            }}
            MenuProps={MenuProps}
            value={filters.organization}
          >
            {organizationList?.data?.data.map((org) => (
              <MenuItem key={org} value={org}>
                {org}
              </MenuItem>
            ))}
          </Select>
          <Box ml='16px'>
            <Select
              width={200}
              menu
              renderValue={() => 'Roles'}
              MenuProps={MenuProps}
              onChange={(event) => {
                setFilters({
                  ...filters,
                  role: event.target.value,
                });
              }}
              value={filters.role}
            >
              {rolesList?.data.map((role: string) => (
                <MenuItem key={role} value={role}>
                  {role}
                </MenuItem>
              ))}
            </Select>
          </Box>
          {filters.organization && (
            <Box ml='16px'>
              <Select
                open={openSiteMenu}
                renderValue={() => 'Site'}
                onOpen={() => setOpenSiteMenu(true)}
                onClose={() => setOpenSiteMenu(false)}
                checkboxes={true}
                width={200}
                labelId='select-type-label'
                id='select-type'
                label='Types'
                onChange={handleChangeSites}
                handleSubmit={handleSubmit}
              >
                {siteList?.data?.data.map((site) => (
                  <MenuItem key={site} value={site}>
                    <Checkbox checked={selectedSite.includes(site)} />
                    <ListItemText primary={toTitleCase(site)} />
                  </MenuItem>
                ))}
              </Select>
            </Box>
          )}
          <Box position='relative' right='0' marginLeft='auto' height='30px'>
            <TextField
              isSearchField
              placeholder='Search'
              id='search-user'
              InputProps={{
                endAdornment: <Search size={20} color='#888888' />,
              }}
              onChange={onSearch}
            />
          </Box>
        </Box>
        <Box sx={styles.lowerHeaderBox}>
          <Box display='flex' flexWrap='nowrap'>
            <Box>
              {filters.role && (
                <Tag
                  key={filters.role}
                  label={filters.role}
                  onDelete={() =>
                    setFilters({
                      ...filters,
                      role: '',
                    })
                  }
                  deleteIcon={<ClearIcon />}
                />
              )}
            </Box>
            <Box>
              {filters.organization && (
                <Tag key={filters.organization} label={filters.organization} />
              )}
            </Box>
            <Box>
              {filters.site?.map((selSite, id) => (
                <Tag
                  key={`${selSite}-${id}`}
                  label={toTitleCase(selSite)}
                  onDelete={() => {
                    setFilters({
                      ...filters,
                      site: filters.site.filter((site) => site !== selSite),
                    });
                    setSelectedSite((prev) =>
                      prev.filter((user) => user !== selSite),
                    );
                  }}
                  deleteIcon={<ClearIcon />}
                />
              ))}
            </Box>
            <Box>
              {filters.groupName && (
                <Tag
                  key={filters.groupName}
                  label={filters.groupName}
                  onDelete={() =>
                    setFilters({
                      ...filters,
                      groupName: '',
                    })
                  }
                  deleteIcon={<ClearIcon />}
                />
              )}
            </Box>
            <Box>
              {(filters.groupName ||
                filters.role ||
                filters.site.length > 0) && (
                <Tag
                  label={'Remove all'}
                  onDelete={() => {
                    setFilters({
                      ...filters,
                      role: '',
                      site: [],
                      groupName: '',
                    });
                  }}
                  deleteIcon={<ClearIcon />}
                  textColor={'#fff'}
                  backgroundColor={'#888'}
                />
              )}
            </Box>
          </Box>
          <Box sx={styles.bulkActionBox}>
            {selected.length ? (
              <Button
                sx={styles.bulkDeleteBtn}
                kind='custom'
                textColor='#F20000'
                backgroundColor='#FFFFFF'
                border='1px solid #F20000'
                hoverBackgroundColor='#EEF0F2'
                padding='5px 24px'
                onClick={handleBulkDelete}
              >
                <TrashCan style={{ margin: '0 10px 0 0' }} />
                Delete
              </Button>
            ) : null}
          </Box>
        </Box>
      </Box>
      <Confirmable
        open={confirmBulkActionModal}
        title={`${
          action && action?.charAt(0) + action?.slice(1).toLowerCase()
        } bulk users`}
        // eslint-disable-next-line max-len
        subTitle={`Are you sure you want to ${action?.toLowerCase()} selected users?`}
        onConfirm={() => {
          bulkDeleteUsersMutation.mutate({
            selectedUserIds,
            BulkAction: BulkAction.DELETE,
          });
          setConfirmBulkActionModal(false);
          setSelectedUserIds(['']);
        }}
        onCancel={() => {
          setConfirmBulkActionModal(false);
        }}
      />
      <UserGroupModal
        open={groupUsersModalVisible}
        organization={filters.organization}
        selectedUsers={selected.map((selectedUser) => ({
          userId: selectedUser.userId,
          firstName: selectedUser.firstName,
          lastName: selectedUser.lastName,
          groupName: selectedUser.groupName,
        }))}
        onCancel={() => setGroupUsersModalVisible(false)}
        onSave={() => setGroupUsersModalVisible(false)}
      />
    </>
  );
};
