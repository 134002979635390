import { FC, useContext, useMemo } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { Close, View } from '@carbon/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { AxiosError, AxiosResponse } from 'axios';
import { yupResolver } from '@hookform/resolvers/yup';

import { Button } from 'src/components';
import { QueryName } from 'src/enums/query';
import { getUserDetails, changePassword } from 'src/apis/access-management';
import { AuthContext, ToastContext } from 'src/context';
import { editUserProfileSchema } from 'src/yup-validations/editProfile';
import { UserDetails } from 'src/types/user';
import { stylesMyProfile } from '../styles';
import { TextInputField } from './TextInputField';

interface UserProfileProps {
  isModalOpen: boolean;
  closeModal: () => void;
}

interface Role {
  role: string;
  description: string;
}

interface RoleMap {
  [key: string]: Role;
}

export interface UserProfileDetails extends UserDetails {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export const UserProfile: FC<UserProfileProps> = ({
  isModalOpen,
  closeModal,
}) => {
  const { showToast } = useContext(ToastContext);

  const { user } = useContext(AuthContext);

  useQuery(
    [QueryName.GET_USER_DETAILS, user?.email],
    () => getUserDetails(user?.email ?? ''),
    {
      enabled: !!user,
      onSuccess: (data) =>
        reset({
          uuid: data?.data?.uuid,
          userId: data?.data?.userId,
          firstName: data?.data?.firstName,
          lastName: data?.data?.lastName,
          enabled: data?.data?.enabled,
          groupName: data?.data?.groupName,
          roles: (data?.data?.roles)[0],
          organizations: data?.data?.organizations[0],
          sites: data?.data?.sites,
          createDate: data?.data?.createDate,
          lastAccess: data?.data?.lastAccess,
          fullName: data?.data?.fullName,
        }),
    },
  );

  const { handleSubmit, control, watch, reset, getValues, formState } =
    useForm<UserProfileDetails>({
      defaultValues: {
        uuid: '',
        firstName: '',
        lastName: '',
        userId: '',
        roles: '',
        organizations: '',
        sites: [],
        enabled: true,
        groupName: '',
        createDate: 0,
        lastAccess: 0,
        fullName: '',
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
      },
      resolver: yupResolver(editUserProfileSchema),
      mode: 'onBlur',
      reValidateMode: 'onChange',
    });

  const { organizations: userOrganization, sites, groupName, roles } = watch();

  const { mutate: editUserProfileMutation } = useMutation<
    AxiosResponse,
    AxiosError,
    {
      uuid: string;
      userId: string;
      currentPassword: string;
      newPassword: string;
    }
  >({
    mutationFn: changePassword,
    onSuccess: async () => {
      showToast('User edited successfully!', 'success');
      handleCloseModal();
    },
    onError: (error) => {
      const title =
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        error?.response?.data?.title ?? 'An error occurred, try again';
      showToast(title, 'error');
    },
  });

  const onSubmit = (data: { oldPassword: string; newPassword: string }) => {
    const { uuid, userId } = getValues();
    editUserProfileMutation({
      uuid,
      userId,
      currentPassword: data.oldPassword,
      newPassword: data.newPassword,
    });
  };
  const handleCloseModal = () => {
    closeModal();
    reset();
  };

  const userRoles: RoleMap = useMemo(
    () => ({
      ADMIN: {
        role: 'Admin',
        description: 'Editor role & manages users for an org.',
      },
      SUPER_USER: {
        role: 'Super User',
        description: 'Manages users only across organizations',
      },
      READ_ONLY: {
        role: 'Viewer',
        description: 'Can see everything on an organization',
      },
    }),
    [],
  );

  return (
    <Dialog open={isModalOpen}>
      <DialogTitle sx={stylesMyProfile.titleWrapper}>
        <Typography sx={stylesMyProfile.title}>My profile</Typography>
        <IconButton onClick={handleCloseModal}>
          <Close size='24' color='#222222' />
        </IconButton>
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent sx={stylesMyProfile.contentWrapper}>
          <Box>
            <Typography sx={stylesMyProfile.subtitle}>Identify</Typography>
            <Box display={'flex'}>
              <Box flex={1}>
                <TextInputField
                  control={control}
                  formState={formState}
                  label={'Last name'}
                  name={'lastName'}
                  isPasswordInput={false}
                />
                <TextInputField
                  control={control}
                  formState={formState}
                  label={'First name'}
                  name={'firstName'}
                  isPasswordInput={false}
                />
                <TextInputField
                  control={control}
                  formState={formState}
                  label={'E-mail address'}
                  name={'userId'}
                  isPasswordInput={false}
                />
              </Box>
              <Box flex={1}>
                <TextInputField
                  control={control}
                  formState={formState}
                  label={'Old password'}
                  name={'oldPassword'}
                  isPasswordInput
                />
                <TextInputField
                  control={control}
                  formState={formState}
                  label={'New password'}
                  name={'newPassword'}
                  isPasswordInput
                />
                <TextInputField
                  control={control}
                  formState={formState}
                  label={'Confirm new password'}
                  name={'confirmPassword'}
                  isPasswordInput
                />
              </Box>
            </Box>
          </Box>
          <Box>
            <Typography sx={stylesMyProfile.subtitle}>Role</Typography>
            <Box display='flex' flexDirection='row' alignItems='center'>
              <View />
              <Typography sx={stylesMyProfile.userRole}>
                {userRoles[roles]?.role}
              </Typography>
              <Typography color='#888888'>
                {userRoles[roles]?.description}
              </Typography>
            </Box>
          </Box>
          <Box display={'flex'} m='10px 0 30px 0'>
            <Box flex={1}>
              <Typography sx={stylesMyProfile.subtitle}>
                Organization
              </Typography>
              {userOrganization}
            </Box>
            <Box flex={1}>
              <Typography sx={stylesMyProfile.subtitle}>Sites</Typography>
              {sites?.join(', ')}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={stylesMyProfile.actionsWrapper}>
          <Button kind='primary-ghost' onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button kind='primary' type='submit'>
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
