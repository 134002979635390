import { Box, Typography } from '@mui/material';
import React from 'react';
import { styles } from './styles';

interface ProgressBarProps {
  progress: number;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({ progress }) => {
  return (
    <Box sx={styles.wrapper}>
      <Box style={styles.containerStyles}>
        <Box sx={styles.fillerStyles(progress)}></Box>
      </Box>
      <Box sx={styles.textWrapper}>
        <Typography color='#006161'>{progress}%</Typography>
      </Box>
    </Box>
  );
};
