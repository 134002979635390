import { Box } from '@mui/material';
import { UsersUsage } from './UsersUsage';

export const Usage: React.FC = () => {
  return (
    <Box display='flex'>
      <Box width='553px'>
        <UsersUsage />
      </Box>
      <Box ml='53px'>right hand column</Box>
    </Box>
  );
};
