import { Box, Typography } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { Main } from 'src/layouts/Main';
import { RulesEngineContext } from 'src/context/RulesEngineContext';
import { Button } from 'src/components';
import { Map } from './Map';
import { EditSelectNetworks } from './EditSelectNetworks';
import { SelectEquipment } from './SelectEquipment';
import { AppContext } from 'src/context';
import { Breadcrumbs } from 'src/components/Breadcrumbs/index';

interface RulesSelectionProps {
  onCancel: () => void;
  ruleName: string;
}

export const EditRulesSelection: React.FC<RulesSelectionProps> = ({
  onCancel,
  ruleName,
}) => {
  const [, setParams] = useSearchParams();
  const {
    selectedAPNs,
    selectedEquipments,
    resetGeneralInfo,
    removeAllAPNs,
    removeAllEquipments,
    ruleType,
  } = useContext(RulesEngineContext);
  const { siteChanged } = useContext(AppContext);

  const showNextStepButton = () => {
    if (ruleType === 'THRESHOLD') {
      return selectedAPNs.length === 0 && selectedEquipments.length === 0;
    }
    if (ruleType === 'CAPACITY') {
      return selectedAPNs.length === 0;
    }
    return selectedAPNs.length === 0 || selectedEquipments.length === 0;
  };
  const isNextStepDisabled = showNextStepButton();

  useEffect(() => {
    if (siteChanged) {
      resetGeneralInfo();
      removeAllAPNs();
      removeAllEquipments();
      setParams({ step: 'type' });
    }
  }, [siteChanged]);

  const { organization, site, id } = useParams<{
    organization: string;
    site: string;
    id: string;
  }>();

  return (
    <Main overrideStyles={{ padding: 0 }} renderOutsideWrapper={<Map />}>
      <Box
        id='selection-container'
        display='flex'
        width='70%'
        sx={{
          height: 'calc(100vh - 50px)',
          clipPath: 'inset(0px -15px 0px 0px)',
          position: 'relative',
          zIndex: 1,
          overflowY: 'auto',
          padding: '24px',
        }}
      >
        <Box sx={{ width: '609px' }}>
          <Breadcrumbs breadcrumbs={[
            {
              title: 'Overview',
              url: '/',
            },
            {
              title: 'Rules',
              url: '/rules',
            },
            {
              title: ruleName,
              url: '/rules/' + String(organization) + '/' + String(site) + '/' + String(id),
            },
            {
              title: 'Edit',
              url: '/rules/edit/' + String(organization) + '/' + String(site) + '/' + String(id),
            },
            {
              title: 'Edit Selection',
            },
          ]}/>
          <Typography variant='h1' sx={{ mb: '45px' }}>
            Edit selection of {ruleName}
          </Typography>
          <EditSelectNetworks />
          {ruleType !== 'CAPACITY' && <SelectEquipment />}
          <Box mt='50px' mb='43px' display='flex' justifyContent='flex-end'>
            <Button kind='primary-ghost' onClick={onCancel}>
              Cancel
            </Button>
            <Button
              sx={{ ml: '15px' }}
              kind='primary'
              disabled={isNextStepDisabled}
              onClick={() => setParams({ step: 'specification' })}
            >
              Next step
            </Button>
          </Box>
          <Box height={20} />
        </Box>
      </Box>
    </Main>
  );
};
