import * as Yup from 'yup';

export const consumptionCustomDateSchema = Yup.object().shape({
  startTime: Yup.date()
    .max(Yup.ref('endTime'), "Start date can't be after end date")
    .typeError('Valid date is required'),
  endTime: Yup.date()
    .min(Yup.ref('startTime'), "End date can't be before start date")
    .typeError('Valid date is required'),
});
