import React, { FC, DragEvent, useCallback, useRef, useState } from 'react';
import {
  Box,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { Close, Document, Download } from '@carbon/icons-react';

import { Button } from 'src/components';
import { ReactComponent as Upload } from 'src/assets/upload.svg';
import { styles } from './styles';

interface BulkAllocateDevicesStepOneProps {
  selectedFile: File | null;
  selectFile: (file: File | null) => void;
  goToSecondStep: () => void;
  handleCloseModal: () => void;
}

export const BulkAllocateDevicesStepOne: FC<
  BulkAllocateDevicesStepOneProps
> = ({ selectedFile, selectFile, goToSecondStep, handleCloseModal }) => {
  const [dragging, setDragging] = useState(false);
  const fileInput = useRef<HTMLInputElement>(null);
  const [error, setError] = useState<boolean>(false);

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragging(true);
  };
  const handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragging(false);
  };
  const handleDrop = async (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragging(false);
    selectFile(event.dataTransfer.files[0]);
    await handleFileSelect(event);
  };

  const handleFileSelect = useCallback(
    (
      event: React.ChangeEvent<HTMLInputElement> | DragEvent<HTMLDivElement>,
    ) => {
      let file = null;
      if (event.target) {
        file = (event.target as HTMLInputElement).files?.[0];
      }
      if ((event as DragEvent<HTMLDivElement>).dataTransfer) {
        file = (event as DragEvent<HTMLDivElement>).dataTransfer.files[0];
      }
      if (file) {
        selectFile(file);
        setError(false);
      }
    },
    [selectFile],
  );

  const onClickUploadArea = () => {
    fileInput.current?.click();
  };
  const removeFile = () => {
    selectFile(null);
    if (fileInput.current) {
      fileInput.current.value = '';
    }
  };
  const handleFileUpload = () => {
    if (selectedFile === null) {
      setError(true);
    } else {
      goToSecondStep();
      setError(false);
    }
  };

  return (
    <>
      <DialogContent>
        <Box sx={styles.boxStepOne}>
          <Typography>Download template:</Typography>
          <IconButton sx={styles.iconButton}>
            <Download style={styles.downloadIcon} />
            <Link
              to={'/files/Bulk_Allocate_Request.xlsx'}
              target='_blank'
              download
              style={{ textDecoration: 'none' }}
            >
              <Typography sx={styles.downloadFormatText}>.xlsx</Typography>
            </Link>
          </IconButton>
          <IconButton sx={styles.iconButton}>
            <Download style={styles.downloadIcon} />
            <Link
              to={'/files/Bulk_Allocate_Request.csv'}
              target='_blank'
              download
              style={{ textDecoration: 'none' }}
            >
              <Typography sx={styles.downloadFormatText}>.csv</Typography>
            </Link>
          </IconButton>
        </Box>
        <Box
          sx={styles.boxStyle(dragging)}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          onClick={onClickUploadArea}
        >
          <Box sx={styles.uploadIcon}>
            <Upload fill='#006161' />
          </Box>
          <Box>
            <Typography sx={styles.dropFilesText}>
              Drop your files here (.xlsx or .csv)
            </Typography>
            <input
              type='file'
              accept='.csv, .xlsx'
              ref={fileInput}
              style={{ display: 'none' }}
              onChange={handleFileSelect}
            />
            <Box sx={styles.browseBox}>
              <Typography ml={'5px'} color='#006161'>
                or
              </Typography>
              <Typography sx={styles.browseText}>Browse...</Typography>
            </Box>
          </Box>
        </Box>
        {error && (
          <Typography sx={styles.errorText}>
            Add files by browse or dragging and dropping.
          </Typography>
        )}
        {selectedFile && (
          <Box sx={styles.selectedFileBox}>
            <Document size='24' color='#222222' />
            <Typography sx={styles.editIconText}>
              {selectedFile.name}
            </Typography>
            <IconButton onClick={removeFile}>
              <Close size='24' color='#000000' />
            </IconButton>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button kind='primary-ghost' onClick={handleCloseModal}>
          Cancel
        </Button>
        <Button kind='primary' onClick={handleFileUpload}>
          Send
        </Button>
      </DialogActions>
    </>
  );
};
