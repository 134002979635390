import { Slider, SliderProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledSlider = styled(Slider)<
  SliderProps & { futurePercentage: number; currentPercentage: number }
>(({ futurePercentage, currentPercentage }) => ({
  marginBottom: '7px',
  padding: '12px 0',
  height: 8,
  '& .MuiSlider-rail': {
    height: 5,
    borderRadius: 0,
    // eslint-disable-next-line max-len
    background: `linear-gradient(90deg, rgba(181,209,209,1) ${futurePercentage}%, rgba(85,207,187,1) ${futurePercentage}%)`,
    opacity: 1,
  },
  '& .MuiSlider-track': {
    // eslint-disable-next-line max-len
    background: `linear-gradient(90deg, #006161 ${currentPercentage}%, rgba(85,207,187,1) ${currentPercentage}%)`,
    height: 5,
    border: 'none',
    borderRadius: 0,
  },
  '& .MuiSlider-thumb': {
    height: 20,
    width: 20,
    backgroundColor: '#fff',
    border: '6px solid #006161',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    backgroundColor: 'unset',
  },
  '& .MuiSlider-mark': {
    backgroundColor: '#bfbfbf',
    height: 9,
    width: 1,
    marginTop: 7,
    '&.MuiSlider-markActive': {
      opacity: 1,
      backgroundColor: 'currentColor',
    },
  },
}));
