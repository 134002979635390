import { Box, Typography } from '@mui/material';
import { FC, useCallback, useContext, useEffect, useRef } from 'react';
import { AppContext } from 'src/context';
import Highcharts, { SeriesOptionsType } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useQuery } from '@tanstack/react-query';
import { QueryName } from 'src/enums/query';
import {
  ConsumptionAnalyticsCriteria,
  ConsumptionAnalyticsRange,
  getConsumptionAnalytics,
} from 'src/apis/consumption';
import { Button, Loading } from 'src/components';
import { useSearchParams } from 'react-router-dom';
import { formatInTimeZone } from 'date-fns-tz';
import { Reset } from '@carbon/icons-react';

const criteriaFilters = [
  {
    label: 'Uplink',
    value: 'UPLINK',
  },
  {
    label: 'Downlink',
    value: 'DOWNLINK',
  },
  {
    label: 'Latency',
    value: 'LATENCY',
  }
];

interface APNConsumptionGraphProps {
  org: string | undefined;
  level: 'SITE' | 'APN';
  ueData: {
    [key: string]: { data: Array<{ y: number; time: string }> };
  } | null;
  currentTime: number;
}

export const APNConsumptionGraph: FC<APNConsumptionGraphProps> = ({
  org,
  level,
  ueData,
  currentTime,
}) => {
  const { sidebarOpen, site } = useContext(AppContext);
  const [params, setParams] = useSearchParams({ criteria: 'LATENCY' });

  const range = params.get('range') ?? 'DAY';
  const criteria = params.get('criteria');
  const startTime = params.get('startTime');
  const endTime = params.get('endTime');
  const apn = params.get('apn');
  const ues = params.get('ues');

  const highchartsRef = useRef<HighchartsReact.RefObject>(null);

  const { data, isLoading } = useQuery(
    [
      QueryName.GET_CONSUMPTION_ANALYTICS,
      site,
      criteria,
      range,
      apn,
      startTime,
      endTime,
      currentTime,
    ],
    () => {
      return getConsumptionAnalytics({
        organization: org as string,
        site,
        criteria: criteria as ConsumptionAnalyticsCriteria,
        level,
        range: range as ConsumptionAnalyticsRange,
        apn: apn as string,
        startTime,
        endTime,
      });
    },
    {
      enabled:
        !!org &&
        !!site &&
        !!(
          (range !== 'CUSTOM' && range !== '') ||
          (range === 'CUSTOM' && startTime && endTime)
        ),
    },
  );

  const getKey = useCallback(() => {
    if (data) {
      let key = '';
      if (level === 'SITE') {
        key = data.data.site as string;
      } else {
        key = data.data.apn as string;
      }
      return key;
    }
  }, [data, level]);

  const getLimit = useCallback(() => {
    let limit = 0;
    switch (params.get('criteria')) {
      case 'LATENCY':
        limit = data?.data.limits.latency ?? 0;
        break;
      case 'UPLINK':
        limit = data?.data.limits.uplink ?? 0;
        break;
      case 'DOWNLINK':
        limit = data?.data.limits.downlink ?? 0;
        break;
      default:
        break;
    }
    return Math.round(limit * 0.85);
  }, [data, params]);

  const getData = useCallback(() => {
    if (data) {
      const key = getKey();
      if (key) {
        return data?.data.data[key]?.map((value) => ({
          y: value.value,
          custom: {
            time: value.time,
          },
        }));
      }
    }
  }, [data, getKey]);

  const getLabels = useCallback(() => {
    if (data) {
      let key = '';
      let dateFormat = '';
      if (level === 'SITE') {
        key = data.data.site as string;
      } else {
        key = data.data.apn as string;
      }

      switch (range) {
        case 'FIVE_MIN':
          dateFormat = 'HH:mm:ss';
          break;
        case 'FIFTEEN_MIN':
          dateFormat = 'HH:mm:ss';
          break;
        case 'HOUR':
          dateFormat = 'HH:mm';
          break;
        case 'DAY':
          dateFormat = 'HH:mm';
          break;
        case 'WEEK':
          dateFormat = 'eee dd';
          break;
        case 'MONTH':
          dateFormat = 'LLL dd';
          break;
        case 'CUSTOM':
          dateFormat = 'LLL dd, yyy HH:mm';
          break;
      }

      return data?.data.data[key]?.map((value) =>
        formatInTimeZone(value.time, 'UTC', dateFormat),
      );
    }
  }, [data, level, range]);

  const getBubblesData = useCallback(() => {
    if (data) {
      const limit = getLimit();
      const key = getKey();
      if (limit && key) {
        const bubblesData: Array<{
          x: number;
          y: number;
          z: number;
          custom: {
            time: string;
          };
        }> = [];
        data?.data.data[key]?.forEach((value, index) => {
          if (value.value > limit) {
            bubblesData.push({
              x: index,
              y: value.value,
              z: value.value,
              custom: {
                time: value.time,
              },
            });
          }
        });
        return bubblesData;
      }
    }
  }, [data, getKey, getLimit]);

  useEffect(() => {
    if (data && ueData) {
      const ueGraphInterval = setInterval(() => {
        if (highchartsRef.current) {
          clearInterval(ueGraphInterval);
          const ueCommonConfig: SeriesOptionsType = {
            type: 'line',
            marker: {
              enabled: false,
            },
            zones: [
              {
                color: '#DFFFB4',
                fillColor: '#177169',
              },
            ],
            lineWidth: 2,
          };
          Object.entries(ueData).forEach(([key, value]) => {
            highchartsRef.current?.chart.series.forEach((series, index) => {
              if (series.name === key) {
                highchartsRef.current?.chart.series[index].remove(false);
              }
            });
            highchartsRef.current?.chart.addSeries(
              {
                ...ueCommonConfig,
                name: key,
                data: [
                  ...value.data.map((ueValue) => ({
                    y: ueValue.y,
                    custom: {
                      time: ueValue.time,
                    },
                  })),
                ],
              },
              false,
            );
          });
          highchartsRef.current?.chart.redraw();
        }
      }, 200);
    }
  }, [data, ueData]);

  const getMaxValue = useCallback(() => {
    const limit = getLimit();
    const key = getKey();
    let max = 0;
    if (limit && key) {
      data?.data.data[key]?.forEach((value) => {
        if (value.value >= max) {
          max = value.value;
        }
      });
      max = max > limit ? max : limit;
    }
    return Math.ceil(max + 20);
  }, [data, getKey, getLimit]);

  const options: Highcharts.Options = {
    chart: {
      height: 350,
      width: 1142,
      backgroundColor: '#006161',
      zooming: {
        type: 'x',
        resetButton: {
          theme: {
            fill: '#fff',
            stroke: '#fff',
            style: {
              color: '#006161',
            },
          },
        },
      },
    },
    boost: {
      enabled: true,
      useGPUTranslations: true,
    },
    credits: {
      enabled: false,
    },
    accessibility: {
      enabled: false,
    },
    title: {
      text: '',
    },
    legend: {
      enabled: false,
    },
    xAxis: {
      lineColor: 'transparent',
      tickColor: 'transparent',
      type: 'category',
      categories: getLabels(),
      labels: {
        style: {
          color: '#B3D0D0',
          fontSize: '10px',
        },
      },
    },
    yAxis: {
      title: {
        text: '',
      },
      min: 0,
      max: getMaxValue(),
      labels: {
        format: `{text} ${criteria === 'LATENCY' ? 'ms' : 'mbps'}`,
        style: {
          color: '#B3D0D0',
          fontSize: '10px',
        },
      },
      gridLineColor: '#176F6F',
      plotLines: [
        {
          value: getLimit(),
          width: 1,
          color: '#FFFFFF',
          zIndex: 4,
          dashStyle: 'Dash',

          label: {
            text: 'defined limit',
            style: {
              color: '#A1C5C5',
              fontSize: '10px',
            },
          },
        },
      ],
    },
    tooltip: {
      useHTML: true,
      backgroundColor: '#F1FCFA',
      borderColor: 'transparent',
      shadow: {
        offsetX: 0,
        offsetY: 0,
        width: 6,
        color: '#00000029',
      },
      shape: 'square',
      formatter: function () {
        let suffix = '';
        if (criteria === 'LATENCY') {
          suffix = 'ms';
        } else {
          suffix = 'mbps';
        }
        // eslint-disable-next-line max-len
        let content = `<p style='text-align: center; margin: 0; color: #222222; font-size: 16px; font-weight: bold;'>${this.y}<span style='font-size: 12px;'>${suffix}</span></p>`;
        const spikeTime = formatInTimeZone(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          this.point.custom.time,
          'UTC',
          'LLL dd, yyy HH:mm',
        );
        // eslint-disable-next-line max-len
        content += `<p style='text-align: center; margin: 0; color: #659F9F; font-size: 12px; margin-top: 4px;'>${spikeTime}</p>`;
        return content;
      },
    },
    plotOptions: {
      bubble: {
        sizeBy: 'area',
        minSize: 10,
        maxSize: 20,
        color: 'transparent',
        marker: {
          lineColor: '#fff',
          fillColor: '#fff',
        },
        cursor: 'pointer',
        opacity: 0.9,
        states: {
          inactive: {
            opacity: 0.9,
          },
        },
        events: {
          click: (event) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const instant = event.point.custom.time;
            setParams({
              criteria,
              range,
              ...(apn && { apn }),
              ...(ues && { ues }),
              ...(startTime && { startTime }),
              ...(endTime && { endTime }),
              ...(instant && { instant }),
            });
          },
        },
      },
      line: {
        states: {
          inactive: {
            opacity: 1,
          },
        },
      },
    },
    series: [
      {
        name: 'apn_chart',
        type: 'line',
        data: getData(),
        marker: {
          enabled: false,
        },
        zones: [
          {
            value: getLimit(),
            color: '#55CFBB',
            fillColor: '#127A75',
          },
          {
            color: '#FFFFFF',
            fillColor: '#A0CAC8',
          },
        ],
        lineWidth: 4,
      },
      {
        name: 'bubble_chart',
        type: 'bubble',
        data: getBubblesData(),
      },
    ],
  };

  return (
    <Box
      bgcolor='#006161'
      height='540px'
      sx={{
        marginLeft: sidebarOpen ? '250px' : '60px',
      }}
    >
      <Box maxWidth='1200px' marginX='auto' py='29px' px='24px'>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Typography
            textTransform='capitalize'
            fontWeight='bold'
            fontSize={16}
            color='#FFFFFF'
          >
            {apn}
          </Typography>
          <Button
            kind='custom'
            textColor='#fff'
            border='1px solid #fff'
            onClick={() =>
              setParams({
                range: 'FIVE_MIN',
                criteria: 'UPLINK',
                ...(apn && { apn }),
                ues: '',
              })
            }
          >
            <Reset style={{ marginRight: '8px' }} /> Reset
          </Button>
        </Box>
        <Box mb='46px' mt='18px'>
          {criteriaFilters.map((criteriaFilter, index) => (
            <Button
              key={criteriaFilter.value}
              kind='custom'
              backgroundColor={
                params.get('criteria') === criteriaFilter.value
                  ? '#fff'
                  : '#006161'
              }
              textColor={
                params.get('criteria') === criteriaFilter.value
                  ? '#006161'
                  : '#fff'
              }
              hoverBackgroundColor={
                params.get('criteria') === criteriaFilter.value
                  ? '#fff'
                  : '#006161'
              }
              borderLeft={index === 0 ? '1px solid #fff' : undefined}
              borderTop='1px solid #fff'
              borderRight='1px solid #fff'
              borderBottom='1px solid #fff'
              onClick={() =>
                setParams({
                  criteria: criteriaFilter.value,
                  range,
                  ...(startTime && { startTime }),
                  ...(endTime && { endTime }),
                  ...(apn && { apn }),
                  ...(ues && { ues }),
                })
              }
            >
              {criteriaFilter.label}
            </Button>
          ))}
        </Box>
        <Loading isLoading={isLoading} minHeight='300px'>
          <HighchartsReact
            ref={highchartsRef}
            highcharts={Highcharts}
            options={options}
          />
        </Loading>
      </Box>
    </Box>
  );
};
