import { FC, useState } from 'react';
import { Box } from '@mui/material';
import { Button } from 'src/components';
import { BulkAction } from './BulkAction';
import { TrashCan } from '@carbon/icons-react';
import { styles } from './styles';
import { RuleConfiguration } from '../../types/rule';

interface BulkActionMenuProps {
  organization: string;
  site: string;
  rules: RuleConfiguration[],
  selectedRuleIds: string[];
}

export const BulkActionMenu: FC<BulkActionMenuProps> = ({
  organization,
  site,
  rules,
  selectedRuleIds,
}) => {
  const [action, setAction] = useState<string | null>(null);
  const isAnySelectedRuleExpired = rules.some((rule: RuleConfiguration) => rule.state === 'EXPIRED' && selectedRuleIds.includes(rule.id));

  return (
    <Box
      sx={{
        display: 'flex',
        padding: '6px 8px',
        bgColor: 'background.paper',
        borderRadius: 1,
      }}
    >
      <Button
        sx={styles.actionButtons}
        kind='custom'
        disabled={isAnySelectedRuleExpired}
        onClick={() => {
          setAction('Activate');
        }}>Activate</Button>

      <Button
        sx={styles.actionButtons}
        kind='custom'
        disabled={isAnySelectedRuleExpired}
        onClick={() => {
          setAction('Deactivate');
        }}>Deactivate</Button>

      <Button
        kind='custom'
        textColor='#F20000'
        backgroundColor='#FFFFFF'
        border='1px solid #F20000'
        hoverBackgroundColor='#EEF0F2'
        padding='5px 24px'
        onClick={() => {
          setAction('Delete');
        }}
      >
        <TrashCan style={{ margin: '0 10px 0 0' }} />
        Delete
      </Button>
      {action && (
        <BulkAction
          ids={selectedRuleIds}
          organization={organization}
          site={site}
          action={action}
          setAction={() => setAction(null)}
        />
      )}
    </Box>
  );
};
