import { useContext } from 'react';
import { ButtonBase, Typography, Box, Button } from '@mui/material';
import {
  ThirtyFpsSelectOutlined,
  TimelineOutlined,
  Timeline,
  Equalizer,
} from '@mui/icons-material';

import { StyleButtonContentContainer } from '../styles';
import { AddWidgetContext } from 'src/context/AddWidgetContext';
import { KPIWidget } from 'src/types/widget';
import { TimeSeriesChartType, WidgetValueType } from 'src/enums/widget';
import { WidgetFieldsContainer } from '../WidgetFieldsContainer';
import { styles } from './styles';

interface TypeProps {
  onPreviousStep: () => void;
  onNextStep: () => void;
}

export const Type: React.FC<TypeProps> = ({ onPreviousStep, onNextStep }) => {
  const { widget: _widget, updateWidget } = useContext(AddWidgetContext);

  const widget = _widget as KPIWidget;

  const onSubmit = () => {
    onNextStep();
  };

  return (
    <WidgetFieldsContainer
      title="Select Metric"
      actions={
        <>
          <Button onClick={onPreviousStep}>Previous Step</Button>
          <Button
            variant="contained"
            onClick={onSubmit}
            disabled={!widget?.type}
          >
            Next Step
          </Button>
        </>
      }
    >
      <Box mt={1}>
        <Box mt={2} display="flex" justifyContent="center">
          <ButtonBase
            sx={styles.widgetTypeButtonContainer}
            onClick={() =>
              updateWidget({
                type: WidgetValueType.CURRENT,
                timeSeriesChartType: undefined,
              })
            }
          >
            <StyleButtonContentContainer
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              active={widget?.type === WidgetValueType.CURRENT}
            >
              <ThirtyFpsSelectOutlined sx={styles.widgetTypeButtonIcon} />
              <Typography variant="body1">Current</Typography>
            </StyleButtonContentContainer>
          </ButtonBase>
          <ButtonBase
            sx={styles.widgetTypeButtonContainer}
            onClick={() =>
              updateWidget({
                type: WidgetValueType.RANGE,
              })
            }
          >
            <StyleButtonContentContainer
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              active={widget?.type === WidgetValueType.RANGE}
            >
              <TimelineOutlined sx={styles.widgetTypeButtonIcon} />
              <Typography variant="body1">Range</Typography>
            </StyleButtonContentContainer>
          </ButtonBase>
        </Box>
      </Box>
      {widget?.type === WidgetValueType.RANGE && (
        <Box mt={2}>
          <Typography>Select Chart Type</Typography>
          <Box mt={2} display="flex" justifyContent="center">
            <ButtonBase
              sx={styles.widgetTypeButtonContainer}
              onClick={() =>
                updateWidget({
                  timeSeriesChartType: TimeSeriesChartType.LINE,
                })
              }
            >
              <StyleButtonContentContainer
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                active={
                  widget?.timeSeriesChartType === TimeSeriesChartType.LINE
                }
              >
                <Timeline sx={styles.widgetTypeButtonIcon} />
                <Typography variant="body1">Line</Typography>
              </StyleButtonContentContainer>
            </ButtonBase>
            <ButtonBase
              sx={styles.widgetTypeButtonContainer}
              onClick={() =>
                updateWidget({
                  timeSeriesChartType: TimeSeriesChartType.COLUMN,
                })
              }
            >
              <StyleButtonContentContainer
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                active={
                  widget?.timeSeriesChartType === TimeSeriesChartType.COLUMN
                }
              >
                <Equalizer sx={styles.widgetTypeButtonIcon} />
                <Typography variant="body1">Column</Typography>
              </StyleButtonContentContainer>
            </ButtonBase>
          </Box>
        </Box>
      )}
    </WidgetFieldsContainer>
  );
};
