import { Main } from 'src/layouts/Main';
import { Button } from 'src/components';
import { RulesTable } from './RulesTable';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Breadcrumbs } from 'src/components/Breadcrumbs/index';
import React from 'react';
import { useUserRole } from 'src/utils/user';

export const Rules = () => {
  const navigate = useNavigate();
  const { isReadyOnlyUser } = useUserRole();

  return (
    <Main>
      <Breadcrumbs
        breadcrumbs={[
          {
            title: 'Overview',
            url: '/',
          },
          {
            title: 'Rules',
          },
        ]}
      />
      <Box
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        mb={3}
      >
        <Typography variant='h1'>Rules</Typography>
        {!isReadyOnlyUser() && (
          <Button kind='primary' onClick={() => navigate('/rule?step=type')}>
            Create rule
          </Button>
        )}
      </Box>
      <RulesTable />
    </Main>
  );
};
