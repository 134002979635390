import { FC, useContext, useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  ListItemText,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AddCircleOutlineOutlined } from '@mui/icons-material';
import { ChooseItem } from '@carbon/icons-react';

import {
  Button,
  ChannelManagementDialog,
  Checkbox,
  Loading,
  Radio,
  Switch,
  TextField,
} from 'src/components';
import {
  AppContext,
  AuthContext,
  GeneralInfo,
  RulesEngineContext,
  ToastContext,
} from 'src/context';
import { specificationThresholdValidationSchema } from 'src/yup-validations/rules-engine';
import { QueryName } from 'src/enums/query';
import { getAllUserEmails } from 'src/apis/access-management';
import { getChannels } from 'src/apis/channel';
import { CreateRuleData, editRule, getRuleTrend } from 'src/apis/rule';
import { RuleConfiguration } from 'src/types/rule';
import { StyledMenuItem } from '../GeneralInformation/styles';
import { PastTrend } from '../SpecificationThreshold/PastTrend';
import { SelectedNetworksAndEquipments } from '../SpecificationThreshold/SelectedNetworksAndEquipments';

interface EditSpecificationThresholdProps {
  onCancel: () => void;
  ruleConfiguration: RuleConfiguration;
}

const styles = {
  editBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    textDecoration: 'underline',
    ':hover': {
      cursor: 'pointer',
      textDecoration: 'none',
    },
  },
  editText: {
    fontSize: '14px',
    color: '#006161',
    marginLeft: '10px',
  },
};

export const EditSpecificationThreshold: FC<
  EditSpecificationThresholdProps
> = ({ onCancel, ruleConfiguration }) => {
  const [, setParams] = useSearchParams();
  const navigate = useNavigate();

  const { showToast } = useContext(ToastContext);
  const { user } = useContext(AuthContext);
  const { site } = useContext(AppContext);
  const {
    generalInfo,
    ruleType,
    selectedAPNs,
    selectedEquipments,
    addGeneralInfo,
    selectThresholdValue,
  } = useContext(RulesEngineContext);

  const [enableNotifications, setEnableNotifications] = useState({
    email: ruleConfiguration.notification?.email,
    slack: ruleConfiguration.notification?.slack,
    teams: ruleConfiguration.notification?.teams,
  });
  const [addChannelDialogVisible, setAddChannelDialogVisible] = useState(false);

  const queryClient = useQueryClient();

  const onSubmit = (data: GeneralInfo) => {
    addGeneralInfo({ ...data });
    const finalData: CreateRuleData = {
      id: ruleConfiguration.id,
      organization: user?.organizations?.[0] as string,
      site,
      name: data.ruleName,
      description: data.description,
      type: ruleType,
      state: data.state,
      from: data.startingFrom
        ? new Date(data.startingFrom).toISOString()
        : undefined,
      to: data.endingOn ? new Date(data.endingOn).toISOString() : undefined,
      createdBy: user?.name as string,
      updatedBy: user?.name as string,
      apns: selectedAPNs.map((apn) => apn.name),
      ues: selectedEquipments
        .filter((ue) => ue.type === 'equipment')
        .map((ue) => ue.id),
      ueNames: selectedEquipments
        .filter((ue) => ue.type === 'equipment')
        .reduce((prev, curr) => {
          return {
            ...prev,
            [curr.id]: curr.name,
          };
        }, {}),
      ueGroups: selectedEquipments
        .filter((ue) => ue.type === 'equipment_group')
        .reduce((prev, curr) => {
          return {
            ...prev,
            [curr.id]: curr.name,
          };
        }, {}),
      priorityMap: selectedEquipments.reduce((prev, curr, index) => {
        return {
          ...prev,
          [index + 1]: curr.id,
        };
      }, {}),
      thresholdType: data.thresholdType,
      threshold: data.threshold,
      notifyOnce: true,
      notification: {
        email: data.notificationEmails.length > 0,
        emailContacts: data.notificationEmails,
        slack: data.slackChannels.length > 0,
        slackChannels: data.slackChannels.reduce((prev, curr) => {
          return {
            ...prev,
            [curr.id]: curr.name,
          };
        }, {}),
        teams: data.teamsChannels.length > 0,
        teamsChannels: data.teamsChannels.reduce((prev, curr) => {
          return {
            ...prev,
            [curr.id]: curr.name,
          };
        }, {}),
      },
    };
    createRuleMutation.mutate(finalData);
  };

  const {
    control,
    formState: { isValid, errors },
    setValue,
    watch,
    handleSubmit,
  } = useForm({
    defaultValues: {
      ruleName: ruleConfiguration.name,
      description: ruleConfiguration.description,
      startingFrom: new Date(ruleConfiguration.from as string),
      state: ruleConfiguration.state,
      endingOn: ruleConfiguration.to
        ? new Date(ruleConfiguration.to)
        : generalInfo.endingOn,
      notificationEmails: generalInfo.notificationEmails,
      slackChannels: generalInfo.slackChannels,
      teamsChannels: generalInfo.teamsChannels,
      threshold: ruleConfiguration.threshold,
      thresholdType: ruleConfiguration.thresholdType,
    },
    resolver: yupResolver(specificationThresholdValidationSchema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const createRuleMutation = useMutation<
    AxiosResponse,
    AxiosError<{ title: string }>,
    CreateRuleData
  >({
    mutationFn: editRule,
    onSuccess: () => {
      showToast('Rule updated successfully!', 'success');
      navigate('/rules');
    },
    onError: (error) =>
      showToast(
        error.response?.data?.title
          ? error.response?.data.title
          : 'An error occurred while editing a rule. Please try again later',
        'error',
      ),
  });

  const type = watch('thresholdType');
  const isLatency = type === 'Latency';
  const value = Number(watch('threshold'));

  const {
    data: trendData,
    isLoading: trendDataLoading,
    isError: trendDataError,
  } = useQuery(
    [QueryName.GET_THRESHOLD_PAST_TREND, type],
    () =>
      getRuleTrend({
        organization: user?.organizations?.[0] as string,
        site,
        range: 'WEEK',
        apns: selectedAPNs.map((apn) => apn.name),
        ueGroups: selectedEquipments
          .filter((ue) => ue.type === 'equipment_group')
          .reduce((prev, curr) => {
            return {
              ...prev,
              [curr.id]: curr.name,
            };
          }, {}),
        ues: selectedEquipments
          .filter((ue) => ue.type === 'equipment')
          .map((ue) => ue.id),
        metricType: type,
        includeMax: true,
        includeRecommended: true,
        level: 'IMSI',
      }),
    {
      enabled:
        !!user?.organizations?.length &&
        !!site &&
        !!(!!selectedAPNs.length || !!selectedEquipments.length),
    },
  );

  const graphData = {
    ...trendData?.data,
    recommendedValue: value,
  };

  const recommendation = trendData?.data?.recommendedValue;

  const { data: emailsData } = useQuery(
    [QueryName.GET_ALL_USER_EMAILS],
    () => getAllUserEmails(user?.organizations?.[0] as string),
    {
      enabled: !!user?.organizations?.[0],
      onError: () =>
        showToast('Unable to get users list. Please try again later!', 'error'),
    },
  );

  const { data: channelsData, refetch: refetchChannels } = useQuery(
    [QueryName.GET_ALL_CHANNELS],
    () => getChannels(user?.organizations?.[0] as string),
    {
      enabled: !!user?.organizations?.[0],
    },
  );

  const handleThresholdValueChange = (value: string) => {
    setValue('threshold', parseInt(value));
  };

  useEffect(() => {
    setValue(
      'notificationEmails',
      ruleConfiguration.notification.emailContacts,
    );
    const slackId = Object.keys(
      ruleConfiguration.notification.slackChannels
        ? ruleConfiguration.notification.slackChannels
        : [],
    );
    const defaultSlacks = channelsData?.data?.channels?.filter((channel) =>
      slackId.includes(channel.id),
    );
    setValue('slackChannels', defaultSlacks ?? []);

    const teamsId = Object.keys(
      ruleConfiguration.notification.teamsChannels
        ? ruleConfiguration.notification.teamsChannels
        : [],
    );
    const defaultTeams = channelsData?.data?.channels?.filter((teams) =>
      teamsId.includes(teams.id),
    );
    setValue('teamsChannels', defaultTeams ?? []);
  }, [setValue, channelsData]);

  return (
    <Box>
      <Box display='flex' justifyContent='space-between' mt='48px'>
        <Box width='50%' mr={2}>
          <Box
            display='flex'
            justifyContent='flex-start'
            alignItems='center'
            mb='30px'
          >
            <Typography variant='h2'>Rule</Typography>
            <Box
              sx={styles.editBox}
              onClick={() => setParams({ step: 'selection' })}
            >
              <Box ml='25px' mt='8px'>
                <ChooseItem size='16' color='#006161' />
              </Box>
              <Typography sx={styles.editText}>Edit selection</Typography>
            </Box>
          </Box>
          <Stack
            display='flex'
            flexDirection='row'
            justifyContent='space-between'
            my={2}
          >
            <Box width='50%'>
              <Box>
                <Typography variant='h3' mb='15px'>
                  Parameters
                </Typography>
                <Box minWidth='280px'>
                  <Controller
                    name='thresholdType'
                    control={control}
                    render={({ field }) => (
                      <RadioGroup row {...field}>
                        <Radio label='Latency' value='Latency' />
                        <Radio label='Uplink' value='Uplink' />
                        <Radio label='Downlink' value='Downlink' />
                      </RadioGroup>
                    )}
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='row'
                  justifyContent='flex-start'
                  m={0}
                  p={0}
                  alignItems='center'
                >
                  <Controller
                    name='threshold'
                    control={control}
                    render={({ field: { onChange, ...field } }) => (
                      <TextField
                        label='Threshold'
                        size='small'
                        type='number'
                        error={
                          !isValid && errors.threshold?.message
                            ? errors.threshold?.message
                            : undefined
                        }
                        onChange={({ target: { value } }) => {
                          onChange(value);
                          handleThresholdValueChange(value);
                        }}
                        InputProps={{
                          sx: {
                            width: '185px',
                            marginBottom: '10px',
                          },
                        }}
                        sx={{
                          display: 'block',
                          'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button':
                            {
                              WebkitAppearance: 'none',
                              margin: 0,
                            },
                          'input[type=number]': {
                            MozAppearance: 'textfield',
                          },
                        }}
                        {...field}
                      />
                    )}
                  />
                  <Typography
                    height='40px'
                    m={1}
                    color='#888888'
                    fontWeight='bold'
                  >
                    {isLatency ? 'ms' : 'mbps'}
                  </Typography>
                </Box>
              </Box>
              <Typography color='#888888' fontSize='14px'>
                Recommendation: {recommendation}{' '}
                {trendDataLoading ? '' : isLatency ? 'ms' : 'mbps'}
              </Typography>
            </Box>
            <SelectedNetworksAndEquipments />
          </Stack>
          <Box width={'100%'}>
            <Loading
              isLoading={trendDataLoading}
              error={
                trendDataError ? 'Unable to get the trend data' : undefined
              }
            >
              <PastTrend trendData={graphData} />
            </Loading>
          </Box>
        </Box>
        <Box width='50%' ml={2}>
          <Box mr='95px'>
            <Typography variant='h2' mb='27px'>
              General information
            </Typography>
            <Controller
              name='description'
              control={control}
              render={({ field }) => (
                <TextField
                  label='Description'
                  size='small'
                  multiline
                  rows={2}
                  InputProps={{
                    sx: {
                      width: '485px',
                    },
                  }}
                  error={
                    !isValid && errors.description?.message
                      ? errors.description?.message
                      : undefined
                  }
                  sx={{ display: 'block', marginBottom: '10px' }}
                  {...field}
                />
              )}
            />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Box display='flex' justifyContent='space-between'>
                <Controller
                  name='startingFrom'
                  control={control}
                  render={({ field }) => (
                    <DateTimePicker
                      label='Starting from (optional)'
                      inputFormat='dd/MM/yyyy HH:mm'
                      renderInput={({ error: _, ...rest }) => (
                        <TextField
                          sx={{ width: '236px' }}
                          error={
                            !isValid && errors.startingFrom?.message
                              ? errors.startingFrom?.message
                              : undefined
                          }
                          {...rest}
                        />
                      )}
                      {...field}
                    />
                  )}
                />

                <Controller
                  name='endingOn'
                  control={control}
                  render={({ field }) => (
                    <DateTimePicker
                      label='Ending on (optional)'
                      inputFormat='dd/MM/yyyy HH:mm'
                      renderInput={({ error: _, ...rest }) => (
                        <TextField
                          sx={{ width: '236px' }}
                          error={
                            !isValid && errors.endingOn?.message
                              ? errors.endingOn?.message
                              : undefined
                          }
                          {...rest}
                        />
                      )}
                      {...field}
                    />
                  )}
                />
              </Box>
            </LocalizationProvider>
            <Typography variant='h4' mt='35px' mb='7px'>
              Rule status
            </Typography>
            <Controller
              name='state'
              control={control}
              render={({ field }) => (
                <RadioGroup row {...field}>
                  <Radio label='Active' value='ACTIVE' />
                  <Radio label='Inactive' value='INACTIVE' />
                </RadioGroup>
              )}
            />

            <Box
              display='flex'
              alignItems='baseline'
              marginBottom='22px'
              mt='36px'
            >
              <Typography variant='h2' sx={{ marginRight: '30px' }}>
                Notifications
              </Typography>
              <Box
                display='flex'
                alignItems='center'
                sx={{ cursor: 'pointer' }}
                onClick={() => setAddChannelDialogVisible(true)}
              >
                <AddCircleOutlineOutlined
                  sx={{
                    height: 16,
                    fill: '#006161',
                  }}
                />
                <Typography
                  fontSize={14}
                  color='#006161'
                  sx={{
                    textDecoration: 'underline',
                    ':hover': { textDecoration: 'none' },
                  }}
                >
                  Add a channel
                </Typography>
              </Box>
            </Box>
            <Box display='flex' alignItems='center' mb='12px'>
              <Typography mr='64px' color='#222222'>
                Email
              </Typography>
              <Switch
                checked={enableNotifications.email}
                onChange={(_, checked) => {
                  setEnableNotifications({
                    ...enableNotifications,
                    email: checked,
                  });
                  setValue('notificationEmails', []);
                }}
              />
            </Box>
            {enableNotifications.email && (
              <Controller
                name='notificationEmails'
                control={control}
                render={({ field: { onChange, ...rest } }) => (
                  <Autocomplete
                    multiple
                    disableCloseOnSelect
                    options={emailsData?.data ?? []}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label='Contacts'
                        sx={{ width: '486px' }}
                      />
                    )}
                    onChange={(_, value) => onChange(value)}
                    renderOption={(props, option, { selected }) => (
                      <StyledMenuItem {...props}>
                        <Checkbox checked={selected} />
                        <ListItemText primary={option} />
                      </StyledMenuItem>
                    )}
                    {...rest}
                  />
                )}
              />
            )}
            <Box mt='26px'>
              <Typography variant='h4'>Push notifications</Typography>
              <Box display='flex' alignItems='center' mt='12px'>
                <Typography color='#222222' mr='64px'>
                  Slack
                </Typography>
                <Switch
                  checked={enableNotifications.slack}
                  onChange={(_, checked) => {
                    setEnableNotifications({
                      ...enableNotifications,
                      slack: checked,
                    });
                    setValue('slackChannels', []);
                  }}
                />
              </Box>
              {enableNotifications.slack && (
                <Controller
                  name='slackChannels'
                  control={control}
                  render={({ field: { onChange, ...rest } }) => (
                    <Autocomplete
                      multiple
                      disableCloseOnSelect
                      options={
                        channelsData?.data?.channels.filter(
                          (channel) => channel.type === 'SLACK',
                        ) ?? []
                      }
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label='Channels'
                          sx={{ width: '486px' }}
                        />
                      )}
                      onChange={(_, value) => onChange(value)}
                      renderOption={(props, option, { selected }) => (
                        <StyledMenuItem {...props}>
                          <Checkbox checked={selected} value={option.id} />
                          <ListItemText primary={option.name} />
                        </StyledMenuItem>
                      )}
                      {...rest}
                    />
                  )}
                />
              )}
              <Box display='flex' alignItems='center' mt='6px' mb='6px'>
                <Typography color='#222222' mr='56px'>
                  Teams
                </Typography>
                <Switch
                  checked={enableNotifications.teams}
                  onChange={(_, checked) => {
                    setEnableNotifications({
                      ...enableNotifications,
                      teams: checked,
                    });
                    setValue('teamsChannels', []);
                  }}
                />
              </Box>
              {enableNotifications.teams && (
                <Controller
                  name='teamsChannels'
                  control={control}
                  render={({ field: { onChange, ...rest } }) => (
                    <Autocomplete
                      multiple
                      disableCloseOnSelect
                      options={
                        channelsData?.data?.channels.filter(
                          (channel) => channel.type === 'TEAMS',
                        ) ?? []
                      }
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label='Channels'
                          sx={{ width: '486px' }}
                        />
                      )}
                      onChange={(_, value) => onChange(value)}
                      renderOption={(props, option, { selected }) => (
                        <StyledMenuItem {...props}>
                          <Checkbox checked={selected} />
                          <ListItemText primary={option.name} />
                        </StyledMenuItem>
                      )}
                      {...rest}
                    />
                  )}
                />
              )}
            </Box>
            <ChannelManagementDialog
              open={addChannelDialogVisible}
              onSave={async () => {
                setAddChannelDialogVisible(false);
                await refetchChannels();
              }}
              onCancel={() => setAddChannelDialogVisible(false)}
            />
          </Box>
          <Box mt='36px' mb='43px' display='flex' justifyContent='flex-end'>
            <Button kind='primary-ghost' onClick={onCancel}>
              Cancel
            </Button>
            <Button
              sx={{ ml: '15px' }}
              padding='8px 46px'
              kind='primary'
              disabled={createRuleMutation.isLoading}
              onClick={handleSubmit(onSubmit)}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
