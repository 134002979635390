import { points, bbox } from '@turf/turf';
import { Box, SvgIcon, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { GeoJSONSourceOptions } from 'mapbox-gl';
import { useSearchParams } from 'react-router-dom';
import Map_, { Layer, MapRef, Source, Marker, Popup } from 'react-map-gl';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { getSite } from 'src/apis/sites';
import { QueryName } from 'src/enums/query';
import { ReactComponent as UEStatic } from 'src/assets/UE-static.svg';
import { ReactComponent as UEMobile } from 'src/assets/UE-mobile.svg';
import {
  getAPNsInstantAnalytics,
  getCoordinates,
  getPastCoordinates,
} from 'src/apis/geo-service';
import {
  AppContext,
  AuthContext,
  GeoMapAPN,
  GeoMapContext,
  GeoMapUE,
  GeoMapUEGroup,
  Point,
} from 'src/context';
import { dateToISO8601 } from 'src/utils/date';
import { differenceInMinutes } from 'date-fns';
import { styles } from 'src/views/Geomap/Map/styles';
import { Checkmark, Close, WarningAlt } from '@carbon/icons-react';

export const Map: React.FC = () => {
  const { user } = useContext(AuthContext);
  const { site, sidebarOpen } = useContext(AppContext);
  const { apns, ues, ueGroups, selectedDate } = useContext(GeoMapContext);

  const [ueCoordinatesBackup, setUECoordinatesBackup] = useState<{
    [key: string]: Point | null;
  }>({});

  const [params] = useSearchParams({
    criteria: 'LATENCY',
  });

  const criteria = params.get('criteria') ?? 'LATENCY';

  const mapRef = useRef<MapRef>(null);

  const [hoveredStateId, setHoveredStateId] = useState<number | null>(null);
  const [hoverInfo, setHoverInfo] = useState<{
    x: number;
    y: number;
    name: string;
    colorValue: string;
    latency: number;
    uplink: number;
    downlink: number;
    confidence: number | null;
  } | null>(null);
  const [cursor, setCursor] = useState<string>('grab');
  const [popup, setPopup] = useState<{
    name: string;
    coordinates: {
      latitude: number;
      longitude: number;
    };
  } | null>(null);

  // TODO: use site coordinates to set center of map,
  // but it might not be required since we display APNs by default,
  // and we calculate fitBounds and update the map anyway
  useQuery(
    [QueryName.GET_SITE_DETAILS, user?.organizations?.[0], site],
    () => getSite(site, user?.organizations?.[0] as string),
    {
      enabled: !!user?.organizations?.[0] && !!site && false,
    },
  );

  const fixDateForAnalytics = (value?: string | null) => {
    if (value) {
      const date = new Date(value);
      const diff = parseInt(differenceInMinutes(date, new Date()).toString());
      if (diff > 0) {
        date.setSeconds(0);
        date.setMinutes(0);
      }
      return `${dateToISO8601(date?.toISOString())}Z`;
    }
  };

  const { data: apnsAnalyticsData } = useQuery(
    [
      QueryName.GET_APNS_ANALYTICS_DATA,
      user?.organizations?.[0],
      'geomap_apns_analytics_data',
      criteria,
      apns,
      selectedDate,
    ],
    () =>
      getAPNsInstantAnalytics({
        organization: user?.organizations?.[0] as string,
        site,
        apns: apns?.map((apn) => apn.name)?.join(',') ?? '',
        dateTime: fixDateForAnalytics(selectedDate?.toISOString()),
        criteria,
      }),
    {
      enabled:
        !!user?.organizations?.length &&
        !!site &&
        !!apns.length &&
        !!selectedDate,
      keepPreviousData: true,
    },
  );

  const getUniqueUEs = (
    ueGroups: GeoMapUEGroup[],
    ues: GeoMapUE[],
    mobileOnly?: boolean,
  ) => {
    const tempUEs: string[] = [];
    ues.forEach((ue) => {
      if (mobileOnly) {
        if (getUEType(ue.type) === 'Mobile') {
          tempUEs.push(ue.id);
        }
      } else {
        tempUEs.push(ue.id);
      }
    });
    ueGroups.forEach((ueGroup) =>
      ueGroup.ues?.forEach((ue) => {
        if (!tempUEs.includes(ue.id)) {
          if (mobileOnly) {
            if (getUEType(ue.type) === 'Mobile') {
              tempUEs.push(ue.id);
            }
          } else {
            tempUEs.push(ue.id);
          }
        }
      }),
    );
    return tempUEs;
  };

  const { data: equipmentsCoordinatesData } = useQuery(
    [
      QueryName.GET_COORDINATES,
      ues,
      ueGroups,
      'geomap_unit_equipments_coordinates_data',
      selectedDate,
    ],
    () =>
      getCoordinates({
        organization: user?.organizations?.[0] as string,
        site,
        ues: getUniqueUEs(ueGroups ?? [], ues ?? []),
        dateTime: fixDateForAnalytics(selectedDate?.toISOString()),
      }),
    {
      enabled:
        !!user?.organizations?.[0] &&
        !!site &&
        (!!ues?.length || !!ueGroups.length),
      keepPreviousData: true,
    },
  );

  const { data: pastEquipmentsCoordinatesData } = useQuery(
    [
      QueryName.GET_PAST_COORDINATES,
      ues,
      ueGroups,
      'geomap_unit_equipments_past_coordinates_data',
      selectedDate,
    ],
    () =>
      getPastCoordinates({
        organization: user?.organizations?.[0] as string,
        site,
        ues: getUniqueUEs(ueGroups ?? [], ues ?? [], true).join(','),
        timeRange: 2,
      }),
    {
      enabled:
        !!user?.organizations?.[0] &&
        !!site &&
        (!!ues?.length || !!ueGroups.length),
      keepPreviousData: true,
    },
  );

  const getValueAndLimit = useCallback(
    (apn: GeoMapAPN) => {
      if (criteria === 'LATENCY') {
        return {
          value: apn.latency,
          limit: apn.limits.latency,
        };
      } else if (criteria === 'UPLINK') {
        return {
          value: apn.uplink,
          limit: apn.limits.uplink,
        };
      } else {
        return {
          value: apn.downlink,
          limit: apn.limits.downlink,
        };
      }
    },
    [criteria],
  );

  const getAPNColor = useCallback(
    (apn: GeoMapAPN) => {
      const { value, limit } = getValueAndLimit(apn);
      const percentage = (value / limit) * 100;
      if (percentage > 85) return 'rgb(252, 26, 24)';
      if (percentage > 70 && percentage <= 85) return 'rgb(191, 126, 0)';
      return 'rgb(5, 191, 5)';
    },
    [getValueAndLimit],
  );

  const getAPNsData = useCallback(() => {
    const tempAPNs: GeoMapAPN[] = [];
    apns.forEach((apn) => {
      const tempAPNAnalytics = apnsAnalyticsData?.data?.find(
        (apnsAnalyticsDataApn) => apnsAnalyticsDataApn.entityId === apn.name,
      );
      tempAPNs.push({
        ...apn,
        latency: tempAPNAnalytics?.latency ?? 0,
        uplink: tempAPNAnalytics?.uplink ?? 0,
        downlink: tempAPNAnalytics?.downlink ?? 0,
        confidence: tempAPNAnalytics?.confidence ?? 0,
        limits: {
          latency: tempAPNAnalytics?.latencyLimit ?? 0,
          uplink: tempAPNAnalytics?.uplinkLimit ?? 0,
          downlink: tempAPNAnalytics?.downlinkLimit ?? 0,
        },
      });
    });
    return tempAPNs;
  }, [apns, apnsAnalyticsData]);

  const geomapData = useCallback((): GeoJSONSourceOptions['data'] => {
    return {
      type: 'FeatureCollection',
      features: getAPNsData().map((apn, index) => ({
        id: index + 1,
        type: 'Feature',
        properties: {
          apnName: apn.name,
          color: getAPNColor(apn),
          apnLatency: apn.latency,
          apnUplink: apn.uplink,
          apnDownlink: apn.downlink,
          apnConfidence: apn.confidence,
        },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[(apn.geometry?.coordinates ?? []) as []]],
        },
      })),
    };
  }, [getAPNsData, getAPNColor]);

  useEffect(() => {
    if (hoveredStateId) {
      const hoveredAPN = getAPNsData()[hoveredStateId - 1];
      if (hoveredAPN) {
        setHoverInfo((prevValue) => {
          return {
            x: prevValue?.x as number,
            y: prevValue?.y as number,
            name: hoveredAPN.name,
            colorValue: getAPNColor(hoveredAPN),
            latency: hoveredAPN.latency,
            uplink: hoveredAPN.uplink,
            downlink: hoveredAPN.downlink,
            confidence: hoveredAPN.confidence,
          };
        });
      }
    }
  }, [getAPNColor, getAPNsData, hoveredStateId]);

  const geomapUEHistoricalData =
    useCallback((): GeoJSONSourceOptions['data'] => {
      return {
        type: 'FeatureCollection',
        features: Object.values(
          pastEquipmentsCoordinatesData?.data.response ?? {},
        ).map((coordinates, index) => ({
          id: index + 1,
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            coordinates: [
              ...coordinates.map((instant) => [
                instant.longitude,
                instant.latitude,
              ]),
            ],
          },
        })),
      };
    }, [pastEquipmentsCoordinatesData]);

  const getUEGeometryData = useCallback(
    (ueid: string): Point | null => {
      const ue = equipmentsCoordinatesData?.data?.ues?.[ueid];
      if (ue) {
        return {
          type: 'POINT',
          coordinates: ue.coordinates,
          radius: ue.radius,
        };
      }
      return null;
    },
    [equipmentsCoordinatesData],
  );

  const getUEsData = useCallback(() => {
    const tempUEs: GeoMapUE[] = [];
    ues.forEach((ue) => {
      let geometry = getUEGeometryData(ue.id);
      const prevUECoordinatesBackup = ueCoordinatesBackup[ue.id];

      if (!geometry && prevUECoordinatesBackup) {
        geometry = {
          type: 'POINT',
          coordinates: prevUECoordinatesBackup.coordinates,
          radius: prevUECoordinatesBackup.radius,
        };
      }

      tempUEs.push({
        ...ue,
        geometry,
      });
    });
    return tempUEs;
  }, [getUEGeometryData, ueCoordinatesBackup, ues]);

  const getUEGroupsData = useCallback(() => {
    const tempUEGroups: GeoMapUEGroup[] = [];
    ueGroups.forEach((ueGroup) => {
      const tempUEs: GeoMapUE[] = [];
      ueGroup?.ues.forEach((ue) => {
        let geometry = getUEGeometryData(ue.id);
        const prevUECoordinatesBackup = ueCoordinatesBackup[ue.id];

        if (!geometry && prevUECoordinatesBackup) {
          geometry = {
            type: 'POINT',
            coordinates: prevUECoordinatesBackup.coordinates,
            radius: prevUECoordinatesBackup.radius,
          };
        }

        tempUEs.push({
          ...ue,
          geometry,
        });
      });
      tempUEGroups.push({
        ...ueGroup,
        ues: tempUEs ?? [],
      });
    });
    return tempUEGroups;
  }, [getUEGeometryData, ueGroups, ueCoordinatesBackup]);

  useEffect(() => {
    const tempUECoordinatesBackup: { [key: string]: Point | null } = {};
    ues.forEach((ue) => {
      const ueData = equipmentsCoordinatesData?.data?.ues?.[ue.id];
      tempUECoordinatesBackup[ue.id] = ueData
        ? {
            type: 'POINT',
            coordinates: ueData.coordinates,
            radius: ueData.radius,
          }
        : null;
    });

    ueGroups.forEach((ueGroup) => {
      ueGroup?.ues?.forEach((ue) => {
        const ueData = equipmentsCoordinatesData?.data?.ues?.[ue.id];
        tempUECoordinatesBackup[ue.id] = ueData
          ? {
              type: 'POINT',
              coordinates: ueData.coordinates,
              radius: ueData.radius,
            }
          : null;
      });
    });

    setUECoordinatesBackup((prevValue) => {
      const finalValue: { [key: string]: Point | null } = {};
      Object.entries(tempUECoordinatesBackup).forEach(([key, newValue]) => {
        finalValue[key] = newValue ?? prevValue?.[key];
      });
      Object.entries(prevValue).forEach(([key, oldValue]) => {
        finalValue[key] = tempUECoordinatesBackup?.[key] ?? oldValue;
      });
      return { ...finalValue };
    });
  }, [ues, ueGroups, equipmentsCoordinatesData]);

  const getUEType = (type: string): 'Mobile' | 'Static' => {
    const ueTypeMap: { [key: string]: 'Mobile' | 'Static' } = {
      AGV: 'Mobile',
      CCTV: 'Static',
      CARGO_CRANE: 'Mobile',
      RTG_CRANE: 'Mobile',
      DRONE: 'Mobile',
      MOBILE: 'Mobile',
      STRADDLE_CARRIER: 'Mobile',
      WATCHDOG: 'Static',
    };
    if (ueTypeMap[type]) {
      return ueTypeMap[type];
    } else {
      return 'Mobile';
    }
  };

  const fitBounds = useCallback(() => {
    let apnsCoordinates: number[][] = [];
    let uesCoordinates: number[][] = [];
    const ueGroupsCoordinates: number[][] = [];

    const updatedUEs = getUEsData();
    const updatedUEGroups = getUEGroupsData();

    if (apns?.length > 0) {
      apnsCoordinates = apns.reduce<number[][]>((prev, curr) => {
        let tempArr = [];
        if (curr.geometry?.coordinates) {
          tempArr = [...prev, ...curr.geometry?.coordinates];
        } else {
          tempArr = [...prev];
        }
        return tempArr;
      }, []);
    }

    if (updatedUEs?.length > 0) {
      uesCoordinates = updatedUEs.reduce<number[][]>((prev, curr) => {
        let tempArr = [];
        if (curr.geometry?.coordinates) {
          tempArr = [...prev, [...curr.geometry?.coordinates]];
        } else {
          tempArr = [...prev];
        }
        return tempArr;
      }, []);
    }

    if (updatedUEGroups?.length > 0) {
      updatedUEGroups.forEach((ueGroup) => {
        const ueGroupUECoordinates = ueGroup.ues.reduce<number[][]>(
          (prev, curr) => {
            let tempArr = [];
            if (curr.geometry?.coordinates) {
              tempArr = [...prev, [...curr.geometry?.coordinates]];
            } else {
              tempArr = [...prev];
            }
            return tempArr;
          },
          [],
        );
        ueGroupsCoordinates.push(...[...ueGroupUECoordinates]);
      });
    }

    const total =
      apnsCoordinates.length +
      uesCoordinates.length +
      ueGroupsCoordinates.length;

    if (
      apnsCoordinates.length === 0 &&
      ueGroupsCoordinates.length === 0 &&
      uesCoordinates.length === 1
    ) {
      if (updatedUEs[0]?.geometry?.coordinates?.length) {
        mapRef.current
          ?.setCenter({
            lat: updatedUEs[0].geometry.coordinates[1],
            lng: updatedUEs[0].geometry.coordinates[0],
          })
          .zoomTo(13);
      }
      return;
    }

    if (
      apnsCoordinates.length === 0 &&
      ueGroupsCoordinates.length === 1 &&
      uesCoordinates.length === 0
    ) {
      mapRef.current
        ?.setCenter({
          lat: ueGroupsCoordinates[0][1],
          lng: ueGroupsCoordinates[0][0],
        })
        .zoomTo(13);
      return;
    }

    if (total > 0) {
      const features = points([
        ...apnsCoordinates,
        ...uesCoordinates,
        ...ueGroupsCoordinates,
      ]);
      const [minLng, minLat, maxLng, maxLat] = bbox(features);

      mapRef.current?.fitBounds(
        [
          [minLng, minLat],
          [maxLng, maxLat],
        ],
        { padding: 50, duration: 1000 },
      );
    }
  }, [getUEsData, getUEGroupsData, apns]);

  useEffect(() => {
    setTimeout(() => {
      mapRef.current?.resize();
      fitBounds();
    }, 0);
  }, [fitBounds, sidebarOpen]);

  const handleSwitch = useCallback(() => {
    switch (criteria) {
      case 'LATENCY':
        return (
          <Typography sx={styles.apnHoverText}>
            {hoverInfo?.latency} ms
          </Typography>
        );
      case 'UPLINK':
        return (
          <Typography sx={styles.apnHoverText}>
            {hoverInfo?.uplink} mbps{' '}
          </Typography>
        );
      case 'DOWNLINK':
        return (
          <Typography sx={styles.apnHoverText}>
            {hoverInfo?.downlink} mbps{' '}
          </Typography>
        );
      default:
        return <></>;
    }
  }, [criteria, hoverInfo]);

  const handleSwitchColor = useCallback(() => {
    switch (hoverInfo?.colorValue) {
      case 'rgb(5, 191, 5)':
        return <Checkmark />;
      case 'rgb(191, 126, 0)':
        return <WarningAlt />;
      case 'rgb(252, 26, 24)':
        return <Close />;
      default:
        return <></>;
    }
  }, [hoverInfo]);

  return (
    <Box
      position='absolute'
      width={sidebarOpen ? 'calc(100vw - 250px)' : 'calc(100vw - 60px)'}
      height='calc(100vh - 50px)'
    >
      <Map_
        ref={mapRef}
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN_2}
        mapStyle='mapbox://styles/mapbox/satellite-streets-v9'
        cursor={cursor}
        style={{
          width: '100%',
          height: '100%',
        }}
        dragRotate={false}
        touchZoomRotate={false}
        onDragStart={() => setCursor('grabbing')}
        onDragEnd={() => setCursor('grab')}
        attributionControl={false}
        onMouseMove={(event) => {
          if (event.features && event.features.length > 0) {
            if (hoveredStateId !== null) {
              mapRef.current?.setFeatureState(
                { source: 'source', id: hoveredStateId },
                { hover: false },
              );
            }
            mapRef.current?.setFeatureState(
              { source: 'source', id: event.features[0].id },
              { hover: true },
            );
            setCursor('pointer');
            setHoveredStateId(event.features[0].id as number);
            setHoverInfo({
              x: event.point.x,
              y: event.point.y,
              name: event.features[0].properties?.apnName,
              colorValue: event.features[0].properties?.color,
              latency: event.features[0].properties?.apnLatency,
              uplink: event.features[0].properties?.apnUplink,
              downlink: event.features[0].properties?.apnDownlink,
              confidence: event.features[0].properties?.apnConfidence,
            });
          }
        }}
        onMouseLeave={() => {
          if (hoveredStateId !== null) {
            mapRef.current?.setFeatureState(
              { source: 'source', id: hoveredStateId },
              { hover: false },
            );
          }
          setCursor('grab');
          setHoveredStateId(null);
          setHoverInfo(null);
        }}
        interactiveLayerIds={['data']}
      >
        <Source id='source' type='geojson' data={geomapData()}>
          <Layer
            {...{
              id: 'data',
              type: 'fill',
              paint: {
                'fill-color': ['get', 'color'],
                'fill-opacity': [
                  'case',
                  ['boolean', ['feature-state', 'hover'], false],
                  0.4,
                  0.3,
                ],
              },
            }}
          />
          <Layer
            {...{
              id: 'outline',
              type: 'line',
              paint: {
                'line-color': ['get', 'color'],
                'line-width': 2,
              },
            }}
          />
        </Source>
        <Source
          id='polylineLayer'
          type='geojson'
          data={geomapUEHistoricalData()}
        >
          <Layer
            {...{
              id: 'polylineLayerData',
              type: 'line',
              layout: {
                'line-join': 'round',
                'line-cap': 'round',
              },
              paint: {
                'line-color': '#006161',
                'line-width': 3,
              },
            }}
          />
        </Source>
        {getUEsData()
          ?.filter((ue) => !!ue.geometry?.coordinates?.length)
          .map((ue) => (
            <Marker
              key={ue.id}
              longitude={ue.geometry?.coordinates?.[0]}
              latitude={ue.geometry?.coordinates?.[1]}
              style={{
                width: '32px',
                height: '32px',
              }}
              anchor='bottom'
              onClick={() =>
                setPopup({
                  name: ue.name,
                  coordinates: {
                    latitude: ue.geometry?.coordinates?.[1] as number,
                    longitude: ue.geometry?.coordinates?.[0] as number,
                  },
                })
              }
            >
              <SvgIcon
                sx={{
                  color: '#00FFD5',
                  width: '32px',
                  height: '32px',
                  cursor: 'pointer',
                }}
              >
                {getUEType(ue.type) === 'Static' ? <UEStatic /> : <UEMobile />}
              </SvgIcon>
            </Marker>
          ))}
        {getUEGroupsData()
          ?.filter((ueGroup) => !!ueGroup.ues.length)
          .map((ueGroup) =>
            ueGroup.ues
              .filter((ue) => !!ue.geometry?.coordinates?.length)
              .map((ue, index) => (
                <Marker
                  key={`${ueGroup.id}-${ue.id}-${index}`}
                  longitude={ue.geometry?.coordinates?.[0]}
                  latitude={ue.geometry?.coordinates?.[1]}
                  anchor='bottom'
                  onClick={() =>
                    setPopup({
                      name: ue.name,
                      coordinates: {
                        latitude: ue.geometry?.coordinates?.[1] as number,
                        longitude: ue.geometry?.coordinates?.[0] as number,
                      },
                    })
                  }
                >
                  <SvgIcon
                    sx={{
                      color: '#00FFD5',
                      width: '40px',
                      height: '40px',
                      cursor: 'pointer',
                    }}
                  >
                    {getUEType(ue.type) === 'Static' ? (
                      <UEStatic />
                    ) : (
                      <UEMobile />
                    )}
                  </SvgIcon>
                </Marker>
              )),
          )}
        {popup && (
          <Popup
            latitude={popup.coordinates.latitude}
            longitude={popup.coordinates.longitude}
            onClose={() => setPopup(null)}
            closeOnClick={false}
            style={{ paddingBottom: '45px' }}
          >
            {popup.name}
          </Popup>
        )}
      </Map_>
      {hoverInfo && (
        <Box
          className='tooltip'
          sx={{
            left: hoverInfo.x,
            top: hoverInfo.y,
            backgroundColor: hoverInfo.colorValue,
            paddingLeft: '10px',
            paddingRight: '10px',
            marginTop: '2px',
          }}
        >
          <Box sx={styles.headingIconBox}>
            {handleSwitchColor()}
            <Typography sx={styles.apnHoverName}>{hoverInfo.name}</Typography>
          </Box>
          {handleSwitch()}
          {selectedDate !== null && selectedDate >= new Date() ? (
            <Typography sx={styles.apnHoverText}>
              {hoverInfo.confidence}% confidence
            </Typography>
          ) : (
            <></>
          )}
        </Box>
      )}
    </Box>
  );
};
