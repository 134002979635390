import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Button } from 'src/components';
import { Main } from 'src/layouts/Main';
import { AddElement } from '../AddElement';
import { TreeViewList } from './TreeViewList';
import { Breadcrumbs } from 'src/components/Breadcrumbs/index';
import { useUserRole } from 'src/utils/user';

export const ManageNetwork: React.FC = () => {
  const { isSuperUser, isReadyOnlyUser } = useUserRole();
  const [isAddElement, setAddElement] = useState<boolean>(false);
  return (
    <Main>
      {!isSuperUser() && (
        <Breadcrumbs
          breadcrumbs={[
            {
              title: 'Overview',
              url: '/',
            },
            {
              title: 'Device management',
              url: '/devices',
            },
            {
              title: 'Manage network',
            },
          ]}
        />
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant='h1'> Manage Network</Typography>
        {!isReadyOnlyUser() && (
          <Button kind='primary' onClick={() => setAddElement(true)}>
            Add an element
          </Button>
        )}
        {isAddElement && (
          <AddElement
            open={isAddElement}
            onCancel={() => setAddElement(false)}
          />
        )}
      </Box>
      <TreeViewList />
    </Main>
  );
};
