import React, { useCallback, useContext, useEffect } from 'react';
import { AppContext, RulesEngineContext } from 'src/context';
import { useParams } from 'react-router-dom';
import { center, circle, points } from '@turf/turf';
import { Typography } from '@mui/material';
import { QueryName } from 'src/enums/query';
import { useQuery } from '@tanstack/react-query';

import { getCoordinates } from 'src/apis/geo-service';
import { Main } from 'src/layouts/Main';
import { RuleConfiguration } from 'src/types/rule';
import { EditSpecificationPrioritization } from 'src/views/Rules/rule/components/Specification/EditSpecificationPrioritization/EditSpecificationPrioritization';
import { EditSpecificationThreshold } from '../EditSpecificationThreshold/EditSpecificationThreshold';
import { EditSpecificationGeofence } from '../EditSpecificationGeoence/EditSpecificationGeofence';
import { Breadcrumbs } from 'src/components/Breadcrumbs';
import { EditSpecificationCapacity } from '../EditSpecificationCapacity/EditSpecificationCapacity';

type Polygon = {
  type: 'POLYGON';
  coordinates?: number[][];
};

type GeoMapAPN = {
  name: string;
  type: 'apn';
  geometry: Polygon | null;
};

interface SpecificationProps {
  onCancel: () => void;
  data: RuleConfiguration;
}

export const EditRulesSpecification: React.FC<SpecificationProps> = ({
  onCancel,
  data,
}) => {
  const { organization, site, id } = useParams<{
    organization: string;
    site: string;
    id: string;
  }>();
  const {
    resetGeneralInfo,
    removeAllAPNs,
    removeAllEquipments,
    addAllAPNs,
    addNewEquipment,
    selectedEquipments,
    selectRuleType,
  } = useContext(RulesEngineContext);
  const { siteChanged } = useContext(AppContext);

  useEffect(() => {
    if (siteChanged) {
      resetGeneralInfo();
      removeAllAPNs();
      removeAllEquipments();
    }
  }, [removeAllAPNs, removeAllEquipments, resetGeneralInfo, siteChanged]);

  const { data: coordinatesData } = useQuery(
    [QueryName.GET_COORDINATES, site, 'apn_coordinates'],
    () =>
      getCoordinates({
        organization: organization ?? '',
        site: site ?? '',
        apns: data?.apns,
        ues: data?.ues,
        ueGroups: data?.ueGroups ? Object.values(data?.ueGroups) : [],
      }),
    {
      enabled: !!organization && !!site && !!data.apns.length,
    },
  );

  const getUeGroupID = (name: string) => {
    if (data?.ueGroups != null) {
      return Object.keys(data.ueGroups).find(
        (key) =>
          data.ueGroups[key as keyof RuleConfiguration['ueGroups']] === name,
      );
    }
  };

  const getUeID = (name: string) => {
    if (data?.ueNames != null) {
      return Object.keys(data.ueNames).find(
        (key) =>
          data.ueNames[key as keyof RuleConfiguration['ueNames']] === name,
      );
    }
  };

  const renderSpecification = () => {
    switch (data?.type) {
      case 'PRIORITIZATION':
        return (
          <EditSpecificationPrioritization
            onCancel={onCancel}
            ruleConfiguration={data}
          />
        );
      case 'THRESHOLD':
        return (
          <EditSpecificationThreshold
            onCancel={onCancel}
            ruleConfiguration={data}
          />
        );
      case 'GEO_FENCE':
        return (
          <EditSpecificationGeofence
            onCancel={onCancel}
            ruleConfiguration={data}
          />
        );
      case 'CAPACITY':
        return (
          <EditSpecificationCapacity
            onCancel={onCancel}
            ruleConfiguration={data}
          />
        );
      default:
        return null;
    }
  };

  const getUeCoordinates = useCallback(() => {
    data?.ues?.forEach((ue) => {
      const ueid = getUeID(ue) ?? '';
      const ueData = coordinatesData?.data.ues[ueid];
      if (ueData != null) {
        addNewEquipment({
          id: ueid,
          name: ue,
          coordinates: {
            latitude: ueData.coordinates[1] ?? 0,
            longitude: ueData.coordinates[0] ?? 0,
          },
          type: 'equipment',
        });
      } else {
        addNewEquipment({
          id: ueid,
          name: ue,
          coordinates: null,
          type: 'equipment',
        });
      }
    });
  }, [addNewEquipment, coordinatesData?.data?.ues, data]);

  const getUeGroupsCoordinates = useCallback(() => {
    if (coordinatesData?.data?.ueGroups) {
      Object.entries(coordinatesData?.data?.ueGroups).forEach(
        ([group, groupUEs]) => {
          let ueGroupCoordinates: {
            latitude: number;
            longitude: number;
          } | null = null;
          if (groupUEs) {
            const groupUEsCoordinates: number[][] = [];

            Object.keys(groupUEs).forEach((ue) => {
              const ueCoordinates = groupUEs[ue].coordinates;
              if (ueCoordinates) {
                groupUEsCoordinates.push(ueCoordinates);
              }
            });

            if (groupUEsCoordinates.length > 0) {
              const features = points([...groupUEsCoordinates]);

              const {
                geometry: { coordinates },
              } = center(features);

              ueGroupCoordinates = {
                latitude: coordinates[1],
                longitude: coordinates[0],
              };
            }
            if (ueGroupCoordinates) {
              addNewEquipment({
                id: getUeGroupID(group) ?? '',
                name: group,
                coordinates: ueGroupCoordinates,
                type: 'equipment_group',
              });
            } else {
              addNewEquipment({
                id: getUeGroupID(group) ?? '',
                name: group,
                coordinates: null,
                type: 'equipment_group',
              });
            }
          }
        },
      );
    }
  }, [addNewEquipment, coordinatesData?.data?.ueGroups]);

  const getApnCoordinates = useCallback(() => {
    if (coordinatesData?.data?.apns) {
      const tempAPNs: GeoMapAPN[] = [];
      Object.entries(coordinatesData?.data?.apns).forEach(([apn, value]) => {
        const geometryData = (): Polygon | null => {
          const tempAPN = value;
          if (tempAPN) {
            if (tempAPN.geoType === 'POLYGON') {
              return {
                type: 'POLYGON',
                coordinates: tempAPN.polygonGeometry?.coordinates,
              };
            } else {
              const coordinates =
                tempAPN.pointGeometry?.coordinates.length === 2 &&
                tempAPN.pointGeometry?.radius
                  ? circle(
                      tempAPN.pointGeometry?.coordinates,
                      tempAPN.pointGeometry?.radius,
                      {
                        steps: 0,
                        units: 'kilometers',
                      },
                    ).geometry.coordinates
                  : undefined;
              return {
                type: 'POLYGON',
                coordinates: coordinates?.[0] ?? undefined,
              };
            }
          }
          return null;
        };
        tempAPNs.push({
          name: apn,
          type: 'apn',
          geometry: geometryData(),
        });
      });
      addAllAPNs([...tempAPNs]);
    }
  }, [addAllAPNs, coordinatesData?.data?.apns]);

  useEffect(() => {
    selectRuleType(data?.type);
    if (coordinatesData != null && selectedEquipments.length < 1) {
      getApnCoordinates();
      getUeCoordinates();
      getUeGroupsCoordinates();
    }
  }, [coordinatesData, data?.type]);

  return (
    <Main>
      <Breadcrumbs
        breadcrumbs={[
          {
            title: 'Overview',
            url: '/',
          },
          {
            title: 'Rules',
            url: '/rules',
          },
          {
            title: data?.name,
            url:
              '/rules/' +
              String(organization) +
              '/' +
              String(site) +
              '/' +
              String(id),
          },
          {
            title: 'Edit',
          },
        ]}
      />
      <Typography variant='h1' mt='14px'>
        Edit {data?.name}
      </Typography>
      {renderSpecification()}
    </Main>
  );
};
