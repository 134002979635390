import styled from '@emotion/styled';
import { Tabs, Tab, TabsProps } from '@mui/material';

export const StyledTabs = styled(Tabs)<TabsProps>`
  & {
    .MuiTabs-indicator {
      background-color: #006161;
      top: 0;
      padding: 2px;
    }
    .MuiTabs-scroller {
      max-width: 1200px;
      margin-inline: auto;
      padding-inline: 24px;
    }
    .Mui-selected {
      background: #fff;
      color: #006161 !important;
      padding-top: 13px,
      padding-bottom: 14px,
    }
    background-color: #eff5f5;
  }
`;

export const StyledTab = styled(Tab)`
  & {
    width: 190px;
    font-size: 11px;
    color: #659f9f;
    font-weight: bold;
    background: #eff5f5;
    padding-top: 15px;
    padding-bottom: 11px;
    padding-left: 58px;
    padding-right: 60px;
  }
`;
