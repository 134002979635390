import { StyledDialog, styles } from './styles';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  RadioGroup,
  Typography,
} from '@mui/material';
import { TextField } from '../TextField';
import { Button } from '../Button';
import Close from '@mui/icons-material/Close';
import { Radio } from 'src/components/Radio';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { createChannelSchema } from 'src/yup-validations/addChannel';
import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext, ToastContext } from 'src/context';
import {
  createChannel,
  CreateChannelData,
  editChannel,
} from 'src/apis/channel';
import { Channel } from 'src/types/channel';

interface ChannelManagementDialogProps {
  channel?: Channel;
  open: boolean;
  onSave: () => void;
  onCancel: () => void;
}

type ChannelInputs = {
  channel_name: string;
  channel_type: string;
  channel_url: string;
};

export const ChannelManagementDialog: React.FC<
  ChannelManagementDialogProps
> = ({ channel, open, onSave, onCancel }) => {
  const { showToast } = useContext(ToastContext);
  const { user } = useContext(AuthContext);
  const [channelName, setChannelName] = useState('');

  const {
    control,
    formState: { isValid, errors },
    handleSubmit,
    reset,
    clearErrors,
  } = useForm({
    defaultValues: { channel_type: 'SLACK', channel_name: '', channel_url: '' },
    resolver: yupResolver(createChannelSchema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    if (channel) {
      reset({
        channel_type: channel.type,
        channel_name: channel.name,
        channel_url: channel.url,
      });
      setChannelName(channel.name);
    } else {
      setChannelName('');
      reset({ channel_type: 'SLACK', channel_name: '', channel_url: '' });
    }
  }, [channel, reset]);

  const handleSave = (data: ChannelInputs) => {
    const channelData: CreateChannelData = {
      id: '',
      organization: user?.organizations?.[0] as string,
      name: data.channel_name,
      url: data.channel_url,
      type: data.channel_type,
      ruleNames: [''],
    };
    if (channel) {
      channelData.id = channel.id;
      editChannelMutation.mutate(channelData);
    } else {
      createChannelMutation.mutate(channelData);
    }
  };
  const handleCancel = () => {
    if (!channel) {
      setChannelName('');
    } else {
      setChannelName(channel?.name);
    }
    clearErrors('channel_name');
    onCancel();
  };

  const handleCleanCancel = () => {
    if (!channel) {
      setChannelName('');
    } else {
      setChannelName(channel?.name);
    }
    reset({ channel_type: 'SLACK', channel_name: '', channel_url: '' });
    onCancel();
    clearErrors('channel_name');
  };

  const createChannelMutation = useMutation<
    AxiosResponse,
    AxiosError,
    CreateChannelData
  >({
    mutationFn: createChannel,
    onSuccess: () => {
      showToast('Channel created successfully!', 'success');
      reset({ channel_type: 'SLACK', channel_name: '', channel_url: '' });
      onSave();
    },
    onError: () => {
      showToast('Failed to create the channel, Please try again', 'error');
    },
  });

  const editChannelMutation = useMutation<
    AxiosResponse,
    AxiosError,
    CreateChannelData
  >({
    mutationFn: editChannel,
    onSuccess: () => {
      showToast('Channel edited successfully!', 'success');
      reset({ channel_type: 'SLACK', channel_name: '', channel_url: '' });
      onSave();
    },
    onError: () => {
      showToast('Failed to edit the channel, Please try again', 'error');
    },
  });

  return (
    <StyledDialog open={open}>
      <DialogTitle>
        {channel ? 'Edit a channel' : 'Add a channel'}
        <IconButton sx={styles.closeIconButton} onClick={handleCancel}>
          <Close fontSize='medium' />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Controller
          name='channel_type'
          control={control}
          render={({ field }) => (
            <RadioGroup row {...field}>
              <Radio label='Slack' value='SLACK' />
              <Radio label='Teams' value='TEAMS' />
            </RadioGroup>
          )}
        />

        <Controller
          name='channel_name'
          control={control}
          render={({ field: { onChange, ...field } }) => (
            <TextField
              fullWidth
              id='channel-name'
              label='Channel name'
              sx={{ mt: '4px', mb: '12px' }}
              {...field}
              onChange={({ target: { value } }) => {
                onChange(value);
                setChannelName(value);
              }}
              value={channelName}
              error={
                !isValid && errors.channel_name?.message
                  ? errors.channel_name?.message
                  : undefined
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end' sx={styles.characterAdornment}>
                    <Typography sx={styles.characterAdornmentText}>
                      {channelName.length}/50
                    </Typography>
                  </InputAdornment>
                ),
              }}
              inputProps={{
                maxLength: 50,
              }}
            />
          )}
        />
        <Controller
          name='channel_url'
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              id='channel-url'
              label='URL'
              error={
                !isValid && errors.channel_url?.message
                  ? errors.channel_url?.message
                  : undefined
              }
              {...field}
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button
          kind={'primary-ghost'}
          onClick={channel ? handleCancel : handleCleanCancel}
        >
          Cancel
        </Button>
        <Button kind={'primary'} onClick={handleSubmit(handleSave)}>
          Save
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};
