import { Search } from '@carbon/icons-react';
import { Box, Typography } from '@mui/material';

import { Loading, TextField } from 'src/components';
import { UserUsageInfo } from './UserUsageInfo';
import { useQuery } from '@tanstack/react-query';
import { QueryName } from 'src/enums/query';
import { useContext, useState } from 'react';
import { AuthContext } from 'src/context';
import { getUsersNameList } from 'src/apis/access-management';

export const UsersUsage: React.FC = () => {
  const { user } = useContext(AuthContext);
  const [searchTerm, setSearchTerm] = useState('');

  const { data: usersList, isLoading } = useQuery(
    [QueryName.GET_USERS_NAME, user?.organizations?.[0]],
    () => getUsersNameList(user?.organizations?.[0] as string, true),
    { enabled: !!user?.organizations?.length },
  );

  const filteredUsers =
    usersList?.data.users.filter(
      (user) =>
        user.fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.policyName?.toLowerCase().includes(searchTerm.toLowerCase()),
    ) ?? [];

  return (
    <Loading isLoading={isLoading}>
      <Box display='flex' justifyContent='space-between' width='553px'>
        <Typography variant='h2'>Users usage</Typography>
        <TextField
          isSearchField
          placeholder='Search'
          InputProps={{
            endAdornment: <Search size={20} color='#888888' />,
          }}
          width={200}
          onChange={(event) => setSearchTerm(event.target.value)}
        />
      </Box>
      <Box
        border='1px solid #D3E1E1'
        mt='26px'
        paddingX='27px'
        maxHeight='645px'
        overflow='auto'
      >
        {filteredUsers.map((user, index) => (
          <UserUsageInfo
            key={user.userId}
            userId={user.userId}
            isLastItem={index === filteredUsers.length - 1}
            firstName={user.firstName}
            lastName={user.lastName}
            dataAllocated={user.dataAllocated}
            dataUsed={user.dataUsed}
            policyName={user.policyName}
          />
        ))}
      </Box>
    </Loading>
  );
};
