import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Divider, TextField, Typography, Box } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useContext } from 'react';
import { useForm, Controller } from 'react-hook-form';

import { createDashboard } from 'src/apis/dashboard';
import { Drawer } from 'src/components';
import { AuthContext } from 'src/context';
import { QueryName } from 'src/enums/query';
import { createDashboardSchema } from 'src/yup-validations/dashboard';
import { styles } from './styles';

type FormPayload = {
  dashboard_name: string;
};

interface CreateDashboardDrawerProps {
  open: boolean;
  onAdd: (dashboardID: string) => void;
  onClose: () => void;
}

export const CreateDashboardDrawer: React.FC<CreateDashboardDrawerProps> = ({
  open,
  onAdd,
  onClose,
}) => {
  const { user } = useContext(AuthContext);

  const queryClient = useQueryClient();

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    resetField,
  } = useForm({
    defaultValues: { dashboard_name: '' },
    resolver: yupResolver(createDashboardSchema),
    mode: 'onChange',
  });

  const { mutate, isLoading } = useMutation<
    unknown,
    AxiosError,
    { name: string; userId: string }
  >({
    mutationFn: createDashboard,
    onSuccess: async (_, { name }) => {
      onAdd(name);
      resetField('dashboard_name');
      await queryClient.invalidateQueries([QueryName.GET_DASHBOARDS_LIST]);
    },
  });

  const onSubmit = (data: FormPayload) => {
    if (user) {
      mutate({ name: data.dashboard_name, userId: user.email });
    }
  };

  return (
    <Drawer open={open} onClose={onClose}>
      <Typography variant="h5" gutterBottom>
        Create Dashboard
      </Typography>
      <Divider />
      <Typography variant="body2" style={styles.drawerSubTitle}>
        This option allows you to create a new dashboard. This Dashboard will be
        private and configurable by you alone, until it is shared.
      </Typography>
      <Box mt={3} display="flex" flexDirection="column" flexGrow={1}>
        <Controller
          name="dashboard_name"
          control={control}
          render={({ field }) => (
            <TextField
              id="dashboard-name"
              label="Dashboard Name"
              size="small"
              fullWidth
              helperText="Name can only contain Alphabets, Numbers and '-, _'"
              FormHelperTextProps={{ style: styles.fieldHelperText }}
              error={!!errors.dashboard_name}
              {...field}
            />
          )}
        />
      </Box>
      <Box display="flex" justifyContent="space-evenly">
        <Button
          disableElevation
          variant="contained"
          sx={styles.addButton}
          onClick={handleSubmit(onSubmit)}
          disabled={isLoading || !isValid}
        >
          Add
        </Button>
        <Button sx={styles.resetButton} onClick={onClose}>
          Cancel
        </Button>
      </Box>
    </Drawer>
  );
};
