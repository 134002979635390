export const styles = {
  tableRow: {
    height: '76px',
    ':hover': {
      bgcolor: 'rgba(0, 0, 0, 0.04)',
    },
    '&:last-child': {
      borderBottom: '2px solid #CCCCCC',
    },
  },
  deviceName: {
    fontWeight: 'bold',
    textDecoration: 'underline',
    color: '#006161',
    cursor: 'pointer',
    paddingRight: '15px',
  },
  description: {
    fontSize: '12px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    paddingRight: '10px',
    color: '#888888',
  },
  text: {
    color: '#888888',
    paddingRight: '15px',
  },
  groupBtn: {
    float: 'right',
    display: 'block',
    marginLeft: '10px',
  },
  selectWrapper: {
    margin: 0,
    padding: 0,
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    height: '45px',
  },
  inputBox: {
    position: 'relative',
    right: '0',
    marginLeft: 'auto',
    marginTop: '5px',
    height: '30px',
  },
  groupBox: {
    display: 'flex-start',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    minHeight: '35px',
  },
  watchDogName: {
    color: '#888888',
    maxWidth: '20ch',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  uploadBox: {
    margin: '15px 0',
    padding: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
  },
  uploadIcon: {
    color: '#006161',
    size: '24',
  },
  uploadDeviceText: {
    size: '24',
    paddingLeft: '11px',
    color: '#006161',
    fontSize: '14px',
    alignItems: 'left',
    textDecoration: 'underline',
    ':hover': {
      textDecoration: 'none',
    },
  },
};
