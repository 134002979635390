import { FC } from 'react';
import { Controller, ControllerProps } from 'react-hook-form';
import { LogicalPartition, Password, View } from '@carbon/icons-react';
import { Box, InputLabel, Typography } from '@mui/material';

import { styles } from './styles';
import { UserDetails } from 'src/types/user';
import { useUserRole } from 'src/utils/user';

interface RolesProps {
  currentRole: string;
}

export const Roles: FC<
  RolesProps & Pick<ControllerProps<UserDetails>, 'control'>
> = ({ control, currentRole }) => {
  const { isSuperUser } = useUserRole();

  const roles = [
    {
      role: 'READ_ONLY',
      title: 'Viewer',
      icon: <View size='32' />,
      description: 'Can see everything on an organization',
    },
    ...(isSuperUser()
      ? [
          {
            role: 'SUPER_USER',
            title: 'User manager',
            icon: <LogicalPartition size='32' />,
            description: 'Manages users only across organizations',
          },
        ] : []
    ),
    {
      role: 'ADMIN',
      title: 'Admin',
      icon: <Password size='32' />,
      description: 'Editor role & manages users for an org.',
    },
  ];

  return (
    <>
      <Typography sx={styles.subtitle}>Role</Typography>
      <Box display={'flex'}>
        {roles.map(({ title, icon, description, role }) => {
          const isCurrentRole = role === currentRole;
          return (
            <Controller
              key={title}
              control={control}
              name='roles'
              render={({ field }) => (
                <InputLabel sx={styles.roleWrapper(isCurrentRole)}>
                  <input
                    {...field}
                    style={{ display: 'none' }}
                    type='radio'
                    value={role}
                    checked={isCurrentRole}
                  />
                  <Box sx={styles.roleIcon(isCurrentRole)}>{icon}</Box>
                  <Typography sx={styles.roleTitle(isCurrentRole)}>
                    {title}
                  </Typography>
                  <Typography sx={styles.roleDescription(isCurrentRole)}>
                    {description}
                  </Typography>
                </InputLabel>
              )}
            />
          );
        })}
      </Box>
    </>
  );
};
