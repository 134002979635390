import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Box, FormControlLabel, Typography } from '@mui/material';

import { UE } from 'src/types/domain';
import { getAllUes } from 'src/apis/ue';
import { QueryName } from 'src/enums/query';
import { Checkbox, Loading } from 'src/components';
import { GeoMapUE } from 'src/context/GeoMapContext';
import { AuthContext, AppContext, GeoMapContext } from 'src/context';

interface UnitsEquipmentProps {
  filter: string;
}
export const UnitsEquipment: React.FC<UnitsEquipmentProps> = ({ filter }) => {
  const { user } = useContext(AuthContext);
  const { site } = useContext(AppContext);
  const { ues, addNewUE, addAllUEs, removeUE } = useContext(GeoMapContext);

  const { data: equipmentsData, isLoading: equipmentsLoading } = useQuery(
    [QueryName.GET_ALL_UES, site, 'geomap_unit_equipments'],
    () => getAllUes({ organization: user?.organizations?.[0] as string, site }),
    {
      enabled: !!user?.organizations?.[0] && !!site,
      cacheTime: 0,
      onSuccess: () => addAllUEs([]),
    },
  );

  const selectUE = (ue: UE) => {
    addNewUE({
      id: ue.ueid,
      name: ue.name,
      type: ue.type,
      geometry: null,
    });
  };

  const selectAllUnitsEquipment = () => {
    const tempAPNs: GeoMapUE[] = [];
    equipmentsData?.data?.devices?.forEach((ue) => {
      tempAPNs.push({
        id: ue.ueid,
        name: ue.name,
        type: ue.type,
        geometry: null,
      });
      addAllUEs([...tempAPNs]);
    });
  };

  return (
    <Box>
      <Typography variant='h4' px='10px' pt='15px'>
        Units equipment
      </Typography>
      <Box pl='18px'>
        <Loading isLoading={equipmentsLoading}>
          <Box display='flex' flexDirection='column'>
            {!filter && (equipmentsData?.data?.total as number) > 0 && (
              <FormControlLabel
                key='all'
                checked={equipmentsData?.data?.total === ues.length}
                onChange={(_, checked) =>
                  checked ? selectAllUnitsEquipment() : addAllUEs([])
                }
                control={<Checkbox size='small' />}
                label={
                  <Typography fontSize='14px' color='#222222'>
                    All
                  </Typography>
                }
              />
            )}
            {equipmentsData?.data?.total === 0 && (
              <Typography fontSize='14px' color='#222222' mt='5px'>
                No ue&apos;s found
              </Typography>
            )}
            {equipmentsData?.data?.devices
              .filter((equipment) =>
                equipment.name?.toLowerCase().includes(filter.toLowerCase()),
              )
              .map((equipment) => (
                <FormControlLabel
                  key={equipment.ueid}
                  checked={ues.findIndex((ue) => ue.id === equipment.ueid) > -1}
                  onChange={(_, checked) =>
                    checked ? selectUE(equipment) : removeUE(equipment.ueid)
                  }
                  control={<Checkbox size='small' />}
                  label={
                    <Typography fontSize='14px' color='#222222'>
                      {equipment.name}
                    </Typography>
                  }
                />
              ))}
          </Box>
        </Loading>
      </Box>
    </Box>
  );
};
