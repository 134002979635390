import styled from '@emotion/styled';
import { Box, BoxProps as MuiBoxProps } from '@mui/material';

interface BoxProps extends MuiBoxProps {
  active: boolean;
}

export const StyledContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'active',
})<BoxProps>`
  & {
    background: #f1fcfa;
    cursor: pointer;

    img {
      height: 159px;
      width: 159px;
      transition: scale 0.1s ease-in;
    }

    :hover {
      box-shadow: 1px 1px 13px #00000029;
    }

    img:hover {
      scale: 101%;
    }

    ${({ active }) => active && 'box-shadow: 1px 1px 13px #00000029'};
  }
`;
