import {
  DialogContent,
  Typography,
  MenuItem,
  DialogActions,
  Stack,
  CircularProgress,
  Backdrop,
} from '@mui/material';
import { Box } from '@mui/system';
import { TextField, Select, Button } from 'src/components';
import countriesList from 'countries-list';
import { useContext } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { createAddOrganizationSchema } from 'src/yup-validations/addOrganization';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import {
  addOrganization,
  editOrganization,
  getOrganization,
} from 'src/apis/org';
import { QueryName } from 'src/enums/query';
import { ToastContext } from 'src/context/ToastContext';
import { MenuProps, styles } from './styles';
import { editOrganizationSchema } from 'src/yup-validations/editOrganisation';

interface AddOrgProps {
  organizationName?: string;
  onCancel: () => void;
}

type AddFormPayload = {
  organization: string;
  country: string;
  description?: string;
};

export const AddOrg: React.FC<AddOrgProps> = ({
  onCancel,
  organizationName,
}) => {
  const queryClient = useQueryClient();
  const { showToast } = useContext(ToastContext);
  const onSubmit = (data: AddFormPayload) => {
    if (organizationName) {
      editOrgMutation({
        organization: organizationName,
        country: data.country,
        description: data.description,
      });
    } else {
      addOrgMutation(data);
    }
  };
  const {
    mutate: addOrgMutation,
    isLoading: addOrgMutationLoading,
    error: addOrgMutationError,
  } = useMutation<
    unknown,
    AxiosError<{
      failures: [
        {
          id: string;
          message: string;
        },
      ];
    }>,
    { organization: string; country: string; description?: string }
  >({
    mutationFn: addOrganization,
    onSuccess: async () => {
      await queryClient.invalidateQueries([QueryName.GET_ORG_NAMES]);
      reset();
      onCancel();
      showToast('Organization added successfully!', 'success');
    },
  });
  useQuery(
    [QueryName.GET_ORGANIZATION, organizationName],
    () => getOrganization(organizationName as string),
    {
      enabled: !!organizationName,
      onSuccess: async (data) => {
        setValue('country', data.country);
        setValue('description', data.description);
      },
    },
  );
  const { control, formState, handleSubmit, reset, setValue } = useForm({
    defaultValues: {
      organization: '',
      country: '',
      description: '',
    },
    resolver: organizationName
      ? yupResolver(editOrganizationSchema)
      : yupResolver(createAddOrganizationSchema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const getErrorMsg = (message: string) => {
    if (message.includes('409')) {
      return 'Another organization with this name already exists.';
    }
    return 'Something went wrong. Try again.';
  };
  const {
    mutate: editOrgMutation,
    error: editOrgMutationError,
    isLoading: editOrgMutationLoading,
  } = useMutation<
    AxiosResponse,
    AxiosError,
    { organization: string; country: string; description?: string }
  >({
    mutationFn: editOrganization,
    onSuccess: async () => {
      reset();
      await queryClient.invalidateQueries([QueryName.GET_ORGANIZATION]);
      onCancel();
      showToast('Organization edited successfully!', 'success');
    },
  });

  return (
    <Box>
      <DialogContent>
        <Box mt='24px'>
          <Typography variant='h3' fontSize='14px'>
            Organization
          </Typography>
        </Box>
        <Box display='flex' justifyContent='space-between' mb='6px' mt='12px'>
          {organizationName ? (
            <Stack direction='column'>
              <Typography variant={'h4'}> Name </Typography>
              <Typography sx={styles.editTitleText}>
                {organizationName}
              </Typography>
            </Stack>
          ) : (
            <Controller
              name='organization'
              control={control}
              render={({ field }) => (
                <TextField
                  width={236}
                  label='Organization'
                  error={
                    formState.errors.organization?.message
                      ? formState.errors.organization?.message
                      : undefined
                  }
                  {...field}
                />
              )}
            />
          )}
          <Controller
            name='country'
            control={control}
            render={({ field }) => (
              <Select
                width={236}
                label='Country'
                MenuProps={MenuProps}
                placeholder='Country'
                error={
                  formState.errors?.country && !organizationName
                    ? 'Please enter a valid country name'
                    : undefined
                }
                {...field}
                onChange={(event) => {
                  field.onChange(event.target.value);
                }}
              >
                {Object.entries(countriesList.countries).map(
                  ([key, country]) => (
                    <MenuItem key={key} value={country.name}>
                      {country.name}
                    </MenuItem>
                  ),
                )}
              </Select>
            )}
          />
        </Box>
        <Box mt='6px' mb='24px'>
          <Controller
            name='description'
            control={control}
            render={({ field }) => (
              <TextField
                width={236}
                label='Description (Optional)'
                {...field}
                onChange={(event) => {
                  field.onChange(event.target.value);
                }}
              />
            )}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button kind='primary-ghost' onClick={onCancel}>
          Cancel
        </Button>
        <Button
          kind='primary'
          onClick={handleSubmit(onSubmit)}
          disabled={addOrgMutationLoading || editOrgMutationLoading}
        >
          Save
        </Button>
      </DialogActions>
      {addOrgMutationError && (
        <Typography
          fontSize='10px'
          color='#F20000'
          mt='5px'
          textAlign='right'
          mr='7px'
        >
          {getErrorMsg(addOrgMutationError.message)}
        </Typography>
      )}
      {editOrgMutationError && (
        <Typography
          fontSize='10px'
          color='#F20000'
          mt='5px'
          textAlign='right'
          mr='7px'
        >
          {getErrorMsg(editOrgMutationError.message)}
        </Typography>
      )}
      <Backdrop
        sx={{ color: '#006161' }}
        open={editOrgMutationLoading || addOrgMutationLoading}
      >
        <CircularProgress size='60px' />
      </Backdrop>
    </Box>
  );
};
