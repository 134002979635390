import { Button, Divider, Typography, Box } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useContext, useState } from 'react';

import { importDashboard } from 'src/apis/dashboard';
import { Confirmable, Drawer, FileUpload } from 'src/components';
import { ToastContext } from 'src/context/ToastContext';
import { validateFile } from 'src/utils/file';
import { styles } from '../styles';

interface ImportDashboardDrawerProps {
  name: string;
  open: boolean;
  onImport: () => void;
  onClose: () => void;
}

export const ImportDashboardDrawer: React.FC<ImportDashboardDrawerProps> = ({
  name,
  open,
  onImport,
  onClose,
}) => {
  const { showToast } = useContext(ToastContext);

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [error, setError] = useState('');

  const handleFileChange = (files: FileList) => {
    const file = files[0];
    const result = validateFile(file, {
      types: ['application/json'],
      message: 'Invalid file. File format should be .json',
    });
    if (result) {
      setError(result);
      setSelectedFile(null);
    } else {
      setError('');
      setSelectedFile(file);
    }
  };

  const { mutate, isLoading } = useMutation<
    unknown,
    AxiosError,
    { name: string; file: File }
  >({
    mutationFn: importDashboard,
    onSuccess: () => {
      showToast('Widgets imported successfully!', 'success');
      onImport();
    },
  });

  const handleImport = () => {
    showToast('Importing widgets...', 'info');
    mutate({ name, file: selectedFile as File });
    setConfirmModalOpen(false);
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <>
      <Drawer open={open} onClose={onClose}>
        <Typography variant="h5" gutterBottom>
          Import Dashboard
        </Typography>
        <Divider />
        <Typography variant="body2" style={styles.drawerSubTitle}>
          This option allows you to import a dashboard.
        </Typography>
        <Box mt={3} display="flex" alignItems="center" mb={1}>
          <Typography mr={1}>File:</Typography>
          <Typography fontSize={14}>
            {selectedFile ? selectedFile.name : 'No File Selected'}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" flexGrow={1}>
          <FileUpload onChange={handleFileChange} />
          {error && (
            <Typography color="red" fontSize={14} mt="2px">
              {error}
            </Typography>
          )}
        </Box>
        <Box display="flex" justifyContent="space-evenly">
          <Button
            disableElevation
            variant="contained"
            sx={styles.addButton}
            onClick={() => setConfirmModalOpen(true)}
            disabled={isLoading || !selectedFile}
          >
            Import
          </Button>
          <Button sx={styles.resetButton} onClick={handleCancel}>
            Cancel
          </Button>
        </Box>
      </Drawer>
      <Confirmable
        open={confirmModalOpen}
        title="Import Widgets"
        subTitle="Performing this action will overwrite existing widgets in the dashboard."
        onConfirm={handleImport}
        onCancel={() => setConfirmModalOpen(false)}
      />
    </>
  );
};
