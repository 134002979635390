import styled from '@emotion/styled';
import { Checkbox } from '@mui/material';

export const StyledCheckbox = styled(Checkbox)`
  & {
    .MuiSvgIcon-root {
      fill: ${({ checked }) => (checked ? '#006161' : '#888888')};
      height: 16px;
      width: 16px;
    }
  }
`;
