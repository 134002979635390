import { Box, Typography } from '@mui/material';
import { Main } from 'src/layouts/Main';
import { RuleConfiguration } from 'src/types/rule';
import { Coordinates } from 'src/types/coordinates';
import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { Map } from './Map';
import { getCoordinates } from 'src/apis/geo-service';
import { ToastContext } from 'src/context';
import { useContext, useEffect } from 'react';
import { RuleNotification } from './Notification';
import { DisplayElements } from './DisplayElements';

interface GeofenceProps {
  ruleConfiguration: RuleConfiguration;
  readMoreShown: boolean;
}

export const GeofenceType: React.FC<GeofenceProps> = ({
  ruleConfiguration,
  readMoreShown,
}) => {
  const { showToast } = useContext(ToastContext);

  const geoTypeMap: { [key: string]: 'Inbound' | 'Outbound' } = {
    INBOUND_BREACH: 'Inbound',
    OUTBOUND_BREACH: 'Outbound',
  };

  const { mutate, data } = useMutation<
    AxiosResponse<Coordinates>,
    AxiosError,
    {
      organization: string;
      site: string;
      apns: string[];
      ues: string[];
      ueGroups: string[];
    }
  >({
    mutationFn: getCoordinates,
    onError: () => {
      showToast('Unable to fetch the coordinates, please try again!', 'error');
    },
  });
  useEffect(() => {
    mutate({
      organization: ruleConfiguration.organization,
      site: ruleConfiguration.site,
      apns: ruleConfiguration.apns,
      ues: ruleConfiguration.ues,
      ueGroups: ruleConfiguration.ueGroups
        ? Object.values(ruleConfiguration.ueGroups)
        : [],
    });
  }, [mutate, ruleConfiguration]);

  return (
    <Main
      hideHeader
      hideSidebar
      overrideStyles={{ padding: 0, marginTop: 'unset' }}
      renderOutsideWrapper={
        <Map data={data?.data as Coordinates} readMoreShown={readMoreShown} />
      }
    >
      <Box
        id='selection-container'
        display='flex'
        width='50%'
        sx={{
          height: 'calc(100vh - 50px)',
          padding: '24px',
          overflowY: 'auto',
          boxShadow: '8px 0 5px -3px rgb(0 0 0 / 25%)',
          zIndex: 1,
          position: 'relative',
        }}
      >
        <Box sx={{ width: '600px' }}>
          <Box display='flex' justifyContent='space-between'>
            <Box
              display='flex'
              alignItems='flex-start'
              flexDirection='column'
              width='60%'
            >
              <Typography variant='h2' pt='11px'>
                Parameters
              </Typography>
              <Typography variant='h3' pt='14px'>
                Geofence
              </Typography>
              <Typography variant='h4' pt='12px'>
                {geoTypeMap[ruleConfiguration.geoFenceType]}
              </Typography>
              {ruleConfiguration.notifyOnce ? (
                <Typography variant='body1'>Notify once</Typography>
              ) : (
                <Typography variant='body1'>Recurring</Typography>
              )}
            </Box>
            <DisplayElements ruleConfiguration={ruleConfiguration} />
          </Box>
          {(ruleConfiguration.notification?.email ||
            ruleConfiguration.notification?.slack ||
            ruleConfiguration.notification?.teams) && (
            <RuleNotification notification={ruleConfiguration.notification} />
          )}
        </Box>
      </Box>
    </Main>
  );
};
