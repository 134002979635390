import {
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useState,
  useCallback,
} from 'react';
import { DialogTitle, IconButton, Typography } from '@mui/material';
import { Close } from '@carbon/icons-react';

import { StyledDialog } from 'src/components/Modal/styles';
import { styles } from 'src/views/DeviceManagement/Listing/BulkAllocateDevices/styles';
import { BulkAllocateDevicesStepOne } from './BulkAllocateDevicesStepOne';
import { BulkAllocateDevicesStepTwo } from './BulkAllocateDevicesStepTwo';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ToastContext } from 'src/context';
import { QueryName } from 'src/enums/query';
import { bulkAllocateUe } from 'src/apis/ue';
import { AxiosError, AxiosResponse } from 'axios';

interface UploadDeviceProps {
  openModal: boolean;
  closeModal: () => void;
}

enum STEPS {
  SELECT_FILE = 0,
  UPLOAD_FILE = 1,
}

export const BulkAllocateDevicesModal: FC<UploadDeviceProps> = ({
  openModal,
  closeModal,
}) => {
  const queryClient = useQueryClient();
  const { showToast } = useContext(ToastContext);

  const [progress, setProgress] = useState(0);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [step, setStep] = useState(STEPS.SELECT_FILE);
  const [result, setResult] = useState<{
    successfulUploads: string[];
    failedUploads: string[];
    errorReportPath: string;
  } | null>(null);

  const selectFile = (file: File | null) => {
    setSelectedFile(file);
  };

  const { mutate: uploadAllocateDevicesFile, isLoading } = useMutation<
    AxiosResponse,
    AxiosError,
    {
      file: File;
      onProgress: Dispatch<SetStateAction<number>>;
    }
  >({
    mutationFn: bulkAllocateUe,
    onSuccess: async (responseData) => {
      setResult({
        successfulUploads: responseData.data.success,
        failedUploads: responseData.data.failed,
        errorReportPath: responseData.data.reportPath,
      });
      if (responseData.data.successCount > 0) {
        await queryClient.invalidateQueries([QueryName.GET_ALL_DEVICES]);
      }
    },
    onError: () => {
      showToast(
        'An error occurred while allocating devices, please try again',
        'error',
      );
    },
  });

  const handleUploadAllocateDevicesFile = useCallback(() => {
    uploadAllocateDevicesFile({
      file: selectedFile as File,
      onProgress: setProgress,
    });
  }, [selectedFile, uploadAllocateDevicesFile]);
  const handleCloseModal = () => {
    closeModal();
    setTimeout(() => {
      setResult(null);
      setProgress(0);
      setSelectedFile(null);
      setStep(STEPS.SELECT_FILE);
    }, 300);
  };

  const goToSecondStep = () => {
    handleUploadAllocateDevicesFile();
    setStep(STEPS.UPLOAD_FILE);
  };

  return (
    <StyledDialog open={openModal}>
      <DialogTitle>
        <Typography sx={styles.uploadDeviceTitle}>
          Upload devices file
        </Typography>
        <IconButton sx={styles.closeIcon} onClick={handleCloseModal}>
          <Close size='24' color='#222222' />
        </IconButton>
      </DialogTitle>
      {step === STEPS.SELECT_FILE && (
        <BulkAllocateDevicesStepOne
          selectedFile={selectedFile}
          selectFile={selectFile}
          goToSecondStep={goToSecondStep}
          handleCloseModal={handleCloseModal}
        />
      )}
      {step === STEPS.UPLOAD_FILE && (
        <BulkAllocateDevicesStepTwo
          handleCloseModal={handleCloseModal}
          selectedFile={selectedFile}
          isLoading={isLoading}
          result={result}
          progress={progress}
        />
      )}
    </StyledDialog>
  );
};
