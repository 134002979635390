import { useContext, useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  Link,
  ListItemText,
  RadioGroup,
  Typography,
} from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AddCircleOutlineOutlined } from '@mui/icons-material';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@tanstack/react-query';
import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError, AxiosResponse } from 'axios';
import { useNavigate } from 'react-router-dom';

import { StyledMenuItem } from './styles';
import {
  GeneralInfo,
  RulesEngineContext,
} from 'src/context/RulesEngineContext';
import { generalInfoValidationSchema } from 'src/yup-validations/rules-engine';
import {
  Switch,
  Button,
  TextField,
  Checkbox,
  Radio,
  ChannelManagementDialog,
} from 'src/components';
import { QueryName } from 'src/enums/query';
import { getAllUserEmails } from 'src/apis/access-management';
import { AppContext, AuthContext, ToastContext } from 'src/context';
import { editRule, CreateRuleData } from 'src/apis/rule';
import { getChannels } from 'src/apis/channel';
import { RuleConfiguration } from 'src/types/rule';

interface GeneralInformationProps {
  onCancel: () => void;
  ruleConfiguration: RuleConfiguration;
}

export const GeneralInformation: React.FC<GeneralInformationProps> = ({
  onCancel,
  ruleConfiguration,
}) => {
  const navigate = useNavigate();

  const { user } = useContext(AuthContext);
  const { showToast } = useContext(ToastContext);
  const { site } = useContext(AppContext);

  const [addChannelDialogVisible, setAddChannelDialogVisible] = useState(false);
  const {
    generalInfo,
    ruleType,
    selectedAPNs,
    selectedEquipments,
    addGeneralInfo,
  } = useContext(RulesEngineContext);

  const { data: emailsData } = useQuery(
    [QueryName.GET_ALL_USER_EMAILS],
    () => getAllUserEmails(user?.organizations?.[0] as string),
    {
      enabled: !!user?.organizations?.[0],
      onError: () =>
        showToast('Unable to get users list. Please try again later!', 'error'),
    },
  );

  const [enableNotifications, setEnableNotifications] = useState({
    email: ruleConfiguration.notification?.email,
    slack: ruleConfiguration.notification?.slack,
    teams: ruleConfiguration.notification?.teams,
  });

  const {
    control,
    formState: { isValid, errors },
    setValue,
    handleSubmit,
  } = useForm({
    defaultValues: {
      ruleName: ruleConfiguration.name,
      description: ruleConfiguration.description,
      startingFrom: new Date(ruleConfiguration.from as string),
      state: ruleConfiguration.state,
      endingOn: ruleConfiguration.to
        ? new Date(ruleConfiguration.to)
        : generalInfo.endingOn,
      notificationEmails: generalInfo.notificationEmails,
      slackChannels: generalInfo.slackChannels,
      teamsChannels: generalInfo.teamsChannels,
    },
    resolver: yupResolver(generalInfoValidationSchema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const { data: channelsData, refetch: refetchChannels } = useQuery(
    [QueryName.GET_ALL_CHANNELS],
    () => getChannels(user?.organizations?.[0] as string),
    {
      enabled: !!user?.organizations?.[0],
    },
  );

  const updateRuleMutation = useMutation<
    AxiosResponse,
    AxiosError<{ title: string }>,
    CreateRuleData
  >({
    mutationFn: editRule,
    onSuccess: () => {
      showToast('Rule updated successfully!', 'success');
      navigate('/rules');
    },
    onError: (error) =>
      showToast(
        error.response?.data?.title
          ? error.response?.data.title
          : 'An error occurred while updating a rule. Please try again later',
        'error',
      ),
  });

  const onSubmit = (data: GeneralInfo) => {
    addGeneralInfo({ ...data });
    const finalData: CreateRuleData = {
      id: ruleConfiguration.id,
      organization: user?.organizations?.[0] as string,
      site,
      name: data.ruleName,
      description: data.description,
      type: ruleType,
      state: data.state,
      from: data.startingFrom
        ? new Date(data.startingFrom).toISOString()
        : undefined,
      to: data.endingOn ? new Date(data.endingOn).toISOString() : undefined,
      createdBy: user?.name as string,
      updatedBy: user?.name as string,
      apns: selectedAPNs.map((apn) => apn.name),
      ues: selectedEquipments
        .filter((ue) => ue.type === 'equipment')
        .map((ue) => ue.id),
      ueNames: selectedEquipments
        .filter((ue) => ue.type === 'equipment')
        .reduce((prev, curr) => {
          return { ...prev, [curr.id]: curr.name };
        }, {}),
      ueGroups: selectedEquipments
        .filter((ue) => ue.type === 'equipment_group')
        .reduce((prev, curr) => {
          return { ...prev, [curr.id]: curr.name };
        }, {}),
      priorityMap: selectedEquipments.reduce((prev, curr, index) => {
        return { ...prev, [index + 1]: curr.id };
      }, {}),
      notifyOnce: true,
      notification: {
        email: data.notificationEmails.length > 0,
        emailContacts: data.notificationEmails,
        slack: data.slackChannels.length > 0,
        slackChannels: data.slackChannels.reduce((prev, curr) => {
          return { ...prev, [curr.id]: curr.name };
        }, {}),
        teams: data.teamsChannels.length > 0,
        teamsChannels: data.teamsChannels.reduce((prev, curr) => {
          return { ...prev, [curr.id]: curr.name };
        }, {}),
      },
    };
    updateRuleMutation.mutate(finalData);
  };

  useEffect(() => {
    setValue(
      'notificationEmails',
      ruleConfiguration.notification.emailContacts,
    );
    const slackId = Object.keys(
      ruleConfiguration?.notification?.slackChannels
        ? ruleConfiguration.notification.slackChannels
        : [],
    );
    const defaultSlacks = channelsData?.data?.channels?.filter((channel) =>
      slackId.includes(channel.id),
    );
    setValue('slackChannels', defaultSlacks ?? []);

    const teamsId = Object.keys(
      ruleConfiguration?.notification?.teamsChannels
        ? ruleConfiguration.notification.teamsChannels
        : [],
    );
    const defaultTeams = channelsData?.data?.channels?.filter((teams) =>
      teamsId.includes(teams.id),
    );
    setValue('teamsChannels', defaultTeams ?? []);
  }, [setValue, channelsData]);

  return (
    <Box>
      <Box mr='95px'>
        <Typography variant='h2' mb='27px'>
          General information
        </Typography>
        <Controller
          name='description'
          control={control}
          render={({ field }) => (
            <TextField
              label='Description'
              size='small'
              multiline
              rows={2}
              InputProps={{
                sx: { width: '485px' },
              }}
              error={
                !isValid && errors.description?.message
                  ? errors.description?.message
                  : undefined
              }
              sx={{ display: 'block', marginBottom: '10px' }}
              {...field}
            />
          )}
        />

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Box display='flex' justifyContent='space-between'>
            <Controller
              name='startingFrom'
              control={control}
              render={({ field }) => (
                <DateTimePicker
                  label='Starting from (optional)'
                  inputFormat='dd/MM/yyyy HH:mm'
                  renderInput={({ error: _, ...rest }) => (
                    <TextField
                      sx={{ width: '236px' }}
                      error={
                        !isValid && errors.startingFrom?.message
                          ? errors.startingFrom?.message
                          : undefined
                      }
                      {...rest}
                    />
                  )}
                  {...field}
                />
              )}
            />

            <Controller
              name='endingOn'
              control={control}
              render={({ field }) => (
                <DateTimePicker
                  label='Ending on (optional)'
                  inputFormat='dd/MM/yyyy HH:mm'
                  renderInput={({ error: _, ...rest }) => (
                    <TextField
                      sx={{ width: '236px' }}
                      error={
                        !isValid && errors.endingOn?.message
                          ? errors.endingOn?.message
                          : undefined
                      }
                      {...rest}
                    />
                  )}
                  {...field}
                />
              )}
            />
          </Box>
        </LocalizationProvider>
        <Typography variant='h4' mt='35px' mb='7px'>
          Rule status
        </Typography>
        <Controller
          name='state'
          control={control}
          render={({ field }) => (
            <RadioGroup row {...field}>
              <Radio label='Active' value='ACTIVE' />
              <Radio label='Inactive' value='INACTIVE' />
            </RadioGroup>
          )}
        />

        <Box display='flex' alignItems='baseline' marginBottom='22px' mt='36px'>
          <Typography variant='h2' sx={{ marginRight: '30px' }}>
            Notifications
          </Typography>
          <Box
            display='flex'
            alignItems='center'
            sx={{ cursor: 'pointer' }}
            onClick={() => setAddChannelDialogVisible(true)}
          >
            <AddCircleOutlineOutlined sx={{ height: 16, fill: '#006161' }} />
            <Link
              fontSize={14}
              color='#006161'
              sx={{ textDecoration: 'underline' }}
            >
              Add a channel
            </Link>
          </Box>
        </Box>
        <Box display='flex' alignItems='center' mb='12px'>
          <Typography mr='64px' color='#222222'>
            Email
          </Typography>
          <Switch
            checked={enableNotifications.email}
            onChange={(_, checked) => {
              setEnableNotifications({
                ...enableNotifications,
                email: checked,
              });
              setValue('notificationEmails', []);
            }}
          />
        </Box>
        {enableNotifications.email && (
          <Controller
            name='notificationEmails'
            control={control}
            render={({ field: { onChange, ...rest } }) => (
              <Autocomplete
                multiple
                disableCloseOnSelect
                options={emailsData?.data ?? []}
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Contacts'
                    sx={{ width: '486px' }}
                  />
                )}
                onChange={(_, value) => onChange(value)}
                renderOption={(props, option, { selected }) => (
                  <StyledMenuItem {...props}>
                    <Checkbox checked={selected} />
                    <ListItemText primary={option} />
                  </StyledMenuItem>
                )}
                {...rest}
              />
            )}
          />
        )}
        <Box mt='26px'>
          <Typography variant='h4'>Push notifications</Typography>
          <Box display='flex' alignItems='center' mt='12px'>
            <Typography color='#222222' mr='64px'>
              Slack
            </Typography>
            <Switch
              checked={enableNotifications.slack}
              onChange={(_, checked) => {
                setEnableNotifications({
                  ...enableNotifications,
                  slack: checked,
                });
                setValue('slackChannels', []);
              }}
            />
          </Box>
          {enableNotifications.slack && (
            <Controller
              name='slackChannels'
              control={control}
              render={({ field: { onChange, ...rest } }) => (
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  options={
                    channelsData?.data?.channels.filter(
                      (channel) => channel.type === 'SLACK',
                    ) ?? []
                  }
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Channels'
                      sx={{ width: '486px' }}
                    />
                  )}
                  onChange={(_, value) => onChange(value)}
                  renderOption={(props, option, { selected }) => (
                    <StyledMenuItem {...props}>
                      <Checkbox checked={selected} value={option.id} />
                      <ListItemText primary={option.name} />
                    </StyledMenuItem>
                  )}
                  {...rest}
                />
              )}
            />
          )}
          <Box display='flex' alignItems='center' mt='6px' mb='6px'>
            <Typography color='#222222' mr='56px'>
              Teams
            </Typography>
            <Switch
              checked={enableNotifications.teams}
              onChange={(_, checked) => {
                setEnableNotifications({
                  ...enableNotifications,
                  teams: checked,
                });
                setValue('teamsChannels', []);
              }}
            />
          </Box>
          {enableNotifications.teams && (
            <Controller
              name='teamsChannels'
              control={control}
              render={({ field: { onChange, ...rest } }) => (
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  options={
                    channelsData?.data?.channels.filter(
                      (channel) => channel.type === 'TEAMS',
                    ) ?? []
                  }
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Channels'
                      sx={{ width: '486px' }}
                    />
                  )}
                  onChange={(_, value) => onChange(value)}
                  renderOption={(props, option, { selected }) => (
                    <StyledMenuItem {...props}>
                      <Checkbox checked={selected} />
                      <ListItemText primary={option.name} />
                    </StyledMenuItem>
                  )}
                  {...rest}
                />
              )}
            />
          )}
        </Box>
        <ChannelManagementDialog
          open={addChannelDialogVisible}
          onSave={async () => {
            setAddChannelDialogVisible(false);
            await refetchChannels();
          }}
          onCancel={() => setAddChannelDialogVisible(false)}
        />
      </Box>
      <Box mt='36px' mb='43px' display='flex' justifyContent='flex-end'>
        <Button kind='primary-ghost' onClick={onCancel}>
          Cancel
        </Button>
        <Button
          sx={{ ml: '15px' }}
          padding='8px 46px'
          kind='primary'
          disabled={updateRuleMutation.isLoading}
          onClick={handleSubmit(onSubmit)}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};
