import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  Box,
  FormControlLabel,
  Typography,
  Stack,
  InputAdornment,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { AxiosError } from 'axios';
import { Search } from '@carbon/icons-react';

import { QueryName } from 'src/enums/query';
import { Button, Checkbox, Loading } from 'src/components';
import { AppContext, AuthContext, ToastContext } from 'src/context';
import { styles } from './styles';
import { getUsersNameList } from 'src/apis/access-management';
import { UsersList } from 'src/types/user';
import { TextField } from 'src/components/TextField';
import { createUsagePolicy } from 'src/apis/policyManagement';
import { DataUsage } from './DataUsage';
import { createPolicy } from 'src/yup-validations/createUsagePolicy';
import { routes } from 'src/routes/routes';

export type UsagePolicy = {
  policyName: string;
  allocationPerUser: string;
};

export type UsersForPolicy = {
  userIds: string;
};

export const SelectUsers: React.FC = () => {
  const { user } = useContext(AuthContext);
  const { showToast } = useContext(ToastContext);
  const organization = user?.organizations?.[0] as string;
  const { site } = useContext(AppContext);
  const [selectedUsers, setSelectedUsers] = useState<UsersForPolicy[]>([]);
  const [allocationPerUser, setAllocationPerUser] = useState('10');
  const randomNumber = Math.round(Math.random() * 999);
  const [policyName, setPolicyName] = useState(`Policy_${randomNumber}`);
  const [filter, setFilter] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    setValue('policyName', `Policy_${randomNumber}`);
    setValue('allocationPerUser', '10');
  }, [organization, site]);

  useEffect(() => {
    if (selectedUsers.length > 0) {
      setErrorMessage(false);
    }
  }, [selectedUsers]);

  const { data: usersList, isLoading } = useQuery(
    [QueryName.GET_USERS_NAME],
    () => getUsersNameList(organization, true),
    {
      enabled: !!user?.organizations?.length,
    },
  );

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      allocationPerUser: '',
      policyName: '',
    },
    resolver: yupResolver(createPolicy),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const addNewUser = (user: UsersForPolicy) => {
    setSelectedUsers((prev) => [...prev, user]);
  };

  const removeUser = (userId: UsersForPolicy['userIds']) => {
    const tempUsers = [...selectedUsers];
    const usersIndex = tempUsers.findIndex((users) => users.userIds === userId);
    tempUsers.splice(usersIndex, 1);
    setSelectedUsers([...tempUsers]);
  };

  const selectUsers = (users: UsersList) => {
    addNewUser({
      userIds: users.userId,
    });
  };

  const {
    mutate: createUsagePolicyMutation,
    isLoading: isLoadingCreateUsagePolicy,
  } = useMutation<
    unknown,
    AxiosError,
    {
      organization: string;
      site: string;
      policyName: string;
      allocationPerUser: number;
      userIds: string[];
    }
  >({
    mutationFn: createUsagePolicy,
    onSuccess: () => {
      showToast('Usage Policy created successfully', 'success');
      navigate(routes.usagePolicy);
    },
    onError: () => {
      showToast('Failed to create usage policy, try again.', 'error');
    },
  });

  const handleSavePolicy = (data: UsagePolicy) => {
    if (selectedUsers.length > 0) {
      const selectedUserIds = selectedUsers.map(
        (selectedUser) => selectedUser.userIds,
      );
      createUsagePolicyMutation({
        organization,
        site,
        policyName: data.policyName,
        allocationPerUser: Number(data.allocationPerUser),
        userIds: selectedUserIds,
      });
    } else {
      setErrorMessage(true);
    }
  };

  return (
    <Loading isLoading={isLoadingCreateUsagePolicy}>
      <Box display='flex'>
        <Box>
          <Stack direction='row' justifyContent='space-between' spacing={2}>
            <Typography variant='h2' pb='17px'>
              Select users
            </Typography>
            <TextField
              isSearchField
              placeholder='Search'
              id='search-users'
              InputProps={{
                endAdornment: <Search size={20} color='#888888' />,
              }}
              onChange={(event) => setFilter(event.target.value)}
              width={187}
            />
          </Stack>
          <Box height={472} width={538} sx={styles.selectUsersBox}>
            <Loading isLoading={isLoading}>
              <Box flexDirection='column' display='flex' paddingTop='10px'>
                {usersList && usersList?.data.users.length === 0 && (
                  <Typography sx={styles.noUsersText}>
                    No users found
                  </Typography>
                )}
                {usersList?.data.users.filter((user) =>
                    (`${user.firstName} ${user.lastName}`).toLowerCase()
                    .includes(filter.toLowerCase()),
                  )
                  .map((user) => (
                  <FormControlLabel
                    key={user.userId}
                    checked={
                      selectedUsers.findIndex(
                        (tempUsers) => tempUsers.userIds === user.userId,
                      ) > -1
                    }
                    onChange={(_, checked) =>
                      checked ? selectUsers(user) : removeUser(user.userId)
                    }
                    control={<Checkbox size='small' />}
                    label={
                      <Stack direction='row'>
                        <Typography sx={styles.userDetailsText}>
                          {user.lastName} {user.firstName}
                        </Typography>
                        {usersList && user.policyName !== '' && (
                          <Typography sx={styles.policyDetailsText}>
                            {user?.policyName} | {user?.dataAllocated}
                          </Typography>
                        )}
                      </Stack>
                    }
                    sx={styles.formControl}
                  />
                ))}
              </Box>
            </Loading>
          </Box>
          {errorMessage && (
            <Box sx={styles.errorBox}>
              <Typography sx={styles.noUserError}> Select a user </Typography>
            </Box>
          )}
        </Box>
        <Box sx={styles.policyBox}>
          <Stack direction='column'>
            <Typography variant='h2'>Policy</Typography>
            <Box sx={styles.parametersBox}>
              <Typography sx={styles.parametersText}> Parameters</Typography>
              <Stack direction='row' sx={styles.usageTextFields}>
                <Stack direction='column'>
                  <Controller
                    name='allocationPerUser'
                    control={control}
                    render={({ field }) => (
                      <TextField
                        width={211}
                        label='Usage limit per user'
                        {...field}
                        onChange={(event) => {
                          field.onChange(event.target.value);
                          setAllocationPerUser(event.target.value);
                        }}
                        InputProps={{
                          sx: {
                            width: '211px',
                          },
                          endAdornment: (
                            <InputAdornment
                              position='end'
                              sx={styles.characterAdornment}
                            >
                              <Typography sx={styles.characterAdornmentText}>
                                {allocationPerUser.length}/5
                              </Typography>
                            </InputAdornment>
                          ),
                        }}
                        inputProps={{ maxLength: 5 }}
                      />
                    )}
                  />
                  {errors?.allocationPerUser && (
                    <Box width={130}>
                      <Typography sx={styles.errors}>
                        {errors.allocationPerUser.message}
                      </Typography>
                    </Box>
                  )}
                </Stack>
                <Typography sx={styles.usageGB}> GB </Typography>
                <Stack direction='column'>
                  <Controller
                    name='policyName'
                    control={control}
                    render={({ field }) => (
                      <TextField
                        width={211}
                        label='Policy name'
                        {...field}
                        onChange={(event) => {
                          field.onChange(event.target.value);
                          setPolicyName(event.target.value);
                        }}
                        InputProps={{
                          sx: {
                            width: '211px',
                          },
                          endAdornment: (
                            <InputAdornment
                              position='end'
                              sx={styles.characterAdornment}
                            >
                              <Typography sx={styles.characterAdornmentText}>
                                {policyName.length}/30
                              </Typography>
                            </InputAdornment>
                          ),
                        }}
                        inputProps={{ maxLength: 30 }}
                      />
                    )}
                  />
                  {errors?.policyName && (
                    <Box width={130}>
                      <Typography sx={styles.errors}>
                        {errors.policyName.message}
                      </Typography>
                    </Box>
                  )}
                </Stack>
              </Stack>
            </Box>
            <DataUsage
              selectedUsers={selectedUsers}
              allocationPerUser={Number(allocationPerUser)}
            />
            <Box sx={styles.operationButtonBox}>
              <Button
                kind='primary-ghost'
                onClick={() => {
                  navigate(routes.usagePolicy);
                }}
                sx={styles.operationButton}
              >
                Cancel
              </Button>
              <Button kind='primary' onClick={handleSubmit(handleSavePolicy)}>
                Create
              </Button>
            </Box>
          </Stack>
        </Box>
      </Box>
    </Loading>
  );
};
