import { Box, Pagination as MuiPagination } from '@mui/material';

interface PaginationProps {
  currentPage: number;
  totalRecords: number;
  perPage?: number;
  disabled?: boolean;
  onChange: (pageStart: number, pageEnd: number) => void;
}

export const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalRecords,
  perPage = 10,
  disabled,
  onChange,
}) => {
  const totalPages = Math.ceil(totalRecords / perPage);

  const getNextPageAttr = (page: number) => {
    const pageStart = page === 1 ? 1 : (page - 1) * perPage + 1;
    let pageEnd = page * perPage;
    if (pageEnd > totalRecords) {
      pageEnd = totalRecords;
    }
    onChange(pageStart, pageEnd);
  };

  return (
    <Box display="flex" justifyContent="flex-end">
      <MuiPagination
        disabled={disabled}
        page={Math.ceil(currentPage / perPage)}
        count={totalPages}
        onChange={(_, page) => getNextPageAttr(page)}
      />
    </Box>
  );
};
