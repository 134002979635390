import { Box, Typography } from '@mui/material';
import { Notification } from 'src/types/rule';

interface NotificationProps {
  notification: Notification;
}

export const RuleNotification: React.FC<NotificationProps> = ({
  notification,
}) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      bgcolor="background.paper"
      pt="53px"
    >
      <Box
        display="flex"
        alignItems="flex-start"
        flexDirection="column"
        width="60%"
      >
        <Box display="flex" alignItems="flex-start" pb="6px">
          <Typography variant="h2">Notifications</Typography>
        </Box>
        {notification.email && (
          <Typography variant="h3" mt="14px" mb="11px">
            Email
          </Typography>
        )}
        {notification.emailContacts?.map((email) => (
          <Typography key={email} variant="body1">
            {email}
          </Typography>
        ))}
      </Box>
      <Box width="40%">
        {(notification.slack || notification.teams) && (
          <Typography variant="h3" mt="46px">
            Push notifications
          </Typography>
        )}
        {notification.slack && notification.slackChannels && (
          <>
            <Typography variant="h4" mt="11px" mb="6px">
              Slack
            </Typography>
            {Object.values(notification.slackChannels).map((slack) => (
              <Typography key={slack} variant="body1">
                {slack}
              </Typography>
            ))}
          </>
        )}
        {notification.teams && notification.teamsChannels && (
          <>
            <Typography variant="h4" mt="14px" mb="6px">
              Teams
            </Typography>
            {Object.values(notification.teamsChannels).map((teams) => (
              <Typography key={teams} variant="body1">
                {teams}
              </Typography>
            ))}
          </>
        )}
      </Box>
    </Box>
  );
};
