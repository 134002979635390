import { StyledDrawer, StyledDrawerProps } from './styles';

interface DrawerProps extends StyledDrawerProps {
  children: React.ReactNode;
}

export const Drawer: React.FC<DrawerProps> = ({ children, ...rest }) => {
  return (
    <StyledDrawer anchor="right" {...rest}>
      {children}
    </StyledDrawer>
  );
};
