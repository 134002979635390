import { Close } from '@mui/icons-material';
import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
} from '@mui/material';
import React from 'react';
import { Button } from '../Button';
import { StyledDialog, styles } from './styles';

interface ConfirmableProps {
  open: boolean;
  title: string;
  subTitle: string;
  onConfirm: () => void;
  onCancel: () => void;
}

export const Confirmable: React.FC<ConfirmableProps> = ({
  open,
  title,
  subTitle,
  onConfirm,
  onCancel,
}) => {
  const handleConfirm = () => {
    onConfirm();
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <StyledDialog open={open}>
      <DialogTitle>
        {title}
        <IconButton sx={styles.closeIconButton} onClick={onCancel}>
          <Close fontSize="medium" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText mb={2}>{subTitle}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button kind="primary-ghost" onClick={handleCancel}>
          Cancel
        </Button>
        <Button kind="primary" onClick={handleConfirm}>
          Confirm
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};
