import { Box, Typography } from '@mui/material';

import { Breadcrumbs } from 'src/components/Breadcrumbs';
import { Main } from 'src/layouts/Main';
import React from 'react';
import { SelectUsers } from './SelectUsers';

export const CreatePolicy: React.FC = () => {
  return (
    <Main>
      <Breadcrumbs
        breadcrumbs={[
          {
            title: 'Overview',
            url: '/',
          },
          {
            title: 'Usage policy',
            url: '/usage-policy',
          },
          {
            title: 'Create usage policy',
          },
        ]}
      />
      <Box display='flex' justifyContent='space-between'>
        <Typography variant='h1'>Create usage policy</Typography>
      </Box>

      <Box mt='45px' display='flex'>
        <SelectUsers />
      </Box>
    </Main>
  );
};
