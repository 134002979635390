import { api } from 'src/utils/axios';
import { ApiResponseType } from 'src/types/api';
import { Site } from 'src/types/domain';

const baseUrl = `${process.env.REACT_APP_BACKEND_URL}`;

export const getSiteNames = (org: string, searchText?: string) => {
  let url = `${baseUrl}topology/v1/metadata/site/name?organization=${org}`;
  if (searchText) {
    url += `?searchText=${searchText}`;
  }
  return api.get<ApiResponseType<string[]>>(url);
};

export const addSite = ({
  organization,
  site,
  description,
  section,
  latitude,
  longitude,
}: {
  organization: string;
  site: string;
  description?: string;
  section?: string[];
  latitude: number;
  longitude: number;
}) => {
  const url = 'topology/v1/site';
  return api.post(url, {
    organization,
    site,
    description,
    section,
    latitude,
    longitude,
  });
};

export const getSite = async (site: string, organization: string) => {
  const { data } = await api.get<ApiResponseType<Site>>(
    `/topology/v1/site?site=${site}`,
    {
      params: {
        organization,
      },
    },
  );
  return data;
};

export const editSite = ({
  organization,
  site,
  description,
  section,
  latitude,
  longitude,
}: {
  organization: string;
  site: string;
  description?: string;
  section?: string[];
  latitude: number;
  longitude: number;
}) => {
  const url = `${baseUrl}topology/v1/site?site=${site}`;
  return api.patch(url, {
    organization,
    site,
    description,
    section,
    latitude,
    longitude,
  });
};
export const getAllSiteNames = (org: string) => {
  return api.get<ApiResponseType<string[]>>('/topology/v1/metadata/site/name', {
    params: {
      organization: org,
    },
  });
};
