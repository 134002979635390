import { FC, useState } from 'react';
import { Box, IconButton, InputAdornment, Typography } from '@mui/material';
import { Controller, ControllerProps, FormState } from 'react-hook-form';
import { View, ViewFilled } from '@carbon/icons-react';

import { TextField } from 'src/components';
import { styles } from './styles';
import { UserDetails } from 'src/types/user';

interface CredentialsProps {
  formState: FormState<UserDetails>;
  generatePassword: () => void;
}

export const Credentials: FC<
  CredentialsProps & Pick<ControllerProps<UserDetails>, 'control'>
> = ({ control, formState, generatePassword }) => {
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => setShowPassword((show) => !show);

  return (
    <Box display={'flex'}>
      <Box flex={1}>
        <Typography sx={styles.subtitle}>Identify</Typography>
        <Controller
          name='lastName'
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              id='lastName'
              name='lastName'
              label='Last name'
              sx={styles.dropdownsWrapper}
              error={!!formState.errors && formState.errors?.lastName?.message}
            />
          )}
        />
        <Controller
          name='firstName'
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              name='firstName'
              label='First name'
              sx={styles.dropdownsWrapper}
              error={!!formState.errors && formState.errors?.firstName?.message}
            />
          )}
        />
      </Box>
      <Box flex={1}>
        <Typography sx={styles.subtitle}>Account</Typography>
        <Controller
          name='userId'
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              name='userId'
              label='E-mail address'
              sx={styles.dropdownsWrapper}
              error={!!formState.errors && formState.errors?.userId?.message}
            />
          )}
        />
        <Controller
          name='password'
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              placeholder=''
              name='password'
              label='Password'
              sx={styles.passwordInput}
              error={!!formState.errors && formState.errors?.password?.message}
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={toggleShowPassword}
                      edge='end'
                    >
                      {showPassword ? (
                        <ViewFilled style={styles.passwordIcon} />
                      ) : (
                        <View style={styles.passwordIcon} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        <Typography sx={styles.generatePassword} onClick={generatePassword}>
          Generate a new password
        </Typography>
      </Box>
    </Box>
  );
};
