import { api } from 'src/utils/axios';
import { ApiResponseType } from 'src/types/api';
import { Channel } from 'src/types/channel';

export type CreateChannelData = {
  id: string;
  organization: string;
  name: string;
  url: string;
  type: string;
  ruleNames: [string];
};

export const createChannel = (data: CreateChannelData) => {
  return api.post('channel/v1', data);
};

export const editChannel = (data: CreateChannelData) => {
  return api.patch('channel/v1', data);
};

export const getChannels = (
  org: string,
  searchText?: string,
  type?: string,
  sortDirection?: string,
  sortCriteria?: string,
  pageStart?: number,
  pageEnd?: number,
) => {
  return api.get<ApiResponseType<{ total: number; channels: Channel[] }>>(
    'channel/v1/list',
    {
      params: {
        organization: org,
        searchText,
        type,
        sortDirection,
        sortCriteria
      },
      headers: {
        pageStart,
        pageEnd,
      },
    },
  );
};

export const deleteChannel = (
  {
    organization,
    id,
  }: {
  organization: string;
  id: string;
}) => {
  return api.delete(
    `/channel/v1?organization=${organization}&id=${id}`,
  );
};
