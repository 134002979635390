import {
  Timeline as MuiTimeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent,
  timelineOppositeContentClasses,
  timelineContentClasses,
  timelineConnectorClasses,
} from '@mui/lab';

interface TimelineProps {
  timeline: Array<{
    oppositeContent?: React.ReactNode;
    content: React.ReactNode;
  }>;
}

export const Timeline: React.FC<TimelineProps> = ({ timeline }) => {
  return (
    <MuiTimeline
      sx={{
        paddingLeft: 0,
        [`& .${timelineOppositeContentClasses.root}`]: {
          flex: 0,
          paddingLeft: 0,
          width: 40,
          paddingRight: '18px',
        },
        [`& .${timelineContentClasses.root}`]: {
          paddingLeft: '29px',
        },
        [`& .${timelineConnectorClasses.root}`]: {
          width: '1px',
        },
      }}
    >
      {timeline.map((item, index) => (
        <TimelineItem key={index} sx={{ minHeight: 60 }}>
          {item.oppositeContent && (
            <TimelineOppositeContent>
              {item.oppositeContent}
            </TimelineOppositeContent>
          )}
          <TimelineSeparator>
            <TimelineDot sx={{ backgroundColor: '#659F9F' }} />
            {timeline.length - 1 !== index && (
              <TimelineConnector
                sx={{
                  backgroundColor: '#659F9F',
                  marginTop: '-35px',
                  marginBottom: '-50px',
                }}
              />
            )}
          </TimelineSeparator>
          <TimelineContent>{item.content}</TimelineContent>
        </TimelineItem>
      ))}
    </MuiTimeline>
  );
};
