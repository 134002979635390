import { createContext, useState, ReactNode } from 'react';
import { Channel } from 'src/types/channel';

type Polygon = {
  type: 'POLYGON';
  coordinates?: number[][];
};

type GeoMapAPN = {
  name: string;
  type: 'apn';
  geometry: Polygon | null;
};

export type Equipment = {
  id: string;
  name: string;
  coordinates: {
    latitude: number;
    longitude: number;
  } | null;
  type: 'equipment' | 'equipment_group';
};

export type GeneralInfo = {
  ruleName: string;
  description: string;
  startingFrom: Date | null;
  endingOn: Date | null;
  state: 'ACTIVE' | 'INACTIVE' | 'EXPIRED';
  notificationEmails: string[];
  slackChannels: Channel[];
  teamsChannels: Channel[];
  notifyOnce?: boolean;
  thresholdType?: string;
  threshold?: number;
  geoFenceType?: string;
};

const defaultValues: {
  ruleType: string;
  selectedAPNs: GeoMapAPN[];
  selectedEquipments: Equipment[];
  generalInfo: GeneralInfo;
  resetGeneralInfo: () => void;
  thresholdType: string;
  thresholdValue: number;
  selectRuleType: (ruleType: string) => void;
  addNewAPN: (apn: GeoMapAPN) => void;
  addAllAPNs: (apns: GeoMapAPN[]) => void;
  removeAPN: (name: string) => void;
  removeAllAPNs: () => void;
  addNewEquipment: (equipment: Equipment) => void;
  removeEquipment: (id: string) => void;
  removeAllEquipments: () => void;
  reOrderEquipments: (equipments: Equipment[]) => void;
  addGeneralInfo: (data: GeneralInfo) => void;
  selectThresholdType: (threshold: string) => void;
  selectThresholdValue: (value: number) => void;
} = {
  ruleType: '',
  selectedAPNs: [],
  selectedEquipments: [],
  generalInfo: {
    ruleName: '',
    description: '',
    startingFrom: null,
    endingOn: null,
    state: 'ACTIVE',
    notificationEmails: [],
    slackChannels: [],
    teamsChannels: [],
  },
  thresholdType: '',
  thresholdValue: 0,
  resetGeneralInfo: () => {},
  selectRuleType: () => {},
  addNewAPN: () => {},
  addAllAPNs: () => {},
  removeAPN: () => {},
  removeAllAPNs: () => {},
  addNewEquipment: () => {},
  removeEquipment: () => {},
  removeAllEquipments: () => {},
  reOrderEquipments: () => {},
  addGeneralInfo: () => {},
  selectThresholdType: () => {},
  selectThresholdValue: () => {},
};

export const RulesEngineContext = createContext(defaultValues);

interface RulesEngineProviderProps {
  children: ReactNode;
}

export const RulesEngineProvider: React.FC<RulesEngineProviderProps> = ({
  children,
}) => {
  const [ruleType, setRuleType] = useState('');
  const [selectedAPNs, setSelectedAPNs] = useState<GeoMapAPN[]>([]);
  const [selectedEquipments, setSelectedEquipments] = useState<Equipment[]>([]);
  const [thresholdValue, setThresholdValue] = useState(0);
  const [thresholdType, setThresholdType] = useState('');
  const [generalInfo, setGeneralInfo] = useState<GeneralInfo>({
    ruleName: '',
    description: '',
    startingFrom: null,
    endingOn: null,
    state: 'ACTIVE',
    notificationEmails: [],
    slackChannels: [],
    teamsChannels: [],
  });

  const resetGeneralInfo = () => {
    setGeneralInfo({
      ruleName: '',
      description: '',
      startingFrom: null,
      endingOn: null,
      state: 'ACTIVE',
      notificationEmails: [],
      slackChannels: [],
      teamsChannels: [],
    });
  };

  const selectRuleType = (ruleType: string) => setRuleType(ruleType);

  const addNewAPN = (apn: GeoMapAPN) =>
    setSelectedAPNs((prev) => [...prev, apn]);

  const addAllAPNs = (apns: GeoMapAPN[]) => setSelectedAPNs([...apns]);

  const removeAPN = (name: GeoMapAPN['name']) => {
    const tempAPNs = [...selectedAPNs];
    const APNIndex = tempAPNs.findIndex((apn) => apn.name === name);
    tempAPNs.splice(APNIndex, 1);
    setSelectedAPNs([...tempAPNs]);
  };

  const removeAllAPNs = () => {
    setSelectedAPNs([]);
  };

  const addNewEquipment = (equipment: Equipment) =>
    setSelectedEquipments((prev) => [...prev, equipment]);

  const removeEquipment = (id: Equipment['id']) => {
    const tempEquipments = [...selectedEquipments];
    const equipmentIndex = tempEquipments.findIndex(
      (equipment) => equipment.id === id,
    );
    tempEquipments.splice(equipmentIndex, 1);
    setSelectedEquipments([...tempEquipments]);
  };

  const removeAllEquipments = () => {
    setSelectedEquipments([]);
  };

  const reOrderEquipments = (equipments: Equipment[]) =>
    setSelectedEquipments([...equipments]);

  const addGeneralInfo = (data: GeneralInfo) => {
    setGeneralInfo({ ...data });
  };

  const selectThresholdType = (selectedThreshold: string) => {
    setThresholdType(selectedThreshold);
  };

  const selectThresholdValue = (value: number) => setThresholdValue(value);

  return (
    <RulesEngineContext.Provider
      value={{
        ruleType,
        selectedAPNs,
        selectedEquipments,
        generalInfo,
        thresholdType,
        thresholdValue,
        resetGeneralInfo,
        selectRuleType,
        addNewAPN,
        addAllAPNs,
        removeAPN,
        removeAllAPNs,
        addNewEquipment,
        removeEquipment,
        removeAllEquipments,
        addGeneralInfo,
        reOrderEquipments,
        selectThresholdType,
        selectThresholdValue,
      }}
    >
      {children}
    </RulesEngineContext.Provider>
  );
};
