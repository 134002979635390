export const siteColumns = [
  {
    key: 'apn',
    label: 'APN',
    width: 150,
  },
  {
    key: 'DOWNLINK_MAX',
    label: 'Peak downlink',
    width: 150,
    isSortable: true,
  },
  {
    key: 'DOWNLINK_AVERAGE',
    label: 'Median downlink',
    width: 150,
    isSortable: true,
  },
  {
    key: 'UPLINK_MAX',
    label: 'Peak uplink',
    width: 150,
    isSortable: true,
  },
  {
    key: 'UPLINK_AVERAGE',
    label: 'Median uplink',
    width: 150,
    isSortable: true,
  },
  {
    key: 'LATENCY',
    label: 'Latency(mc)',
    width: 150,
    isSortable: true,
  },
  {
    key: 'peakLatencyGraph',
    label: 'Peak latency graph',
    width: 150,
  },
];
export const apnColumns = [
  {
    key: 'imsi',
    label: 'Equipment',
    width: 150,
  },
  {
    key: 'DOWNLINK_MAX',
    label: 'Peak downlink',
    width: 150,
    isSortable: true,
  },
  {
    key: 'DOWNLINK_AVERAGE',
    label: 'Median downlink',
    width: 150,
    isSortable: true,
  },
  {
    key: 'UPLINK_MAX',
    label: 'Peak uplink',
    width: 150,
    isSortable: true,
  },
  {
    key: 'UPLINK_AVERAGE',
    label: 'Median uplink',
    width: 150,
    isSortable: true,
  },
  {
    key: 'LATENCY',
    label: 'Latency',
    width: 150,
    isSortable: true,
  },
  {
    key: 'latencyData',
    label: 'Peak latency graph',
    width: 150,
  },
];
