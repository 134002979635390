import {
  DialogContent,
  Typography,
  DialogActions,
  Box,
  MenuItem,
  CircularProgress,
  Backdrop,
  Stack,
} from '@mui/material';
import { useState, useContext, useRef, useCallback } from 'react';
import { Button, Select, TextField } from 'src/components';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Map_, { Marker, MapRef, MarkerDragEvent } from 'react-map-gl';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import { FmdGood } from '@mui/icons-material';
import { GeocoderControl } from 'src/components/MapSearch';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { createEndpointSchema } from 'src/yup-validations/endpoint';
import { EndpointConnectionInfo } from 'src/types/domain';
import { AxiosError, AxiosResponse } from 'axios';
import { addEndpoint, editEndpoint, getAllEndpoints } from 'src/apis/endpoint';
import { QueryName } from 'src/enums/query';
import { useOrganizations, useSites } from '../state';
import { getListofVendors, addOrganization } from 'src/apis/org';
import { MenuProps, styles } from './styles';
import { ToastContext } from 'src/context/ToastContext';
import './styles.css';
import { addSite as addSiteApi } from 'src/apis/sites';
import countriesList from 'countries-list';
import { editEndpointSchema } from 'src/yup-validations/editEndpoint';
import { useUserRole } from 'src/utils/user';
interface AddEndpointProps {
  onCancel: () => void;
  organizationName?: string;
  siteName?: string;
  endpointName?: string;
}

export type Endpoint = {
  organization: string;
  site: string;
  endpoint: string;
  description: string;
  vendor: string;
  connectionInfo?: EndpointConnectionInfo;
  siteDescription: string;
  siteName: string;
  organizationName: string;
  country: string;
  organizationDescription: string;
};

const initialViewState = {
  latitude: 54.61745492998754,
  longitude: -5.905370495296432,
  zoom: 10,
};

export const AddEndpoint: React.FC<AddEndpointProps> = ({
  onCancel,
  organizationName,
  siteName,
  endpointName,
}) => {
  const { isSuperUser } = useUserRole();

  const [org, setOrg] = useState<string>('');
  const [siteLocation, setSiteLocation] = useState(false);
  const { showToast } = useContext(ToastContext);
  const queryClient = useQueryClient();
  const mapRef = useRef<MapRef>(null);
  const [viewport, setViewport] = useState({
    latitude: 54.61745492998754,
    longitude: -5.905370495296432,
    flag: false,
  });
  const [initialKeyUrl, setInitialKeyUrl] = useState({
    key: '',
    url: '',
  });
  const [initialVendor, setInitialVendor] = useState('');
  const {
    control,
    watch,
    handleSubmit,
    reset,
    resetField,
    formState: { isSubmitted },
    setValue,
    formState,
  } = useForm<Endpoint>({
    defaultValues: {
      organization: '',
      site: '',
      endpoint: '',
      vendor: '',
      description: '',
      connectionInfo: {
        key: '',
        url: '',
        protocol: '',
      },
      siteDescription: '',
      siteName: '',
      organizationName: '',
      country: '',
      organizationDescription: '',
    },
    resolver: endpointName
      ? yupResolver(editEndpointSchema)
      : yupResolver(createEndpointSchema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });
  const {
    mutate: addEndpointMutation,
    isLoading: endpointMutationLoading,
    reset: endpointReset,
    error: endpointMutationError,
  } = useMutation<
    unknown,
    AxiosError,
    {
      organization: string;
      site: string;
      description?: string;
      endpoint: string;
      vendor: string;
      connectionInfo?: EndpointConnectionInfo;
    }
  >({
    mutationFn: addEndpoint,
    onSuccess: async () => {
      reset();
      onCancel();
      await queryClient.invalidateQueries([QueryName.GET_ENDPOINTS]);
      showToast('Endpoint added successfully!', 'success');
    },
  });

  const {
    mutate: addOrgMutation,
    isLoading: orgMutationLoading,
    reset: orgReset,
    error: orgMutationError,
  } = useMutation<
    unknown,
    AxiosError<{
      failures: [
        {
          id: string;
          message: string;
        },
      ];
    }>,
    {
      country: string;
      organization: string;
      site: string;
      siteDescription?: string;
      description?: string;
      latitude: number;
      longitude: number;
      endpoint: string;
      vendor: string;
      connectionInfo: EndpointConnectionInfo;
      endpointDescription?: string;
    }
  >({
    mutationFn: addOrganization,
    onSuccess: async (
      _,
      {
        organization,
        site,
        siteDescription,
        latitude,
        longitude,
        endpoint,
        vendor,
        connectionInfo,
        endpointDescription,
      },
    ) => {
      addSiteMutation({
        organization,
        site,
        description: siteDescription,
        latitude,
        longitude,
        endpoint,
        vendor,
        connectionInfo,
        endpointDescription,
      });
      reset();
      await queryClient.invalidateQueries([QueryName.GET_ORG_NAMES]);
    },
  });

  const {
    mutate: addSiteMutation,
    isLoading: siteMutationLoading,
    reset: siteReset,
    error: siteMutationError,
  } = useMutation<
    unknown,
    AxiosError,
    {
      organization: string;
      site: string;
      description?: string;
      latitude: number;
      longitude: number;
      endpoint: string;
      vendor: string;
      connectionInfo: EndpointConnectionInfo;
      endpointDescription?: string;
    }
  >({
    mutationFn: addSiteApi,
    onSuccess: async (
      _,
      {
        organization,
        endpoint,
        vendor,
        connectionInfo,
        endpointDescription,
        site,
      },
    ) => {
      addEndpointMutation({
        organization,
        site,
        description: endpointDescription,
        endpoint,
        vendor,
        ...(connectionInfo?.url !== '' && {
          connectionInfo: {
            url: connectionInfo?.url,
            key: connectionInfo?.key,
            protocol: 'REST',
          },
        }),
      });
      reset();
      await queryClient.invalidateQueries([
        QueryName.GET_SITE_NAMES,
        QueryName.GET_ENDPOINTS,
      ]);
    },
  });
  const {
    mutate: editEndpointMutation,
    isLoading: editEndpointMutationLoading,
    reset: editEndpointReset,
    error: editEndpointMutationError,
  } = useMutation<
    AxiosResponse,
    AxiosError,
    {
      organization: string;
      site: string;
      endpoint: string;
      vendor: string;
      description?: string;
      connectionInfo?: EndpointConnectionInfo;
    }
  >({
    mutationFn: editEndpoint,
    onSuccess: async () => {
      reset();
      await queryClient.invalidateQueries([QueryName.GET_ALL_ENDPOINTS]);
      onCancel();
      showToast('Endpoint edited successfully!', 'success');
    },
  });
  const onSubmit = (data: Endpoint) => {
    if (organizationType === 'new') {
      if (!viewport.flag) {
        setSiteLocation(true);
      } else {
        addOrgMutation({
          country: data.country,
          organization: data.organizationName,
          site: data.siteName,
          siteDescription: data.siteDescription,
          description: data.organizationDescription,
          latitude: viewport.latitude,
          longitude: viewport.longitude,
          endpoint: data.endpoint,
          vendor: data.vendor,
          connectionInfo: data.connectionInfo as EndpointConnectionInfo,
          endpointDescription: data.description,
        });
      }
    } else if (endpointName) {
      editEndpointMutation({
        organization: organizationName as string,
        site: siteName as string,
        endpoint: endpointName,
        description: data.description,
        vendor: data.vendor,
        connectionInfo: {
          url: data.connectionInfo?.url as string,
          key: data.connectionInfo?.key as string,
          protocol: 'REST',
        },
      });
    } else if (addSite === 'new') {
      addSiteMutation({
        organization: data.organization,
        site: data.siteName,
        description: data.siteDescription,
        latitude: viewport.latitude,
        longitude: viewport.longitude,
        endpoint: data.endpoint,
        vendor: data.vendor,
        connectionInfo: data.connectionInfo as EndpointConnectionInfo,
        endpointDescription: data.description,
      });
    } else {
      addEndpointMutation({
        organization: data.organization,
        site: data.site,
        description: data.description,
        endpoint: data.endpoint,
        vendor: data.vendor,
        ...(data.connectionInfo?.url !== '' && {
          connectionInfo: {
            url: data.connectionInfo?.url as string,
            key: data.connectionInfo?.key as string,
            protocol: 'REST',
          },
        }),
      });
    }
  };

  const organizationList = useOrganizations();
  const siteList = useSites(org);

  const getErrorMsg = (message: string) => {
    if (message.includes('409')) {
      return 'Another endpoint with this name already exists.';
    }
    return 'Something went wrong while adding endpoint. Try again.';
  };

  const handleClose = () => {
    reset();
    endpointReset();
    siteReset();
    orgReset();
    setOrg('');
    editEndpointReset();
    onCancel();
  };

  const getSiteErrorMsg = (message: string) => {
    if (message.includes('409')) {
      return 'Another site with this name already exists.';
    }
    return 'Something went wrong while adding site. Try again.';
  };

  const getOrgErrorMsg = (message: string) => {
    if (message.includes('409')) {
      return 'Another organization with this name already exists.';
    }
    return 'Something went wrong while adding organization. Try again.';
  };
  const { data: vendor } = useQuery([QueryName.GET_VENDORS], getListofVendors);
  const organizationType: string = watch('organization');

  const vendorType: string = watch('vendor');
  const addSite: string = watch('site');
  const onMarkerDragEnd = useCallback((event: MarkerDragEvent) => {
    setViewport({
      longitude: event?.lngLat.lng,
      latitude: event?.lngLat.lat,
      flag: true,
    });
  }, []);
  const setCoordinates = (event: any) => {
    const coordinates = event.result.geometry.coordinates;
    setViewport({
      longitude: coordinates[0],
      latitude: coordinates[1],
      flag: true,
    });
  };
  useQuery(
    [QueryName.GET_ALL_ENDPOINTS, siteName],
    () => getAllEndpoints(organizationName as string, siteName as string),
    {
      enabled: !!organizationName && !!siteName,
      onSuccess: (data) => {
        if (data.data) {
          const endpointInfoFiltered = data.data.endpoints.filter(
            (endpoint) => endpoint.endpoint === endpointName,
          );
          setInitialKeyUrl({
            url: endpointInfoFiltered[0].connectionInfo?.url as string,
            key: endpointInfoFiltered[0].connectionInfo?.key as string,
          });
          setInitialVendor(endpointInfoFiltered[0].vendor);
          setValue('vendor', endpointInfoFiltered[0].vendor);
          setValue('description', endpointInfoFiltered[0].description);
          setValue(
            'connectionInfo.key',
            endpointInfoFiltered[0].connectionInfo?.key as string,
          );
          setValue(
            'connectionInfo.url',
            endpointInfoFiltered[0].connectionInfo?.url as string,
          );
        }
      },
      onError: async () => {
        showToast('Error fetching data. Please refresh the page', 'error');
      },
    },
  );
  return (
    <Box>
      <DialogContent>
        <Box mt='24px'>
          <Typography variant='h3' fontSize='14px'>
            Organization
          </Typography>
        </Box>
        <Box display='flex' justifyContent='space-between' mt='12px'>
          {endpointName ? (
            <>
              <Stack direction='column'>
                <Typography variant={'h4'}>Name</Typography>
                <Typography sx={styles.editTitleText}>
                  {organizationName}
                </Typography>
              </Stack>
              <Stack direction='column' paddingRight={'190px'}>
                <Typography variant={'h4'}>Site</Typography>
                <Typography sx={styles.editTitleText}>{siteName}</Typography>
              </Stack>
            </>
          ) : (
            <Controller
              name='organization'
              control={control}
              render={({ field }) => (
                <Select
                  width={236}
                  label='Organization'
                  MenuProps={MenuProps}
                  placeholder='Organization'
                  error={
                    !!formState.errors &&
                    formState.errors?.organization?.message
                  }
                  {...field}
                  onChange={(event) => {
                    if (event.target.value === 'newOrg') {
                      setValue('site', '');
                      field.onChange('new');
                    } else {
                      field.onChange(event.target.value);
                      setOrg(event.target.value);
                      resetField('site');
                    }
                  }}
                >
                  {organizationList?.data?.data.map((org) => (
                    <MenuItem key={org} value={org}>
                      {org}
                    </MenuItem>
                  ))}
                  <MenuItem style={{ display: 'none' }} key='new' value='new'>
                    New element
                  </MenuItem>
                  {isSuperUser() && (
                    <MenuItem
                      style={{
                        background: '#EFF5F5 0% 0% no-repeat padding-box',
                      }}
                      key='newOrg'
                      value='newOrg'
                    >
                      <AddCircleOutlineIcon
                        fontSize='small'
                        style={{ color: '#006161' }}
                      />
                      <span style={{ color: '#006161', marginLeft: '5px' }}>
                        Add an element
                      </span>
                    </MenuItem>
                  )}
                </Select>
              )}
            />
          )}
          {organizationType !== 'new' && !endpointName ? (
            <Controller
              name='site'
              control={control}
              render={({ field }) => (
                <Select
                  width={236}
                  label='Site'
                  MenuProps={MenuProps}
                  placeholder='Site'
                  error={!!formState.errors && formState.errors?.site?.message}
                  {...field}
                  onChange={(event) => {
                    if (event.target.value === 'addSite') {
                      field.onChange('new');
                    } else {
                      field.onChange(event.target.value);
                    }
                  }}
                >
                  {siteList?.data?.data.map((site) => (
                    <MenuItem key={site} value={site}>
                      {site}
                    </MenuItem>
                  ))}
                  <MenuItem style={{ display: 'none' }} key='new' value='new'>
                    New element
                  </MenuItem>
                  {isSuperUser() && (
                    <MenuItem
                      style={{
                        background: '#EFF5F5 0% 0% no-repeat padding-box',
                      }}
                      key='addSite'
                      value='addSite'
                    >
                      <AddCircleOutlineIcon
                        fontSize='small'
                        style={{ color: '#006161' }}
                      />
                      <span style={{ color: '#006161', marginLeft: '5px' }}>
                        Add an element
                      </span>
                    </MenuItem>
                  )}
                </Select>
              )}
            />
          ) : (
            !endpointName && (
              <Controller
                name='site'
                control={control}
                render={({ field }) => (
                  <Select
                    width={236}
                    label='Site'
                    MenuProps={MenuProps}
                    placeholder='Site'
                    {...field}
                    value={'new'}
                  >
                    <MenuItem key='new' value='new'>
                      New element
                    </MenuItem>
                  </Select>
                )}
              />
            )
          )}
        </Box>
        {organizationType === 'new' && (
          <Box>
            <Box display='flex' justifyContent='space-between' my='6px'>
              <Controller
                name='organizationName'
                control={control}
                render={({ field }) => (
                  <TextField
                    width={236}
                    label='Organization Name'
                    error={
                      !!formState.errors &&
                      formState.errors?.organizationName?.message
                    }
                    {...field}
                  />
                )}
              />
              <Controller
                name='siteName'
                control={control}
                render={({ field }) => (
                  <TextField
                    width={236}
                    label='Site Name'
                    {...field}
                    error={
                      !!formState.errors && formState.errors?.siteName?.message
                    }
                  />
                )}
              />
            </Box>
            <Box display='flex' justifyContent='space-between' my='6px'>
              <Controller
                name='country'
                control={control}
                render={({ field: { onChange, ...field } }) => (
                  <Select
                    width={236}
                    label='Country'
                    MenuProps={MenuProps}
                    placeholder='Country'
                    error={
                      !!formState.errors && formState.errors?.country?.message
                    }
                    {...field}
                    onChange={({ target: { value } }) => {
                      onChange(value);
                    }}
                  >
                    {Object.entries(countriesList.countries).map(
                      ([key, country]) => (
                        <MenuItem key={key} value={country.name}>
                          {country.name}
                        </MenuItem>
                      ),
                    )}
                  </Select>
                )}
              />
              <Controller
                name='siteDescription'
                control={control}
                render={({ field }) => (
                  <TextField
                    width={236}
                    label='Site Description (Optional)'
                    {...field}
                  />
                )}
              />
            </Box>
            <Box display='flex' justifyContent='space-between' my='6px'>
              <Controller
                name='organizationDescription'
                control={control}
                render={({ field }) => (
                  <TextField
                    width={236}
                    label='Organization Description (Optional)'
                    {...field}
                  />
                )}
              />
            </Box>
          </Box>
        )}
        {addSite === 'new' && (
          <Box>
            <Typography variant='h3' fontSize='14px' mt='24px'>
              Site
            </Typography>
            <Box display='flex' justifyContent='space-between' mt='7px'>
              <Controller
                name='siteName'
                control={control}
                render={({ field }) => (
                  <TextField
                    width={236}
                    label='Name'
                    {...field}
                    error={
                      !!formState.errors && formState.errors?.siteName?.message
                    }
                  />
                )}
              />
              <Controller
                name='siteDescription'
                control={control}
                render={({ field }) => (
                  <TextField
                    width={236}
                    label='Description (Optional)'
                    {...field}
                  />
                )}
              />
            </Box>
          </Box>
        )}
        {(addSite === 'new' || organizationType === 'new') && (
          <Box width='100%' mb='24px' mt='24px'>
            <Typography variant='h3' fontSize='14px'>
              Site location
            </Typography>
            <Box
              sx={{
                border: siteLocation || (isSubmitted && !viewport.flag) ? 1 : 0,
                borderColor:
                  siteLocation || (isSubmitted && !viewport.flag)
                    ? '#FF0000'
                    : 'none',
                marginTop: '16px',
              }}
            >
              <Map_
                ref={mapRef}
                initialViewState={initialViewState}
                mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN_2}
                mapStyle='mapbox://styles/mapbox/light-v9'
                style={{ width: '100%', height: '324px' }}
              >
                <Marker
                  longitude={viewport.longitude}
                  latitude={viewport.latitude}
                  anchor='bottom'
                  draggable
                  onDragEnd={onMarkerDragEnd}
                >
                  <FmdGood sx={styles.marker} />
                </Marker>
                {siteMutationLoading ||
                endpointMutationLoading ||
                orgMutationLoading ||
                editEndpointMutationLoading ? (
                  ''
                ) : (
                  <GeocoderControl
                    mapboxAccessToken={
                      process.env.REACT_APP_MAPBOX_TOKEN_2 as string
                    }
                    position='top-left'
                    onResult={setCoordinates}
                  />
                )}
              </Map_>
            </Box>
            {siteLocation ||
              (isSubmitted && !viewport.flag && (
                <Typography
                  fontSize='10px'
                  color='#F20000'
                  mt='5px'
                  textAlign='left'
                  mr='7px'
                >
                  {'Site location is required'}
                </Typography>
              ))}
          </Box>
        )}
        <Typography variant='h3' fontSize='14px' mt='24px'>
          Endpoint
        </Typography>
        <Box display='flex' justifyContent='space-between' my='6px' mt='12px'>
          {endpointName ? (
            <Stack direction='column'>
              <Typography variant={'h4'}>Name</Typography>
              <Typography sx={styles.editTitleText}>{endpointName}</Typography>
            </Stack>
          ) : (
            <Controller
              name='endpoint'
              control={control}
              render={({ field }) => (
                <TextField
                  width={236}
                  label='Endpoint Name'
                  error={
                    !!formState.errors && formState.errors?.endpoint?.message
                  }
                  {...field}
                />
              )}
            />
          )}
          <Controller
            name='vendor'
            control={control}
            render={({ field }) => (
              <Select
                width={236}
                label='Endpoint Vendor'
                MenuProps={MenuProps}
                placeholder='Vendor'
                error={!!formState.errors && formState.errors?.vendor?.message}
                {...field}
                onChange={(event) => {
                  field.onChange(event.target.value);
                  if (endpointName && event.target.value !== initialVendor) {
                    resetField('connectionInfo');
                  } else if (
                    endpointName &&
                    event.target.value === initialVendor
                  ) {
                    resetField('connectionInfo');
                    setValue('connectionInfo.key', initialKeyUrl.key);
                    setValue('connectionInfo.url', initialKeyUrl.url);
                    editEndpointReset();
                  }
                }}
              >
                {vendor?.data?.map((vendor) => (
                  <MenuItem key={vendor} value={vendor}>
                    {vendor}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </Box>
        {(vendorType === 'ERICSSON_EP5G' || vendorType === 'RHOMBUS_CCTV') && (
          <Box display='flex' justifyContent='space-between' mb='6px'>
            <Controller
              name='connectionInfo.url'
              control={control}
              render={({ field }) => (
                <TextField
                  width={236}
                  label='Url'
                  error={
                    !!formState.errors &&
                    formState.errors?.connectionInfo?.url?.message
                  }
                  {...field}
                  onChange={(event) => {
                    field.onChange(event.target.value);
                  }}
                />
              )}
            />
            <Controller
              name='connectionInfo.key'
              control={control}
              render={({ field }) => (
                <TextField
                  width={236}
                  label='Key'
                  error={
                    !!formState.errors &&
                    formState.errors?.connectionInfo?.key?.message
                  }
                  {...field}
                  onChange={(event) => {
                    field.onChange(event.target.value);
                  }}
                />
              )}
            />
          </Box>
        )}
        <Box mb='24px'>
          <Controller
            name='description'
            control={control}
            render={({ field }) => (
              <TextField
                width={236}
                label='Endpoint description (Optional)'
                {...field}
                onChange={(event) => {
                  field.onChange(event.target.value);
                }}
              />
            )}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button kind='primary-ghost' onClick={handleClose}>
          Cancel
        </Button>
        <Button
          kind='primary'
          onClick={handleSubmit(onSubmit)}
          disabled={
            endpointMutationLoading ||
            siteMutationLoading ||
            editEndpointMutationLoading
          }
        >
          Save
        </Button>
      </DialogActions>
      {endpointMutationError && (
        <Typography
          fontSize='10px'
          color='#F20000'
          mt='5px'
          textAlign='right'
          mr='7px'
        >
          {getErrorMsg(endpointMutationError.message)}
        </Typography>
      )}
      {siteMutationError && (
        <Typography
          fontSize='10px'
          color='#F20000'
          mt='5px'
          textAlign='right'
          mr='7px'
        >
          {getSiteErrorMsg(siteMutationError.message)}
        </Typography>
      )}
      {orgMutationError && (
        <Typography
          fontSize='10px'
          color='#F20000'
          mt='5px'
          textAlign='right'
          mr='7px'
        >
          {getOrgErrorMsg(orgMutationError.message)}
        </Typography>
      )}
      {editEndpointMutationError && (
        <Typography
          fontSize='10px'
          color='#F20000'
          mt='5px'
          textAlign='right'
          mr='7px'
        >
          Something went wrong while updating endpoint. Try again.
        </Typography>
      )}
      <Backdrop
        sx={{ color: '#006161' }}
        open={
          siteMutationLoading ||
          endpointMutationLoading ||
          orgMutationLoading ||
          editEndpointMutationLoading
        }
      >
        <CircularProgress size='60px' />
      </Backdrop>
    </Box>
  );
};
