import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { formatInTimeZone } from 'date-fns-tz';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import More from 'highcharts/highcharts-more';
import { Rule } from 'src/types/rule';

More(Highcharts);

interface TrendChartProps {
  trendData: Rule['trendData'];
}

export const TrendChart: React.FC<TrendChartProps> = ({ trendData }) => {
  const options: Highcharts.Options = {
    chart: {
      height: 370,
      width: 563,
      zooming: {
        type: 'x',
        resetButton: {
          theme: {
            fill: '#fff',
            stroke: '#55CFBB',
            style: {
              color: '#55CFBB',
            },
          },
        },
      },
    },
    credits: {
      enabled: false,
    },
    accessibility: {
      enabled: false,
    },
    title: {
      text: '',
    },
    legend: {
      enabled: false,
    },
    xAxis: {
      lineColor: 'transparent',
      tickColor: 'transparent',
      type: 'category',
      labels: {
        style: {
          color: '#888888',
          fontSize: '10px',
        },
      },
    },
    yAxis: {
      title: {
        text: '',
      },
      min: 0,
      max: 100,
      tickAmount: 6,
      labels: {
        style: {
          color: '#888888',
          fontSize: '10px',
        },
      },
      gridLineColor: 'transparent',
      plotLines: [
        {
          value: 70,
          width: 1,
          color: '#006161',
          zIndex: 4,
          dashStyle: 'Dash',

          label: {
            text: 'Threshold value',
            style: {
              color: '#659F9F',
              fontSize: '10px',
            },
          },
        },
      ],
    },
    tooltip: {
      useHTML: true,
      backgroundColor: '#F1FCFA',
      borderColor: 'transparent',
      shadow: {
        offsetX: 0,
        offsetY: 0,
        width: 6,
        color: '#00000029',
      },
      shape: 'square',
      style: {
        pointerEvents: 'auto',
      },
      formatter: function () {
        // eslint-disable-next-line max-len
        return `<p style="text-align: center; margin: 0; color: #222222; font-size: 14px;">${this.y}</p>`;
      },
    },
    series: [
      {
        type: 'area',
        data: trendData?.reduce((prev: Array<[string, number]>, curr) => {
          prev.push([
            formatInTimeZone(
              curr.time,
              Intl.DateTimeFormat().resolvedOptions().timeZone,
              'dd/MM',
            ),
            curr.value,
          ]);
          return prev;
        }, []),
        marker: { enabled: false },
        zones: [
          {
            color: '#56CFBB',
            fillColor: '#B3EAE1',
          },
        ],
      },
    ],
  };
  return (
    <Box>
      <Typography variant='h3' mb='10px' mt='33px'>
        Current trend
      </Typography>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </Box>
  );
};
