import { ApiResponseType } from 'src/types/api';
import { Coordinates } from 'src/types/coordinates';
import { InstantApnAnalytics } from 'src/types/geo-map';
import { api } from 'src/utils/axios';

export const getCoordinates = ({
  organization,
  site,
  apns,
  ues,
  ueGroups,
  dateTime,
}: {
  organization: string;
  site: string;
  apns?: string[];
  ues?: string[];
  ueGroups?: string[];
  dateTime?: string;
}) => {
  const url = 'geo/v1/coordinates';
  return api.post<ApiResponseType<Coordinates>>(url, {
    organization,
    site,
    apns,
    ues,
    ueGroups,
    dateTime,
  });
};

export const getAPNsInstantAnalytics = ({
  organization,
  site,
  apns,
  dateTime,
  criteria,
}: {
  organization: string;
  site: string;
  apns: string;
  dateTime?: string;
  criteria?: string;
}) => {
  return api.get<ApiResponseType<InstantApnAnalytics[]>>(
    'pm/v1/analytics/instant/apn/list',
    {
      params: {
        organization,
        site,
        apns,
        dateTime,
        criteria,
      },
    },
  );
};

export const getPastCoordinates = ({
  organization,
  site,
  ues,
  timeRange,
}: {
  organization: string;
  site: string;
  ues: string;
  timeRange: number;
}) => {
  const url = 'geo/v1/ue/history';
  return api.get<
    ApiResponseType<{
      response: {
        [key: string]: Array<{
          latitude: number;
          longitude: number;
          timestamp: string;
          timestampMs: number;
          ueId: string;
        }>;
      };
    }>
  >(url, {
    params: {
      organization,
      site,
      ues,
      timeRange,
    },
  });
};
