import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { formatInTimeZone } from 'date-fns-tz';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import More from 'highcharts/highcharts-more';
import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Rule } from 'src/types/rule';

More(Highcharts);

interface SuccessTrendProps {
  trendData: Rule['trendData'];
}

export const SuccessTrend: React.FC<SuccessTrendProps> = ({ trendData }) => {
  const [params] = useSearchParams({ filter: 'DAY' });

  const filterLabel = useCallback(() => {
    const currentFilter = params.get('filter');
    if (currentFilter === 'MONTH') {
      return 'MMM yy';
    } else if (currentFilter === 'WEEK') {
      return 'iii dd';
    } else {
      return 'HH: mm';
    }
  }, [params]);

  const options: Highcharts.Options = {
    chart: {
      height: 330,
      width: 690,
      zooming: {
        type: 'x',
        resetButton: {
          theme: {
            fill: '#fff',
            stroke: '#55CFBB',
            style: {
              color: '#55CFBB',
            },
          },
        },
      },
    },
    credits: {
      enabled: false,
    },
    accessibility: {
      enabled: false,
    },
    title: {
      text: '',
    },
    legend: {
      enabled: false,
    },
    xAxis: {
      lineColor: 'transparent',
      tickColor: 'transparent',
      type: 'category',
      labels: {
        style: {
          color: '#888888',
          fontSize: '10px',
        },
      },
    },
    yAxis: {
      title: {
        text: '',
      },
      min: 0,
      max: 100,
      tickAmount: 6,
      labels: {
        style: {
          color: '#888888',
          fontSize: '10px',
        },
      },
      gridLineColor: 'transparent',
      plotLines: [
        {
          value: 70,
          width: 1,
          color: '#006161',
          zIndex: 4,
          dashStyle: 'Dash',

          label: {
            text: 'defined limit',
            style: {
              color: '#659F9F',
              fontSize: '10px',
            },
          },
        },
      ],
    },
    tooltip: {
      useHTML: true,
      backgroundColor: '#F1FCFA',
      borderColor: 'transparent',
      shadow: {
        offsetX: 0,
        offsetY: 0,
        width: 6,
        color: '#00000029',
      },
      shape: 'square',
      style: {
        pointerEvents: 'auto',
      },
      formatter: function () {
        // eslint-disable-next-line max-len
        let content = `<p style="text-align: center; margin: 0; color: #222222; font-size: 14px;">${this.y}</p>`;
        content +=
          // eslint-disable-next-line max-len
          '<a href="#" style="color: #007777; text-decoration: underline;">See consumption</a>';
        return content;
      },
    },
    series: [
      {
        type: 'area',
        data: trendData?.reduce((prev: Array<[string, number]>, curr) => {
          prev.push([
            formatInTimeZone(
              curr.time,
              Intl.DateTimeFormat().resolvedOptions().timeZone,
              filterLabel(),
            ),
            curr.value,
          ]);
          return prev;
        }, []),
        marker: { enabled: false },
        zones: [
          {
            value: 70,
            color: '#56CFBB',
            fillColor: '#B3EAE1',
          },
          {
            color: '#006161',
            fillColor: '#6FB4AF',
          },
        ],
      },
    ],
  };
  return (
    <Box>
      <Typography variant='h2' mb='10px'>
        Success trend
      </Typography>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </Box>
  );
};
