import { createContext, useState } from 'react';

export type Point = {
  type: 'POINT';
  coordinates?: number[];
  radius?: number;
};

export type Polygon = {
  type: 'POLYGON';
  coordinates?: number[][];
};

export type GeoMapAPN = {
  name: string;
  type: 'apn';
  criteria: string;
  uplink: number;
  downlink: number;
  latency: number;
  limits: Limits;
  confidence: number | null;
  geometry: Polygon | null;
};

export type GeoMapUE = {
  id: string;
  name: string;
  type: string;
  geometry: Point | null;
};

export type GeoMapUEGroup = {
  id: string;
  name: string;
  ues: GeoMapUE[];
};

type Limits = {
  uplink: number;
  downlink: number;
  latency: number;
};

interface GeoMapContextProviderProps {
  children: React.ReactNode;
}

const defaultValues: {
  apns: GeoMapAPN[];
  ues: GeoMapUE[];
  ueGroups: GeoMapUEGroup[];
  selectedDate: Date | null;
  sliderDate: Date | null;
  selectDate: (date: Date) => void;
  selectSliderDate: (date: Date) => void;
  addNewAPN: (apn: GeoMapAPN) => void;
  addAllAPNs: (apn: GeoMapAPN[]) => void;
  removeAPN: (apnName: string) => void;
  addNewUE: (ue: GeoMapUE) => void;
  addAllUEs: (ues: GeoMapUE[]) => void;
  removeUE: (id: string) => void;
  addNewUEGroup: (ueGroup: GeoMapUEGroup) => void;
  addAllUEGroups: (ueGroups: GeoMapUEGroup[]) => void;
  removeUEGroup: (id: string) => void;
} = {
  apns: [],
  ues: [],
  ueGroups: [],
  selectedDate: null,
  sliderDate: null,
  selectDate: () => {},
  selectSliderDate: () => {},
  addNewAPN: () => {},
  addAllAPNs: () => {},
  removeAPN: () => {},
  addNewUE: () => {},
  addAllUEs: () => {},
  removeUE: () => {},
  addNewUEGroup: () => {},
  addAllUEGroups: () => {},
  removeUEGroup: () => {},
};

export const GeoMapContext = createContext(defaultValues);

export const GeoMapContextProvider: React.FC<GeoMapContextProviderProps> = ({
  children,
}) => {
  const [apns, setApns] = useState<GeoMapAPN[]>([]);
  const [ues, setUEs] = useState<GeoMapUE[]>([]);
  const [ueGroups, setUEGroups] = useState<GeoMapUEGroup[]>([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [sliderDate, setSliderDate] = useState(new Date());

  const addNewAPN = (apn: GeoMapAPN) => setApns((prev) => [...prev, apn]);

  const addAllAPNs = (apns: GeoMapAPN[]) => setApns([...apns]);

  const removeAPN = (name: GeoMapAPN['name']) => {
    const tempAPNs = [...apns];
    const APNIndex = tempAPNs.findIndex((apn) => apn.name === name);
    tempAPNs.splice(APNIndex, 1);
    setApns([...tempAPNs]);
  };

  const addNewUE = (equipment: GeoMapUE) =>
    setUEs((prev) => [...prev, equipment]);

  const addAllUEs = (equipment: GeoMapUE[]) => setUEs([...equipment]);

  const removeUE = (id: GeoMapUE['id']) => {
    const tempEquipments = [...ues];
    const equipmentIndex = tempEquipments.findIndex(
      (equipment) => equipment.id === id,
    );
    tempEquipments.splice(equipmentIndex, 1);
    setUEs([...tempEquipments]);
  };

  const addNewUEGroup = (ueGroup: GeoMapUEGroup) =>
    setUEGroups((prev) => [...prev, ueGroup]);

  const addAllUEGroups = (ueGroups: GeoMapUEGroup[]) =>
    setUEGroups([...ueGroups]);

  const removeUEGroup = (id: GeoMapUEGroup['id']) => {
    const tempEquipments = [...ueGroups];
    const equipmentIndex = tempEquipments.findIndex(
      (equipment) => equipment.id === id,
    );
    tempEquipments.splice(equipmentIndex, 1);
    setUEGroups([...tempEquipments]);
  };

  const selectDate = (date: Date) => setSelectedDate(date);

  const selectSliderDate = (date: Date) => setSliderDate(date);

  return (
    <GeoMapContext.Provider
      value={{
        apns,
        ues,
        ueGroups,
        selectedDate,
        sliderDate,
        selectSliderDate,
        selectDate,
        addNewAPN,
        addAllAPNs,
        removeAPN,
        addNewUE,
        addAllUEs,
        removeUE,
        addNewUEGroup,
        addAllUEGroups,
        removeUEGroup,
      }}
    >
      {children}
    </GeoMapContext.Provider>
  );
};
