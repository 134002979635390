import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useQuery } from '@tanstack/react-query';
import ClearIcon from '@mui/icons-material/Clear';
import { Search } from '@carbon/icons-react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import {
  Box,
  Link,
  ListItemText,
  MenuItem,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';

import {
  Button,
  Checkbox,
  Chip,
  Loading,
  Pagination,
  Select,
  Table,
  TextField,
} from 'src/components';
import { QueryName } from 'src/enums/query';
import { Page } from 'src/types/shared';
import { getAllDevices, getAllUEGroups, getAllUeTypes } from 'src/apis/ue';
import { AppContext, AuthContext, LicenseContext } from 'src/context';
import { toTitleCase } from 'src/utils/rule';
import { Tag } from 'src/components/Tag';
import { Device, WatchDog } from 'src/types/device';
import { Group } from 'src/types/group';
import { styles } from './styles';
import { GroupModal } from './GroupModal/GroupModal';
import { AllocateUEModal } from './AllocateUEModal/AllocateUEModal';

const tableColumns = [
  {
    label: 'Object',
    key: 'name',
  },
  {
    label: 'Active',
    key: 'isOnline',
  },
  {
    label: 'Owner',
    key: 'owner',
  },
  {
    label: 'APN',
    key: 'apn',
  },
  {
    label: 'Groups',
    key: 'groups',
  },
];

const watchdog = [
  {
    label: 'Watchdog',
    key: 'watchdog',
  },
];

export const DevicesTable = () => {
  const [params, setParams] = useSearchParams({});
  const { hasNDOLicense } = useContext(LicenseContext);
  const ueType = params.get('ueType');
  const group = params.get('group');
  const { user } = useContext(AuthContext);
  const { site } = useContext(AppContext);
  const navigate = useNavigate();
  const organization = user?.organizations?.[0] as string;
  const includeWatchdog = true;
  const initialPages = {
    pageStart: 1,
    pageEnd: 10,
  };
  const [selected, setSelected] = useState<Device[]>([]);
  const { state } = useLocation();
  const [isGroupModalOpen, setIsGroupModalOpen] = useState<boolean>(false);
  const [searchText, setSearchQuery] = useState<string>('');
  const [ueSelectedTypes, setUESelectedTypes] = useState<string[]>(
    ueType ? ueType.split(',') : [],
  );
  const [selectedTemporaryUEs, setSelectedTemporaryUEs] = useState<string[]>(
    ueType ? ueType.split(',') : [],
  );
  const [selectedGroups, setSelectedGroups] = useState<string[]>(
    group ? group.split(',') : [],
  );
  const [selectedTemporaryGroups, setSelectedTemporaryGroups] = useState<
    string[]
  >(group ? group.split(',') : []);
  const [openUEsMenu, setOpenUEsMenu] = useState(false);
  const [openGroupMenu, setOpenGroupMenu] = useState(false);
  const [page, setPage] = useState<Page>(initialPages);
  const columns = [
    ...(!hasNDOLicense ? tableColumns : [...tableColumns, ...watchdog]),
  ];
  const { data: ueTypes } = useQuery([QueryName.GET_ALL_UE_TYPES], () =>
    getAllUeTypes(),
  );
  const [showAllocateMenu, setAllocateMenu] = useState(false);
  const { data, isLoading, isFetching } = useQuery(
    [
      QueryName.GET_ALL_DEVICES,
      organization,
      site,
      ueSelectedTypes,
      selectedGroups,
      searchText,
      page.pageStart,
      page.pageEnd,
    ],
    () =>
      getAllDevices(
        organization,
        site !== '' ? site : undefined,
        ueSelectedTypes?.length ? ueSelectedTypes?.join(',') : undefined,
        selectedGroups?.length ? selectedGroups?.join(',') : undefined,
        searchText ?? undefined,
        includeWatchdog,
        page.pageStart,
        page.pageEnd,
      ),
    {
      onSuccess: (data) => {
        if (page.pageEnd > data?.data?.total) {
          setPage(initialPages);
        }
      },
      enabled: !!organization && !!site,
    },
  );

  const getOwnerName = useCallback(
    (device: Device) => {
      let name = '';
      if (device?.ownerLastName) {
        name += `${device.ownerLastName}`;
      }
      if (device?.ownerFirstName) {
        name += ` ${device.ownerFirstName}`;
      }
      if (user?.email === device?.ownerEmail) {
        return `Me (${name.trim()})`;
      }
      return name.trim() || '-';
    },
    [user?.email],
  );

  const rows = data?.data.devices.map((device: Device) => ({
    name: device.name,
    ueid: device.ueid,
    description: device.name,
    type: device.type,
    isOnline: device.isOnline,
    apn: device.apn,
    ueGroups: device.ueGroups,
    watchDogs: device?.watchDogs,
    owner: getOwnerName(device),
  }));

  const { data: availableGroups, refetch } = useQuery(
    [QueryName.GET_ALL_UE_GROUPS, site],
    () =>
      getAllUEGroups({
        organization,
        site,
      }),
    { enabled: !!organization && !!site },
  );

  useEffect(() => {
    void refetch();
  }, [isGroupModalOpen, refetch]);

  useEffect(() => {
    if (state?.unAllocatedUes?.length > 0) {
      setSelected(state?.unAllocatedUes);
    }
  }, [state]);

  const handlePageChange = (pageStart: number, pageEnd: number) => {
    setPage({
      pageStart,
      pageEnd,
    });
  };

  const isSelected = (device: Device) =>
    selected.filter((dev) => dev.ueid === device.ueid).length > 0;

  let timer: ReturnType<typeof setTimeout>;
  const debounceSearch = (event: React.ChangeEvent) => {
    const value = (event.target as HTMLInputElement).value;
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      setSearchQuery(value);
    }, 500);
  };

  const toggleSelected = useCallback(
    (device: Device) => {
      const selectedIndex = selected
        .map((dev) => dev.ueid)
        .indexOf(device.ueid);
      const newSelected = [...selected];
      if (selectedIndex === -1) {
        newSelected.push(device);
      } else {
        newSelected.splice(selectedIndex, 1);
      }
      return newSelected;
    },
    [selected],
  );

  const handleClickCheckbox = useCallback(
    (
      event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
      device: Device,
    ) => {
      const newSelected = toggleSelected(device);
      setSelected(newSelected);
    },
    [toggleSelected],
  );

  const handleSelectAllCheckboxes = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows?.map((row: Device) => row);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleGroupDevices = () => {
    setIsGroupModalOpen(true);
  };

  const handleCloseGroupModal = () => {
    setIsGroupModalOpen(false);
  };

  const checkedAllCheckboxes =
    rows?.length > 0 && selected?.length === rows?.length;
  const indeterminate = selected?.length > 0 && selected?.length < rows?.length;

  const handleChangeUEs = (event: ChangeEvent<{ value: string[] }>) => {
    const [selectedOption] = event.target.value;
    setSelectedTemporaryUEs((prev) => {
      if (prev.includes(selectedOption)) {
        return prev.filter((option) => option !== selectedOption);
      }
      return [...prev, selectedOption];
    });
  };

  const handleOpenUEsMenu = () => {
    setOpenUEsMenu(true);
  };

  const handleCloseUEsMenu = () => {
    setOpenUEsMenu(false);
  };

  const handleSubmitUEs = () => {
    setUESelectedTypes(selectedTemporaryUEs);
    handleCloseUEsMenu();
    setPage(initialPages);
    setParams({
      ueType: selectedTemporaryUEs.join(','),
      ...(group && { group }),
    });
  };

  useEffect(() => {
    setParams({
      ...(ueSelectedTypes.length > 0 && { ueType: ueSelectedTypes.join(',') }),
      ...(selectedGroups.length > 0 && { group: selectedGroups.join(',') }),
    });
  }, [ueSelectedTypes, selectedGroups, setParams]);

  const deleteType = (type: string) => {
    setUESelectedTypes((prev) => prev.filter((item) => item !== type));
    setSelectedTemporaryUEs((prev) => prev.filter((item) => item !== type));
    setPage(initialPages);
  };

  const handleChangeGroups = (event: ChangeEvent<{ value: string[] }>) => {
    const [selectedOption] = event.target.value;
    setSelectedTemporaryGroups((prev) => {
      if (prev.includes(selectedOption)) {
        return prev.filter((option) => option !== selectedOption);
      }
      return [...prev, selectedOption];
    });
  };

  const handleOpenGroupsMenu = () => {
    setOpenGroupMenu(true);
  };

  const handleCloseGroupsMenu = () => {
    setOpenGroupMenu(false);
  };

  const handleSubmitGroups = () => {
    setSelectedGroups(selectedTemporaryGroups);
    handleCloseGroupsMenu();
    setPage(initialPages);
    setParams({
      group: selectedTemporaryGroups.join(','),
      ...(ueType && { ueType }),
    });
  };

  const deleteGroup = (group: string) => {
    setSelectedGroups((prev) => prev.filter((item) => item !== group));
    setSelectedTemporaryGroups((prev) => prev.filter((item) => item !== group));
    setPage(initialPages);
  };

  return (
    <>
      <GroupModal
        selectedDevices={selected}
        open={isGroupModalOpen}
        onCancel={handleCloseGroupModal}
        onSave={handleCloseGroupModal}
      />
      <AllocateUEModal
        selectedDevices={selected}
        open={showAllocateMenu}
        onCancel={() => setAllocateMenu(false)}
      />
      <Box sx={styles.selectWrapper}>
        <Box>
          <Select
            renderValue={() => 'UE Type'}
            sx={{ margin: '5px' }}
            width={180}
            placeholder='UE Type'
            labelId='select-type-label'
            id='select-type'
            label='UE Type'
            onChange={handleChangeUEs}
            open={openUEsMenu}
            onOpen={handleOpenUEsMenu}
            onClose={handleCloseUEsMenu}
            checkboxes={true}
            handleSubmit={handleSubmitUEs}
          >
            {ueTypes?.data.map((ueType: string) => (
              <MenuItem key={ueType} value={ueType}>
                <Checkbox checked={selectedTemporaryUEs.includes(ueType)} />
                <ListItemText primary={toTitleCase(ueType)} />
              </MenuItem>
            ))}
          </Select>

          <Select
            renderValue={() => 'Groups'}
            sx={{ margin: '5px' }}
            width={180}
            placeholder='Groups'
            labelId='select-groups-label'
            id='select-groups'
            label='Groups'
            onChange={handleChangeGroups}
            open={openGroupMenu}
            onOpen={handleOpenGroupsMenu}
            onClose={handleCloseGroupsMenu}
            checkboxes={true}
            handleSubmit={handleSubmitGroups}
          >
            {availableGroups?.data?.groups?.map((group: Group) => (
              <MenuItem key={group.groupName} value={group.groupName}>
                <Checkbox
                  checked={selectedTemporaryGroups.includes(group.groupName)}
                />
                <ListItemText primary={toTitleCase(group.groupName)} />
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box sx={styles.inputBox}>
          <TextField
            isSearchField
            placeholder='Search'
            id='search-equipments'
            InputProps={{ endAdornment: <Search size={20} color='#888888' /> }}
            onChange={debounceSearch}
          />
        </Box>
      </Box>
      <Box sx={styles.groupBox}>
        {ueSelectedTypes?.map((ueSelectedType, idx) => (
          <Tag
            key={`${ueSelectedType}-${idx}`}
            label={toTitleCase(ueSelectedType)}
            onDelete={() => deleteType(ueSelectedType)}
            deleteIcon={<ClearIcon />}
          />
        ))}
        {selectedGroups?.map((selectedGroup, idx) => (
          <Tag
            key={`${selectedGroup}-${idx}`}
            label={toTitleCase(selectedGroup)}
            onDelete={() => deleteGroup(selectedGroup)}
            deleteIcon={<ClearIcon />}
          />
        ))}
        {(ueSelectedTypes.length > 0 || selectedGroups.length > 0) && (
          <Tag
            label={'Remove all'}
            onDelete={() => {
              setUESelectedTypes([]);
              setSelectedTemporaryUEs([]);
              setSelectedGroups([]);
              setSelectedTemporaryGroups([]);
              setPage(initialPages);
            }}
            deleteIcon={<ClearIcon />}
            textColor={'#fff'}
            backgroundColor={'#888'}
          />
        )}
        {selected.length > 0 && (
          <Box sx={styles.groupBtn}>
            <Button
              kind='secondary-ghost'
              onClick={() => setAllocateMenu(true)}
            >
              Allocate
            </Button>
            <Button
              kind='secondary-ghost'
              onClick={handleGroupDevices}
              sx={styles.groupBtn}
            >
              Group
            </Button>
          </Box>
        )}
      </Box>
      <Loading isLoading={isLoading || isFetching}>
        <Table
          handleSelectAllCheckboxes={handleSelectAllCheckboxes}
          indeterminate={indeterminate}
          checkedAllCheckboxes={checkedAllCheckboxes}
          checkbox={true}
          columns={columns}
          renderRows={() =>
            rows?.map((row: Device, index: number) => {
              const isItemSelected = isSelected(row);
              const labelId = `enhanced-table-checkbox-${index}`;
              return (
                <TableRow
                  hover
                  onClick={(event: React.MouseEvent<HTMLTableRowElement>) =>
                    handleClickCheckbox(event, row)
                  }
                  role='checkbox'
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.ueid}
                  selected={isItemSelected}
                  sx={styles.tableRow}
                >
                  <TableCell padding='checkbox'>
                    <Checkbox
                      color='primary'
                      checked={isItemSelected}
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </TableCell>
                  <TableCell padding='none'>
                    <Link
                      onClick={() =>
                        navigate(
                          `/devices/${organization}/${site}/${row.ueid}`,
                          {
                            state: { back: true },
                          },
                        )
                      }
                      sx={styles.deviceName}
                    >
                      {row.ueid} {row.type ? ' | ' + String(row.type) : ''}
                    </Link>
                    <Typography sx={styles.description}>{row.name}</Typography>
                  </TableCell>
                  <TableCell padding='none'>
                    <Chip
                      kind={row.isOnline ? 'primary' : 'default'}
                      variant='outlined'
                      label={row.isOnline ? 'ACTIVE' : 'INACTIVE'}
                    />
                  </TableCell>
                  <TableCell padding='none'>
                    <Typography sx={styles.text}>{row.owner}</Typography>
                  </TableCell>
                  <TableCell padding='none'>
                    <Typography sx={styles.text}>{row.apn}</Typography>
                  </TableCell>
                  <TableCell padding='none'>
                    <Box m={0} p={0} display='flex' flexDirection='column'>
                      {row.ueGroups?.map((group: string, index: number) => (
                        <Typography
                          key={`${group}-${index}`}
                          sx={styles.text}
                          margin='2px 0'
                        >
                          {group}
                        </Typography>
                      ))}
                    </Box>
                  </TableCell>
                  {hasNDOLicense && (
                    <TableCell padding='none'>
                      {row.watchDogs?.map((watchdog: WatchDog) => (
                        <Box
                          key={watchdog.name}
                          display='flex'
                          flex-direction='row'
                          alignItems='center'
                          justifyContent='space-between'
                          mt={1}
                          mb={1}
                        >
                          <Typography
                            sx={styles.watchDogName}
                            margin='0 20px 0 0'
                          >
                            {watchdog.name}
                          </Typography>
                          <Chip
                            kind={
                              watchdog.isOnline
                                ? 'primary-ghost'
                                : 'default-ghost'
                            }
                            label={watchdog.isOnline ? 'ACTIVE' : 'INACTIVE'}
                            border={
                              watchdog.isOnline
                                ? '2px solid #00a410'
                                : '2px solid #888888'
                            }
                          />
                        </Box>
                      ))}
                    </TableCell>
                  )}
                </TableRow>
              );
            })
          }
        />
      </Loading>
      {data?.data.total > 10 && (
        <Pagination
          currentPage={page.pageStart}
          disabled={isLoading || isFetching}
          totalRecords={data?.data.total}
          onChange={handlePageChange}
        />
      )}
    </>
  );
};
