import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

interface ColumnChartProps
  extends Pick<Highcharts.SeriesSplineOptions, 'data'> {
  xAxisLabels?: string[];
  yAxisTitle: string;
  tooltipLabel: string;
  height: string | number;
  width: string | number;
  zoomOnAxis?: 'x' | 'y' | 'xy';
}

export const ColumnChart: React.FC<ColumnChartProps> = ({
  xAxisLabels,
  yAxisTitle,
  tooltipLabel,
  data,
  height,
  width,
  zoomOnAxis,
}) => {
  const options: Highcharts.Options = {
    chart: {
      zooming: {
        type: zoomOnAxis,
      },
      height,
      width,
    },
    credits: {
      enabled: false,
    },
    accessibility: {
      enabled: false,
    },
    title: {
      text: '',
    },
    legend: {
      enabled: false,
    },
    xAxis: {
      categories: xAxisLabels,
    },
    yAxis: {
      title: {
        text: yAxisTitle,
      },
    },
    series: [
      {
        type: 'column',
        name: tooltipLabel,
        data,
      },
    ],
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};
