import { Box, Link, MenuItem, RadioGroup, Typography } from '@mui/material';
import {
  Button,
  Chip,
  Radio,
  Select,
  SplitButton,
  Status,
  Switch,
  TextField,
  ChipIcon,
} from 'src/components';
import { Main } from 'src/layouts/Main';

export const ComponentsList: React.FC = () => {
  return (
    <Main>
      <Box
        display='flex'
        justifyContent='space-between'
        flexWrap='wrap'
        gap={6}
      >
        <Box>
          <Typography variant='h4'>Typography:</Typography>
          <br />
          <Typography variant='h1'>H1_Title</Typography>
          <Typography variant='h2'>H2_Title</Typography>
          <Typography variant='h3'>H3_Title</Typography>
          <Typography variant='h4'>H4_Title</Typography>
          <Typography variant='body1'>body1 - used as default text</Typography>
          <Typography variant='body2'>body2 - used as legend text</Typography>
          <Typography variant='caption'>caption text</Typography>
        </Box>
        <Box>
          <Typography variant='h4'>Link:</Typography>
          <br />
          <Link>Link</Link>
        </Box>
        <Box>
          <Typography variant='h4'>Filter Field:</Typography>
          <br />
          <Select width={200} menu renderValue={() => 'Filter'}>
            <MenuItem value={10}>Ten</MenuItem>
            <MenuItem value={20}>Twenty</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem>
          </Select>
        </Box>
        <Box>
          <Typography variant='h4'>Select Fields:</Typography>
          <br />
          <Select
            width={236}
            label='Empty select placeholder'
            placeholder='Empty select placeholder'
          >
            <MenuItem value={10}>Ten</MenuItem>
            <MenuItem value={20}>Twenty</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem>
          </Select>
          <br />
          <br />
          <Select
            width={236}
            label='Empty select placeholder'
            placeholder='Empty select placeholder'
            error='Some error occurred'
          >
            <MenuItem value={10}>Ten</MenuItem>
            <MenuItem value={20}>Twenty</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem>
          </Select>
        </Box>
        <Box>
          <Typography variant='h4'>Text Fields:</Typography>
          <br />
          <TextField width={236} label='Empty text field placeholder' />
          <br />
          <br />
          <TextField
            width={236}
            label='Empty text field placeholder'
            error='Some error occurred'
          />
        </Box>
        <Box>
          <Typography variant='h4'>Buttons:</Typography>
          <br />
          <Box display='flex' flexDirection='column' rowGap={1}>
            <Button kind='primary'>Primary Button</Button>
            <Button kind='primary-ghost'>Primary Ghost Button</Button>
            <Button kind='secondary'>Secondary Button</Button>
            <Button kind='secondary-ghost'>Secondary Ghost Button</Button>
            <Button
              kind='custom'
              textColor='#F20000'
              backgroundColor='#fff'
              padding='8px 24px'
              border='1px solid #F20000'
            >
              Custom Button
            </Button>
            <SplitButton
              kind='primary'
              label='Edit'
              primaryAction={() => console.log('primary action button clicked')}
              options={[
                {
                  label: 'label without styles',
                  action: () => console.log('secondary action clicked'),
                },
                {
                  label: 'label with styles',
                  action: () => console.log('secondary action clicked'),
                  styles: {
                    color: 'red',
                  },
                },
              ]}
            />
            <SplitButton
              kind='primary-ghost'
              label='Edit'
              primaryAction={() => console.log('primary action button clicked')}
              options={[
                {
                  label: 'label without styles',
                  action: () => console.log('secondary action clicked'),
                },
                {
                  label: 'label with styles',
                  action: () => console.log('secondary action clicked'),
                  styles: {
                    color: 'red',
                  },
                },
              ]}
            />
          </Box>
        </Box>
        <Box>
          <Typography variant='h4'>Switch:</Typography>
          <Switch checked />
          <Switch checked={false} />
        </Box>
        <Box>
          <Typography variant='h4'>Radio buttons in separate lines:</Typography>
          <RadioGroup defaultValue='active'>
            <Radio label='Active' value='active' />
            <Radio label='Inactive' value='inactive' />
          </RadioGroup>
          <br />
          <Typography variant='h4'>Radio buttons in row:</Typography>
          <RadioGroup row defaultValue='active'>
            <Radio label='Active' value='active' />
            <Radio label='Inactive' value='inactive' />
          </RadioGroup>
        </Box>
        <Box>
          <Typography variant='h4'>Chip:</Typography>
          <br />
          <Box display='flex' flexDirection='column' rowGap={1}>
            <Chip label='active' kind='primary' />
            <Chip label='active' kind='primary-ghost'></Chip>
            <Chip label='Error' kind='danger'></Chip>
            <Chip label='Error' kind='danger-ghost'></Chip>
            <Chip label='Inactive' kind='default'></Chip>
            <Chip label='Inactive' kind='default-ghost'></Chip>
            <ChipIcon label='Inactive' kind='primary-ghost'></ChipIcon>
            <ChipIcon label='Inactive' kind='danger-ghost'></ChipIcon>
            <ChipIcon label='Inactive' kind='default-ghost'></ChipIcon>
          </Box>
        </Box>
        <Box>
          <Typography variant='h4'>Status:</Typography>
          <br />
          <Status kind={'success'} text={'Working'} />
          <Status kind={'warning'} text={'Warning'} />
          <Status kind={'danger'} text={'Stop'} />
        </Box>
      </Box>
    </Main>
  );
};
