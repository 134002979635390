import { Box, Typography } from '@mui/material';

import { Main } from 'src/layouts/Main';
import {
  alertType,
  boundaryType,
  capacityType,
  prioritizeType,
} from 'src/views/Rules/rule/assets';
import { Type } from './Type';
import React, { useContext } from 'react';
import { RulesEngineContext } from 'src/context';
import { useSearchParams } from 'react-router-dom';
import { Button, Steps } from 'src/components';
import { Breadcrumbs } from 'src/components/Breadcrumbs/index';

const ruleTypes = [
  {
    id: 1,
    name: 'Prioritization',
    value: 'PRIORITIZATION',
    description:
      // eslint-disable-next-line max-len
      'Select which equipment should have prioritised access to the available network capacity and which should yield capacity when required.',
    image: prioritizeType,
  },
  {
    id: 2,
    name: 'Threshold',
    value: 'THRESHOLD',
    description:
      "Raise an alert if selected equipment's network metrics cross a specified threshold value.",
    image: alertType,
  },
  {
    id: 3,
    name: 'Capacity',
    value: 'CAPACITY',
    description:
      // eslint-disable-next-line max-len
      'Request selected network to downgrade usage if their throughput breaches a defined level.',
    image: capacityType,
  },
  {
    id: 4,
    name: 'Geofence',
    value: 'GEO_FENCE',
    description:
      // eslint-disable-next-line max-len
      'Raise an alert if selected equipment enters or exits the network boundary.',
    image: boundaryType,
  },
];

const steps = [
  { title: 'Type', value: 'type' },
  { title: 'Selection', value: 'selection' },
  { title: 'Specification', value: 'specification' },
];

interface RuleTypeProps {
  onCancel: () => void;
}

export const RuleType: React.FC<RuleTypeProps> = ({ onCancel }) => {
  const [, setParams] = useSearchParams();
  const { ruleType, selectRuleType } = useContext(RulesEngineContext);

  return (
    <Main>
      <Breadcrumbs breadcrumbs={[
        {
          title: 'Overview',
          url: '/',
        },
        {
          title: 'Rules',
          url: '/rules'
        },
        {
          title: 'Create a rule',
        },
      ]}/>
      <Typography variant='h1' sx={{ mb: '26px' }}>
        Rules
      </Typography>
      <Box display='flex' justifyContent='center'>
        <Box sx={{ width: '700px' }}>
          <Steps
            activeStep={0}
            steps={steps}
            onClick={(step) => setParams({ step: step.toString() })}
          />
        </Box>
      </Box>
      <Box
        display='flex'
        justifyContent='space-between'
        gap='14px'
        flexWrap='wrap'
      >
        {ruleTypes.map((rule) => (
          <Type
            key={rule.id}
            title={rule.name}
            descp={rule.description}
            image={rule.image}
            active={ruleType === rule.value}
            onSelect={() => {
              selectRuleType(rule.value);
              setParams({ step: 'selection' });
            }}
          />
        ))}
      </Box>
      <Box mt='36px' mb='43px' display='flex' justifyContent='flex-end'>
        <Button kind='primary-ghost' onClick={onCancel}>
          Cancel
        </Button>
      </Box>
    </Main>
  );
};
