import { Slider } from '@mui/material';
import { styled } from '@mui/material/styles';
export const styles = {
  textBox: {
    width: '600px',
  },
  shadowBox: {
    display: 'flex',
    width: '50%',
    height: 'calc(100vh - 50px)',
    overflowY: 'auto',
    boxShadow: '8px 0 5px -3px rgb(0 0 0 / 25%)',
    zIndex: 1,
    position: 'relative',
    paddingTop: '20px',
    paddingLeft: '20px',
  },
  networkDetailsBox: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    width: '100%',
  },
  cancelBtn: {
    marginRight: '12px',
  },
  marker: {
    cursor: 'pointer',
    color: '#3CDECB',
    width: '34px',
    height: '34px',
    filter: 'drop-shadow(0px 3px 6px #00000029)',
    '&.MuiSvgIcon-root:hover': {
      color: '#2EA392',
    },
  },
  mapContainer: {
    position: 'absolute',
    right: '0px',
    top: '0px',
    height: 'calc(100vh - 50px)',
    display: 'flex',
    width: '50%',
  },
  editSections: {
    color: '#222222',
    fontSize: '14px',
    fontWeight: 'bold',
    marginBottom: '12px',
  },
  coverageRadiusContainer: {
    position: 'absolute',
    zIndex: '2',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  controlAreaBox: {
    backgroundColor: 'white',
    display: 'flex',
    margin: '10px',
    paddingX: '25px',
    paddingTop: '10px',
    borderRadius: '6px',
  },
  map: {
    width: '100%',
    height: '100%',
  },
  radiusLimits: {
    padding: '10px',
    color: '#006161',
    fontSize: '14px',
  },
  layerPaint: {
    'fill-color': '#088',
    'fill-opacity': 0.4,
    'fill-outline-color': '#55CFBB',
  },
  coverageAreaTitle: {
    color: '#222222',
    fontWeight: 'bold',
    fontSize: '14px',
    paddingLeft: '35px',
    paddingRight: '135px',
  },
  operationButtonsBox: {
    display: 'flex',
    width: '456px',
    flexDirection: 'row-reverse',
  },
};

export const StyledCoverageSlider = styled(Slider)(() => ({
  marginBottom: '7px',
  padding: '12px 0',
  height: 8,
  '& .MuiSlider-rail': {
    height: 5,
    borderRadius: 0,
    background: '#55CFBB',
    opacity: 1,
  },
  '& .MuiSlider-track': {
    background: '#006161',
    height: 5,
    border: 'none',
    borderRadius: 0,
  },
  '& .MuiSlider-thumb': {
    height: 20,
    width: 20,
    backgroundColor: '#fff',
    border: '6px solid #006161',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
}));
