import {
  DialogContent,
  Typography,
  DialogActions,
  Box,
  MenuItem,
  Stack,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Button, Loading, Select, TextField } from 'src/components';
import Map_, { Marker, MapRef, MarkerDragEvent } from 'react-map-gl';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import { FmdGood } from '@mui/icons-material';
import { MenuProps, styles } from './styles';
import './styles.css';
import { useRef, useState, useCallback, useContext } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { AxiosError, AxiosResponse } from 'axios';
import { createSiteSchema } from 'src/yup-validations/addSite';
import { editSiteSchema } from 'src/yup-validations/editSite';
import { QueryName } from 'src/enums/query';
import { addSite, editSite, getSite } from 'src/apis/sites';
import { useOrganizations } from '../state';
import { ToastContext } from 'src/context/ToastContext';
import { GeocoderControl } from 'src/components/MapSearch';
import countriesList from 'countries-list';
import { addOrganization } from 'src/apis/org';

interface AddSiteProps {
  onCancel: () => void;
  organizationName?: string;
  siteName?: string;
}

type AddFormPayload = {
  organization: string;
  site: string;
  description?: string;
  country: string;
  organizationName: string;
  orgDescription?: string;
};

const initialViewState = {
  latitude: 54.61745492998754,
  longitude: -5.905370495296432,
  zoom: 10,
};

export const AddSite: React.FC<AddSiteProps> = ({
  onCancel,
  organizationName,
  siteName,
}) => {
  const organizationList = useOrganizations();
  const [siteLocation, setSiteLocation] = useState(false);
  const [viewport, setViewport] = useState({
    latitude: 54.61745492998754,
    longitude: -5.905370495296432,
    flag: false,
  });
  const [editSiteDescription, setEditSiteDescription] = useState<string>('');
  const { showToast } = useContext(ToastContext);
  const queryClient = useQueryClient();
  const [isLoading, setLoading] = useState(true);
  const [editViewState, setEditViewState] = useState({
    latitude: 0,
    longitude: 0,
    zoom: 0,
  });
  const {
    control,
    formState: { errors, isSubmitted },
    handleSubmit,
    reset,
    watch,
  } = useForm({
    defaultValues: {
      organization: '',
      site: '',
      description: '',
      country: '',
      organizationName: '',
      orgDescription: '',
    },
    resolver: siteName
      ? yupResolver(editSiteSchema)
      : yupResolver(createSiteSchema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });
  const organizationType: string = watch('organization');
  const {
    mutate: addSiteMutation,
    isLoading: addSiteMutationLoading,
    error: addSiteMutationError,
    reset: addSiteMutationReset,
  } = useMutation<
    unknown,
    AxiosError,
    {
      organization: string;
      site: string;
      description?: string;
      latitude: number;
      longitude: number;
    }
  >({
    mutationFn: addSite,
    onSuccess: async () => {
      reset();
      await queryClient.invalidateQueries([QueryName.GET_SITE_NAMES]);
      showToast('Site added successfully!', 'success');
      onCancel();
    },
  });
  const onSubmit = (data: AddFormPayload) => {
    if (!viewport.flag && !siteName) {
      setSiteLocation(true);
    } else if (organizationType === 'new') {
      addOrgMutation({
        organization: data.organizationName,
        country: data.country,
        description: data.orgDescription,
        site: data.site,
        organizationName: data.organizationName,
        siteDescription: data.description ? data.description : '',
      });
    } else if (siteName) {
      editSiteMutation({
        organization: siteData?.organization as string,
        site: siteName,
        description: editSiteDescription,
        latitude: viewport.latitude,
        longitude: viewport.longitude,
      });
    } else {
      addSiteMutation({
        organization: data.organization,
        site: data.site,
        description: data.description,
        latitude: viewport.latitude,
        longitude: viewport.longitude,
      });
    }
  };

  const { mutate: addOrgMutation, isLoading: addOrgMutationLoading } =
    useMutation<
      unknown,
      AxiosError<{
        failures: [
          {
            id: string;
            message: string;
          },
        ];
      }>,
      {
        organization: string;
        country: string;
        description?: string;
        site: string;
        organizationName: string;
        siteDescription: string;
      }
    >({
      mutationFn: addOrganization,
      onSuccess: async (_, { site, organizationName, siteDescription }) => {
        addSiteMutation({
          organization: organizationName,
          site,
          description: siteDescription,
          latitude: viewport.latitude,
          longitude: viewport.longitude,
        });
        await queryClient.invalidateQueries([QueryName.GET_ORG_NAMES]);
        onCancel();
        showToast('Organization added successfully!', 'success');
      },
    });
  const { data: siteData } = useQuery(
    [QueryName.GET_SITE_DETAILS, siteName],
    () => getSite(siteName as string, organizationName as string),
    {
      enabled: !!siteName,
      onSuccess: async (data) => {
        setEditSiteDescription(data.description as string);
        setEditViewState({
          latitude: parseFloat(data?.latitude),
          longitude: parseFloat(data?.longitude),
          zoom: 10,
        });
        setViewport({
          latitude: parseFloat(data?.latitude),
          longitude: parseFloat(data?.longitude),
          flag: false,
        });
        setLoading(false);
      },
    },
  );
  const handleClose = () => {
    reset();
    addSiteMutationReset();
    onCancel();
  };
  const getErrorMsg = (message: string) => {
    if (message.includes('409')) {
      return 'Another site with this name already exists.';
    }
    return 'Something went wrong. Try again.';
  };
  const mapRef = useRef<MapRef>(null);
  const setCoordinates = (event: any) => {
    const coordinates = event.result.geometry.coordinates;
    setViewport({
      longitude: coordinates[0],
      latitude: coordinates[1],
      flag: true,
    });
  };
  const onMarkerDragEnd = useCallback((event: MarkerDragEvent) => {
    setViewport({
      longitude: event?.lngLat.lng,
      latitude: event?.lngLat.lat,
      flag: true,
    });
  }, []);
  const {
    mutate: editSiteMutation,
    isLoading: editSiteLoading,
    error: editSiteMutationError,
  } = useMutation<
    AxiosResponse,
    AxiosError,
    {
      organization: string;
      site: string;
      description?: string;
      section?: string[];
      latitude: number;
      longitude: number;
    }
  >({
    mutationFn: editSite,
    onSuccess: async () => {
      reset();
      await queryClient.invalidateQueries([QueryName.GET_SITE_DETAILS]);
      onCancel();
      showToast('Site edited successfully!', 'success');
    },
  });

  return (
    <Box>
      <DialogContent>
        <Box mt='24px'>
          <Typography variant='h3' fontSize='14px'>
            Organization
          </Typography>
        </Box>
        <Box display='flex' justifyContent='space-between' mt='12px'>
          {siteName ? (
            <Stack direction='column'>
              <Typography variant={'h4'}> Name </Typography>
              <Typography sx={styles.editTitleText}>
                {siteData?.organization}
              </Typography>
            </Stack>
          ) : (
            <Controller
              name='organization'
              control={control}
              render={({ field }) => (
                <Select
                  width={236}
                  label='Organization'
                  MenuProps={MenuProps}
                  placeholder='Organization'
                  error={
                    errors?.organization
                      ? 'Please enter a valid organization'
                      : undefined
                  }
                  {...field}
                  onChange={(event) => {
                    if (event.target.value === 'newOrg') {
                      field.onChange('new');
                    } else {
                      field.onChange(event.target.value);
                    }
                  }}
                >
                  {organizationList?.data?.data.map((org) => (
                    <MenuItem key={org} value={org}>
                      {org}
                    </MenuItem>
                  ))}
                  <MenuItem style={{ display: 'none' }} key='new' value='new'>
                    New element
                  </MenuItem>
                  <MenuItem
                    style={{
                      background: '#EFF5F5 0% 0% no-repeat padding-box',
                    }}
                    key='newOrg'
                    value='newOrg'
                  >
                    <AddCircleOutlineIcon
                      fontSize='small'
                      style={{ color: 'green' }}
                    />
                    <span style={{ color: 'green', marginLeft: '5px' }}>
                      Add an element
                    </span>
                  </MenuItem>
                </Select>
              )}
            />
          )}
          {organizationType === 'new' && (
            <Controller
              name='organizationName'
              control={control}
              render={({ field }) => (
                <TextField
                  width={236}
                  label='Name'
                  {...field}
                  onChange={(event) => {
                    field.onChange(event.target.value);
                  }}
                  error={
                    errors?.organizationName
                      ? 'Please enter a valid organization'
                      : undefined
                  }
                />
              )}
            />
          )}
        </Box>
        {organizationType === 'new' && (
          <Box display='flex' justifyContent='space-between' mt='7px'>
            <Controller
              name='country'
              control={control}
              render={({ field }) => (
                <Select
                  width={236}
                  label='Country'
                  MenuProps={MenuProps}
                  placeholder='Country'
                  error={
                    errors?.country
                      ? 'Please enter a valid country name'
                      : undefined
                  }
                  {...field}
                >
                  {Object.entries(countriesList.countries).map(
                    ([key, country]) => (
                      <MenuItem key={key} value={country.name}>
                        {country.name}
                      </MenuItem>
                    ),
                  )}
                </Select>
              )}
            />
            <Controller
              name='orgDescription'
              control={control}
              render={({ field }) => (
                <TextField
                  width={236}
                  label='Description (Optional)'
                  {...field}
                />
              )}
            />
          </Box>
        )}
        <Typography variant='h3' fontSize='14px' mt='24px'>
          Site
        </Typography>
        <Box display='flex' justifyContent='space-between' mt='12px'>
          {siteName ? (
            <Stack direction='column'>
              <Typography variant={'h4'}> Name </Typography>
              <Typography sx={styles.editTitleText}>{siteName}</Typography>
            </Stack>
          ) : (
            <Controller
              name='site'
              control={control}
              render={({ field }) => (
                <TextField
                  width={236}
                  label='Name'
                  {...field}
                  error={
                    errors.site?.message ? errors.site?.message : undefined
                  }
                />
              )}
            />
          )}
          <Controller
            name='description'
            control={control}
            render={({ field: { onChange, ...field } }) => (
              <TextField
                width={236}
                label='Description (Optional)'
                {...field}
                onChange={({ target: { value } }) => {
                  onChange(value);
                  setEditSiteDescription(value);
                }}
                value={editSiteDescription}
              />
            )}
          />
        </Box>
        <Box width='100%' mb='24px' mt='24px'>
          <Typography variant='h3' fontSize='14px' mb='7px'>
            Site location
          </Typography>
          <Loading isLoading={siteName ? isLoading : !isLoading}>
            <Box
              sx={{
                border:
                  (siteLocation || (isSubmitted && !viewport.flag)) &&
                  !editSiteLoading
                    ? 1
                    : 0,
                borderColor:
                  (siteLocation || (isSubmitted && !viewport.flag)) &&
                  !editSiteLoading
                    ? '#FF0000'
                    : 'none',
                marginTop: '16px',
              }}
            >
              <Map_
                ref={mapRef}
                initialViewState={siteName ? editViewState : initialViewState}
                mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN_2}
                mapStyle='mapbox://styles/mapbox/light-v9'
                style={{ width: '100%', height: '324px' }}
              >
                <Marker
                  longitude={viewport.longitude}
                  latitude={viewport.latitude}
                  anchor='bottom'
                  draggable
                  onDragEnd={onMarkerDragEnd}
                >
                  <FmdGood sx={styles.marker} />
                </Marker>
                {addSiteMutationLoading || editSiteLoading ? (
                  ''
                ) : (
                  <GeocoderControl
                    mapboxAccessToken={
                      process.env.REACT_APP_MAPBOX_TOKEN_2 as string
                    }
                    position='top-left'
                    onResult={setCoordinates}
                  />
                )}
              </Map_>
            </Box>
          </Loading>
          {siteLocation ||
            (isSubmitted && !viewport.flag && !editSiteLoading && (
              <Typography
                fontSize='10px'
                color='#F20000'
                mt='5px'
                textAlign='left'
                mr='7px'
              >
                {'Site location is required'}
              </Typography>
            ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button kind='primary-ghost' onClick={handleClose}>
          Cancel
        </Button>
        <Button kind='primary' onClick={handleSubmit(onSubmit)}>
          Save
        </Button>
      </DialogActions>
      {addSiteMutationError && (
        <Typography
          fontSize='10px'
          color='#F20000'
          mt='5px'
          textAlign='right'
          mr='7px'
        >
          {getErrorMsg(addSiteMutationError.message)}
        </Typography>
      )}
      {editSiteMutationError && (
        <Typography
          fontSize='10px'
          color='#F20000'
          mt='5px'
          textAlign='right'
          mr='7px'
        >
          {getErrorMsg(editSiteMutationError.message)}
        </Typography>
      )}
      <Backdrop
        sx={{ color: '#006161' }}
        open={
          editSiteLoading || addSiteMutationLoading || addOrgMutationLoading
        }
      >
        <CircularProgress size='60px' />
      </Backdrop>
    </Box>
  );
};
