import React, { FC, useContext } from 'react';
import { RulesEngineContext } from 'src/context';
import { Box, Typography } from '@mui/material';

export const SelectedNetworks: FC = () => {
  const { selectedAPNs } = useContext(RulesEngineContext);
  return (
    <Box mb='36px' mr='40px'>
      <Typography variant='h3' mb='27px'>
        Equipment concerned
      </Typography>
      <Typography variant='h4' mb='5px'>
        Networks
      </Typography>
      <Box>
        {selectedAPNs.map((selectedAPN) => (
          <Typography variant='body1' key={selectedAPN.name}>
            {selectedAPN.name}
          </Typography>
        ))}
      </Box>
    </Box>
  );
};
