import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useContext,
  useState,
} from 'react';
import { DialogTitle, IconButton, Typography } from '@mui/material';
import { Close } from '@carbon/icons-react';

import { StyledDialog } from 'src/components/Modal/styles';
import { UploadStepOne } from './UploadStepOne';
import { UploadStepTwo } from './UploadStepTwo';
import { styles } from './styles';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { uploadBulkUsers } from 'src/apis/access-management';
import { QueryName } from 'src/enums/query';
import { ToastContext } from 'src/context';

interface UploadUsersProps {
  showModal: boolean;
  closeModal: () => void;
}

enum STEPS {
  SELECT_FILE = 0,
  UPLOAD_FILE = 1,
}

export const UploadUsersModal: FC<UploadUsersProps> = ({
  showModal,
  closeModal,
}) => {
  const queryClient = useQueryClient();
  const { showToast } = useContext(ToastContext);

  const [progress, setProgress] = useState(0);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [step, setStep] = useState(STEPS.SELECT_FILE);
  const [result, setResult] = useState<{
    successfulUploads: string[];
    failedUploads: string[];
    errorReportPath: string;
  } | null>(null);

  const selectFile = (file: File | null) => {
    setSelectedFile(file);
  };

  const { mutate: uploadFile, isLoading } = useMutation<
    AxiosResponse,
    AxiosError,
    {
      file: File;
      onProgress: Dispatch<SetStateAction<number>>;
    }
  >({
    mutationFn: uploadBulkUsers,
    onSuccess: async (responseData) => {
      setResult({
        successfulUploads: responseData.data.success,
        failedUploads: responseData.data.failed,
        errorReportPath: responseData.data.reportPath,
      });
      if (responseData.data.successCount > 0) {
        await queryClient.invalidateQueries([QueryName.GET_ALL_USERS]);
      }
    },
    onError: () => {
      showToast(
        'An error occurred while uploading file, please try again',
        'error',
      );
    },
  });

  const handleUploadFile = useCallback(() => {
    uploadFile({
      file: selectedFile as File,
      onProgress: setProgress,
    });
  }, [selectedFile, uploadFile]);

  const handleClose = () => {
    closeModal();
    setTimeout(() => {
      setResult(null);
      setProgress(0);
      setSelectedFile(null);
      setStep(STEPS.SELECT_FILE);
    }, 300);
  };

  const goToSecondStep = () => {
    handleUploadFile();
    setStep(STEPS.UPLOAD_FILE);
  };

  return (
    <StyledDialog open={showModal}>
      <DialogTitle sx={styles.titleWrapper}>
        <Typography sx={styles.title}>Upload user file</Typography>
        <IconButton onClick={closeModal}>
          <Close size='24' color='#222222' />
        </IconButton>
      </DialogTitle>
      {step === STEPS.SELECT_FILE && (
        <UploadStepOne
          selectedFile={selectedFile}
          selectFile={selectFile}
          goToSecondStep={goToSecondStep}
          handleClose={handleClose}
        />
      )}
      {step === STEPS.UPLOAD_FILE && (
        <UploadStepTwo
          selectedFile={selectedFile}
          isLoading={isLoading}
          result={result}
          progress={progress}
          handleClose={handleClose}
        />
      )}
    </StyledDialog>
  );
};
