import { Link, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { styles } from './styles';
import { ToastContext } from 'src/context';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useContext, useEffect, useState } from 'react';
import { Calendar, User, ChevronDown, ChevronUp } from '@carbon/icons-react';
import { Confirmable, SplitButton, Chip } from 'src/components';
import { formatDate } from 'src/utils/date';
import { QueryName } from 'src/enums/query';
import { activateRule, deactivateRule, deleteRule } from 'src/apis/rule';
import { capitalize } from 'lodash';
import { colors } from 'src/config/theme';
import { RuleConfiguration } from 'src/types/rule';
import { RenameRuleModal } from 'src/components/Modal/RenameRuleModal';
import { useNavigate } from 'react-router-dom';
import { useUserRole } from 'src/utils/user';

interface RuleHeaderProps {
  ruleConfiguration: RuleConfiguration;
  showMore: (moreInfo: boolean) => void;
}

export const RuleDetailsHeader: React.FC<RuleHeaderProps> = ({
  ruleConfiguration,
  showMore,
}) => {
  const [renameDialogOpen, setRenameDialogOpen] = useState(false);
  const navigate = useNavigate();
  const [activateConfirmOpen, setActivateConfirmOpen] = useState(false);
  const [deactivateConfirmOpen, setDeactivateConfirmOpen] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const queryClient = useQueryClient();
  const { showToast } = useContext(ToastContext);
  const [readMoreShown, setReadMoreShown] = useState(false);
  const { isReadyOnlyUser } = useUserRole();
  const activateMutation = useMutation<
    AxiosResponse,
    AxiosError,
    { organization: string; site: string; id: string }
  >({
    mutationFn: activateRule,
    onSuccess: async () => {
      showToast('Rule Activated successfully!', 'success');
      await queryClient.invalidateQueries([QueryName.GET_RULE_DETAIL]);
    },
    onError: () => {
      showToast('Failed to activate the rule, Please try again', 'error');
    },
  });
  const deactivateMutation = useMutation<
    AxiosResponse,
    AxiosError,
    { organization: string; site: string; id: string }
  >({
    mutationFn: deactivateRule,
    onSuccess: async () => {
      showToast('Rule Deactivated successfully!', 'success');
      await queryClient.invalidateQueries([QueryName.GET_RULE_DETAIL]);
    },
    onError: () => {
      showToast('Failed to deactivate the rule, Please try again', 'error');
    },
  });
  const deleteMutation = useMutation<
    unknown,
    AxiosError,
    { organization: string; site: string; id: string }
  >({
    mutationFn: deleteRule,
    onSuccess: async () => {
      showToast('Rule deleted successfully!', 'success');
      navigate('/rules');
    },
    onError: () => {
      showToast('Failed to delete the rule, Please try again', 'error');
    },
  });
  const handleEdit = () => {
    navigate(
      `/rules/edit/${ruleConfiguration.organization}/${ruleConfiguration.site}/${ruleConfiguration.id}`,
    );
  };
  const handleRenameRule = async () => {
    setRenameDialogOpen(false);
    await queryClient.invalidateQueries([QueryName.GET_RULE_DETAIL]);
  };

  const menuOption = [
    ...(ruleConfiguration.state === 'ACTIVE'
      ? [
          {
            label: 'Deactivate',
            action: () => setDeactivateConfirmOpen(true),
          },
        ]
      : [
          {
            label: 'Activate',
            action: () => setActivateConfirmOpen(true),
          },
        ]),
    {
      label: 'Rename',
      action: () => setRenameDialogOpen(true),
    },
    {
      label: 'Delete',
      action: () => setDeleteConfirmOpen(true),
      styles: {
        color: 'red',
      },
    },
  ];
  const showMoreDescription = () => {
    setReadMoreShown((prevState) => !prevState);
  };
  useEffect(() => {
    if (readMoreShown) {
      showMore(true);
    } else {
      showMore(false);
    }
  }, [readMoreShown, showMore]);
  return (
    <Box sx={styles.root} mt='10px'>
      <Confirmable
        open={deleteConfirmOpen}
        title='Delete Rule'
        subTitle={`Are you sure to delete ${ruleConfiguration.name} from rules?`}
        onConfirm={() => {
          deleteMutation.mutate({
            organization: ruleConfiguration.organization,
            site: ruleConfiguration.site,
            id: ruleConfiguration.id,
          });
          setDeleteConfirmOpen(false);
        }}
        onCancel={() => setDeleteConfirmOpen(false)}
      />
      <Confirmable
        open={activateConfirmOpen}
        title='Activate Rule'
        subTitle={`Are you sure to activate ${ruleConfiguration.name}?`}
        onConfirm={() => {
          activateMutation.mutate({
            organization: ruleConfiguration.organization,
            site: ruleConfiguration.site,
            id: ruleConfiguration.id,
          });
          setActivateConfirmOpen(false);
        }}
        onCancel={() => setActivateConfirmOpen(false)}
      />
      <Confirmable
        open={deactivateConfirmOpen}
        title='Deactivate Rule'
        subTitle={`Are you sure to deactivate ${ruleConfiguration.name}?`}
        onConfirm={() => {
          deactivateMutation.mutate({
            organization: ruleConfiguration.organization,
            site: ruleConfiguration.site,
            id: ruleConfiguration.id,
          });
          setDeactivateConfirmOpen(false);
        }}
        onCancel={() => setDeactivateConfirmOpen(false)}
      />
      <Box sx={styles.leftcontent}>
        <Box sx={styles.heading}>
          <Typography variant='h1'>{ruleConfiguration.name}</Typography>
          <Typography variant='h1' sx={styles.ruleType}>
            {capitalize(ruleConfiguration.type)}
          </Typography>
          <Box mt='2px'>
            {ruleConfiguration.state === 'ACTIVE' ? (
              <Chip label={ruleConfiguration.state} kind='primary' />
            ) : (
              <Chip label={ruleConfiguration.state} kind='default'></Chip>
            )}
          </Box>
        </Box>
        <Box sx={styles.heading}>
          {ruleConfiguration.from && (
            <>
              <Typography variant='body2' sx={styles.boldText}>
                From
              </Typography>
              <Typography variant='body2' ml='10px'>
                {formatDate(ruleConfiguration.from)}
              </Typography>
            </>
          )}
          <Box ml='30px' />
          {ruleConfiguration.to && (
            <>
              <Typography variant='body2' sx={styles.boldText}>
                To
              </Typography>
              <Typography variant='body2' ml='10px'>
                {formatDate(ruleConfiguration.to)}
              </Typography>
            </>
          )}
        </Box>
        <Box sx={styles.description}>
          {ruleConfiguration.description.length >= 200 ? (
            <Typography variant='body2'>
              {readMoreShown
                ? ruleConfiguration.description
                : ruleConfiguration.description.substring(0, 200)}
              {readMoreShown ? '' : '...'}
              <Link
                sx={styles.descriptionExpandLink}
                onClick={showMoreDescription}
              >
                {readMoreShown ? (
                  <Box sx={styles.boxShow}>
                    <Typography sx={styles.descriptionExpandText}>
                      Show less
                    </Typography>
                    <ChevronUp style={styles.showMoreIcon} />
                  </Box>
                ) : (
                  <Box sx={styles.boxShow}>
                    <Typography sx={styles.descriptionExpandText}>
                      Show more
                    </Typography>
                    <ChevronDown style={styles.showMoreIcon} />
                  </Box>
                )}
              </Link>
            </Typography>
          ) : (
            <Typography variant='body2'>
              {ruleConfiguration.description}
            </Typography>
          )}
        </Box>
        <Box sx={styles.heading}>
          <Box display='flex' alignItems='center' mr='58px'>
            <User color={colors.secondaryLight} />
            <Typography variant='body2' ml='4px'>
              Created by {ruleConfiguration.createdBy}
            </Typography>
          </Box>
          {ruleConfiguration.createdAt && (
            <Box display='flex' alignItems='center'>
              <Calendar color={colors.secondaryLight} />
              <Typography variant='body2' ml='6px'>
                {formatDate(ruleConfiguration.createdAt)}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      {!isReadyOnlyUser() && (
        <Box>
          <SplitButton
            kind='primary'
            label='Edit'
            primaryAction={() => handleEdit()}
            options={menuOption}
          />
        </Box>
      )}
      <RenameRuleModal
        openDialog={renameDialogOpen}
        onSave={handleRenameRule}
        onCancel={() => setRenameDialogOpen(false)}
        ruleConfiguration={ruleConfiguration}
      />
    </Box>
  );
};
