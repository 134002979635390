import styled from '@emotion/styled';
import { FormControlLabel } from '@mui/material';

export const StyledFormControlLabel = styled(FormControlLabel)`
  & {
    .MuiTypography-root {
      color: #222222;
    }
  }
`;
