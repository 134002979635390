export const styles = {
  successStatusIcon: {
    color: '#00A410',
    height: '13px',
    width: '13px',
    marginRight: '5px',
  },
  warningStatusIcon: {
    color: '#FF9900',
    height: '13px',
    width: '13px',
    marginRight: '5px',
  },
  dangerStatusIcon: {
    color: '#F20000',
    height: '13px',
    width: '13px',
    marginRight: '5px',
  },
  successText: {
    color: '#00A410',
  },
  warningText: {
    color: '#FF9900',
  },
  dangerText: {
    color: '#F20000',
  },
};
