import { useQuery } from '@tanstack/react-query';
import { QueryName } from 'src/enums/query';
import { getSiteNames } from 'src/apis/sites';
import { getOrgNames } from 'src/apis/org';

export function useOrganizations () {
  return useQuery([QueryName.GET_ORG_NAMES], () => getOrgNames());
}

export function useSites (organization: string | null) {
  return useQuery(
    [QueryName.GET_SITE_NAMES, { organization }],
    () => getSiteNames(organization as string),
    { enabled: !!organization },
  );
}
