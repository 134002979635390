export const styles = {
  consumption: {
    fontWeight: 'bold',
    fontSize: '26px',
    color: '#006161',
  },
  timeLineButton: {
    fontSize: '14px',
    color: '#006161',
    textTransform: 'capitalize',
    borderColor: 'transparent',

    '&:hover': {
      borderColor: '#83B6B6FF!important',
    },
  },
  siteSelect: {
    fontSize: '14px',
    color: '#222222',
    textTransform: 'capitalize',
    borderColor: 'transparent',
    width: '147px',
    height: '45px',
  },
  selectedSite: {
    color: '#158686',
    fontSize: '14px',
    fontWeight: 'bold',
    borderColor: '#006161!important',
    background: '#F1FCFA',
    textTransform: 'capitalize',
    width: '147px',
    height: '45px',
  },
  customize: {
    fontSize: '14px',
    color: '#006161',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    borderColor: 'transparent',
  },
  change: {
    fontSize: '14px',
    color: '#007777',
    textDecoration: 'underline',
    textTransform: 'capitalize',
  },
  linkBack: {
    fontSize: '18px',
    color: '#006161',
    padding: '6px8px!important',
    textTransform: 'capitalize',
  },
  calendarPadding: {
    marginLeft: '10px',
  },
  border: {
    color: '#006161',
    borderColor: '#006161!important',
    background: '#F1FCFA',
    textTransform: 'capitalize',
    borderRadius: '0!important',
  },

  boldText: {
    textAlign: 'left',
    color: '#222222',
    fontWeight: 'bold',
    fontSize: '16px',
  },
  iconClose: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    right: '15px',
    top: '10px',
    color: '#222222',
  },
};
