import { Box, Typography, Stack } from '@mui/material';
import { styles } from './styles';
import { PieChart } from './PieChart';
import { UsersForPolicy } from './SelectUsers';

interface DataUsageProps {
  selectedUsers: UsersForPolicy[];
  allocationPerUser: number;
}

export const DataUsage: React.FC<DataUsageProps> = ({
  selectedUsers,
  allocationPerUser,
}) => {
  return (
    <Box display='flex'>
      <Box sx={styles.dataUsageBox}>
        <Typography sx={styles.dataUsageText}> Data usage </Typography>
        <Stack direction='row' sx={styles.dataUsageStack}>
          <PieChart
            selectedUsers={selectedUsers}
            allocationPerUser={allocationPerUser}
          />
          <Stack direction='column' sx={styles.usedUsageStack}>
            <Typography sx={styles.usedUsageTextLarge}>
              0<span style={styles.gbText}>GB</span>
            </Typography>
            <Typography sx={styles.usedUsageTextSmall}>used</Typography>
            <Typography sx={styles.allowenceTextLarge}>
              {
                  (Number(allocationPerUser ?? 0) * (selectedUsers.length))
              }
              <span style={styles.gbText}>GB</span>
            </Typography>
            <Typography sx={styles.allowenceTextSmall}>allocated</Typography>
          </Stack>
        </Stack>
      </Box>
      <Stack direction='column' justifyContent='space-evenly'>
        <Box sx={styles.usageInfoBox}>
          <Typography sx={styles.usageInfoHeading}>Users selected</Typography>
          <Typography sx={styles.usageInfoText}>
            {selectedUsers.length}
          </Typography>
        </Box>
        <Box sx={styles.usageInfoBox}>
          <Typography sx={styles.usageInfoHeading}>Total Usage</Typography>
          <Stack sx={styles.totalUsageStack} direction='row'>
            <Typography sx={styles.usageInfoText}>
              {
                (Number(allocationPerUser ?? 0) * (selectedUsers.length))
              }
            </Typography>
            <Typography sx={styles.usageGBText}>GB</Typography>
          </Stack>
        </Box>
        <Box sx={styles.recurrenceBox}>
          <Typography sx={styles.usageInfoHeading}>Recurrence</Typography>
          <Typography sx={styles.usageInfoText}>
            On the 1<sup style={{ fontSize: '12px' }}>st</sup> of every month
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
};
