import { api } from 'src/utils/axios';
import { ApiResponseType } from 'src/types/api';
import { Organization } from 'src/types/domain';

const baseUrl = `${process.env.REACT_APP_BACKEND_URL}`;

export const getOrgNames = (searchText?: string) => {
  return api.get<ApiResponseType<string[]>>(
    '/topology/v1/metadata/organization/name',
    {
      params: {
        searchText,
      },
    },
  );
};
export const getListofVendors = () => {
  return api.get<ApiResponseType<string[]>>(
    '/topology/v1/metadata/vendor/name',
  );
};

export const getOrganization = async (organization: string) => {
  const { data } = await api.get<Organization>(
    `/topology/v1/organization?organization=${organization}`,
  );
  return data;
};

export const editOrganization = ({
  organization,
  country,
  description,
}: {
  organization: string;
  country: string;
  description?: string;
}) => {
  const url = `${baseUrl}topology/v1/organization?organization=${organization}`;
  return api.patch(url, {
    organization,
    country,
    description,
  });
};

export const addOrganization = ({
  organization,
  country,
  description,
}: {
  organization: string;
  country: string;
  description?: string;
}) => {
  const url = '/topology/v1/organization';
  return api.post(url, {
    organization,
    country,
    description,
  });
};

export const deleteOrganization = ({
  organization,
}: {
  organization: string;
}) => {
  const url = `/topology/v1/organization?organization=${organization}`;
  return api.delete(url);
};

export const deleteSite = ({
  organization,
  site,
}: {
  organization: string;
  site: string;
}) => {
  const url = `/topology/v1/site?organization=${organization}&site=${site}`;
  return api.delete(url);
};
export const deleteEndpoint = ({
  organization,
  site,
  endpoint,
}: {
  organization: string;
  site: string;
  endpoint: string;
}) => {
  const url = `/topology/v1/endpoint?organization=${organization}&site=${site}&endpoint=${endpoint}`;
  return api.delete(url);
};
export const getAllOrganizationNames = () => {
  return api.get<ApiResponseType<string[]>>(
    '/topology/v1/metadata/organization/name',
  );
};
