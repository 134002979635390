import React, { useContext } from 'react';
import { StyledTabs, StyledTab } from './styles';
import { AppContext } from 'src/context';
import { Box } from '@mui/material';

interface TabsProps {
  value: string;
  action: (selectedTab: string) => void;
  options: Array<{
    label: string;
    value: string;
    styles?: React.CSSProperties;
  }>;
}

export const Tabs: React.FC<TabsProps> = ({ value, action, options }) => {
  const { sidebarOpen } = useContext(AppContext);
  return (
    <Box
      sx={{
        marginLeft: sidebarOpen ? '250px' : '60px',
      }}
    >
      <StyledTabs value={value} onChange={(_, tab) => action(tab)}>
        {options.map((option, index) => (
          <StyledTab
            key={option.value}
            label={option.label}
            value={option.value}
          />
        ))}
      </StyledTabs>
    </Box>
  );
};
