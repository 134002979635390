import { Box } from '@mui/system';
import { DocumentUnknown } from '@carbon/icons-react';
import { Typography } from '@mui/material';

import { styles } from './styles';

interface NoDataProps {
  message: string;
  minHeight?: string;
}

export const NoData: React.FC<NoDataProps> = ({ message, minHeight }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      sx={{ ...styles.container, ...(minHeight && { minHeight }) }}
    >
      <DocumentUnknown color="gray" size={32} />
      <Typography color="gray" mt={1}>
        {message}
      </Typography>
    </Box>
  );
};
