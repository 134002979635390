import React, { useContext, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { Main } from 'src/layouts/Main';
import { RuleDetailsHeader } from './RuleDetailsHeader';
import { QueryName } from 'src/enums/query';
import { RuleTab } from './RuleTab';
import { Loading, Tabs } from 'src/components';
import { getRulesInfo } from 'src/apis/rule';
import { EventLogs } from './EventLogs';
import { AppContext, ToastContext } from 'src/context';
import { Breadcrumbs } from 'src/components/Breadcrumbs/index';

const menuOption = [
  {
    label: 'EVENT LOGS',
    value: 'Event',
  },
  {
    label: 'RULE',
    value: 'Rule',
  },
];

export const RuleDetails: React.FC = () => {
  const { organization, site, id } = useParams<{
    organization: string;
    site: string;
    id: string;
  }>();
  const { siteChanged, site: selectedSite } = useContext(AppContext);
  const navigate = useNavigate();
  const [readMoreShown, setReadMoreShown] = useState(false);
  const { showToast } = useContext(ToastContext);

  const [params, setParams] = useSearchParams({ filter: 'DAY' });

  const [selectedTab, setSelectedTab] = useState('Event');

  const handleChange = (tab: string) => {
    setSelectedTab(tab);
  };

  const setShowMore = (moreInfo: boolean) => {
    moreInfo ? setReadMoreShown(true) : setReadMoreShown(false);
  };

  const { data, isLoading, isFetching, isError } = useQuery(
    [QueryName.GET_RULE_DETAIL, params.get('filter'), site],
    () =>
      getRulesInfo(
        organization as string,
        site as string,
        id as string,
        params.get('filter') ?? 'DAY',
      ),
  );

  useEffect(() => {
    if (siteChanged) {
      navigate(`/rules/${organization}/${selectedSite}/${id}`);
    }
    if (isError) {
      showToast('Unable to get the rule details!', 'error');
      navigate('/rules');
    }
  }, [siteChanged, selectedSite, isError]);

  return (
    <>
      <Main>
        <Box>
          <Loading isLoading={isLoading || isFetching}>
            {data?.data.configuration && (
              <><Breadcrumbs breadcrumbs={[
                {
                  title: 'Overview',
                  url: '/',
                },
                {
                  title: 'Rules',
                  url: '/rules',
                },
                {
                  title: data?.data.configuration.name,
                },
              ]}/>
                <RuleDetailsHeader
                ruleConfiguration={data?.data.configuration}
                showMore={setShowMore} /></>
            )}
          </Loading>
        </Box>
      </Main>
      {!isError && !isLoading && (
        <>
          <Tabs
            value={selectedTab}
            action={(tab) => {
              handleChange(tab);
            }}
            options={menuOption}
          />
          {selectedTab === 'Event' ? (
            <EventLogs
              selectedFilter={params.get('filter') ?? 'DAY'}
              trendData={data.data.trendData}
              executionData={data.data.executionData}
              recentEventLogs={data.data.eventLogs}
              onFilterChange={(filter) => setParams({ filter })}
            />
          ) : (
            <RuleTab
              ruleConfiguration={data?.data?.configuration}
              readMoreShown={readMoreShown}
            />
          )}
        </>
      )}
    </>
  );
};
