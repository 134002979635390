import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { UsersForPolicy } from './SelectUsers';

interface PieChartProps {
  selectedUsers: UsersForPolicy[];
  allocationPerUser: number;
}
export const PieChart: React.FC<PieChartProps> = ({
  selectedUsers,
  allocationPerUser,
}) => {
  const options: Highcharts.Options = {
    chart: {
      height: 240,
      width: 240,
      backgroundColor: undefined,
    },
    credits: {
      enabled: false,
    },
    accessibility: {
      enabled: false,
    },
    title: {
      text: '',
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        allowPointSelect: false,
        cursor: 'default',
        slicedOffset: 0,
        color: '#006161',
        dataLabels: {
          enabled: false,
        },
        states: {
          inactive: {
            enabled: false,
          },
          hover: {
            brightness: 0,
            enabled: false,
          },
        },
      },
    },
    series: [
      {
        type: 'pie',
        name: 'Usage',
        data: [
          {
            selected: true,
            sliced: true,
            name: 'used data',
            y: 0,
            color: '#9FBCBC',
          },
          {
            name: 'allocated data',
            y: selectedUsers.length * allocationPerUser,
            color: '#006161',
          },
        ],
      },
    ],
  };
  return <HighchartsReact highcharts={Highcharts} options={options} />;
};
