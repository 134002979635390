import { IconButton, List, Tooltip, Box } from '@mui/material';
import { useCallback, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  ChartLineData,
  Map,
  IntentRequestCreate,
  Events,
  IotPlatform,
} from '@carbon/icons-react';
import { ReactComponent as Logo } from 'src/assets/NDO-logo.svg';
import { ReactComponent as LogoCollapsed } from 'src/assets/NDO-logo-collapsed.svg';
import { ReactComponent as Collapse } from 'src/assets/collapse.svg';
import { ReactComponent as Expand } from 'src/assets/expand.svg';
import { AppContext } from 'src/context';
import {
  StyeledDrawerHeader,
  stylesSidebar,
  Drawer,
  StyeledDrawerFooter,
} from './styles';
import { colors } from 'src/config/theme';
import { useUserRole } from 'src/utils/user';
import { TooltipItem } from './TooltipItem';

export const Sidebar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { sidebarOpen, toggleSidebar } = useContext(AppContext);
  const { isSuperUser, isAdminUser, isReadyOnlyUser } = useUserRole();
  const defaultMenu = [
    {
      label: 'Consumption',
      path: 'consumption',
      icon: (
        <ChartLineData
          size={20}
          color={
            location.pathname.includes('consumption')
              ? colors.secondary
              : colors.black
          }
        />
      ),
      action: () => navigate('/consumption/site?criteria=LATENCY&range=DAY'),
      role: ['ADMIN', 'READ_ONLY'],
    },
    {
      label: 'Geomap',
      path: 'geomap',
      icon: (
        <Map
          size={20}
          color={
            location.pathname.includes('geomap')
              ? colors.secondary
              : colors.black
          }
        />
      ),
      action: () => navigate('/geomap?criteria=LATENCY'),
      role: ['ADMIN', 'READ_ONLY'],
    },
    {
      label: 'Rules',
      path: 'rules',
      icon: (
        <IntentRequestCreate
          size={20}
          color={
            location.pathname.includes('rules')
              ? colors.secondary
              : colors.black
          }
        />
      ),
      action: () => navigate('/rules'),
      role: ['ADMIN', 'READ_ONLY'],
    },
    {
      label: 'Device management',
      path: 'devices',
      icon: (
        <IotPlatform
          size={20}
          color={
            location.pathname.includes('devices')
              ? colors.secondary
              : colors.black
          }
        />
      ),
      action: () => navigate('/devices'),
      role: ['ADMIN'],
    },
    {
      label: 'Manage network',
      path: 'networks',
      icon: (
        <IotPlatform
          size={20}
          color={
            location.pathname.includes('networks')
              ? colors.secondary
              : colors.black
          }
        />
      ),
      action: () => navigate('/networks'),
      role: ['SUPER_USER'],
    },
    {
      label: 'User management',
      path: 'users',
      icon: (
        <Events
          size={20}
          color={
            location.pathname.includes('users')
              ? colors.secondary
              : colors.black
          }
        />
      ),
      action: () => navigate('/users'),
      role: ['SUPER_USER', 'ADMIN'],
    },
  ];

  const getUserMenu = useCallback(() => {
    if (isSuperUser()) {
      return [
        ...defaultMenu.filter((item) => item.role.includes('SUPER_USER')),
      ];
    } else if (isAdminUser()) {
      return [...defaultMenu.filter((item) => item.role.includes('ADMIN'))];
    } else if (isReadyOnlyUser()) {
      return [...defaultMenu.filter((item) => item.role.includes('READ_ONLY'))];
    }
  }, [defaultMenu, isAdminUser, isReadyOnlyUser, isSuperUser]);
  return (
    <Drawer variant='permanent' open={sidebarOpen}>
      <StyeledDrawerHeader>
        <IconButton onClick={toggleSidebar} sx={stylesSidebar.toggleBtn}>
          {sidebarOpen && (
            <Tooltip title='Collapse menu'>
              <Collapse fill={colors.secondary} height={12} />
            </Tooltip>
          )}
          {!sidebarOpen && (
            <Tooltip title='Expand menu'>
              <Expand fill={colors.secondary} height={12} />
            </Tooltip>
          )}
        </IconButton>
      </StyeledDrawerHeader>
      <Box
        sx={{
          ...(sidebarOpen && stylesSidebar.logoWrapper),
          ...(!sidebarOpen && stylesSidebar.logoWrapperCollapsed),
        }}
      >
        {sidebarOpen && <Logo fill={colors.secondary} height={50} />}
        {!sidebarOpen && <LogoCollapsed fill={colors.secondary} height={50} />}
      </Box>
      <List sx={stylesSidebar.navItemsWrapper}>
        {getUserMenu()?.map((item, index) => (
          <TooltipItem key={index} item={item} sidebarOpen={sidebarOpen} />
        ))}
      </List>
      {sidebarOpen && (
        <StyeledDrawerFooter>
          <p> Powered by Zinkworks</p>
        </StyeledDrawerFooter>
      )}
    </Drawer>
  );
};
