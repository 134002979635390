import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import makeStyles from '@mui/styles/makeStyles';
import {
  Box,
  Button,
  TextField,
  InputAdornment,
  Alert,
  Collapse,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Visibility, VisibilityOff, AccountCircle } from '@mui/icons-material';

import { AuthLayout } from 'src/layouts';
import { ReactComponent as Logo } from 'src/assets/NDO-logo.svg';
import { routes } from 'src/routes/routes';
import { loginValidationSchema } from 'src/yup-validations/LoginValidation';
import { AuthContext } from 'src/context';

const useStyles = makeStyles(() => ({
  root: {
    padding: '0 30px',
    borderRadius: '10px',
    width: '350px',
    backgroundColor: 'white',
    margin: '20px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

type FormData = {
  email: string;
  password: string;
};

export const Login: React.FC = () => {
  const { authState, handleLogin } = useContext(AuthContext);
  const classes = useStyles();

  const [showPassword, setShowPassword] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<FormData>({
    resolver: yupResolver(loginValidationSchema),
    shouldFocusError: true,
    mode: 'onChange',
  });

  const handleShowPassword = () => setShowPassword((show) => !show);

  const onSubmit = ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    handleLogin(email, password);
  };

  return (
    <AuthLayout>
      <Box className={classes.root} boxShadow={12}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          margin={3}
        >
          <Logo fill="#00D4B1" height={60} />
        </Box>
        <Collapse in={!!authState.error}>
          <Box mb={1}>
            <Alert severity="error">{authState.error}</Alert>
          </Box>
        </Collapse>
        <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
          <Box mb={1}>
            <TextField
              fullWidth
              placeholder="E-mail"
              {...register('email')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Box mr={1} mt={1}>
                      <AccountCircle />
                    </Box>
                  </InputAdornment>
                ),
              }}
              helperText={isDirty && errors?.email?.message}
              error={!!errors?.email}
            />
          </Box>
          <Box mb={1}>
            <TextField
              fullWidth
              type={showPassword ? 'text' : 'password'}
              placeholder="Password"
              {...register('password')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Box
                      mr={1}
                      mt={1}
                      sx={{ cursor: 'pointer' }}
                      onClick={handleShowPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </Box>
                  </InputAdornment>
                ),
              }}
              helperText={isDirty && errors?.password?.message}
              error={!!errors?.password}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'spaceBetween',
              marginBottom: '10px',
            }}
          >
            <Link to={routes.forgotPassword}>Forgot password?</Link>
          </Box>
          <Button
            disabled={authState.loggingIn}
            size="large"
            variant="contained"
            color="primary"
            type="submit"
          >
            Login
          </Button>
          <p>
            Do not have account? <Link to={routes.register}>Sign up</Link>
          </p>
        </form>
      </Box>
    </AuthLayout>
  );
};
