import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Chip, ChipProps } from '@mui/material';

export interface StyledChipProps extends Omit<ChipProps, 'color'> {
  kind: 'primary-ghost' | 'danger-ghost' | 'default-ghost';
  label?: string;
}

const getCSS = ({ kind }: StyledChipProps) => {
  switch (kind) {
    case 'primary-ghost':
      return css`
        .MuiChip-label,
        .MuiChip-icon {
          color: #00a410;
          font-weight: 700;
        }
        background-color: transparent;
        border: 1px solid #00a410;
        border-radius: 1px;
      `;
    case 'danger-ghost':
      return css`
        .MuiChip-label,
        .MuiChip-icon {
          color: #f20000;
          font-weight: 700;
        }
        border: 1px solid #f20000;
        background-color: transparent;
        border-radius: 1px;
      `;
    case 'default-ghost':
      return css`
        .MuiChip-label {
          color: #888888;
        }
        border: 1px solid #888888;
        background-color: transparent;
        border-radius: 1px;
      `;
    default:
      return css``;
  }
};

export const StyledChip = styled(Chip)<StyledChipProps>`
  & {
    border-radius: 1px;
    font-size: 12px;
    height: 22px;
    line-height: 1.143;
    text-transform: capitalize;
    padding-right: 0px;
    .MuiChip-label {
      text-align: center;
      text-transform: uppercase;
    }
    .MuiChip-icon {
      font-size: large;
      color: #888888;
    }
    ${getCSS}
  }
`;
