import { api } from 'src/utils/axios';
import { Policy } from 'src/types/policy';
import { ApiResponseType } from 'src/types/api';

export const createUsagePolicy = ({
  organization,
  site,
  policyName,
  allocationPerUser,
  userIds,
}: {
  organization: string;
  site: string;
  policyName: string;
  allocationPerUser: number;
  userIds: string[];
}) => {
  const url = '/policymanagement/v1/policy';
  return api.post(url, {
    organization,
    site,
    policyName,
    allocationPerUser,
    userIds,
  });
};

export const deleteUsagePolicy = ({
  organization,
  site,
  policyId,
}: {
  organization: string;
  site: string;
  policyId: string;
}) => {
  return api.delete('/policymanagement/v1/policy', {
    params: {
      organization,
      site,
      policyId,
    },
  });
};
export const getAllPolicies = (organization: string, site: string) => {
  return api.get<ApiResponseType<{ total: number; policies: Policy[] }>>(
    '/policymanagement/v1/policy/list',
    {
      params: {
        organization,
        site,
      },
    },
  );
};

export const getUsagePolicyDetails = (
  organization: string,
  site: string,
  policyId: string,
) => {
  return api.get<ApiResponseType>('/policymanagement/v1/policy', {
    params: {
      organization,
      site,
      policyId,
    },
  });
};
